/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { observer } from 'mobx-react'
import { PlusIcon } from '@heroicons/react/24/outline'
import { PlusCircleIcon } from '@heroicons/react/24/solid'
import dayjs from 'dayjs'
import _ from 'lodash'
import { twMerge as mergeClassNames } from 'tailwind-merge'

// Components
import { Button } from '../../components/Button'
import { DataTable } from '../../components/DataTable'
import { EventHeader } from '../../components/EventHeader'
import { Modal } from '../../components/Modal'
import { PurchaseLicensesModal } from '../../components/PurchaseLicensesModal'
import { Select } from '../../components/Select'
import { StateContainer } from '../../components/StateContainer'
import { AddUserModal } from '../../components/AddUserModal'

// Images
import Edit from '../../assets/images/editCircle.svg'

// Stores
import { NavigationStoreContext } from '../../stores/NavigationStore'

// Service
import {
  getLicenses,
  updateLicense,
  updateLicenseAndCreateUser,
} from '../../services/licenses.service'
import { getOrganizationUsers } from '../../services/organizations.service'
import { createTransaction } from '../../services/purchases.service'

// Utils
import { handlePagination, toast } from '../../utils/helpers'
import { getChargeData, getPricingData } from '../../components/PurchaseLicensesModal/helpers'

const DEFAULT = {
  assignee: '',
}

/**
 *
 * ExhibitorEventLicenses
 *
 */
const ExhibitorEventLicenses = observer(() => {
  // Context
  const { event, eventId, organizationId } = useContext(NavigationStoreContext)

  // State
  const [loading, setLoading] = useState(true)
  const [loadingUsers, setLoadingUsers] = useState(false)
  const [licenses, setLicenses] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [users, setUsers] = useState([])
  const [licenseToUpdate, setLicenseToUpdate] = useState(null)
  const [showUserModal, setShowUserModal] = useState(false)
  const [loadingUser, setLoadingUser] = useState(false)
  const [reassignUser, setReassignUser] = useState(false)
  const [updatingLicense, setUpdatingLicense] = useState(false)
  const [showLicensesModal, setShowLicensesModal] = useState(false)
  const [loadingPurchase, setLoadingPurchase] = useState(false)

  // Pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [totalRows, setTotalRows] = useState(0)
  const [perPage, setPerPage] = useState(20)
  const [pages, setPages] = useState(null)

  const handleErrors = (m) => toast(m, 'error')
  const handleSuccesses = (m) => toast(m, 'success')

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: DEFAULT,
  })

  /**
   * Gets the updated list of licenses; updates pagination.
   * @param {string} url
   */
  const getUpdatedLicenses = async (url) => {
    const response = await getLicenses(url, handleErrors, setLoading)

    if (response) {
      setTotalRows(response.count)
      setPages({ next: response.next, previous: response.previous })
      setLicenses(response.results)
    }
  }

  /**
   * Gets the updated list of organization users.
   * @param {string} url
   */
  const getUpdatedUserList = async (url) => {
    const response = await getOrganizationUsers(url, handleErrors, setLoadingUsers, () => {})

    if (response) {
      // Filter out users that have already been assigned to a license, unless they are assigned to a license where isRefunded is true.
      const assignableUsers = _.filter(response.results, (role) => {
        const assignedUser = _.find(licenses, (license) =>
          license.assignee ? license.assignee.id === role.id && !license.isRefunded : false,
        )
        return !assignedUser
      })

      setUsers(
        assignableUsers.map((user) => ({
          label: `${user.user.firstName} ${user.user.lastName}`,
          id: `${user.id}`,
        })),
      )
    }
  }

  /**
   * Handles submitting the assign license form.
   * @param {object} data
   */
  const onSubmit = async (data) => {
    await updateLicense(
      eventId,
      event.eventExhibitor.id,
      licenseToUpdate.id,
      {
        assignee: data.assignee ? data.assignee.id : null,
      },
      handleErrors,
      setUpdatingLicense,
      (m) => {
        handleSuccesses(m)
        getUpdatedLicenses(
          `/events/${eventId}/exhibitors/${event.eventExhibitor.id}/licenses/?limit=${perPage}&expand=assignee`,
        )
        setReassignUser(false)
        setShowModal(false)
        reset(DEFAULT)
      },
    )
  }

  /**
   * Handles submitting the add user form.
   * @param {object} data
   */
  const userModalOnSubmit = async (data) => {
    const updatedData = {
      newUserFirstName: data.firstName,
      newUserLastName: data.lastName,
      newUserEmail: data.email,
      newUserRole: data.role,
    }

    await updateLicenseAndCreateUser(
      eventId,
      event.eventExhibitor.id,
      licenseToUpdate.id,
      updatedData,
      handleErrors,
      setLoadingUser,
      async (m) => {
        await getUpdatedLicenses(
          `/events/${eventId}/exhibitors/${event.eventExhibitor.id}/licenses/?limit=${perPage}&expand=assignee`,
        )
        handleSuccesses(m)
        setShowUserModal(false)
      },
    )
  }

  const submitPurchasingForm = async (data) => {
    const pricingInfo = getPricingData(event)
    const chargeData = await getChargeData(
      data,
      pricingInfo,
      licenses.filter((license) => !license.isFreeLicense && !license.isRefunded).length,
    )

    await createTransaction(
      eventId,
      event.eventExhibitor.id,
      chargeData,
      handleErrors,
      setLoadingPurchase,
      () => {
        handleSuccesses('Licenses purchased successfully')
        setShowLicensesModal(false)
        getUpdatedLicenses(
          `/events/${eventId}/exhibitors/${event.eventExhibitor.id}/licenses/?limit=${perPage}&expand=assignee`,
        )
      },
    )
  }

  /**
   * When the row count changes, get the updated licenses list.
   */
  useEffect(() => {
    if (event?.eventExhibitor && event?.eventExhibitor.id) {
      getUpdatedLicenses(
        `/events/${eventId}/exhibitors/${event.eventExhibitor.id}/licenses/?limit=${perPage}&expand=assignee`,
      )
    }
  }, [event?.eventExhibitor, perPage])

  const clearForm = () => {
    reset(DEFAULT)
  }

  /**
   * Configures the assignee options for the select.
   *
   * Since we remove users that have already been assigned to a license,
   * we need to add the current assignee back to the list to ensure it can be unassigned.
   * @returns {array} An array of users that can be assigned to a license.
   */
  const configureAssigneeOptions = () => {
    if (licenseToUpdate.assignee) {
      return _.concat(users, {
        label: `${licenseToUpdate.assignee.user.firstName} ${licenseToUpdate.assignee.user.lastName}`,
        id: `${licenseToUpdate.assignee.id}`,
      })
    }

    return users
  }

  /**
   * Renders the license assignee or a button to assign a license.
   * @param {object} assignee
   * @param {object} license
   */
  const renderAssignee = (assignee, license) => {
    if (!assignee) {
      return (
        <button
          type="button"
          className="space-between flex items-center gap-1 font-medium text-black"
          onClick={async () => {
            await getUpdatedUserList(`/organizations/${organizationId}/roles/?expand=user`)
            setLicenseToUpdate(license)
            setShowModal(true)
          }}
        >
          <PlusCircleIcon className="h-5 fill-purple" />
          Assign license
        </button>
      )
    }

    return (
      <div className="space-between flex items-center gap-4">
        <span className={mergeClassNames('text-gray-500', license.isRefunded && 'line-through')}>
          {assignee.user.firstName} {assignee.user.lastName}
        </span>
        {assignee.user.isInvited && assignee.user.lastLogin === null && (
          <div className="rounded-full bg-status-blue px-2.5 py-0.5">
            <span className="text-xs font-bold text-status-darkBlue">Invite Pending</span>
          </div>
        )}
        {dayjs().isBefore(dayjs(event.startsAt)) && !license.isRefunded && (
          <button
            className="px-2 py-0.5 font-bold text-purple hover:rounded-full hover:bg-status-purple disabled:opacity-50"
            type="button"
            onClick={async () => {
              await getUpdatedUserList(`/organizations/${organizationId}/roles/?expand=user`)

              reset({
                assignee: {
                  label: `${assignee.user.firstName} ${assignee.user.lastName}`,
                  id: assignee.id,
                },
              })

              setLicenseToUpdate(license)
              setReassignUser(true)
              setShowModal(true)
            }}
          >
            Reassign
          </button>
        )}
      </div>
    )
  }

  return (
    <div className="h-full w-full">
      <StateContainer>
        <div className="relative flex h-full w-full flex-col space-y-3 overflow-y-auto p-3">
          <EventHeader event={event} />
          <div className="mb-4 flex w-full flex-row place-items-center justify-between">
            <span className="text-md font-semibold">Licenses</span>

            <Button
              background="bg-purple border-purple hover:bg-purple-600"
              icon={<PlusIcon className="h-5 fill-white sm:h-6" />}
              label="Purchase Additional Licenses"
              onClick={() => setShowLicensesModal(true)}
            />
          </div>
          <div className="grid w-full">
            <DataTable
              columns={[
                {
                  id: 'name',
                  grow: 2,
                  name: 'Licenses ID',
                  selector: (row) => row.id,
                  cell: (row) => (
                    <>
                      <span
                        className={mergeClassNames(
                          'font-medium text-black',
                          row.isRefunded && 'text-gray-500',
                        )}
                      >
                        {row.licenseId}
                      </span>
                      {row.isRefunded && (
                        <span className="ml-4 rounded-2xl bg-blue-300 px-3 py-1 text-xs font-bold text-info">
                          Refunded
                        </span>
                      )}
                    </>
                  ),
                  minWidth: '300px',
                },
                {
                  id: 'staff-assigned',
                  grow: 1,
                  name: 'Staff Assigned',
                  selector: (row) => row.assignee,
                  cell: (row) => renderAssignee(row.assignee, row),
                  minWidth: '300px',
                },
              ]}
              data={licenses}
              defaultSortFieldId="dates"
              defaultSortAsc
              onChangePage={(page) =>
                handlePagination(
                  page,
                  currentPage,
                  perPage,
                  totalRows,
                  pages,
                  setCurrentPage,
                  getUpdatedLicenses,
                  `/events/${eventId}/exhibitors/${event.eventExhibitor.id}/licenses/?expand=assignee&limit=`,
                )
              }
              onChangeRowsPerPage={async (currentRowsPerPage) => setPerPage(currentRowsPerPage)}
              pagination
              paginationPerPage={perPage}
              paginationRowsPerPageOptions={[5, 10, 15, 20, 30, 50]}
              paginationTotalRows={totalRows}
              paginationServer
              progressPending={loading}
              sortServer
            />
          </div>
        </div>
      </StateContainer>

      <Modal
        actions={[
          {
            type: 'cancel',
            label: 'Cancel',
            onClick: () => {
              reset(DEFAULT)
              setReassignUser(false)
            },
          },
          {
            type: 'submit',
            label: reassignUser ? 'Reassign' : 'Assign',
            onClick: handleSubmit(onSubmit),
          },
        ]}
        icon={
          reassignUser ? (
            <img src={Edit} alt="Reassign" />
          ) : (
            <PlusIcon className="h-5 stroke-white sm:h-6" />
          )
        }
        content={
          <div className="mt-3 flex flex-col space-y-4 text-center sm:mt-5">
            <span className="text-left font-nunito font-medium">
              You can reassign licenses until: {dayjs(event?.startsAt).format('M/D/YYYY')}
            </span>

            <Controller
              name="assignee"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  className="rounded-2xl border-gray-550 py-2.5 pr-4  placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                  disabled={loading || users.length === 0}
                  error={errors.assignee && 'This field is required'}
                  fullWidth
                  hoverElement={
                    <Button
                      label="Unassign"
                      onClick={clearForm}
                      className="border-transparent bg-transparent py-0 leading-none hover:bg-transparent sm:py-0"
                    />
                  }
                  id="assignee"
                  name="assignee"
                  nunito
                  onChange={onChange}
                  options={configureAssigneeOptions()}
                  label=""
                  placeholder={users.length > 0 ? 'Select a Staff Member' : 'No users to display'}
                  reset={clearForm}
                  style={{ flex: true, width: '100%' }}
                  value={value}
                />
              )}
            />

            <button
              type="button"
              className="space-between flex items-center gap-1 font-nunito font-medium text-black"
              onClick={() => {
                setShowModal(false)
                setShowUserModal(true)
              }}
            >
              <PlusCircleIcon className="h-5 fill-purple" />
              Create a New User
            </button>
          </div>
        }
        loading={loadingUsers || updatingLicense}
        onClose={() => {
          setShowModal(false)
          reset(DEFAULT)
          setReassignUser(false)
        }}
        open={showModal}
        setOpen={setShowModal}
        title={reassignUser ? 'Reassign License' : 'Assign License'}
      />

      {showUserModal && (
        <AddUserModal
          endOfFormContent={`You can reassign licenses until: ${dayjs(event.startsAt).format(
            'M/D/YYYY',
          )}`}
          loadingUser={loadingUser}
          onSubmit={userModalOnSubmit}
          roles={{
            allowRoleChange: true,
            key: 'role',
            options: [
              {
                label: 'Admin',
                description:
                  'Admins can access all Events and manage Qualifiers and Materials. Admins can also add, edit, and delete other Users.',
              },
              {
                label: 'Booth Staff',
                description:
                  'Booth Staff will only have access to Events they are assigned Licenses for Booth Staff can be managed from the Event License page.',
              },
            ],
            showRoleDescriptions: true,
          }}
          setEditUser={() => {}}
          showRoleDescriptions
          showUserModal={showUserModal}
          setShowUserModal={setShowUserModal}
        />
      )}

      {showLicensesModal && (
        <PurchaseLicensesModal
          event={event}
          loading={loadingPurchase}
          onSubmit={submitPurchasingForm}
          showModal={showLicensesModal}
          setShowModal={setShowLicensesModal}
          licenses={licenses}
        />
      )}
    </div>
  )
})

export default ExhibitorEventLicenses
