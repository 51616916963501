/* eslint-disable indent */
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import _ from 'lodash'
import { ArrowPathIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { twMerge as mergeClassNames } from 'tailwind-merge'
import GoogleFontLoader from 'react-google-font'
import dayjs from 'dayjs'
import { getAuth, onAuthStateChanged, signInWithCustomToken } from 'firebase/auth'

// Components
import { AdminOverride } from './AdminOverride'
import { BadgePreview } from '../../components/BadgePreview'
import { Button } from '../../components/Button'
import { CogIcon } from '../../components/CogIcon'
import { KioskPopups } from '../../components/KioskPopups'
import { Modal } from '../../components/Modal'
import { QRIcon } from '../../components/QRIcon'
import { Select } from '../../components/Select'
import { SettingsModal } from './SettingsModal'
import { ScanIcon } from '../../components/ScanIcon'
import { Staff } from './Staff'
import { StateContainer } from '../../components/StateContainer'
import { TextInput } from '../../components/TextInput'

// Images
import BadgerTextLogo from '../../assets/images/badgr_text_logo.svg'
import PoweredByBadger from '../../assets/images/powered_by_badger_pill.svg'

// Store
import { KioskStoreContext } from '../../stores/KioskStore'
import { NavigationStoreContext } from '../../stores/NavigationStore'
import { UserStoreContext } from '../../stores/UserStore'

// Service
import {
  getAttendee,
  getAttendees,
  getAttendeesFiltered,
  getBadgeConfiguration,
  getBadgeImages,
  getEvent,
  getGlobalBrandSettings,
  getKioskConfiguration,
  getRegistrationSyncData,
  signInWithFirebase,
} from '../../services/firestore/firestore.service'
import {
  getEventKioskKPIs,
  getKioskConfiguration as getApiKioskConfiguration,
} from '../../services/kiosks.service'
import { getGlobalCustomFont } from '../../services/settings.service'
import { refreshAccessToken } from '../../services/user.service'

// Utils & Style
import { toast } from '../../utils/helpers'
import { getGlobalFontsFromBadge } from '../EventBadgeBuilder/helpers'
import useFonts from '../../hooks/Fonts'

const EventKiosk = observer(() => {
  // Context
  const navigate = useNavigate()
  const {
    eventId,
    globalBrandSettings,
    location,
    mode,
    name,
    resetKiosk,
    setGlobalBrandSettings,
    setKPIs,
    startNewKiosk,
  } = useContext(KioskStoreContext)
  const { event: storedEvent, organizationId } = useContext(NavigationStoreContext)
  const { offlineToken, refreshToken, setCurrentTokens } = useContext(UserStoreContext)

  // State
  const [authenticated, setAuthenticated] = useState(false)
  const [showStartKioskModal, setShowStartKioskModal] = useState(
    eventId === null || eventId !== storedEvent.id,
  )
  const [loading, setLoading] = useState(false)
  const [deviceLocations, setDeviceLocations] = useState([])
  const [startingKiosk, setStartingKiosk] = useState(false)
  const [attendeeError, setAttendeeError] = useState(false)
  const [error, setError] = useState(null)
  const [showPasswordModal, setShowPasswordModal] = useState(false)
  const [kioskStyles, setKioskStyles] = useState({
    header: '',
    footer: '',
    backgroundColor: '#E4E9F0',
    fontColor: '#111827',
  })
  const [filteredAttendees, setFilteredAttendees] = useState([])
  const [search, setSearch] = useState('')
  const [registrationSyncData, setRegistrationSyncData] = useState(null)

  // Check-In
  const [configuration, setConfiguration] = useState(null)
  const [badgeConfiguration, setBadgeConfiguration] = useState(null)
  const [badgeImages, setBadgeImages] = useState(null)
  const [attendeeToCheckIn, setAttendeeToCheckIn] = useState(null)
  const [customFonts, setCustomFonts] = useState([])
  const [customFontUrls, setCustomFontUrls] = useState([])
  const [googleFonts, setGoogleFonts] = useState([])
  const [printAttendee, setPrintAttendee] = useState(null)
  const [printAttendees, setPrintAttendees] = useState(null)
  const [showSettings, setShowSettings] = useState(false)

  // Scanning
  const [capturedScanValue, setCapturedScanValue] = useState(null)
  let scannerTimeout = null
  let capturedScan = ''

  // Ref
  const searchRef = useRef()

  useFonts(customFontUrls)

  const handleErrors = (m) => toast(m, 'error')

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm({
    defaultValues: {
      deviceLocation: null,
      deviceName: null,
    },
  })

  /**
   * Handles capturing the value from a scan event.
   * @param {object} event
   */
  const handleScan = ({ target, key }) => {
    // If the target is an input, do not capture the scan
    if (target.tagName.toUpperCase() === 'INPUT') return

    // Skip special keys
    if (key === 'Shift' || key === 'Clear' || key === 'Meta') {
      return
    }

    // Append key to captured scan
    capturedScan += key

    // If no timeout started, this is the first key we are capturing.
    // We should set a timeout to store the captured value and clear
    // the captured value and timeout after 1 second.
    if (!scannerTimeout) {
      scannerTimeout = setTimeout(() => {
        // If the captured scan is longer than 20 characters, store it.
        if (capturedScan.length > 20) {
          setCapturedScanValue(capturedScan)
        }

        capturedScan = ''
        scannerTimeout = null
      }, 1000)
    }
  }

  const addLog = (m) => console.log(`(Kiosk) [${dayjs().format('HH:mm:ss')}] ${m}`)

  /**
   * Configures the kiosk styles based on the provided configuration objects.
   * @param {object} firestoreConfiguration
   * @param {object} apiConfiguration
   * @param {object} globalConfiguration
   */
  const configureKioskStyles = (firestoreConfiguration, apiConfiguration, globalConfiguration) => {
    const styles = {
      header: '',
      footer: '',
      backgroundColor: '#ffffff',
      fontColor: '#111827',
    }

    // If we aren't using the default brand, and we successfully retrieved the API kiosk configuration
    // Use the API header and footer
    if (!firestoreConfiguration?.useDefaultBrand && apiConfiguration) {
      styles.header = `url("${apiConfiguration.signedHeaderImage}"`
      styles.footer = `url("${apiConfiguration.signedFooterImage}"`
    }
    // Otherwise, use the global settings configuration if we recieved it
    else if (firestoreConfiguration?.useDefaultBrand && globalConfiguration) {
      styles.header = `url(data:image/png;base64,${globalConfiguration.headerBase64}`
      styles.footer = `url(data:image/png;base64,${globalConfiguration.footerBase64}`
    }

    // Set remaining styles based on whether we are using default branding or not
    if (firestoreConfiguration?.useDefaultBrand && globalConfiguration) {
      styles.backgroundColor = globalConfiguration.backgroundColor
      styles.fontColor = globalConfiguration.fontColor
    } else if (!firestoreConfiguration?.useDefaultBrand) {
      styles.backgroundColor = firestoreConfiguration.backgroundColor
      styles.fontColor = firestoreConfiguration.fontColor
    }

    setKioskStyles(styles)
  }

  useEffect(() => {
    // Sign into firebase and get event data
    const authenticateWithFirebase = async () => {
      setLoading(true)
      addLog('Authenticating with Firebase...')

      // Sign into firestore
      const signedIn = await signInWithFirebase(
        offlineToken,
        refreshToken,
        setCurrentTokens,
        handleErrors,
      )

      if (signedIn) {
        setAuthenticated(true)

        addLog('Authenticated with Firebase. Kicking off data pulls for prefetching..')

        // Start prefetch event data and attendees
        getAttendees(storedEvent.id)
        getEvent(storedEvent.id)

        addLog('Started prefetch for event data and attendees. Starting full data pull..')

        const [globalSettings, registrationSync, config, apiConfig, badgeConfig, images] =
          await Promise.all([
            getGlobalBrandSettings(handleErrors),
            getRegistrationSyncData(storedEvent.id),
            getKioskConfiguration(storedEvent.id),
            getApiKioskConfiguration(storedEvent.id, storedEvent.kioskConfiguration.id, false),
            getBadgeConfiguration(storedEvent.id),
            getBadgeImages(storedEvent.id),
          ])
        if (globalSettings) setGlobalBrandSettings(globalSettings)
        if (registrationSync) setRegistrationSyncData(registrationSync)
        if (badgeConfig) setBadgeConfiguration(badgeConfig)
        if (images) setBadgeImages(images)
        if (config) {
          setConfiguration(config)

          // Configure kiosk styles
          configureKioskStyles(config, apiConfig, globalSettings)
        }

        addLog(
          'Pulled global settings, registration sync data, kiosk configuration, badge configuration and badge images. Starting font pulls..',
        )

        // Pre-load badge fonts and kiosk configuration font
        const [badgeGoogleFonts, badgeCustomFonts] = getGlobalFontsFromBadge(
          badgeConfig?.defaultFontGoogle || badgeConfig?.defaultFontCustom,
          badgeConfig?.configObjects,
        )

        if (config?.googleFont) {
          badgeGoogleFonts.push({ font: config.googleFont, weights: ['regular', 'italic'] })
        }
        setGoogleFonts(_.uniq(badgeGoogleFonts))

        if (config?.customFont) {
          badgeCustomFonts.push(config.customFont)
        }
        setCustomFonts(_.uniq(badgeCustomFonts))

        // Get kiosk device locations
        if (config?.kioskDeviceLocations) {
          setDeviceLocations(
            _.map(config.kioskDeviceLocations, (d) => ({ id: d.id, label: d.name })),
          )
        }

        setLoading(false)

        // If the event ID is not set or does not match the current event ID, reset the kiosk
        if (eventId === null || eventId !== storedEvent.id) {
          resetKiosk()
        }
      } else {
        handleErrors('Error authenticating with kiosk...')
        setLoading(false)
        navigate(`/organization/${organizationId}/event/${storedEvent.id}/check-in/settings`)
      }
    }

    addLog('Starting Kiosk...')

    authenticateWithFirebase()
  }, [])

  /**
   * Handles signing in the user again if the auth state changes.
   * @param user
   */
  const handleAuthChange = async (user) => {
    // If no `user`, we need to sign in again
    if (!user) {
      // Try to refresh tokens
      const tokens = await refreshAccessToken(refreshToken)

      if (tokens) {
        setCurrentTokens(tokens)

        const auth = getAuth()
        await signInWithCustomToken(auth, tokens.offline)
      } else {
        handleErrors('Error authenticating with kiosk...')
        navigate(`/organization/${organizationId}/event/${storedEvent.id}/check-in/settings`)
      }
    }
  }

  useEffect(() => {
    let unsubscribe

    if (authenticated) {
      const auth = getAuth()
      unsubscribe = onAuthStateChanged(auth, handleAuthChange)
    }

    return () => {
      if (unsubscribe) unsubscribe()
    }
  }, [authenticated])

  useEffect(() => {
    // Get all updated custom font data
    if (customFonts.length > 0 && customFontUrls.length === 0) {
      addLog('Starting data pull for custom fonts...')
      const fonts = []

      Promise.all(_.map(customFonts, (f) => getGlobalCustomFont(f, setError, () => {}))).then(
        (data) => {
          _.forEach(data, (d) => {
            fonts.push(d)
          })

          setCustomFontUrls(fonts)

          addLog('Pulled custom fonts.')
        },
      )
    }
  }, [customFonts])

  /**
   * Set up poll for badging KPIs
   * - Gets updated KPIs every 5 minutes
   * - Clears polling when leaving the kiosk
   */
  useEffect(() => {
    let interval

    if (interval === null && eventId) {
      const setUpKpiPoll = async () => {
        let response = await getEventKioskKPIs(eventId)
        setKPIs(response, new Date())

        interval = setInterval(async () => {
          response = await getEventKioskKPIs(eventId)
          if (response) setKPIs(response, new Date())
        }, 300000) // 5 minutes
      }

      setUpKpiPoll()
    }

    return () => {
      if (interval) clearInterval(interval)
    }
  }, [eventId])

  useEffect(() => {
    if (!showStartKioskModal) {
      // Set up listeners for scan events
      document.addEventListener('keydown', handleScan)
    }

    return () => {
      document.removeEventListener('keydown', handleScan)
    }
  }, [showStartKioskModal])

  useEffect(() => {
    if (capturedScanValue) {
      const getAttendeeFromScan = async () => {
        // Get attendee from firestore
        const response = await getAttendee(storedEvent.id, capturedScanValue)
        if (response) {
          setAttendeeToCheckIn(response)
        } else {
          setAttendeeError(true)
        }

        // Clear captured scan value
        setCapturedScanValue(null)
      }

      getAttendeeFromScan()
    }
  }, [capturedScanValue])

  /**
   * Gets attendees from firestore filtered by `searchTerm` and stores in state.
   * @param {string} searchTerm
   */
  const getFilteredAttendees = async (searchTerm) => {
    const response = await getAttendeesFiltered(storedEvent.id, searchTerm)
    setFilteredAttendees(response)
  }

  const filterAttendees = useCallback(_.debounce(getFilteredAttendees, 150), [])

  /**
   * Handles starting a new kiosk.
   * @param {string} kioskMode
   * @param {object} data
   */
  const handleStartNewKiosk = async (kioskMode, data) => {
    addLog(
      'Starting new kiosk: pulling event, registration sync data, badge configuration, badge images, kiosk configuration and pulling attendees..',
    )

    // Pull necessary data from firestore
    setStartingKiosk(true)
    const [
      firestoreEvent,
      globalSettings,
      registrationSync,
      badgeConfig,
      images,
      kioskConfiguration,
      apiConfig,
    ] = await Promise.all([
      getEvent(storedEvent.id),
      getGlobalBrandSettings(() => {}),
      getRegistrationSyncData(storedEvent.id),
      getBadgeConfiguration(storedEvent.id),
      getBadgeImages(storedEvent.id),
      getKioskConfiguration(storedEvent.id),
      getApiKioskConfiguration(storedEvent.id, storedEvent.kioskConfiguration.id, false),
      getAttendees(storedEvent.id),
    ])
    setStartingKiosk(false)

    addLog('Pulled all necessary data, verifying results and starting kiosk..')

    if (badgeConfig === null) {
      setError('Missing badge configuration.')
    } else if (_.some([firestoreEvent, kioskConfiguration], _.isNil)) {
      setError('Error pre-loading data from firestore. Please try again.')
    } else {
      // Start a new kiosk
      startNewKiosk(storedEvent.id, data.deviceLocation, kioskMode, data.deviceName)

      setRegistrationSyncData(registrationSync)
      setConfiguration(kioskConfiguration)
      setBadgeConfiguration(badgeConfig)
      setBadgeImages(images)

      // Configure kiosk styles
      configureKioskStyles(kioskConfiguration, apiConfig, globalSettings)

      const [badgeGoogleFonts, badgeCustomFonts] = getGlobalFontsFromBadge(
        badgeConfig.defaultFontGoogle || badgeConfig?.defaultFontCustom,
        badgeConfig.configObjects,
      )
      if (kioskConfiguration?.googleFont) {
        badgeGoogleFonts.push({
          font: kioskConfiguration.googleFont,
          weights: ['regular', 'italic'],
        })
      }
      setGoogleFonts(_.uniq(badgeGoogleFonts))

      if (kioskConfiguration?.customFont) {
        badgeCustomFonts.push(kioskConfiguration.customFont)
      }
      setCustomFonts(_.uniq(badgeCustomFonts))

      // Close the modal
      setShowStartKioskModal(false)
      addLog('Kiosk started!')
    }
  }

  const renderStartKioskModal = () => {
    if (error) {
      return (
        <div className="flex flex-col items-center justify-center space-y-2">
          <span className="text-lg font-bold text-red">
            {error.includes('kiosk') ? 'Error Starting Kiosk' : 'Error loading Settings'}
          </span>
          <span className="text-sm text-red-500">{error}</span>

          <Button
            background="bg-white"
            label="Exit Kiosk Mode"
            onClick={() =>
              navigate(`/organization/${organizationId}/event/${storedEvent.id}/check-in/settings`)
            }
          />
        </div>
      )
    }

    if (loading) {
      return (
        <div className="flex flex-col items-center justify-center space-y-2">
          <span className="text-lg font-bold">
            {startingKiosk ? 'Starting Kiosk...' : 'Loading Settings...'}
          </span>

          <span className="flex items-center pr-3">
            <div className="h-5 w-5">
              {/* eslint-disable-next-line tailwindcss/no-custom-classname, tailwindcss/classnames-order */}
              <svg className="h-5 w-5 motion-safe:animate-spin-slow" viewBox="0 0 40 40">
                <ArrowPathIcon className="h-5 w-5" aria-hidden="true" />
              </svg>
            </div>
          </span>
        </div>
      )
    }

    return (
      <div className="flex flex-col">
        <span className="text-xl font-semibold">Kiosk Settings</span>

        <div className="mt-3 flex w-full flex-row gap-6">
          <div className="flex w-full flex-col space-y-3">
            <Controller
              name="deviceLocation"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  className="rounded-lg border-gray-400 py-2.5 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                  data-testid="deviceLocation"
                  disabled={startingKiosk}
                  error={errors.deviceLocation && 'This field is required'}
                  id="deviceLocation"
                  label="Device Location"
                  onChange={(v) => {
                    onChange(v)
                  }}
                  options={deviceLocations}
                  placeholder="Select a Device Location"
                  style={{ flex: true, width: '100%' }}
                  value={value}
                />
              )}
              rules={{ required: true }}
            />

            <TextInput
              className="rounded-lg border-gray-400 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
              data-testid="deviceName"
              disabled={startingKiosk}
              error={errors.deviceName && 'This field is required'}
              fullWidth
              nunito
              label="Device Name"
              placeholder="Enter Device Name"
              {...register('deviceName', {
                required: true,
              })}
            />
          </div>

          <div className="flex w-full flex-col justify-between">
            <Button
              background="bg-purple border-purple hover:bg-purple-600"
              label="Launch Attendee Mode"
              loading={startingKiosk}
              onClick={handleSubmit((data) => handleStartNewKiosk('attendee', data))}
            />

            <Button
              className="border-black bg-black hover:bg-black-light"
              label="Launch Staff Mode"
              loading={startingKiosk}
              onClick={handleSubmit((data) => handleStartNewKiosk('staff', data))}
            />

            <Button
              background="bg-white"
              label="Exit Kiosk Mode"
              onClick={() =>
                navigate(
                  `/organization/${organizationId}/event/${storedEvent.id}/check-in/settings`,
                )
              }
            />
          </div>
        </div>
      </div>
    )
  }

  const configureFont = () => {
    if (configuration?.useDefaultBrand && globalBrandSettings?.googleFont) {
      return globalBrandSettings.googleFont
    }

    if (configuration?.googleFont) {
      return configuration.googleFont
    }

    if (configuration?.customFont && customFontUrls.length > 0) {
      const custom = _.find(customFontUrls, (f) => f.id === configuration.customFont)
      return custom ? `'${custom.name}'` : 'Inter'
    }

    return 'Inter'
  }

  return (
    <div
      className="absolute inset-0"
      style={{
        backgroundColor: kioskStyles.backgroundColor,
      }}
    >
      <StateContainer>
        <div
          className={mergeClassNames('relative h-full w-full', showStartKioskModal && 'hidden')}
          style={{
            fontFamily: configureFont(),
            color: kioskStyles.fontColor,
          }}
        >
          <button
            className="absolute left-0 top-1/4 z-20 flex cursor-pointer items-center justify-center rounded-r-xl bg-white p-3 shadow-md"
            onClick={() => setShowPasswordModal(true)}
            type="button"
          >
            <CogIcon className="h-8 fill-black" />
          </button>

          <div className="relative flex h-full flex-col items-center justify-between">
            <div
              className="flex h-[150px] w-full items-center justify-center bg-cover bg-center bg-no-repeat"
              style={{
                backgroundImage: kioskStyles.header,
                backgroundColor: '#0D0B0D', // Fallback color
              }}
            >
              {configuration?.useDefaultBrand && (
                <span className="text-4xl font-bold text-white">{storedEvent.name}</span>
              )}
            </div>

            {mode === 'staff' ? (
              <div className="absolute inset-y-[14%] z-10 flex h-[70%] w-[80%] flex-col self-center rounded-2xl bg-white px-6 py-4 shadow-lg">
                <Staff
                  configuration={configuration}
                  handleBulkPrint={setPrintAttendees}
                  event={storedEvent}
                  registrationSyncData={registrationSyncData}
                  registrationSyncEnabled={storedEvent.enableRegistrationSync}
                  setAttendeeToCheckIn={setAttendeeToCheckIn}
                />
              </div>
            ) : (
              <div className="absolute top-[18%] z-10 h-[65%] rounded-2xl bg-white p-6 shadow-lg">
                <div className="flex h-full items-center">
                  <div className="flex basis-1/2 justify-center px-16">
                    <div className="flex max-w-[465px] flex-col items-center">
                      <div className="mb-2 text-center text-4xl font-bold">
                        Have your QR code ready?
                      </div>
                      <span className="mb-6 text-center text-2xl font-bold">
                        Scan your QR code with Scanner.
                      </span>
                      <ScanIcon className="h-25 fill-black" />
                    </div>
                  </div>

                  <span className="h-full w-[1px] bg-black"></span>

                  <div className="flex basis-1/2 justify-center px-16">
                    <div className="flex max-w-[465px] flex-col items-center gap-4">
                      <div className="text-center text-2xl font-bold">
                        No QR code? Search for your registration below.
                      </div>

                      <div className="relative w-full">
                        <TextInput
                          className="rounded-lg border-gray-400 py-2.5 pl-10 pr-4 text-inherit placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                          labelStyles="text-inherit"
                          fullWidth
                          icon={
                            <MagnifyingGlassIcon
                              className="ml-2 h-5 text-gray-dark"
                              aria-hidden="true"
                            />
                          }
                          nunito
                          onChange={async (e) => {
                            setSearch(e.target.value)
                            if (e.target.value.length > 1) {
                              await filterAttendees(e.target.value)
                            } else {
                              setFilteredAttendees([])
                            }
                          }}
                          label="Search"
                          placeholder="Enter your First Name, Last Name, or Email"
                          ref={searchRef}
                          search
                        />

                        {search.length > 1 && (
                          <div className="absolute z-10 max-h-[200px] w-full overflow-y-auto rounded-lg bg-white shadow-lg">
                            {filteredAttendees?.length > 0 ? (
                              filteredAttendees.map((attendee) => (
                                <div
                                  key={attendee.id}
                                  className="flex w-full items-center justify-between border-b border-gray-400 px-4 py-2"
                                >
                                  <div className="flex flex-col">
                                    <span className="text-sm font-medium">
                                      {attendee.firstName} {attendee.lastName}
                                    </span>
                                    <span className="text-sm font-normal text-gray-600">
                                      {attendee.companyName}
                                    </span>
                                  </div>

                                  <Button
                                    background="bg-purple"
                                    label="That's Me!"
                                    onClick={() => {
                                      setAttendeeToCheckIn(attendee)
                                      setSearch('')
                                      searchRef.current.value = ''
                                    }}
                                    size="sm"
                                  />
                                </div>
                              ))
                            ) : (
                              <div className="w-full p-4 text-center">No results found.</div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="absolute bottom-2 left-4 flex items-center gap-2">
                  <QRIcon className="h-4 fill-black" />
                  {name}
                </div>

                {!configuration?.useDefaultBrand && (
                  <span className="absolute bottom-2 right-4">
                    <img src={PoweredByBadger} className="h-12" alt="" />
                  </span>
                )}
              </div>
            )}

            <div
              className="flex h-[150px] w-full items-center justify-start bg-cover bg-center bg-no-repeat pl-12"
              style={{
                backgroundImage: kioskStyles.footer,
                backgroundColor: '#0D0B0D', // Fallback color
              }}
            >
              {configuration?.useDefaultBrand && !kioskStyles.footer && (
                <img src={BadgerTextLogo} className="h-12" alt="" />
              )}
            </div>
          </div>
        </div>
      </StateContainer>

      {showStartKioskModal && (
        <Modal actions={null} content={renderStartKioskModal()} open={showStartKioskModal} />
      )}

      {!showStartKioskModal && attendeeError && (
        <Modal
          actions={[
            {
              type: 'cancel',
              label: 'Cancel',
              onClick: () => setAttendeeError(null),
            },
          ]}
          content={
            <div className="mt-4 flex flex-col items-center">
              <span className="text-center text-2xl font-bold">Error Getting Attendee Data</span>

              <p className="my-4 text-center text-gray">
                Please see a staff member for assistance.
              </p>
            </div>
          }
          icon={<ExclamationTriangleIcon className="h-6 w-6 stroke-white" />}
          iconBackground="bg-red"
          open
        />
      )}

      {showSettings && (
        <SettingsModal
          categories={_.sortBy(_.keys(registrationSyncData?.attendeeCategories))}
          show
          setShow={setShowSettings}
        />
      )}

      {showPasswordModal && (
        <AdminOverride
          configuration={configuration}
          submit={() => {
            reset({
              deviceLocation: location,
              deviceName: name,
            })

            setShowPasswordModal(false)
            setShowSettings(true)
          }}
          setShow={setShowPasswordModal}
        />
      )}

      {attendeeToCheckIn && (
        <KioskPopups
          attendee={attendeeToCheckIn}
          badgeImages={badgeImages}
          categories={registrationSyncData?.attendeeCategories}
          clearAttendee={setAttendeeToCheckIn}
          configuration={configuration}
          eventId={storedEvent.id}
          handlePrintBadge={setPrintAttendee}
          locationId={location ? location.id : ''}
          mode={mode}
          name={name}
        />
      )}

      {googleFonts && googleFonts.length > 0 && <GoogleFontLoader fonts={googleFonts} />}

      {(printAttendee || printAttendees) && (
        <BadgePreview
          attendee={printAttendee}
          attendees={printAttendees}
          badgeImages={badgeImages}
          categories={registrationSyncData?.attendeeCategories}
          config={badgeConfiguration}
          endPreview={() => {
            setPrintAttendee(null)
            setPrintAttendees(null)
          }}
          kiosk
        />
      )}
    </div>
  )
})

export default EventKiosk
