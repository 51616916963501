import React, { Fragment, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import {
  ArrowUpTrayIcon,
  PlusIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import _ from 'lodash'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { Menu, MenuButton, MenuItems } from '@headlessui/react'

// Images
import Inbox from '../../assets/images/inbox.svg'

// Files
import template from '../../assets/files/attendee_import_template.xlsx'

// Components
import { Button } from '../../components/Button'
import { DataTable } from '../../components/DataTable'
import { DownloadIcon } from '../../components/DownloadIcon'
import { EventHeader } from '../../components/EventHeader'
import { FileUploader } from '../../components/FileUploader'
import { Modal } from '../../components/Modal'
import { StateContainer } from '../../components/StateContainer'
import { SyncExternalAttendeeModal } from '../../components/SyncExternalAttendeeModal'
import { TextInput } from '../../components/TextInput'
import { Tooltip } from '../../components/Tooltip'

// Store
import { NavigationStoreContext } from '../../stores/NavigationStore'
import { TaskStoreContext } from '../../stores/TaskStore'
import { UserStoreContext } from '../../stores/UserStore'

// Service
import {
  createAttendeeSync,
  getAttendees,
  getAttendeeSyncStatus,
  uploadAttendeeList,
} from '../../services/attendees.service'
import { getOrganizationApiOptions } from '../../services/organizations.service'

// Utils & Styles
import { formatDuration } from '../../utils/formatters'
import { handlePagination, toast } from '../../utils/helpers'
import colors from '../../utils/colors'
import AttendeeModal from './AttendeeModal'

const CATEGORY_COLORS = [
  { background: colors.purple.DEFAULT, label: colors.white.DEFAULT },
  { background: colors.teal.DEFAULT, label: colors.white.DEFAULT },
  { background: colors.blue.DEFAULT, label: colors.white.DEFAULT },
  { background: colors.purple[900], label: colors.white.DEFAULT },
  { background: colors.teal[900], label: colors.white.DEFAULT },
  { background: colors.blue[900], label: colors.white.DEFAULT },
  { background: colors.purple.light, label: colors.gray.dark },
  { background: colors.teal.light, label: colors.gray.dark },
  { background: colors.blue.light, label: colors.gray.dark },
  { background: colors.purple.dark, label: colors.white.DEFAULT },
  { background: colors.teal.dark, label: colors.white.DEFAULT },
  { background: colors.blue.dark, label: colors.white.DEFAULT },
]

const BUTTON_STYLES =
  'font-bold text-blue-600 hover:rounded-full hover:bg-status-blue px-2 py-0.5 disabled:cursor-not-allowed disabled:opacity-50'

/**
 *
 * EventAttendees
 *
 */
const EventAttendees = observer(() => {
  // Context
  const { task, setParent, setTask, setType } = useContext(TaskStoreContext)
  const { event, attendeeSyncTask, setAttendeeSyncTask } = useContext(NavigationStoreContext)
  const { isEEUser, isOrganizationAdmin, user } = useContext(UserStoreContext)
  const { eventId, orgId } = useParams()

  const readOnly = event ? event.enableRegistrationSync : false

  // State
  const [loading, setLoading] = useState(false)
  const [registrationOptions, setRegistrationOptions] = useState([])
  const [loadingAttendees, setLoadingAttendees] = useState(false)
  const [attendees, setAttendees] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [filter, setFilter] = useState(null)
  const [showAttendeeModal, setShowAttendeeModal] = useState(false)
  const [editAttendee, setEditAttendee] = useState(null)
  const [showImportModal, setShowImportModal] = useState(false)
  const [loadingImport, setLoadingImport] = useState(false)
  const [uploadFile, setUploadFile] = useState(null)
  const [importedFile, setImportedFile] = useState(null)
  const [categories, setCategories] = useState([])

  // Sync
  const [loadingSync, setLoadingSync] = useState(false)
  const [pingSync, setPingSync] = useState(false)
  const [singleAttendeeSyncs, setSingleAttendeeSyncs] = useState([])
  const [showSyncExternalAttendeeModal, setShowSyncExternalAttendeeModal] = useState(false)

  // Pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [totalRows, setTotalRows] = useState(0)
  const [perPage, setPerPage] = useState(20)
  const [pages, setPages] = useState(null)

  // Ref
  const searchInputRef = useRef(null)

  const BASE_URL = `/events/${eventId}/attendees/?expand=sessions&`

  const handleErrors = (m) => toast(m, 'error')
  const handleSuccesses = (m) => toast(m, 'success')

  /**
   * Gets the updated list of attendees; updates pagination.
   * @param {string} url
   * @returns list of results
   */
  const getUpdatedAttendeeList = async (url) => {
    const response = await getAttendees(url, handleErrors, setLoadingAttendees, () => {})

    if (response) {
      setTotalRows(response.count)
      setPages({ next: response.next, previous: response.previous })
      setAttendees(response.results)
    }
  }

  useEffect(() => {
    const getUpdatedData = async () => {
      const optionsResponse = await getOrganizationApiOptions(orgId, handleErrors, setLoading)

      if (optionsResponse) {
        setRegistrationOptions(
          _.map(_.keys(optionsResponse), (system) => ({
            label: _.capitalize(system),
            id: system,
            type: optionsResponse[system],
          })),
        )
      }
    }

    if (event?.enableRegistrationSync) {
      getUpdatedData()
    }

    // Set up the sync ping if there is still one in progress
    if (attendeeSyncTask && !pingSync) setPingSync(true)

    // Configure category options
    const categoryOptions = _.map(_.keys(event?.attendeeCategories), (k) => ({
      id: k,
      label: _.capitalize(event.attendeeCategories[k]),
    }))
    setCategories(_.sortBy(categoryOptions))
  }, [])

  useEffect(() => {
    if (task && task.status === 'Imported') {
      getUpdatedAttendeeList(`${BASE_URL}limit=${perPage}`)
    }
  }, [task])

  useEffect(() => {
    let interval = null

    if (pingSync) {
      if (interval === null) {
        interval = setInterval(async () => {
          const response = await getAttendeeSyncStatus(eventId, attendeeSyncTask.id)

          if (response.status !== 'Pending' && response.status !== 'Processing') {
            clearInterval(interval)
            setAttendeeSyncTask(null)
            setPingSync(false)

            // Clear any searches
            filterAttendees('')
            setSearchTerm('')
            searchInputRef.current.value = ''
          } else {
            setAttendeeSyncTask(response)
          }
        }, 5000)
      }
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [pingSync])

  /**
   * When the filter or row count changes, get the updated list of attendees.
   */
  useEffect(() => {
    if (filter) {
      getUpdatedAttendeeList(`${BASE_URL}limit=${perPage}&${filter}`)
    } else {
      getUpdatedAttendeeList(`${BASE_URL}limit=${perPage}`)
    }
  }, [filter, perPage])

  /**
   * Updates the search query based on `search`.
   * @param {string} search
   */
  const updateSearch = (search) => {
    let updatedFilter = ''
    if (search) updatedFilter = `q=${search}`

    setFilter(updatedFilter)
  }

  const filterAttendees = useCallback(_.debounce(updateSearch, 500), [])

  /**
   * Handles triggering a registration sync.
   */
  const triggerRegistrationSync = async (payload, singleAttendee = false) => {
    const response = await createAttendeeSync(
      eventId,
      payload,
      handleErrors,
      setLoadingSync,
      () => {},
    )

    if (response) {
      if (!singleAttendee) {
        setAttendeeSyncTask(response)
        setPingSync(true)
        handleSuccesses('Attendee sync triggered.')
      } else {
        setSingleAttendeeSyncs((prev) => [...prev, response])
        handleSuccesses('Attendee sync triggered. Refresh the page to see updates.')
      }
    }
  }

  const sortedCategoryNames = _.sortBy(_.values(event?.attendeeCategories))
  const columns = [
    {
      id: 'name',
      grow: 1,
      name: 'Name',
      selector: (row) => `${row.firstName} ${row.lastName}`,
      cell: (row) => (
        <div className="flex flex-col">
          <span className="text-sm font-bold text-dark">
            {row.firstName} {row.lastName}
          </span>

          <span className="text-xs text-gray-600">{row.email}</span>
        </div>
      ),
      sortable: true,
      sortBy: 'first_name',
    },
    {
      id: 'category',
      grow: 0.5,
      name: 'Category',
      selector: (row) => row.categoryName,
      cell: (row) => {
        if (!event?.attendeeCategories || !row.categoryId) return null

        const category = event?.attendeeCategories[row.categoryId]
        const index = _.findIndex(sortedCategoryNames, (k) => k === category)

        let displayColors
        if (index === -1) {
          displayColors = { background: colors.gray.light, label: colors.gray.dark }
        } else {
          displayColors = CATEGORY_COLORS[index] || {
            background: colors.gray.light,
            label: colors.gray.dark,
          }
        }

        return (
          <div className="flex w-full items-center justify-center">
            <span
              className="rounded-full px-2.5 py-1 text-xs font-medium"
              style={{ backgroundColor: displayColors.background, color: displayColors.label }}
            >
              {_.capitalize(category) || 'No Category'}
            </span>
          </div>
        )
      },
      center: true,
      sortable: true,
      sortBy: 'category_name',
    },
    {
      id: 'companyName',
      grow: 1,
      name: 'Company/Title',
      selector: (row) => row.companyName,
      cell: (row) => (
        <div className="flex flex-col">
          {!row.companyName && !row.title ? (
            <span className="text-xs text-gray-600">N/A</span>
          ) : (
            <>
              <span className="text-sm font-bold text-dark">{row.companyName}</span>

              <span className="text-xs text-gray-600">{row.title}</span>
            </>
          )}
        </div>
      ),
      sortable: true,
      sortBy: 'company_name',
    },
    {
      id: 'resync',
      grow: 0.5,
      name: '',
      cell: (row) => {
        const matchingSyncTask = _.find(
          singleAttendeeSyncs,
          (t) => t.externalId === row.externalId,
        )

        let { lastSyncedAt } = row
        if (lastSyncedAt) {
          lastSyncedAt = dayjs(lastSyncedAt).format('MM/DD/YYYY HH:mm A')
        }

        return (
          <div className="flex flex-col items-center justify-center gap-1">
            <button
              className={BUTTON_STYLES}
              disabled={!!matchingSyncTask}
              type="button"
              onClick={() => {
                triggerRegistrationSync(
                  {
                    syncType: 'Attendee - Single',
                    externalId: row.externalId,
                  },
                  true,
                )
              }}
            >
              {matchingSyncTask ? 'Resyncing...' : 'Resync'}
            </button>

            {lastSyncedAt && (
              <span className="text-xs text-gray-600">Last Synced: {lastSyncedAt}</span>
            )}
          </div>
        )
      },
      minWidth: '250px',
      center: true,
      omit: !event?.enableRegistrationSync || !isEEUser,
    },
    {
      id: 'view/edit',
      grow: 0.25,
      name: '',
      cell: (row) => (
        <button
          className={BUTTON_STYLES}
          disabled={row.id === user.id}
          type="button"
          onClick={() => {
            setEditAttendee(row)
            setShowAttendeeModal(true)
          }}
        >
          {readOnly ? 'View' : 'Edit'}
        </button>
      ),
      center: true,
      omit: !isOrganizationAdmin(orgId) || !isEEUser,
    },
  ]

  const renderFileUploader = () => {
    if (uploadFile) {
      return (
        <div className="flex flex-col">
          <FileUploader
            acceptedFileTypes={[
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            ]}
            autoSave
            handleUploadToServer={async (file) => {
              setImportedFile(file)
              setUploadFile(null)
            }}
            id="file"
            maxFiles={1}
            type={null}
          />

          <div className="-mt-4 mr-2 flex flex-row place-items-center justify-between">
            <span className="text-xs italic text-gray-500">
              Upload a single file that is .xlsx or .xlsm
            </span>

            <button className="place-self-end" type="button" onClick={() => setUploadFile(null)}>
              <span className="text-xs">Cancel Upload</span>
            </button>
          </div>
        </div>
      )
    }

    return (
      <div className="flex flex-row items-center space-x-4 self-center">
        <span className="text-sm text-gray-500">
          {importedFile ? importedFile.name : 'Upload File'}
        </span>

        <Button
          background="bg-white"
          icon={<img src={Inbox} alt="Upload" className="h-5" />}
          label={importedFile ? 'Change' : 'Upload'}
          onClick={() => setUploadFile(true)}
          outlined
        />
      </div>
    )
  }

  const hasCompletedRegistrationConfig = () => {
    if (!event || !event.registrationType) return false
    if (!event.externalEventId) return false

    const registrationType = _.find(registrationOptions, (o) => o.label === event.registrationType)
    if (registrationType === undefined) return false
    if (registrationType.type === 'API Key' && !event.externalAccountId && !event.apiKey)
      return false
    if (
      registrationType.type === 'OAuth 2.0 Client Credentials' &&
      (!event.clientId || !event.clientSecret)
    )
      return false
    return true
  }

  return (
    <div className="h-full w-full">
      <StateContainer loading={loading}>
        <div className="h-full w-full flex-col space-y-3 overflow-y-auto p-3">
          <div className="mb-3 flex flex-col items-start justify-between space-y-1 sm:flex-row sm:items-center sm:space-y-0">
            <EventHeader event={event} />

            <div className="flex flex-row items-center space-x-1 sm:flex-col sm:items-end sm:space-y-1">
              {!readOnly && isOrganizationAdmin(orgId) && isEEUser && (
                <Button
                  background="bg-purple border-purple hover:bg-purple-600"
                  icon={<ArrowUpTrayIcon className="h-5 stroke-white sm:h-5" />}
                  label="Import Attendees"
                  onClick={() => setShowImportModal(true)}
                />
              )}

              {readOnly && isEEUser && hasCompletedRegistrationConfig() && (
                <div className="flex flex-col space-y-1">
                  <Tooltip
                    content={
                      <div className="rounded-lg bg-white px-2 pb-0.5">
                        <span className="text-xs">Sync is in progress.</span>
                      </div>
                    }
                    display={attendeeSyncTask}
                    fullWidth
                    placement="left"
                  >
                    <Menu>
                      <div className="flex flex-row gap-[1px]">
                        <Button
                          background="bg-purple border-purple hover:bg-purple-600 rounded-r-none"
                          disabled={attendeeSyncTask}
                          fullWidth
                          loading={loadingSync}
                          label="Resync Attendee Updates"
                          onClick={() =>
                            triggerRegistrationSync({ syncType: 'Attendees - Updates Only' })
                          }
                        />

                        <MenuButton className="rounded-r-md bg-purple px-1 hover:bg-purple-600">
                          <ChevronDownIcon className="h-5 stroke-white" />
                        </MenuButton>
                      </div>

                      <MenuItems
                        anchor="bottom end"
                        className="w-52 rounded-md bg-white [--anchor-gap:2px]"
                      >
                        <Button
                          background="bg-purple border-purple right-0 hover:bg-purple-600 rounded-b-none mb-[1px]"
                          disabled={attendeeSyncTask || event?.disableSyncAllButton}
                          fullWidth
                          loading={loadingSync}
                          label="Resync All Attendees"
                          onClick={() =>
                            triggerRegistrationSync({ syncType: 'Attendees - All Time' })
                          }
                        />

                        <Button
                          background="bg-purple border-purple right-0 hover:bg-purple-600 rounded-t-none"
                          fullWidth
                          label="Sync External Attendee"
                          onClick={() => setShowSyncExternalAttendeeModal(true)}
                        />
                      </MenuItems>
                    </Menu>
                  </Tooltip>

                  {event.attendeesLastSyncedAt && !event.attendeeSyncStatusMessage && (
                    <span className="self-end text-xs">
                      Last Updated {dayjs(event.attendeesLastSyncedAt).format('MM/DD/YYYY h:mm A')}
                    </span>
                  )}

                  {event?.currentSyncInterval && (
                    <span className="self-end text-xs italic">
                      Syncing every {formatDuration(event.currentSyncInterval)}.
                    </span>
                  )}

                  {event.attendeesSyncStatusMessage && (
                    <span className="text-xs text-error">{event.attendeesSyncStatusMessage}</span>
                  )}
                </div>
              )}

              {event && event.attendeeListModifiedAt && (
                <span className="text-xs font-semibold">
                  Last {readOnly ? 'Sync' : 'Import'}:{' '}
                  {dayjs(event.attendeeListModifiedAt).format('MM/DD/YYYY h:mm A')}
                </span>
              )}
            </div>
          </div>

          <span className="text-md font-bold">Attendees</span>

          <div className="flex w-full flex-row items-center justify-between">
            <TextInput
              className="w-full rounded-full py-2.5 pl-10 pr-4 placeholder:font-normal placeholder:text-gray-600 md:w-[450px]"
              icon={<MagnifyingGlassIcon className="ml-2 h-5 text-gray-dark" aria-hidden="true" />}
              id="search"
              endIcon={
                searchTerm ? (
                  <button
                    type="button"
                    onClick={() => {
                      filterAttendees('')
                      setSearchTerm('')
                      searchInputRef.current.value = ''
                    }}
                  >
                    <XMarkIcon className="mr-2 h-5 text-gray-dark" aria-hidden="true" />
                  </button>
                ) : null
              }
              name="search"
              onChange={(e) => {
                filterAttendees(e.target.value)
                setSearchTerm(e.target.value)
              }}
              placeholder="Looking for something?"
              ref={searchInputRef}
              value={searchTerm}
            />

            {!readOnly && isOrganizationAdmin(orgId) && isEEUser && (
              <Button
                background="bg-purple border-purple hover:bg-purple-600"
                icon={<PlusIcon className="h-5 sm:h-6" />}
                label="Add Attendee"
                onClick={() => setShowAttendeeModal(true)}
              />
            )}
          </div>

          <DataTable
            columns={columns}
            data={attendees}
            defaultSortFieldId="name"
            defaultSortAsc
            onChangePage={(page) =>
              handlePagination(
                page,
                currentPage,
                perPage,
                totalRows,
                pages,
                setCurrentPage,
                getUpdatedAttendeeList,
                `${BASE_URL}limit=`,
                filter,
              )
            }
            onChangeRowsPerPage={async (currentRowsPerPage) => setPerPage(currentRowsPerPage)}
            onSort={(column, direction) => {
              const d = direction === 'asc' ? '' : '-'
              const url = `${BASE_URL}order_by=${d}${column.sortBy}&limit=${perPage}`
              getUpdatedAttendeeList(url)
            }}
            pagination
            paginationPerPage={perPage}
            paginationRowsPerPageOptions={[10, 15, 20, 30, 50]}
            paginationTotalRows={totalRows}
            paginationServer
            progressPending={loadingAttendees}
            sortServer
          />
        </div>
      </StateContainer>

      {showAttendeeModal && (
        <AttendeeModal
          attendee={editAttendee}
          availableCustomFields={event?.availableCustomFields}
          categories={categories}
          eventId={eventId}
          enabledCustomFields={event?.enabledCustomFields}
          enableRegistrationSync={event?.enableRegistrationSync}
          onClose={(refresh = false) => {
            setEditAttendee(null)
            setShowAttendeeModal(false)

            if (refresh) getUpdatedAttendeeList(`${BASE_URL}limit=${perPage}`)
          }}
          sessions={event?.sessions}
        />
      )}

      <Modal
        actions={[
          {
            type: 'cancel',
            label: 'Cancel',
            onClick: () => {
              setUploadFile(null)
              setImportedFile(null)
            },
          },
          {
            type: 'submit',
            label: 'Import',
            disabled: !importedFile,
            onClick: async () => {
              const response = await uploadAttendeeList(
                eventId,
                { file: importedFile.file },
                handleErrors,
                setLoadingImport,
              )

              if (response) {
                // Clear & close the modal
                setUploadFile(null)
                setImportedFile(null)
                setShowImportModal(false)

                // Set up the task to watch
                setType('Upload')
                setParent({ type: 'attendee-import', id: response.event })
                setTask(response)
              }
            },
          },
        ]}
        icon={<DownloadIcon className="h-5 stroke-white sm:h-6" />}
        content={
          <div className="mt-3 flex flex-col space-y-4 text-center sm:mt-5">
            <Button
              background="bg-green-50"
              className="self-center"
              label="Download Template"
              onClick={() => {
                // Download the template
                const downloadLink = document.createElement('a')
                downloadLink.href = template
                downloadLink.download = 'Executivevents Attendee Import Template.xlsx'
                downloadLink.target = '_blank'
                downloadLink.click()
                downloadLink.remove()
              }}
            />

            {renderFileUploader()}
          </div>
        }
        loading={loadingImport}
        onClose={() => {
          setShowImportModal(false)
        }}
        open={showImportModal}
        setOpen={setShowImportModal}
        title="Import"
      />

      {showSyncExternalAttendeeModal && (
        <SyncExternalAttendeeModal
          eventId={eventId}
          onClose={(refresh = false) => {
            setShowSyncExternalAttendeeModal(false)

            if (refresh) getUpdatedAttendeeList(`${BASE_URL}limit=${perPage}`)
          }}
        />
      )}
    </div>
  )
})

export default EventAttendees
