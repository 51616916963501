/* eslint-disable import/no-cycle */
import { createContext } from 'react'
import { action, computed, observable } from 'mobx'
import * as Sentry from '@sentry/react'
import _ from 'lodash'

// Store
import { BaseStore } from './BaseStore'
import { navigation } from './NavigationStore'

// Service & Utils
import { getUser, updateUser } from '../services/user.service'

// Utils
import { getErrorMessage } from '../utils/helpers'

const ROLE_RANKS = {
  Admin: 1,
  Member: 2,
}

const INITIAL_STATE = {
  accessToken: '',
  refreshToken: '',
  offlineToken: '',
  user: {},
  loading: false,
  lastUpdated: null,
  error: null,
}

const TYPES = {
  accessToken: observable,
  refreshToken: observable,
  offlineToken: observable,
  user: observable,
  loading: observable,
  error: observable,
  lastUpdated: observable,
  isEEUser: computed,
  isAuthenticated: computed,
}

export class UserStore extends BaseStore {
  get isAuthenticated() {
    return this.accessToken !== ''
  }

  get isEEUser() {
    if (this.user && this.user.id) {
      return this.user.eeRole !== null && this.user.eeRole !== '' && this.user.eeRole !== undefined
    }
    return null
  }

  get isEEAdmin() {
    if (this.user && this.user.id) {
      return this.user.eeRole === 'Admin'
    }
    return null
  }

  get hasOrganizationRole() {
    return (organizationId) => {
      if (this.isEEAdmin) return true
      const match = _.find(
        this.user.organizationRoles,
        (o) => o.organization.id === organizationId,
      )
      return match || false
    }
  }

  get isOrganizationAdmin() {
    return (organizationId) => {
      if (this.isEEAdmin) return true

      const organizationRole = _.find(
        this.user.organizationRoles,
        (o) => o.organization.id === organizationId,
      )
      if (organizationRole) return organizationRole.role === 'Admin'
      return false
    }
  }

  /**
   * Updates the stored user data.
   * @param {object} payload
   */
  updateUser = action(async (payload) => {
    this.loading = true
    this.error = null

    try {
      const currentUser = await updateUser({ ...user, ...payload })
      this.user = currentUser

      Sentry.setUser({
        ...currentUser,
      })

      this.lastUpdated = new Date()
      this.loading = false
    } catch (err) {
      this.error = getErrorMessage(err)
      this.loading = false
    }
  })

  /**
   * Gets updated user data with the specified `id`.
   * @param {string} id
   */
  getUpdatedUser = action(async (id, loggingIn) => {
    this.loading = true
    this.error = null

    try {
      const currentUser = await getUser({ id })
      if (currentUser) {
        this.user = currentUser

        if ((currentUser.eeRole === null || currentUser.eeRole === '') && loggingIn) {
          const { activeOrganization } = currentUser
          let activeRole = null

          if (activeOrganization) {
            // Find the matching organization role for this organization
            activeRole = _.find(
              currentUser.organizationRoles,
              (o) => o.organization.id === activeOrganization.id,
            )
          } else {
            // Sort organization roles by type (Admin, Member)
            const sortedRoles = _.sortBy(currentUser.organizationRoles, (o) => ROLE_RANKS[o.role])

            // Use the first role as the active role
            // eslint-disable-next-line prefer-destructuring
            activeRole = sortedRoles[0]
          }

          navigation.setOrganizationId(activeRole.organization.id)

          let type = ''
          if (activeRole.organization.type === 'Organizer') type = 'organization'
          else if (activeRole.organization.type === 'Exhibitor') type = 'exhibitor'
          navigation.setType(type)

          navigation.updateActiveEntity(type, activeRole.organization.id)

          const updatedUser = await updateUser({
            id: currentUser.id,
            activeOrganization: activeRole.organization.id,
          })
          this.user = updatedUser
        }

        Sentry.setUser({
          ...currentUser,
        })

        this.lastUpdated = new Date()
        this.loading = false
      }
    } catch (err) {
      this.error = getErrorMessage(err)
      this.loading = false
    }
  })

  setCurrentTokens = action(({ access, refresh, offline }) => {
    this.accessToken = access
    this.refreshToken = refresh
    this.offlineToken = offline
  })

  setCurrentUser = action((user) => {
    this.user = {
      ...this.user,
      ...user,
    }
  })
}

export const user = new UserStore(INITIAL_STATE, TYPES)
export const UserStoreContext = createContext(user)
