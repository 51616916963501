import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { PlusIcon, XCircleIcon } from '@heroicons/react/24/outline'
import { Controller, useFieldArray } from 'react-hook-form'
import { twMerge as mergeClassNames } from 'tailwind-merge'

// Components
import { Button } from '../Button'
import { Select } from '../Select'
import { TextInput } from '../TextInput'

// Utils & Styles
import { INCLUSION_OPTIONS, OPERATOR_OPTIONS } from '../../utils/constants'
import { configureSelectedMergeFieldOptions } from '../../utils/helpers'

const DEFAULT_LOGIC = {
  inclusionLogic: INCLUSION_OPTIONS[0],
  mergeField: null,
  operator: null,
  value: null,
}

const MergeFieldLogicFields = ({
  admissionItems = null,
  baseField,
  categories,
  control,
  clearErrors,
  errors,
  getValues,
  loading = false,
  mergeFields = {},
  mergeFieldOptions,
  register,
  setValue,
  sessions = null,
  watch,
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: baseField,
    rules: { required: true },
  })

  const inclusionLogicButtons = useMemo(() => {
    if (fields.length === 5) return null

    if (fields.length === 1) {
      return (
        <div className="flex flex-row gap-4">
          <Button
            className="border-gray-200 bg-gray-200 text-gray-700 hover:bg-gray-300"
            icon={<PlusIcon className="h-5 stroke-gray stroke-2" />}
            label="AND"
            onClick={() => {
              append(DEFAULT_LOGIC)
              setValue('inclusionLogic', INCLUSION_OPTIONS[0])
            }}
          />

          <Button
            className="border-gray-200 bg-gray-200 text-gray-700 hover:bg-gray-300"
            icon={<PlusIcon className="h-5 stroke-gray stroke-2" />}
            label="OR"
            onClick={() => {
              append({ ...DEFAULT_LOGIC, inclusionLogic: INCLUSION_OPTIONS[1] })
              setValue('inclusionLogic', INCLUSION_OPTIONS[1])
              setValue(`${baseField}.0.inclusionLogic`, INCLUSION_OPTIONS[1])
            }}
          />
        </div>
      )
    }

    if (getValues(`${baseField}.0.inclusionLogic`)?.id === 'AND') {
      return (
        <Button
          className="border-gray-200 bg-gray-200 text-gray-700 hover:bg-gray-300"
          icon={<PlusIcon className="h-5 stroke-gray stroke-2" />}
          label="AND"
          onClick={() => append(DEFAULT_LOGIC)}
        />
      )
    }

    return (
      <Button
        className="border-gray-200 bg-gray-200 text-gray-700 hover:bg-gray-300"
        icon={<PlusIcon className="h-5 stroke-gray stroke-2" />}
        label="OR"
        onClick={() => append({ ...DEFAULT_LOGIC, inclusionLogic: INCLUSION_OPTIONS[1] })}
      />
    )
  }, [fields, watch(`${baseField}.0.inclusionLogic`)])

  const renderValueField = (key, i) => {
    const mergeField = watch(`${key}.mergeField`)
    const options = configureSelectedMergeFieldOptions(
      mergeField,
      mergeFields,
      categories,
      admissionItems,
      sessions,
    )

    if (!options) {
      return (
        <TextInput
          className="rounded-2xl border-gray-550 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
          data-testid={`${key}.value`}
          disabled={loading || watch(`${key}.operator`)?.id === 'populated'}
          error={errors[baseField] && errors[baseField][i]?.value && 'This field is required'}
          fullWidth
          id={`${key}.value`}
          inputStyles="rounded-none"
          name={`${key}.value`}
          nunito
          label="Value"
          placeholder="Value"
          {...register(`${key}.value`, {
            required: watch(`${key}.operator`)?.id !== 'populated',
          })}
        />
      )
    }

    const selectedId = getValues(`${key}.value`)?.id || getValues(`${key}.value`)
    const value = _.find(options, (o) => o.id === selectedId) || null

    return (
      <Controller
        name={`${key}.value`}
        control={control}
        render={() => (
          <Select
            className="rounded-2xl border-gray-550 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
            data-testid={`${key}.value`}
            disabled={loading || watch(`${key}.operator`)?.id === 'populated'}
            error={errors[baseField] && errors[baseField][i]?.value && 'This field is required'}
            fullWidth
            id={`${key}.value`}
            label="Value"
            name={`${key}.value`}
            nunito
            onChange={(e) => {
              setValue(`${key}.value`, e)
              clearErrors(`${key}.value`)
            }}
            options={options}
            placeholder="Select an Option"
            search
            style={{ flex: true, width: '100%' }}
            value={value}
          />
        )}
        rules={{
          required: watch(`${key}.operator`)?.id !== 'populated',
        }}
      />
    )
  }

  return (
    <>
      <div className="flex w-full flex-col gap-4">
        {fields.length > 1 && (
          <Controller
            name="inclusionLogic"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Select
                className="rounded-2xl border-gray-550 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                data-testid="inclusionLogic"
                disabled={loading}
                error={errors.inclusionLogic && 'This field is required'}
                fullWidth
                id="inclusionLogic"
                name="inclusionLogic"
                nunito
                onChange={(d) => {
                  onChange(d)

                  // Update the inclusion logic for each logic field
                  const logic = INCLUSION_OPTIONS.find((o) => o.id === d.id)
                  _.forEach(fields, (l, i) => {
                    setValue(`${baseField}.${i}.inclusionLogic`, logic)
                  })
                }}
                options={INCLUSION_OPTIONS}
                label="Inclusion Logic"
                placeholder="Select a Inclusion Logic"
                style={{ flex: true, width: '100%' }}
                value={value}
              />
            )}
            rules={{ required: true }}
          />
        )}

        {_.map(fields, (field, i) => {
          const key = `${baseField}.${i}`
          return (
            <div className="flex w-full flex-row" key={field.id}>
              <div
                className={mergeClassNames(
                  'flex h-full w-9 shrink-0 flex-col items-center justify-center gap-1',
                  i === 0 && 'w-0',
                  i !== 0 && 'mr-4',
                )}
              >
                {i > 0 && (
                  <span className="rounded-lg bg-gray-200 p-1 text-xs font-semibold text-gray-700">
                    {getValues('inclusionLogic')?.label}
                  </span>
                )}

                {i !== 0 && (
                  <Button
                    icon={<XCircleIcon className="h-6 stroke-inherit" />}
                    className="rounded-full stroke-red p-0 hover:stroke-red-600"
                    iconOnly
                    onClick={() => remove(i)}
                  />
                )}
              </div>

              <div className="flex w-full flex-row gap-4">
                <Controller
                  name={`${key}.mergeField`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      className="rounded-2xl border-gray-550 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                      data-testid={`${key}.mergeField`}
                      disabled={loading}
                      error={
                        errors[baseField] &&
                        errors[baseField][i]?.mergeField &&
                        'This field is required'
                      }
                      fullWidth
                      id={`${key}.mergeField`}
                      label="Merge Field"
                      name={`${key}.mergeField`}
                      nunito
                      onChange={(e) => {
                        // Reset logic field value
                        setValue(`${key}.value`, null)

                        // Update the merge field
                        onChange(e)
                      }}
                      options={mergeFieldOptions}
                      placeholder="Select a Merge Field"
                      search
                      style={{ flex: true, width: '100%' }}
                      value={value}
                    />
                  )}
                  rules={{ required: true }}
                />

                <Controller
                  name={`${key}.operator`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      className="rounded-2xl border-gray-550 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                      data-testid={`${key}.operator`}
                      disabled={loading}
                      error={
                        errors[baseField] &&
                        errors[baseField][i]?.operator &&
                        'This field is required'
                      }
                      fullWidth
                      id={`${key}.operator`}
                      label="Operator"
                      name={`${key}.operator`}
                      nunito
                      onChange={onChange}
                      options={OPERATOR_OPTIONS}
                      placeholder="Select an Operator"
                      style={{ flex: true, width: '100%' }}
                      value={value}
                    />
                  )}
                  rules={{ required: true }}
                />

                {renderValueField(key, i)}
              </div>
            </div>
          )
        })}
      </div>
      <div className="flex w-full flex-row justify-center">{inclusionLogicButtons}</div>
    </>
  )
}

MergeFieldLogicFields.propTypes = {
  admissionItems: PropTypes.object,
  baseField: PropTypes.string.isRequired,
  categories: PropTypes.object,
  control: PropTypes.object,
  clearErrors: PropTypes.func,
  errors: PropTypes.object,
  getValues: PropTypes.func,
  loading: PropTypes.bool,
  mergeFields: PropTypes.object,
  mergeFieldOptions: PropTypes.array.isRequired,
  register: PropTypes.func,
  sessions: PropTypes.object,
  setValue: PropTypes.func,
  watch: PropTypes.func,
}

export default MergeFieldLogicFields
