import { getAuth, signInWithCustomToken } from 'firebase/auth'
import {
  collection,
  doc,
  getDoc,
  getDocs,
  setDoc,
  query,
  where,
  updateDoc,
} from 'firebase/firestore'
import _ from 'lodash'
import dayjs from 'dayjs'

// Database
import { firebaseApp, instance } from './instance'
import { convertIncomingData, convertOutgoingData } from '../transforms'
import { refreshAccessToken } from '../user.service'

const converter = {
  toFirestore: (data) => convertOutgoingData(data),
  fromFirestore: (snapshot, options) => convertIncomingData(snapshot.data(options)),
}

/**
 *
 * Converters for manually converting all data coming from and going to firestore.
 *
 */
const convertedCollection = (path) => collection(instance, path).withConverter(converter)
const convertedDoc = (path, id) => doc(instance, path, id).withConverter(converter)

/**
 * Handles signing in with Firebase using our custom token.
 * @param {string} token
 * @param {func} setError
 * @returns authenticated status
 */
export const signInWithFirebase = async (token, refresh, updateTokens, setError) => {
  const auth = getAuth(firebaseApp)

  // If the user is not signed in, sign them in
  if (!auth.currentUser) {
    try {
      await signInWithCustomToken(auth, token)
      return true
    } catch (err) {
      // Try to refresh tokens
      const tokens = await refreshAccessToken(refresh)

      if (tokens) {
        updateTokens(tokens)
        await signInWithCustomToken(auth, tokens.offline)
        return true
      }

      setError(err.message)
      return false
    }
  }

  return true
}

/**
 * Gets the global brand settings from Firestore.
 * @param {func} setLoading
 * @param {func} setError
 * @param {func} setSuccess
 */
export const getGlobalBrandSettings = async (setError) => {
  const snapshot = await getDocs(convertedCollection('global-brand-settings'))
  if (!snapshot.empty) {
    // Get the first document, there should only be one
    const results = snapshot.docs.map((d) => d.data())
    return results[0]
  }

  setError('Error getting brand settings.')
  return null
}

/**
 * Gets the event from Firestore.
 * @param {string} eventId
 * @param {func} setLoading
 * @param {func} setError
 */
export const getEvent = async (eventId, setLoading = () => {}, setError = () => {}) => {
  setLoading(true)

  const snapshot = await getDoc(convertedDoc('events', eventId))
  if (!snapshot.empty) {
    const data = snapshot.data()
    setLoading(false)
    return data
  }

  setError('Error getting event data.')
  setLoading(false)
  return null
}

/**
 * Gets the event registration sync data from Firestore.
 * @param {string} eventId
 * @param {func} setLoading
 * @param {func} setError
 */
export const getRegistrationSyncData = async (
  eventId,
  setLoading = () => {},
  setError = () => {},
) => {
  setLoading(true)

  const snapshot = await getDocs(convertedCollection(`events/${eventId}/reg-sync-data`))
  if (!snapshot.empty) {
    // Get the first document, there should only be one
    const results = snapshot.docs.map((d) => d.data())
    setLoading(false)
    return results[0]
  }

  setError('Error getting kiosk configuration.')
  setLoading(false)
  return null
}

/**
 * Gets the event kiosk configuration from Firestore.
 * @param {string} eventId
 * @param {func} setLoading
 * @param {func} setError
 */
export const getKioskConfiguration = async (
  eventId,
  setLoading = () => {},
  setError = () => {},
) => {
  setLoading(true)

  const snapshot = await getDocs(convertedCollection(`events/${eventId}/kiosk-configuration`))
  if (!snapshot.empty) {
    // Get the first document, there should only be one
    const results = snapshot.docs.map((d) => d.data())
    setLoading(false)
    return results[0]
  }

  setError('Error getting kiosk configuration.')
  setLoading(false)
  return null
}

/**
 * Gets the event badge configuration from Firestore.
 * @param {string} eventId
 * @param {func} setLoading
 * @param {func} setError
 */
export const getBadgeConfiguration = async (
  eventId,
  setLoading = () => {},
  setError = () => {},
) => {
  setLoading(true)

  const snapshot = await getDocs(convertedCollection(`events/${eventId}/badge-configuration`))
  if (!snapshot.empty) {
    // Get the first document, there should only be one
    const results = snapshot.docs.map((d) => d.data())
    setLoading(false)
    return results[0]
  }

  setError('Error getting badge configuration.')
  setLoading(false)
  return null
}

/**
 * Gets the event badge images from Firestore
 * @param {string} eventId
 * @param {func} setLoading
 * @param {func} setError
 */
export const getBadgeImages = async (eventId, setLoading = () => {}, setError = () => {}) => {
  setLoading(true)

  const snapshot = await getDocs(convertedCollection(`events/${eventId}/badge-images`))
  if (!snapshot.empty) {
    const results = snapshot.docs.map((d) => d.data())
    setLoading(false)
    return results
  }

  setError('Error getting badge images.')
  setLoading(false)
  return null
}

/**
 * Gets the attendee list from Firestore.
 * @param {string} eventId
 * @param {func} setLoading
 * @param {func} setError
 */
export const getAttendees = async (eventId, setLoading = () => {}, setError = () => {}) => {
  setLoading(true)
  console.log(`(Kiosk) [${dayjs().format('HH:mm:ss')}] Kicking off attendee prefetch`)
  const snapshot = await getDocs(convertedCollection(`events/${eventId}/kiosk-attendees`))
  if (!snapshot.empty) {
    const results = snapshot.docs.map((d) => d.data())
    console.log(`(Kiosk) [${dayjs().format('HH:mm:ss')}] Attendee prefetch finished`)
    setLoading(false)
    return results
  }
  console.log(`(Kiosk) [${dayjs().format('HH:mm:ss')}] Attendee prefetch failed`)
  setError('Error getting attendees.')
  setLoading(false)
  return null
}

/**
 * Gets the attendee list from Firestore.
 * @param {string} eventId
 * @param {string} search
 * @param {func} setLoading
 * @param {func} setError
 */
export const getAttendeesFiltered = async (
  eventId,
  search,
  setLoading = () => {},
  setError = () => {},
) => {
  setLoading(true)

  const snapshot = await getDocs(convertedCollection(`events/${eventId}/kiosk-attendees`))
  const results = snapshot.docs.map((d) => d.data())

  const searchValue = search.toLowerCase()
  // break up the search value into space-delimited parts and check against all fields
  const searchParts = searchValue.split(' ')

  try {
    const filteredResults = _.filter(results, (a) =>
      searchParts.every(
        // Search by first name, last name, email, and company name
        // using regular expression of parts
        (part) =>
          new RegExp(part).test(a.firstName?.toLowerCase()) ||
          new RegExp(part).test(a.lastName?.toLowerCase()) ||
          new RegExp(part).test(a.email?.toLowerCase()) ||
          new RegExp(part).test(a.companyName?.toLowerCase()),
      ),
    )

    setLoading(false)
    return filteredResults
  } catch (_e) {
    setError('Error getting attendees.')
    setLoading(false)
    return null
  }
}

/**
 * Gets the attendee from Firestore.
 * @param {string} eventId
 * @param {string} attendeeId
 * @param {func} setLoading
 */
export const getAttendee = async (eventId, attendeeId, setLoading = () => {}) => {
  setLoading(true)

  const snapshot = await getDoc(convertedDoc(`events/${eventId}/kiosk-attendees`, attendeeId))
  if (!snapshot.empty) {
    const data = snapshot.data()
    return data
  }

  setLoading(false)
  return null
}

/**
 * Updates the attendee from Firestore.
 * @param {string} eventId
 * @param {string} attendeeId (verification id)
 * @param {object} payload
 */
export const updateAttendee = async (eventId, attendeeId, payload) => {
  await updateDoc(
    doc(instance, `events/${eventId}/kiosk-attendees`, `${attendeeId}`),
    convertOutgoingData(payload),
  )
}

/**
 * Add quick badge.
 * @param {string} eventId
 * @param {object} payload
 */
export const addQuickBadge = async (eventId, payload) => {
  await setDoc(
    doc(instance, `events/${eventId}/quick-badges`, `${payload.id}`),
    convertOutgoingData(payload),
  )
}

/**
 * Add badge print log.
 * @param {string} eventId
 * @param {object} payload
 */
export const addBadgePrintLog = async (eventId, payload) => {
  await setDoc(
    doc(instance, `events/${eventId}/badge-print-logs`, `${payload.id}`),
    convertOutgoingData(payload),
  )
}

/**
 * Gets the badge print logs for the specified `attendeeId`.
 * @param {string} eventId
 * @param {string} attendeeId
 * @param {func} setError
 * @param {func} setLoading
 */
export const getBadgePrintLogs = async (
  eventId,
  attendeeId,
  setError = () => {},
  setLoading = () => {},
) => {
  setLoading(true)

  const printLogs = await query(
    convertedCollection(`events/${eventId}/badge-print-logs`),
    where('attendee', '==', attendeeId),
  )

  const snapshot = await getDocs(printLogs)
  if (!snapshot.empty) {
    const data = snapshot.docs.map((d) => d.data())
    setLoading(false)
    return data
  }

  setError('Error getting badge print logs.')
  setLoading(false)
  return null
}
