import React, { useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { ClipboardDocumentListIcon, PencilIcon, PlusIcon } from '@heroicons/react/24/outline'
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/20/solid'
import { Controller, useForm } from 'react-hook-form'

// Components
import { MergeFieldLogicFields } from '../../components/MergeFieldLogicFields'
import { Modal } from '../../components/Modal'
import { TextInput } from '../../components/TextInput'
import { Select } from '../../components/Select'

// Utils & Service
import { INCLUSION_OPTIONS, NON_SYNCED_FIELDS } from '../../utils/constants'
import { configureMergeFieldOptions, mapLogicValues, toast } from '../../utils/helpers'
import {
  createCustomPopupMessage,
  deleteCustomPopupMessage,
  updateCustomPopupMessage,
} from '../../services/kiosks.service'

const POPUP_IDENTIFIERS = [
  { label: 'Attendee', id: 'attendee-welcome' },
  { label: 'Staff', id: 'staff-welcome' },
  { label: 'Both', id: 'both' },
]

const DEFAULT_LOGIC = {
  inclusionLogic: INCLUSION_OPTIONS[0],
  mergeField: null,
  operator: null,
  value: null,
}

const CustomPopupModal = ({
  admissionItems,
  availableCustomFields,
  categories,
  customPopup = null,
  enabledCustomFields = null,
  eventId,
  kioskConfigurationId,
  onClose,
  sessions,
}) => {
  // State
  const [loadingCustomMessage, setLoadingCustomMessage] = useState(false)
  const [filteredMergeFields, setFilteredMergeFields] = useState()
  const [searchTerm, setSearchTerm] = useState('')

  // Ref
  const searchInputRef = useRef()

  const handleError = (message) => toast(message, 'error')
  const handleSuccess = (message) => toast(message, 'success')

  const mergeFieldLabels = useMemo(() => {
    if (!enabledCustomFields) {
      return NON_SYNCED_FIELDS
    }

    const customFields = _.map(enabledCustomFields, (f) => availableCustomFields[f]?.text)
    return _.concat(NON_SYNCED_FIELDS, customFields)
  }, [])

  const mergeFieldOptions = useMemo(
    () =>
      configureMergeFieldOptions(
        mergeFieldLabels,
        availableCustomFields,
        categories,
        admissionItems,
        sessions,
      ),
    [mergeFieldLabels],
  )

  const {
    control,
    clearErrors,
    handleSubmit,
    formState: { errors },
    register,
    getValues,
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      message: '',
      popupIdentifier: POPUP_IDENTIFIERS[0],
      popupMessageLogic: [DEFAULT_LOGIC],
    },
  })

  useEffect(() => {
    setFilteredMergeFields(mergeFieldLabels)
  }, [mergeFieldLabels])

  useEffect(() => {
    if (customPopup) {
      reset({
        message: customPopup.message,
        inclusionLogic: INCLUSION_OPTIONS.find(
          (o) => o.id === customPopup.popupMessageLogic[0].inclusionLogic,
        ),
        popupIdentifier: POPUP_IDENTIFIERS.find((o) => o.id === customPopup.popupIdentifier),
        popupMessageLogic: mapLogicValues(
          customPopup.popupMessageLogic,
          mergeFieldOptions,
          availableCustomFields,
          categories,
          admissionItems,
          sessions,
        ),
      })
    }
  }, [customPopup, availableCustomFields])

  const actions = [
    {
      type: 'cancel',
      label: 'Cancel',
      onClick: () => {
        setFilteredMergeFields(mergeFieldLabels)
        onClose()
      },
    },
    {
      type: 'submit',
      label: customPopup ? 'Update' : 'Submit',
      onClick: handleSubmit(async (data) => {
        const updatedData = { ...data, popupIdentifier: data.popupIdentifier.id }

        if (updatedData.popupIdentifier === 'attendee-welcome') {
          updatedData.audience = 'Attendee'
        } else if (updatedData.popupIdentifier === 'staff-welcome') {
          updatedData.audience = 'Staff'
        } else {
          updatedData.audience = 'Both'
        }

        const logic = _.map(updatedData.popupMessageLogic, (l) => ({
          ...l,
          mergeField: l.mergeField.id,
          operator: l.operator.id,
          inclusionLogic: l.inclusionLogic.id,
          value: l.value?.id || l.value,
        }))
        updatedData.popupMessageLogic = logic

        if (customPopup) {
          await updateCustomPopupMessage(
            eventId,
            kioskConfigurationId,
            { id: customPopup.id, ...updatedData },
            handleError,
            setLoadingCustomMessage,
            (m) => {
              handleSuccess(m)
              onClose()
            },
          )
        } else {
          await createCustomPopupMessage(
            eventId,
            kioskConfigurationId,
            updatedData,
            handleError,
            setLoadingCustomMessage,
            (m) => {
              handleSuccess(m)
              onClose()
            },
          )
        }
      }),
    },
  ]

  if (customPopup) {
    actions.push({
      type: 'delete',
      label: 'Delete',
      onClick: () =>
        deleteCustomPopupMessage(
          eventId,
          kioskConfigurationId,
          customPopup.id,
          handleError,
          setLoadingCustomMessage,
          (m) => {
            handleSuccess(m)
            onClose()
          },
        ),
    })
  }

  return (
    <Modal
      actions={actions}
      icon={
        customPopup ? (
          <PencilIcon className="h-5 stroke-white sm:h-6" />
        ) : (
          <PlusIcon className="h-5 stroke-white sm:h-6" />
        )
      }
      containerClassName="h-[82%]"
      content={
        <div className="flex h-full w-full flex-col gap-4 py-3 text-center sm:py-5">
          <span className="text-center font-nunito italic text-gray-550">
            Set up a custom message to display on either the Attendee or Staff Welcome popup.
          </span>

          <div className="flex h-full w-full flex-row space-x-4 overflow-y-hidden">
            <div className="flex h-full w-3/4 flex-col gap-4 overflow-y-auto">
              <TextInput
                className="rounded-2xl border-gray-550 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                data-testid="message"
                disabled={loadingCustomMessage}
                error={errors.message && 'This field is required'}
                fullWidth
                id="message"
                inputStyles="rounded-none"
                name="message"
                nunito
                label="Custom Message"
                placeholder="Custom Message"
                {...register('message', { required: true })}
              />

              <Controller
                name="popupIdentifier"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    className="rounded-2xl border-gray-550 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                    data-testid="popupIdentifier"
                    disabled={loadingCustomMessage}
                    error={errors.popupIdentifier && 'This field is required'}
                    fullWidth
                    id="popupIdentifier"
                    name="popupIdentifier"
                    nunito
                    onChange={onChange}
                    options={POPUP_IDENTIFIERS}
                    label="Audience"
                    placeholder="Select an Audience"
                    style={{ flex: true, width: '100%' }}
                    value={value}
                  />
                )}
                rules={{ required: true }}
              />

              <div className="mb-10 flex h-full w-full flex-col gap-4">
                <span className="text-left font-nunito font-semibold">Logic Fields</span>

                <MergeFieldLogicFields
                  admissionItems={admissionItems}
                  baseField="popupMessageLogic"
                  categories={categories}
                  control={control}
                  clearErrors={clearErrors}
                  errors={errors}
                  getValues={getValues}
                  loading={loadingCustomMessage}
                  mergeFields={availableCustomFields}
                  mergeFieldOptions={mergeFieldOptions}
                  register={register}
                  sessions={sessions}
                  setValue={setValue}
                  watch={watch}
                />
              </div>
            </div>

            <div className="h-[95%] w-[1px] bg-black" />

            <div className="flex w-1/4 flex-col">
              <span className="w-fit pb-1 font-nunito text-sm font-medium text-gray-700">
                Merge Fields
              </span>

              <TextInput
                autoComplete="off"
                className="w-full self-center rounded-lg py-2.5 pl-10 pr-4 placeholder:font-normal placeholder:text-gray-600"
                icon={<MagnifyingGlassIcon className="ml-2 h-5 text-purple" aria-hidden="true" />}
                id="search"
                endIcon={
                  searchTerm ? (
                    <button
                      type="button"
                      onClick={() => {
                        setFilteredMergeFields(mergeFieldLabels)
                        setSearchTerm('')
                        searchInputRef.current.value = ''
                      }}
                    >
                      <XMarkIcon className="mr-2 h-5 text-gray-dark" aria-hidden="true" />
                    </button>
                  ) : null
                }
                name="search"
                onChange={(e) => {
                  setFilteredMergeFields(
                    _.filter(mergeFieldLabels, (o) => o.toLowerCase().includes(e.target.value)),
                  )

                  setSearchTerm(e.target.value)
                }}
                placeholder="Search"
                ref={searchInputRef}
                type="text"
                value={searchTerm}
              />

              <div className="mb-5 mt-2 overflow-y-scroll">
                {filteredMergeFields && filteredMergeFields.length > 0 ? (
                  _.map(filteredMergeFields, (f, i) => (
                    <button
                      className="group flex cursor-pointer flex-row text-start"
                      key={`${f}:${i}`}
                      onClick={() => {
                        const matchingCustomField = _.find(
                          _.keys(availableCustomFields),
                          (k) => availableCustomFields[k].text === f,
                        )

                        if (matchingCustomField) {
                          navigator.clipboard.writeText(`{{ ${matchingCustomField} }}`)
                        } else {
                          navigator.clipboard.writeText(`{{ ${f} }}`)
                        }
                        handleSuccess('Merge field copied to clipboard!')
                      }}
                      type="button"
                    >
                      <span className="group-hover:text-purple">{f}</span>

                      <ClipboardDocumentListIcon className="hidden h-6 group-hover:flex group-hover:stroke-purple" />
                    </button>
                  ))
                ) : (
                  <span className="self-start text-sm text-gray-800">
                    No Merge Fields Available.
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      }
      className="sm:h-[90vh] sm:max-h-[1200px] sm:w-[90vw] sm:max-w-screen-xl"
      open
      title={`${customPopup ? 'Edit' : 'Add'} Custom Message`}
    />
  )
}

CustomPopupModal.propTypes = {
  admissionItems: PropTypes.object,
  availableCustomFields: PropTypes.object,
  categories: PropTypes.object,
  customPopup: PropTypes.object,
  enabledCustomFields: PropTypes.array,
  enableRegistrationSync: PropTypes.bool,
  eventId: PropTypes.string.isRequired,
  kioskConfigurationId: PropTypes.string.isRequired,
  mergeFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClose: PropTypes.func.isRequired,
  sessions: PropTypes.object,
}

export default CustomPopupModal
