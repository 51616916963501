import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import QRious from 'qrious'

import { ClipboardDocumentListIcon } from '@heroicons/react/24/outline'

// Components
import { Button } from '../../components/Button'
import { EventHeader } from '../../components/EventHeader'
import { PasswordInput } from '../../components/PasswordInput'
import { QRIcon } from '../../components/QRIcon'
import { StateContainer } from '../../components/StateContainer'

// Store
import { NavigationStoreContext } from '../../stores/NavigationStore'

// Service
import { triggerTrackrTokenRefresh, updateEvent } from '../../services/events.service'

// Utils
import { toast } from '../../utils/helpers'

/**
 *
 * TrackrAccess
 *
 */
const TrackrAccess = observer(() => {
  // Context
  const { event, eventId, setEvent } = useContext(NavigationStoreContext)

  // State
  const [passwordError, setPasswordError] = useState(false)
  const [adminPassword, setAdminPassword] = useState('')
  const [loading, setLoading] = useState(false)

  const handleSuccess = (m) => toast(m, 'success')
  const handleError = (m) => toast(m, 'error')

  useEffect(() => {
    if (event) {
      setAdminPassword(event.trackrAdminPassword)
    }
  }, [event])

  return (
    <div className="h-full w-full">
      <StateContainer>
        <div className="flex h-full w-full flex-col overflow-y-auto p-3">
          <div className="flex flex-col gap-6">
            <div className="flex flex-col items-start justify-between space-y-1 px-3 sm:flex-row sm:space-y-0 md:items-center">
              <EventHeader event={event} />
            </div>

            <div className="font-bold">Trackr Access</div>

            <div className="shadow-xm flex w-full flex-col space-y-8 rounded-[15px] bg-white px-5 pb-24 pt-4">
              <div className="flex flex-col items-start space-y-6">
                <div className="flex items-start justify-between">
                  <div className="basis-3/4">
                    <div className="font-bold">Manage Access</div>
                    <div className="text-xs text-gray-600">
                      Users will be prompted to scan a QR code upon opening the app. Alternatively,
                      a link can be provided that will redirect users to the Session in the Trackr
                      app. When access is reset, existing links and QR codes are invalidated and
                      new ones must be provided.
                    </div>
                  </div>
                  <Button
                    className="border-red bg-white text-red hover:bg-white"
                    label="Reset Event Access"
                    loading={loading}
                    outlined
                    onClick={async () => {
                      const updatedEvent = await triggerTrackrTokenRefresh(
                        eventId,
                        handleError,
                        setLoading,
                        handleSuccess,
                      )
                      setEvent(updatedEvent)
                    }}
                  />
                </div>

                <Button
                  background="bg-purple border-purple hover:bg-purple-600"
                  icon={<ClipboardDocumentListIcon className="h-5 sm:h-6" />}
                  label="Copy Trackr Access URL"
                  onClick={() => {
                    navigator.clipboard.writeText(event.trackrDeepLink)
                    handleSuccess('Trackr Access URL copied to clipboard!')
                  }}
                />
                <Button
                  background="bg-purple border-purple hover:bg-purple-600"
                  icon={<QRIcon className="h-4 fill-white sm:h-5" />}
                  label="Generate Trackr Access QR Code"
                  onClick={() => {
                    const qr = new QRious({
                      value: event.trackrDeepLink,
                    })

                    const qrDataURL = qr.toDataURL()
                    const link = document.createElement('a')
                    link.href = qrDataURL
                    link.download = `trackr-access-qr-${eventId}.png`
                    link.click()
                  }}
                />
              </div>
              <div className="flex flex-col space-y-2">
                <div className="font-bold">Admin Password</div>
                <div className="text-xs text-gray-600">
                  User can enter Admin mode by entering this password in the Trackr app.
                </div>
                <div className="max-w-[300px]">
                  <PasswordInput
                    disableAutoComplete
                    className="rounded-2xl border-gray-550 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                    hideTooltip
                    id="admin-password"
                    name="adminPassword"
                    error={passwordError && 'Password must be exactly 4 digits (no characters).'}
                    type="number"
                    pattern="\d{4}"
                    minLength={4}
                    maxLength={4}
                    onChange={(e) => setAdminPassword(e.target.value)}
                    onBlur={(e) => {
                      if (e.target.value === event.trackrAdminPassword) return
                      // if the value is four digits it's valid
                      if (/^\d{4}$/.test(e.target.value)) {
                        updateEvent(
                          {
                            id: eventId,
                            trackrAdminPassword: e.target.value,
                          },
                          () => {},
                          () => {},
                          handleSuccess('Admin password updated.'),
                        )
                        setPasswordError(false)
                      } else {
                        setPasswordError(true)
                      }
                    }}
                    value={adminPassword}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </StateContainer>
    </div>
  )
})

export default TrackrAccess
