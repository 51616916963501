import React from 'react'
import { CircleStackIcon, HomeIcon } from '@heroicons/react/24/outline'

// Components
import { BarChartIcon } from '../BarChartIcon'
import { CheckListIcon } from '../CheckListIcon'
import { CircleCheckIcon } from '../CircleCheckIcon'
import { CogIcon } from '../CogIcon'
import { KeyIcon } from '../KeyIcon'
import { LeadsIcon } from '../LeadsIcon'
import { OrganizationIcon } from '../OrganizationIcon'
import { QRIcon } from '../QRIcon'
import { StarsIcon } from '../StarsIcon'
import { UsersOutlineIcon } from '../UsersOutlineIcon'

const TRACKER_ENABLED = import.meta.env.VITE_TRACKR_ENABLED === 'true'

const EXECUTIVE_DASHBOARD_OPTIONS = [
  { label: 'Dashboard', path: '/dashboard', icon: <HomeIcon className="w-[18px]" /> },
  { label: 'Users', path: '/users', icon: <UsersOutlineIcon className="w-[18px]" /> },
  {
    label: 'Organizations',
    path: '/organizations',
    icon: <OrganizationIcon className="w-[18px]" />,
  },
  { label: 'Reports', path: '/reports', icon: <BarChartIcon className="w-[18px]" /> },
  { label: 'Settings', path: '/settings', icon: <CogIcon className="w-[18px]" /> },
]

const ORGANIZER_EVENT_DASHBOARD_OPTIONS = (event, urlPrefix, isEEUser) => {
  const { id, enableBadging, enableLeadRetrieval, enableRegistrationSync, enableTrackr } = event

  const options = [
    {
      label: 'Event',
      icon: <HomeIcon className="w-[18px]" />,
      options: [
        {
          label: 'Event Home',
          path: `${urlPrefix}/event/${id}/home`,
          regex: RegExp(`${urlPrefix}/event/[A-Za-z0-9-]*/home`),
        },
        {
          label: 'Event Reports',
          path: `${urlPrefix}/event/${id}/reports`,
          regex: RegExp(`${urlPrefix}/event/[A-Za-z0-9-]*/reports`),
        },
        {
          label: 'Event Emails',
          path: `${urlPrefix}/event/${id}/emails`,
          regex: RegExp(`${urlPrefix}/event/[A-Za-z0-9-]*/emails`),
        },
      ],
    },
    {
      label: 'Data Management',
      icon: <CircleStackIcon className="w-[18px]" />,
      options: [
        {
          label: 'Attendees',
          path: `${urlPrefix}/event/${id}/data-management/attendees`,
          regex: RegExp(`${urlPrefix}/event/[A-Za-z0-9-]*/data-management/attendees`),
        },
      ],
    },
  ]

  if (enableRegistrationSync && isEEUser) {
    options[1].options.push({
      label: 'API Config',
      path: `${urlPrefix}/event/${id}/data-management/api-config`,
      regex: RegExp(`${urlPrefix}/event/[A-Za-z0-9-]*/data-management/api-config`),
    })
  }

  const leadRetrievalOptions = [
    {
      label: 'Exhibitors',
      path: `${urlPrefix}/event/${id}/lead-retrieval/exhibitors`,
      regex: RegExp(`${urlPrefix}/event/[A-Za-z0-9-]*/lead-retrieval/exhibitors`),
    },
    {
      label: 'Licenses',
      path: `${urlPrefix}/event/${id}/lead-retrieval/licenses`,
      regex: RegExp(`${urlPrefix}/event/[A-Za-z0-9-]*/lead-retrieval/licenses`),
    },
  ]

  const trackrOptions = [
    {
      label: 'Session List',
      path: `${urlPrefix}/event/${id}/sessions/list`,
      regex: RegExp(`${urlPrefix}/event/[A-Za-z0-9-]*/sessions/list`),
    },
    {
      label: 'Trackr Access',
      path: `${urlPrefix}/event/${id}/sessions/access`,
      regex: RegExp(`${urlPrefix}/event/[A-Za-z0-9-]*/sessions/access`),
    },
  ]

  if (!isEEUser) {
    // Do not render 'Licenses' if non-ee user
    leadRetrievalOptions.splice(1, 1)
  }

  if (enableLeadRetrieval) {
    options.push({
      label: 'Lead Retrieval',
      icon: <QRIcon className="w-[18px] stroke-inherit" />,
      options: leadRetrievalOptions,
    })
  }

  if (TRACKER_ENABLED && enableTrackr) {
    options.push({
      label: 'Sessions',
      icon: <CheckListIcon className="w-[18px] stroke-inherit" />,
      options: trackrOptions,
    })
  }

  const badgingOptions = [
    {
      label: 'Settings',
      path: `${urlPrefix}/event/${id}/check-in/settings`,
      regex: RegExp(`${urlPrefix}/event/[A-Za-z0-9-]*/check-in/settings`),
    },
  ]

  if (isEEUser) {
    badgingOptions.push({
      label: 'Badge Builder',
      path: `${urlPrefix}/event/${id}/check-in/badge-builder`,
      regex: RegExp(`${urlPrefix}/event/[A-Za-z0-9-]*/check-in/badge-builder`),
    })
  }

  if (enableBadging) {
    options.push({
      label: 'Check In',
      icon: <CircleCheckIcon className="w-[18px] stroke-inherit" />,
      options: badgingOptions,
    })
  }

  return options
}

const EXHIBITOR_EVENT_DASHBOARD_OPTIONS = (event, urlPrefix) => [
  {
    label: 'Event Home',
    path: `${urlPrefix}/event/${event.id}/home`,
    icon: <HomeIcon className="w-[18px] stroke-inherit" />,
  },
  {
    label: 'Leads',
    path: `${urlPrefix}/event/${event.id}/leads`,
    icon: <LeadsIcon className="w-[18px] stroke-inherit" />,
  },
  {
    label: 'App Setup',
    icon: <StarsIcon className="w-[18px] stroke-inherit" />,
    options: [
      {
        label: 'Materials',
        path: `${urlPrefix}/event/${event.id}/app-setup/materials`,
        regex: RegExp(`${urlPrefix}/event/[A-Za-z0-9-]*/app-setup/materials`),
      },
      {
        label: 'Qualifiers',
        path: `${urlPrefix}/event/${event.id}/app-setup/qualifiers`,
        regex: RegExp(`${urlPrefix}/event/[A-Za-z0-9-]*/app-setup/qualifiers`),
      },
    ],
  },
  {
    label: 'Licenses',
    path: `${urlPrefix}/event/${event.id}/licenses`,
    icon: <KeyIcon className="w-[18px] stroke-inherit" />,
  },
  {
    label: 'Reports',
    path: `${urlPrefix}/event/${event.id}/reports`,
    icon: <BarChartIcon className="w-[18px] stroke-inherit" />,
  },
]

const ORGANIZER_ORGANIZATION_DASHBOARD_OPTIONS = (urlPrefix) => [
  {
    label: 'Dashboard',
    path: `${urlPrefix}/dashboard`,
    icon: <HomeIcon className="w-[18px]" />,
  },
  { label: 'Users', path: `${urlPrefix}/users`, icon: <UsersOutlineIcon className="w-[18px]" /> },
  { label: 'Reports', path: `${urlPrefix}/reports`, icon: <BarChartIcon className="w-[18px]" /> },
]

const EXHIBITOR_ORGANIZATION_DASHBOARD_OPTIONS = (urlPrefix) => [
  {
    label: 'Exhibitor Dashboard',
    path: `${urlPrefix}/dashboard`,
    icon: <HomeIcon className="w-[18px]" />,
  },
  {
    label: 'Users',
    path: `${urlPrefix}/users`,
    icon: <UsersOutlineIcon className="w-[18px]" />,
  },
  { label: 'Reports', path: `${urlPrefix}/reports`, icon: <BarChartIcon className="w-[18px]" /> },
]

export const MENU_OPTIONS = (navigate, logout) => [
  { label: 'Profile', onClick: () => navigate('/profile') },
  { label: 'Logout', onClick: () => logout() },
]

export const getNavigationOptions = (type, event, urlPrefix, isEEUser) => {
  switch (type) {
    case 'admin':
      return EXECUTIVE_DASHBOARD_OPTIONS
    case 'organizer-event':
      return ORGANIZER_EVENT_DASHBOARD_OPTIONS(event, urlPrefix, isEEUser)
    case 'exhibitor-event':
      return EXHIBITOR_EVENT_DASHBOARD_OPTIONS(event, urlPrefix)
    case 'organizer-organization':
      return ORGANIZER_ORGANIZATION_DASHBOARD_OPTIONS(urlPrefix)
    case 'exhibitor-organization':
      return EXHIBITOR_ORGANIZATION_DASHBOARD_OPTIONS(urlPrefix)
    default:
      return []
  }
}

const EE_HIGH_LEVEL_NAV_OPTIONS = [
  {
    label: 'Dashboard',
    path: '/dashboard',
  },
  {
    label: 'Users',
    path: '/users',
  },
  {
    label: 'Organizations',
    path: '/organizations',
  },
  {
    label: 'Reports',
    path: '/reports',
  },
  {
    label: 'Settings',
    path: '/settings',
  },
]

const ORGANIZER_EVENT_HIGH_LEVEL_NAVIGATION_OPTIONS = (urlPrefix, eventRoute, isEEUser) => {
  const options = {
    'Organizer Menu': [
      {
        label: 'Dashboard',
        path: `${urlPrefix}/dashboard`,
      },
      {
        label: 'Users',
        path: `${urlPrefix}/users`,
      },
      {
        label: 'Reports',
        path: `${urlPrefix}/reports`,
      },
    ],
  }

  if (isEEUser) {
    if (!eventRoute) return { 'EventStack Menu': EE_HIGH_LEVEL_NAV_OPTIONS }
    options['EventStack Menu'] = EE_HIGH_LEVEL_NAV_OPTIONS
  }

  return options
}

const EXHIBITOR_EVENT_HIGH_LEVEL_NAVIGATION_OPTIONS = (urlPrefix, eventRoute, isEEUser) => {
  const options = {
    'Exhibitor Menu': [
      {
        label: 'Exhibitor Dashboard',
        path: `${urlPrefix}/dashboard`,
      },
      {
        label: 'Users',
        path: `${urlPrefix}/users`,
      },
      {
        label: 'Reports',
        path: `${urlPrefix}/reports`,
      },
    ],
  }

  if (isEEUser) {
    if (!eventRoute) return { 'EventStack Menu': EE_HIGH_LEVEL_NAV_OPTIONS }
    options['EventStack Menu'] = EE_HIGH_LEVEL_NAV_OPTIONS
  }

  return options
}

export const getHighLevelNavigationOptions = (type, urlPrefix, eventRoute, isEEUser) => {
  switch (type) {
    case 'organization':
      return ORGANIZER_EVENT_HIGH_LEVEL_NAVIGATION_OPTIONS(urlPrefix, eventRoute, isEEUser)
    case 'exhibitor':
      return EXHIBITOR_EVENT_HIGH_LEVEL_NAVIGATION_OPTIONS(urlPrefix, eventRoute, isEEUser)
    default:
      return []
  }
}
