import React, { useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { ClipboardDocumentListIcon } from '@heroicons/react/24/outline'
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/20/solid'
import { useForm } from 'react-hook-form'
import { twMerge as mergeClassNames } from 'tailwind-merge'

// Components
import { Button } from '../../components/Button'
import { MergeFieldLogicFields } from '../../components/MergeFieldLogicFields'
import { Modal } from '../../components/Modal'
import { TextInput } from '../../components/TextInput'
import { Tooltip } from '../../components/Tooltip'
import { Toggle } from '../../components/Toggle'

// Images
import Add from '../../assets/images/add.svg'
import Edit from '../../assets/images/editCircle.svg'

// Service
import { updateSession } from '../../services/events.service'

// Utils & Styles
import { INCLUSION_OPTIONS, NON_SYNCED_FIELDS } from '../../utils/constants'
import { configureMergeFieldOptions, mapLogicValues, toast } from '../../utils/helpers'

const DEFAULT_LOGIC = {
  inclusionLogic: INCLUSION_OPTIONS[0],
  mergeField: null,
  operator: null,
  value: null,
}

const SessionOptions = ({ event, loading, session, setSession }) => {
  const mergeFields = event.enabledCustomFields
    ? [...NON_SYNCED_FIELDS, ...event.enabledCustomFields]
    : NON_SYNCED_FIELDS

  // State
  const [filteredMergeFields, setFilteredMergeFields] = useState(mergeFields)
  const [showMergeFieldModal, setShowMergeFieldModal] = useState(false)
  const [showAccessControlModal, setShowAccessControlModal] = useState(false)
  const [loadingUpdatedSession, setLoadingUpdatedSession] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [insightsMessage, setInsightsMessage] = useState('')

  const handleError = (message) => toast(message, 'error')
  const handleSuccess = (message) => toast(message, 'success')

  const mergeFieldOptions = useMemo(() => {
    let mergeFieldLabels = NON_SYNCED_FIELDS
    if (event?.enabledCustomFields) {
      const customFields = _.map(
        event?.enabledCustomFields,
        (f) => event?.availableCustomFields[f]?.text,
      )
      mergeFieldLabels = _.concat(NON_SYNCED_FIELDS, customFields)
    }

    return configureMergeFieldOptions(
      mergeFieldLabels,
      event?.availableCustomFields,
      event?.attendeeCategories,
      event?.admissionItems,
      event?.sessions,
    )
  }, [])

  const {
    control,
    clearErrors,
    handleSubmit,
    formState: { errors },
    register,
    getValues,
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      accessControlLogic: [DEFAULT_LOGIC],
    },
  })

  useEffect(() => {
    if (session.accessControlLogic.length > 0) {
      reset({
        inclusionLogic: INCLUSION_OPTIONS.find(
          (o) => o.id === session.accessControlLogic[0].inclusionLogic,
        ),
        accessControlLogic: mapLogicValues(
          session.accessControlLogic,
          mergeFieldOptions,
          event.availableCustomFields,
          event.attendeeCategories,
          event.admissionItems,
          event.sessions,
        ),
      })
    }

    if (session.enableInsights) {
      setInsightsMessage(session.insightsMessage)
    }
  }, [session, event])

  // Ref
  const searchInputRef = useRef()

  /**
   * Handles saving changes to the session.
   * @param {object} data
   */
  const handleSaveChanges = async (data) => {
    const updatedSession = {
      ...data,
      eventId: event.id,
      id: session.id,
    }

    // Optimistically update the session with the changes
    const previousSession = { ...session }
    setSession({ ...session, ...updatedSession })

    await updateSession(
      updatedSession,
      (e) => {
        handleError(e)

        // Revert back to previous session
        setSession(previousSession)
      },
      setLoadingUpdatedSession,
      handleSuccess,
    )
  }

  return (
    <div className="flex h-full w-full flex-col gap-5 overflow-y-auto px-9">
      <div className="mt-9 text-xl font-bold">All Session Options</div>
      <div className="mb-8 flex flex-col gap-8 lg:mb-0 lg:flex-row">
        <div className="space-y-2 lg:basis-3/5">
          <span className="font-bold">Session Insights</span>
          <Toggle
            label="Enable Insight"
            checked={session.enableInsights}
            disabled={loading}
            onChange={(e) => handleSaveChanges({ enableInsights: e })}
          />
          {session.enableInsights && (
            <>
              <TextInput
                className="rounded-2xl border-gray-550 px-4 py-2.5 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                data-testid="insightsMessage"
                disabled={loading}
                id="insightsMessage"
                inputStyles="rounded-none rounded-t-md font-nunito"
                name="insightsMessage"
                onChange={(e) => setInsightsMessage(e.target.value)}
                onBlur={() => handleSaveChanges({ insightsMessage })}
                value={insightsMessage}
              />

              <Button
                background="bg-purple-100"
                className="border-purple-100 text-purple-700"
                label="Merge Fields"
                onClick={() => setShowMergeFieldModal(true)}
              />
            </>
          )}
        </div>

        <div className="flex flex-col space-y-2 lg:basis-2/5">
          <span className="font-bold">Session Check-out</span>
          <Toggle
            label="Enable Session Check-out"
            checked={session.enableCheckOut}
            disabled={loading}
            onChange={(e) => handleSaveChanges({ enableCheckOut: e })}
          />

          <span className="font-bold">Photo Verification</span>
          <Tooltip
            content={
              <div className="rounded-lg bg-background px-2 pb-0.5">
                <span className="text-xs">Photos are not configured to be synced.</span>
              </div>
            }
            display={event.enableRegistrationSync && !event.enablePhotoSync}
            fullWidth
            placement="top"
          >
            <Toggle
              label="Enable Photo Verification"
              checked={session.enablePhotoVerification}
              disabled={loading || (event.enableRegistrationSync && !event.enablePhotoSync)}
              onChange={(e) => handleSaveChanges({ enablePhotoVerification: e })}
            />
          </Tooltip>
        </div>
      </div>

      <div className="flex flex-col gap-8 pb-8 lg:flex-row">
        {session.type === 'Access Control' && (
          <>
            <div className="flex flex-col gap-4 lg:basis-3/5">
              <div className="flex items-center justify-between">
                <span className="text-xl font-bold">Access Control Options</span>
                <Button
                  background="bg-purple border-purple hover:bg-purple-600"
                  label={
                    session.accessControlLogic.length > 0
                      ? 'Edit Access Logic'
                      : 'Add Access Logic'
                  }
                  onClick={() => setShowAccessControlModal(true)}
                />
              </div>

              <div className="flex flex-col gap-2">
                {_.map(session.accessControlLogic, (l, i) => (
                  <div
                    className={mergeClassNames(
                      'flex flex-row items-center justify-between gap-4',
                      i !== session.accessControlLogic.length - 1 &&
                        'border-b border-gray-300 pb-2',
                    )}
                    key={l.id}
                  >
                    <span className="flex flex-row gap-2">
                      <span>{`{{ ${l.mergeField} }}`}</span>
                      <span>{l.operator}</span>
                      <span className="font-bold">{l.value}</span>
                    </span>
                    {i !== session.accessControlLogic.length - 1 && (
                      <span className="flex items-center rounded-full bg-purple-100 px-2.5 py-1 text-xs font-bold text-purple">
                        {l.inclusionLogic}
                      </span>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className="mt-12 space-y-2 lg:basis-2/5">
              <span className="font-bold">Session Override</span>
              <Toggle
                label="Enable In-App Registration"
                checked={session.enableSessionOverride}
                disabled={loading}
                onChange={(e) => handleSaveChanges({ enableSessionOverride: e })}
              />
            </div>
          </>
        )}
      </div>

      <Modal
        actions={[
          {
            type: 'cancel',
            label: 'Close',
            onClick: () => {
              setFilteredMergeFields(mergeFields)
              setShowMergeFieldModal(false)
            },
          },
        ]}
        icon={<ClipboardDocumentListIcon className="h-5 stroke-white sm:h-6" />}
        content={
          <div className="mt-3 flex max-h-56 flex-col sm:mt-5">
            <TextInput
              autoComplete="off"
              className="w-[90%] self-center rounded-lg py-2.5 pl-10 pr-4 placeholder:font-normal placeholder:text-gray-600"
              icon={<MagnifyingGlassIcon className="ml-2 h-5 text-purple" aria-hidden="true" />}
              id="search"
              endIcon={
                searchTerm ? (
                  <button
                    type="button"
                    onClick={() => {
                      setFilteredMergeFields(mergeFields)
                      setSearchTerm('')
                      searchInputRef.current.value = ''
                    }}
                  >
                    <XMarkIcon className="mr-2 h-5 text-gray-dark" aria-hidden="true" />
                  </button>
                ) : null
              }
              name="search"
              onChange={(e) => {
                setFilteredMergeFields(
                  _.filter(mergeFields, (o) => o.toLowerCase().includes(e.target.value)),
                )

                setSearchTerm(e.target.value)
              }}
              placeholder="Search"
              ref={searchInputRef}
              type="text"
              value={searchTerm}
            />

            <div className="mx-8 mt-2 flex flex-col overflow-y-scroll">
              {_.map(filteredMergeFields, (f, i) => (
                <button
                  className="group flex cursor-pointer flex-row"
                  key={`${i}`}
                  onClick={() => {
                    navigator.clipboard.writeText(`{{ ${f} }}`)
                    handleSuccess('Merge field copied to clipboard!')
                  }}
                  type="button"
                >
                  <span className="group-hover:text-purple">{f}</span>

                  <ClipboardDocumentListIcon className="hidden h-6 group-hover:flex group-hover:stroke-purple" />
                </button>
              ))}
            </div>
          </div>
        }
        open={showMergeFieldModal}
        title="Copy Merge Field"
      />

      <Modal
        actions={[
          {
            type: 'cancel',
            label: 'Close',
            onClick: () => {
              setShowAccessControlModal(false)
            },
          },
          {
            type: 'submit',
            label: 'Submit',
            onClick: handleSubmit(async (data) => {
              const logic = _.map(data.accessControlLogic, (l) => ({
                ...l,
                mergeField: l.mergeField.id,
                operator: l.operator.id,
                inclusionLogic: l.inclusionLogic.id,
                value: l.value?.id || l.value,
              }))

              await handleSaveChanges({ accessControlLogic: logic })
              setShowAccessControlModal(false)
            }),
          },
        ]}
        icon={
          <img
            src={session.accessControlLogic?.length > 0 ? Edit : Add}
            alt={session.accessControlLogic?.length > 0 ? 'Edit' : 'Add'}
          />
        }
        content={
          <div className="mb-2 flex h-full w-full flex-col gap-4 pt-4">
            <MergeFieldLogicFields
              admissionItems={event.admissionItems}
              baseField="accessControlLogic"
              categories={event.attendeeCategories}
              control={control}
              clearErrors={clearErrors}
              errors={errors}
              getValues={getValues}
              loading={loadingUpdatedSession}
              mergeFields={event.availableCustomFields}
              mergeFieldOptions={mergeFieldOptions}
              register={register}
              sessions={event.sessions}
              setValue={setValue}
              watch={watch}
            />
          </div>
        }
        className="w-[90%] sm:max-w-screen-md"
        open={showAccessControlModal}
        title={session.accessControlLogic.length > 0 ? 'Edit' : 'Add Access Logic'}
      />
    </div>
  )
}

SessionOptions.propTypes = {
  event: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  session: PropTypes.object.isRequired,
  setSession: PropTypes.func.isRequired,
}

export { SessionOptions }
