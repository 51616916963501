// Utils and Service
import axios from './instance'
import { getErrorMessage } from '../utils/helpers'

/**
 * Adds a new kiosk configuration for the specified `event`.
 * @param {string} eventId
 * @param {func} setError
 * @param {func} setLoading
 * @returns `data` kiosk configuration
 */
export const addKioskConfiguration = async (
  eventId,
  payload,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.post(
      `/events/${eventId}/kiosk-configurations/?expand=kiosk_popups,kiosk_device_locations`,
      payload,
    )

    setLoading(false)
    setSuccess('Kiosk configuration created successfully!')
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Gets the kiosk configuration for the specified `eventId`.
 * @param {string} eventId
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` results
 */
export const getKioskConfiguration = async (
  eventId,
  kioskConfigurationId,
  expand = true,
  setError = () => {},
  setLoading = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.get(
      `/events/${eventId}/kiosk-configurations/${kioskConfigurationId}/${
        expand ? '?expand=kiosk_popups,kiosk_device_locations' : ''
      }`,
    )

    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Updates the specified kiosk configuration for the specified `event`.
 * @param {string} eventId
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` kiosk configuration
 */

export const updateKioskConfiguration = async (
  eventId,
  payload,
  setError,
  setLoading,
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.patch(
      `/events/${eventId}/kiosk-configurations/${payload.id}/?expand=kiosk_popups,kiosk_device_locations`,
      payload,
    )

    setLoading(false)
    setSuccess('Kiosk configuration updated successfully!')
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Updates the specified popup configuration for the specified event kiosk configuration.
 * @param {string} eventId
 * @param {string} kioskConfigId
 * @param {func} setError
 * @param {func} setLoading
 * @returns `data` kiosk configuration
 */
export const updatePopupConfiguration = async (
  eventId,
  kioskConfigId,
  payload,
  setError,
  setLoading,
) => {
  setLoading(true)

  try {
    const data = await axios.patch(
      `/events/${eventId}/kiosk-configurations/${kioskConfigId}/kiosk-popups/${payload.id}/`,
      payload,
    )

    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Adds a new device location for the specified `event` and `kioskConfigId`.
 * @param {string} eventId
 * @param {string} kioskConfigId
 * @param {object} payload
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` device location
 */
export const addDeviceLocation = async (
  eventId,
  kioskConfigId,
  payload,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.post(
      `/events/${eventId}/kiosk-configurations/${kioskConfigId}/kiosk-device-locations/`,
      payload,
    )

    setLoading(false)
    setSuccess('Device location created successfully!')
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Updates the specified device location for the specified `event`, `kioskConfigId`, and `deviceLocationId`.
 * @param {string} eventId
 * @param {string} kioskConfigId
 * @param {object} payload
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` device location
 */

export const updateDeviceLocation = async (
  eventId,
  kioskConfigId,
  payload,
  setError,
  setLoading,
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.patch(
      `/events/${eventId}/kiosk-configurations/${kioskConfigId}/kiosk-device-locations/${payload.id}/`,
      payload,
    )

    setLoading(false)
    setSuccess('Device location updated successfully!')
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Deletes the specified device location for the specified `event`, `kioskConfigId`, and `deviceLocationId`.
 * @param {string} eventId
 * @param {string} kioskConfigId
 * @param {string} deviceLocationId
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 */

export const deleteDeviceLocation = async (
  eventId,
  kioskConfigId,
  deviceLocationId,
  setError,
  setLoading,
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.delete(
      `/events/${eventId}/kiosk-configurations/${kioskConfigId}/kiosk-device-locations/${deviceLocationId}/`,
    )

    setLoading(false)
    setSuccess('Device location deleted successfully!')
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Gets the the kiosk KPIs for the specified `eventId`.
 * @param {string} eventId
 * @param {func} setError
 * @param {func} setLoading
 * @returns `data` results
 */
export const getEventKioskKPIs = async (eventId, setError = () => {}, setLoading = () => {}) => {
  setLoading(true)

  try {
    const data = await axios.get(`/events/${eventId}/kiosk-kpis/`)

    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Gets the list of custom pop-up messages using the specified `eventId` and `kioskConfigurationId`.
 * @param {string} eventId
 * @param {string} kioskConfigurationId
 * @param {func} setError
 * @param {func} setLoading
 * @returns `data` results
 */
export const getCustomPopups = async (
  eventId,
  kioskConfigurationId,
  setError = () => {},
  setLoading = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.get(
      `/events/${eventId}/kiosk-configurations/${kioskConfigurationId}/kiosk-custom-popup-messages/?limit=99999`,
    )

    setLoading(false)
    return data.results
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Creates a new custom popup message using the specified `eventId`, `kioskConfigurationId` and `payload`.
 * @param {string} eventId
 * @param {string} kioskConfigurationId
 * @param {object} payload
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` results
 */
export const createCustomPopupMessage = async (
  eventId,
  kioskConfigurationId,
  payload,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.post(
      `/events/${eventId}/kiosk-configurations/${kioskConfigurationId}/kiosk-custom-popup-messages/`,
      payload,
    )

    setLoading(false)
    setSuccess('Custom popup message created successfully.')
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Updates a custom popup message using the specified `eventId`, `kioskConfigurationId` and `payload`.
 * @param {string} eventId
 * @param {string} kioskConfigurationId
 * @param {object} payload
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` results
 */
export const updateCustomPopupMessage = async (
  eventId,
  kioskConfigurationId,
  payload,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.patch(
      `/events/${eventId}/kiosk-configurations/${kioskConfigurationId}/kiosk-custom-popup-messages/${payload.id}/`,
      payload,
    )

    setLoading(false)
    setSuccess('Custom popup message updated successfully.')
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Deletes the custom popup message using the specified `eventId`, `kioskConfigurationId` and `popupId`.
 * @param {string} eventId
 * @param {string} kioskConfigurationId
 * @param {string} popupId
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` results
 */
export const deleteCustomPopupMessage = async (
  eventId,
  kioskConfigurationId,
  popupId,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.delete(
      `/events/${eventId}/kiosk-configurations/${kioskConfigurationId}/kiosk-custom-popup-messages/${popupId}`,
    )

    setLoading(false)
    setSuccess('Custom popup message deleted successfully.')
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}
