/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/prop-types */
import React, { useCallback, useContext, useEffect, useState } from 'react'
import {
  ArrowDownTrayIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  InformationCircleIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid'
import _ from 'lodash'
import dayjs from 'dayjs'
import { observer } from 'mobx-react'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

// Components
import { Button } from '../../components/Button'
import CustomDataTable, { defaultStyles } from '../../components/DataTable/DataTable'
import { EventHeader } from '../../components/EventHeader'
import { StateContainer } from '../../components/StateContainer'
import { TextInput } from '../../components/TextInput'

// Service
import { createLeadsExport, getLeads } from '../../services/leads.service'

// Stores
import { NavigationStoreContext } from '../../stores/NavigationStore'
import { TaskStoreContext } from '../../stores/TaskStore'

// Utils
import { formatPhoneNumber } from '../../utils/formatters'
import { handlePagination, toast } from '../../utils/helpers'

dayjs.extend(utc)
dayjs.extend(timezone)

const ExpandedNotes = ({ data }) => (
  <div
    className="flex flex-col justify-center p-4"
    style={{
      backgroundColor: '#E3F2F9',
      borderBottom: '1px solid #E5E7EB',
      paddingLeft: '64px',
      minHeight: '52px',
    }}
  >
    <span className="text-sm font-bold">Notes</span>
    <span className="text-xs text-gray-500">{data.notes}</span>
  </div>
)

/**
 *
 * EventLeads
 *
 */
const EventLeads = observer(() => {
  // Context
  const { event, eventId } = useContext(NavigationStoreContext)
  const { setParent, setTask, setType } = useContext(TaskStoreContext)

  // Pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [totalRows, setTotalRows] = useState(0)
  const [perPage, setPerPage] = useState(20)
  const [pages, setPages] = useState(null)

  // State
  const [leads, setLeads] = useState([])
  const [filter, setFilter] = useState(null)
  const [searchTerm, setSearchTerm] = useState(null)
  const [loading, setLoading] = useState(true)
  const [sortedColumn, setSortedColumn] = useState('temperature')
  const [expandedRows, setExpandedRows] = useState([])
  const [loadingExport, setLoadingExport] = useState(false)

  const handleErrors = (m) => toast(m, 'error')

  /**
   * Gets the updated list of leads; updates pagination.
   * @param {string} url
   * @returns list of results
   */
  const getUpdatedLeads = async (url) => {
    const response = await getLeads(url, handleErrors, setLoading)

    if (response) {
      setLeads(response.results)
      setTotalRows(response.count)
      setPages({ next: response.next, previous: response.previous })
    }
  }

  /**
   * Updates the search query based on `search`.
   * @param {string} search
   */
  const updateSearch = (search) => {
    let updatedFilter = ''
    if (search) updatedFilter = `q=${search}`

    setFilter(updatedFilter)
  }

  const filterLeads = useCallback(_.debounce(updateSearch, 500), [])

  /**
   * When the filter or row count changes, get the updated list of attendees.
   */
  useEffect(() => {
    if (filter && event?.eventExhibitor) {
      getUpdatedLeads(
        `/events/${eventId}/exhibitors/${event.eventExhibitor.id}/leads/?expand=scanned_by,scanned_by_user,attendee&limit=${perPage}&page=1&${filter}&order_by=${sortedColumn}`,
      )
    } else if (event?.eventExhibitor) {
      getUpdatedLeads(
        `/events/${eventId}/exhibitors/${event.eventExhibitor.id}/leads/?expand=scanned_by,scanned_by_user,attendee&limit=${perPage}&page=1&order_by=${sortedColumn}`,
      )
    }
  }, [event?.eventExhibitor, filter, perPage])

  return (
    <div className="h-full w-full">
      <StateContainer>
        <div className="relative flex h-full w-full flex-col space-y-3 overflow-y-auto p-3">
          <div className="flex flex-col space-y-3 sm:flex-row sm:place-items-center sm:justify-between sm:space-y-0">
            <EventHeader event={event} />

            <Button
              background="bg-purple border-purple hover:bg-purple-600 self-start"
              icon={<ArrowDownTrayIcon className="h-3 stroke-white sm:h-4" />}
              label="Export Leads"
              loading={loadingExport}
              onClick={async () => {
                const task = await createLeadsExport(
                  eventId,
                  event.eventExhibitor.id,
                  handleErrors,
                  setLoadingExport,
                )

                if (task) {
                  setType('Download')
                  setParent({
                    type: 'leads',
                    label: 'Leads',
                    id: task.id,
                    eventExhibitorId: event.eventExhibitor.id,
                    eventId,
                    eventName: event.name,
                    fileName: `${event.name}_Leads`,
                  })
                  setTask(task)
                }
              }}
            />
          </div>

          <div className="flex items-center gap-4">
            <span className="text-md font-semibold">Leads</span>

            <div className="flex items-center rounded-md bg-blue-50 px-2 md:hidden">
              <InformationCircleIcon className="mr-2 h-5 text-blue-800" />
              <span className="py-2 text-sm font-medium text-blue-dark">
                Add and manage leads in the app
              </span>
            </div>
          </div>

          <div className="flex flex-col justify-between gap-4 md:flex-row">
            <TextInput
              className="flex w-full rounded-full py-2.5 pl-10 pr-4 placeholder:font-normal placeholder:text-gray-600"
              containerClassName="md:min-w-[40%] md:max-w-[60%]"
              icon={<MagnifyingGlassIcon className="ml-2 h-5 text-gray-dark" aria-hidden="true" />}
              id="search"
              endIcon={
                filter ? (
                  <button
                    type="button"
                    onClick={() => {
                      filterLeads('')
                      setSearchTerm('')
                    }}
                  >
                    <XMarkIcon className="mr-2 h-5 text-gray-dark" aria-hidden="true" />
                  </button>
                ) : null
              }
              name="search"
              onChange={(e) => {
                filterLeads(e.target.value)
                setSearchTerm(e.target.value)
              }}
              placeholder="Looking for somebody?"
              value={searchTerm}
            />

            <div className="hidden items-center rounded-md bg-blue-50 px-4 md:flex md:min-w-[30%]">
              <InformationCircleIcon className="mr-2 h-5 text-blue-800" />
              <span className="py-2 text-sm font-medium text-blue-dark md:py-0 xl:pr-20">
                Add and manage leads in the app
              </span>
            </div>
          </div>

          <div className="grid">
            <CustomDataTable
              columns={[
                {
                  id: 'name',
                  grow: 1,
                  name: 'Name',
                  selector: (row) => `${row.attendeeFirstName} ${row.attendeeLastName}`,
                  cell: (row) => (
                    <div className="flex flex-col">
                      <span className="font-bold text-dark">
                        {row.attendeeFirstName} {row.attendeeLastName}
                      </span>
                      {row.scannedAt && (
                        <span className="text-xs text-dark">
                          Scanned at:{' '}
                          {dayjs(row.scannedAt).tz(event.timezone).format('M/D/YYYY h:mm A')}
                        </span>
                      )}
                    </div>
                  ),
                  minWidth: '200px',
                  sortable: true,
                  sortBy: 'attendee_first_name',
                },
                {
                  id: 'temperature',
                  grow: 0.25,
                  name: 'Temp',
                  selector: (row) => row.temperature,
                  cell: (row) => (
                    <div className="">
                      {row.temperature === 'Hot' && <span className="text-2xl">🥳</span>}
                      {row.temperature === 'Warm' && <span className="text-2xl">🤔</span>}
                      {row.temperature === 'Cold' && <span className="text-2xl">🥶</span>}
                    </div>
                  ),
                  sortable: true,
                  sortBy: 'temperature',
                },
                {
                  id: 'contact',
                  grow: 1,
                  name: 'Contact',
                  selector: (row) => `${row.attendeeEmail} ${row.attendeePhoneNumber}`,
                  cell: (row) => (
                    <div className="flex flex-col">
                      {!row.attendeeEmail && !row.attendeePhoneNumber ? (
                        <span>N/A</span>
                      ) : (
                        <>
                          <span>{row.attendeeEmail}</span>
                          <span>
                            {row.attendeePhoneNumber && formatPhoneNumber(row.attendeePhoneNumber)}
                          </span>
                        </>
                      )}
                    </div>
                  ),
                  minWidth: '200px',
                  sortable: true,
                  sortBy: 'attendee_email',
                },
                {
                  id: 'organization-title',
                  grow: 0.5,
                  name: 'Organization/Title',
                  selector: (row) => `${row.attendeeCompanyName} ${row.attendeeTitle}`,
                  cell: (row) => (
                    <div className="flex flex-col">
                      {!row.attendeeCompanyName && !row.attendeeTitle ? (
                        <span>N/A</span>
                      ) : (
                        <>
                          <span>{row.attendeeCompanyName}</span>
                          <span>{row.attendeeTitle}</span>
                        </>
                      )}
                    </div>
                  ),
                  minWidth: '200px',
                  sortable: true,
                  sortBy: 'attendee_company_name',
                },
                {
                  id: 'scanned-by',
                  grow: 0.5,
                  name: 'Scanned By',
                  selector: (row) => row.scannedBy,
                  cell: (row) => (
                    <div>
                      {!row.scannedBy ? (
                        <span>N/A</span>
                      ) : (
                        <span>
                          {row.scannedBy.user.firstName} {row.scannedBy.user.lastName}
                        </span>
                      )}
                    </div>
                  ),
                  minWidth: '150px',
                  sortable: true,
                  sortBy: 'scanned_by__user__first_name',
                },
              ]}
              conditionalRowStyles={[
                {
                  when: (row) => expandedRows.includes(row.id),
                  style: {
                    backgroundColor: '#E3F2F9',
                    borderBottom: 'none',
                  },
                },
              ]}
              customStyles={{
                ...defaultStyles,
                expanderButton: {
                  style: {
                    '&:hover:enabled': {
                      backgroundColor: 'transparent',
                    },
                    '&:hover:not(:disabled)': {
                      backgroundColor: 'transparent',
                    },
                    '&:focus': {
                      backgroundColor: 'transparent',
                    },
                  },
                },
              }}
              data={leads}
              defaultSortFieldId="temperature"
              defaultSortAsc
              expandableIcon={{
                collapsed: <ChevronDownIcon className="h-5 stroke-dark" />,
                expanded: <ChevronUpIcon className="h-5 stroke-dark" />,
              }}
              expandableRows
              expandableRowsComponent={ExpandedNotes}
              onChangePage={(page) =>
                handlePagination(
                  page,
                  currentPage,
                  perPage,
                  totalRows,
                  pages,
                  setCurrentPage,
                  getUpdatedLeads,
                  `/events/${eventId}/exhibitors/${event.eventExhibitor.id}/leads/?expand=scanned_by,scanned_by_user&limit=`,
                  `order_by=${sortedColumn}`,
                )
              }
              onChangeRowsPerPage={async (currentRowsPerPage) => setPerPage(currentRowsPerPage)}
              onRowExpandToggled={(expanded, row) => {
                if (expanded) {
                  setExpandedRows([...expandedRows, row.id])
                } else {
                  setExpandedRows(expandedRows.filter((r) => r !== row.id))
                }
              }}
              onSort={(column, direction) => {
                const d = direction === 'asc' ? '' : '-'
                setSortedColumn(`${d}${column.sortBy}`)
                const url = `/events/${eventId}/exhibitors/${event.eventExhibitor.id}/leads/?expand=scanned_by,scanned_by_user,attendee&order_by=${d}${column.sortBy}&limit=${perPage}`
                getUpdatedLeads(url)
              }}
              pagination
              paginationPerPage={perPage}
              paginationRowsPerPageOptions={[10, 15, 20, 30, 50]}
              paginationTotalRows={totalRows}
              paginationServer
              progressPending={loading}
              sortServer
            />
          </div>
        </div>
      </StateContainer>
    </div>
  )
})

export default EventLeads
