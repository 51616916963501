import React, { useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Layer, Stage } from 'react-konva'
import QRious from 'qrious'
import JSPDF from 'jspdf'

// Components
import { BadgeGroup } from '../BadgeGroup'
import { BadgeImage } from '../BadgeImage'
import { BadgeRectangle } from '../BadgeRectangle'
import { BadgeText } from '../BadgeText'

// Utils
import { validateLogic } from '../../utils/helpers'

const MERGE_FIELD = /{{\s(.*?)\s}}/

/**
 *
 * BadgePreview
 *
 */
const BadgePreview = ({
  attendee,
  attendees,
  badgeImages,
  categories,
  config,
  endPreview,
  kiosk,
}) => {
  // State
  const [attendeeToPrint, setAttendeeToPrint] = useState({ index: 0, attendee: null })
  const [badge, setBadge] = useState(null)
  const [lastBadge, setLastBadge] = useState(false)

  // Refs
  const frontRef = useRef()
  const backRef = useRef()
  const extra1Ref = useRef()
  const extra2Ref = useRef()
  const refs = [frontRef, backRef, extra1Ref, extra2Ref]

  // Timeouts
  const attendeeTimeout = useRef()
  const badgeTimeout = useRef()
  const lastBadgeTimeout = useRef()

  /**
   * Handles filling in attendee data for text fields.
   * @param {object} attrs
   */
  const updateTextField = (attendeeDetails, attrs) => {
    const updatedAttrs = attrs

    // Check if the text field has a merge field
    if (MERGE_FIELD.test(updatedAttrs.text)) {
      // Handle the full name merge field because it requires first and last name
      if (updatedAttrs.accessor === 'fullName') {
        updatedAttrs.text = `${attendeeDetails.firstName || ''} ${attendeeDetails.lastName || ''}`
      }
      // Manually handle the `presetLocation` merge field since this changes
      // depending on the attendee's country.
      else if (updatedAttrs.accessor === 'presetLocation') {
        // For Non-API Synced Attendees in the US
        if (
          (attendeeDetails.country !== undefined && attendeeDetails.country === 'United States') ||
          attendeeDetails.country === 'US'
        ) {
          // Only concatenate city and state if both are present
          if (attendeeDetails.city && attendeeDetails.state)
            updatedAttrs.text = `${attendeeDetails.city}, ${attendeeDetails.state}`
          else updatedAttrs.text = attendeeDetails.city || attendeeDetails.state || ''
        }
        // TODO: remove once we are sure base location works as expected
        // For API Synced Attendees in the US
        else if (
          attendeeDetails.customData &&
          attendeeDetails.customData.country !== undefined &&
          (attendeeDetails.customData.country === 'United States' ||
            attendeeDetails.customData?.country === 'US')
        ) {
          // Only concatenate city and state if both are present
          if (attendeeDetails.customData.city && attendeeDetails.customData.state)
            updatedAttrs.text = `${attendeeDetails.customData.city}, ${attendeeDetails.customData.state}`
          else
            updatedAttrs.text =
              attendeeDetails.customData.city || attendeeDetails.customData.state || ''
        }
        // For Non-API Synced Attendees outside the US
        else if (attendeeDetails.country !== undefined) {
          // Only concatenate city and country if both are present
          if (attendeeDetails.city && attendeeDetails.country)
            updatedAttrs.text = `${attendeeDetails.city}, ${attendeeDetails.country}`
          else updatedAttrs.text = attendeeDetails.city || attendeeDetails.country || ''
        }
        // TODO: remove once we are sure base location works as expected
        // For API Synced Attendees outside the US
        else if (attendeeDetails.customData && attendeeDetails.customData.country !== undefined) {
          // Only concatenate city and country if both are present
          if (attendeeDetails.customData.city && attendeeDetails.customData.country)
            updatedAttrs.text = `${attendeeDetails.customData.city}, ${attendeeDetails.customData.country}`
          else
            updatedAttrs.text =
              attendeeDetails.customData.city || attendeeDetails.customData.country || ''
        } else {
          updatedAttrs.text = ''
        }
      }
      // Only pull from `customData` if the `accessor` isn't valid for the base attendee
      // Get the merge field accessor and verify that the attendee has `customData`
      else if (
        (attendeeDetails[updatedAttrs.accessor] === undefined ||
          !attendeeDetails[updatedAttrs.accessor]) &&
        attendeeDetails.customData &&
        attendeeDetails.customData[updatedAttrs.accessor] !== undefined
      ) {
        updatedAttrs.text = `${attendeeDetails.customData[updatedAttrs.accessor] || ''}`
      }
      // Only pull from `customData` if the `accessor` isn't valid for the base attendee
      // If the attendee does not have this custom data (e.g Cvent doesn't return it if its not set)
      // then we should just replace the merge field with an empty string.
      else if (
        (attendeeDetails[updatedAttrs.accessor] === undefined ||
          !attendeeDetails[updatedAttrs.accessor]) &&
        attendee.customData &&
        attendee.customData[updatedAttrs.accessor] === undefined
      ) {
        updatedAttrs.text = ''
      }
      // Otherwise, update the text field with the attendee data
      else {
        updatedAttrs.text = `${attendeeDetails[updatedAttrs.accessor] || ''}`
      }

      // Remove any escaped quotes
      updatedAttrs.text = updatedAttrs.text.replaceAll(/[\\"]/g, '')
    }

    return updatedAttrs
  }

  /**
   * Parse elements and update text fields with attendee data.
   * @param {object} element
   */
  const parseElement = (attendeeDetails, element) => {
    const updatedElement = element

    // Fill in text fields with attendee data
    if (updatedElement.className === 'Text') {
      updatedElement.attrs = updateTextField(attendeeDetails, updatedElement.attrs)
    }
    // If the element is a QR code, generate the QR code using the attendee id
    // Don't generate if the attendee hasn't opted into GDPR or if the badge is a quick badge
    else if (
      updatedElement.className === 'Image' &&
      updatedElement.attrs.qrImage &&
      attendeeDetails.gdprOptIn &&
      !attendeeDetails.quickBadge
    ) {
      const qr = new QRious({
        size: updatedElement.attrs.width,
        value: attendeeDetails.id,
      })

      updatedElement.attrs.src = qr.toDataURL()
    }
    // Fill in text fields with attendee data for groups
    else if (updatedElement.className === 'Group') {
      updatedElement.children = _.map(updatedElement.children, (child) => {
        const updatedChild = child

        if (updatedChild.className === 'Text') {
          updatedChild.attrs = updateTextField(attendeeDetails, updatedChild.attrs)
        } else if (updatedElement.className === 'Image' && updatedElement.attrs.qrImage) {
          const qr = new QRious({
            size: updatedElement.attrs.width,
            value: attendeeDetails.id,
          })

          updatedElement.attrs.src = qr.toDataURL()
        }

        return updatedChild
      })
    }
  }

  /**
   * Generate a badge for the specified `attendee`.
   * @param {object} attendee
   */
  const generateBadgeForAttendee = (attendeeDetails) => {
    // Generate badge config for printing
    const badgeConfig = { 1: _.cloneDeep(config.configObject[1]) }

    // If there are more than 1 panels, add the second panel (mirrored if enabled)
    if (config.numPanels > 1) {
      badgeConfig['2'] = _.cloneDeep(config.configObject[`${config.enableMirrorContent ? 1 : 2}`])

      // If there are more than 2 panels, add the remaining panels
      if (config.numPanels > 2) {
        // Add remaining panels
        _.forEach(_.range(3, config.numPanels + 1), (i) => {
          badgeConfig[`${i}`] = _.cloneDeep(config.configObject[`${i}`])
        })
      }
    }

    // Loop through badge config and replace any merge fields with attendee data
    _.forEach(_.keys(badgeConfig), (panelIndex) => {
      const panel = badgeConfig[panelIndex]
      _.forEach(panel.children, (layer) => {
        // If the layer has children, loop through them
        if (layer.children.length > 0) {
          _.forEach(layer.children, (child) => {
            let updatedChild = child
            // If the child is a `Group`, then we need to loop through those children
            if (child.class_name === 'Group') {
              updatedChild.children = _.map(updatedChild.children, (groupChild) =>
                parseElement(attendeeDetails, groupChild),
              )
            } else {
              updatedChild = parseElement(attendeeDetails, child)
            }
          })
        }
      })
    })

    setBadge(badgeConfig)
  }

  /**
   * Create a new instance of JSPDF.
   */
  const pdf = useMemo(() => {
    if ((attendees || attendee) && config) {
      // Calculate the height of the pdf
      const count = attendees ? attendees.length : 1
      const height =
        config.configObject[1].attrs.height * 2 * _.keys(config.configObject).length * count

      return new JSPDF({
        orientation: 'p',
        unit: 'px',
        format: [config.configObject[1].attrs.width * 2, height],
        hotfixes: ['px_scaling'],
      })
    }

    return null
  }, [attendees, attendee, config])

  /**
   * Start badge generation for the specified `attendee`.
   */
  useEffect(() => {
    if (attendeeToPrint.attendee) {
      generateBadgeForAttendee(attendeeToPrint.attendee)
    }
  }, [attendeeToPrint])

  /**
   * Start badge generation for the specified `attendee` or bulk generate
   * for the specified `attendees`.
   */
  useEffect(() => {
    if (attendee) {
      setAttendeeToPrint({ ...attendeeToPrint, attendee })
    } else if (attendees) {
      // Loop through each attendee and generate a badge for each
      _.forEach(attendees, (attendeeDetails, i) => {
        // Generate badge for each attendee, waiting in between
        attendeeTimeout.current = setTimeout(() => {
          setAttendeeToPrint({ index: i, attendee: attendeeDetails })
        }, 800 * (i + 1))
      })
    }
  }, [attendee, attendees])

  /**
   * When bulk printing, open the pdf in a new tab after the last badge is generated.
   */
  useEffect(() => {
    if (lastBadge) {
      lastBadgeTimeout.current = setTimeout(() => {
        // Open the pdf in a new tab
        pdf.autoPrint()
        const blob = pdf.output('bloburl')

        // If we are previewing the badge, open the pdf in a new tab
        if (!kiosk) window.open(blob)
        // Otherwise open print dialog with the file
        else {
          const hiddenFrame = document.createElement('iframe')
          hiddenFrame.style.position = 'fixed'
          hiddenFrame.style.width = '1px'
          hiddenFrame.style.height = '1px'
          hiddenFrame.style.opacity = '0.01'
          const isSafari = /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent)
          if (isSafari) {
            // fallback in safari
            hiddenFrame.onload = () => {
              try {
                hiddenFrame.contentWindow.document.execCommand('print', false, null)
              } catch (e) {
                hiddenFrame.contentWindow.print()
              }
            }
          }
          hiddenFrame.src = blob
          document.body.appendChild(hiddenFrame)

          setTimeout(() => {
            document.body.removeChild(hiddenFrame)
          }, 3000) // 3 seconds
        }

        // Reset everything and end the preview
        setBadge(null)
        setAttendeeToPrint({ index: 0, attendee: null })
        setLastBadge(false)

        // End the preview
        endPreview()
      }, 500)
    }

    return () => {
      if (attendeeTimeout.current) clearTimeout(attendeeTimeout.current)
      if (badgeTimeout.current) clearTimeout(badgeTimeout.current)
      if (lastBadgeTimeout.current) clearTimeout(lastBadgeTimeout.current)
    }
  }, [lastBadge])

  useEffect(() => {
    if (badge) {
      if (!attendees) {
        badgeTimeout.current = setTimeout(() => {
          // Get image data urls for each panel
          const updatedBadgeImages = []
          _.forEach(refs, (ref) => {
            if (ref.current) {
              const dataUrl = ref.current.toDataURL({ pixelRatio: 2 })
              updatedBadgeImages.push(dataUrl)
            }
          })

          // Add each panel as an image to the pdf
          _.forEach(updatedBadgeImages, (image, index) => {
            pdf.addImage(
              image,
              0,
              badge[1].attrs.height * 2 * index,
              badge[1].attrs.width * 2,
              badge[1].attrs.height * 2,
            )
          })

          // Open the pdf in a new tab
          pdf.autoPrint()
          const blob = pdf.output('bloburl')

          // If we are previewing the badge, open the pdf in a new tab
          if (!kiosk) window.open(blob)
          // Otherwise open print dialog with the file
          else {
            const hiddenFrame = document.createElement('iframe')
            hiddenFrame.style.position = 'fixed'
            hiddenFrame.style.width = '1px'
            hiddenFrame.style.height = '1px'
            hiddenFrame.style.opacity = '0.01'
            const isSafari = /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent)
            if (isSafari) {
              // fallback in safari
              hiddenFrame.onload = () => {
                try {
                  hiddenFrame.contentWindow.document.execCommand('print', false, null)
                } catch (e) {
                  hiddenFrame.contentWindow.print()
                }
              }
            }
            hiddenFrame.src = blob
            document.body.appendChild(hiddenFrame)

            setTimeout(() => {
              document.body.removeChild(hiddenFrame)
            }, 3000) // 3 seconds
          }

          // Clear the badge and attendee
          setBadge(null)
          setAttendeeToPrint({ index: 0, attendee: null })

          // End the preview
          endPreview()
        }, 100)
      } else {
        badgeTimeout.current = setTimeout(() => {
          // Get image data urls for each panel
          const updatedBadgeImages = []
          _.forEach(refs, (ref) => {
            if (ref.current) {
              const dataUrl = ref.current.toDataURL({ pixelRatio: 2 })
              updatedBadgeImages.push(dataUrl)
            }
          })
          const updatedHeight = badge[1].attrs.height * 2

          // Calculate the y offset for the images
          let y = 0
          if (attendeeToPrint.index > 0) {
            // Multiple the height of each badge image by 2, the number of panels, and the number of attendees added
            // to get the y offset for the next badge
            y = updatedHeight * updatedBadgeImages.length * attendeeToPrint.index
          }

          // Add each panel as an image to the pdf
          _.forEach(updatedBadgeImages, (image) => {
            pdf.addImage(image, 0, y, badge[1].attrs.width * 2, updatedHeight)

            // Increment the y offset for the next badge
            y += updatedHeight
          })

          // If this is the last badge, trigger the print
          if (attendeeToPrint.index === attendees.length - 1) {
            setLastBadge(true)
          }

          // Reset badge refs
          frontRef.current = null
          backRef.current = null
          extra1Ref.current = null
          extra2Ref.current = null

          // Clear the badge and attendee
          setBadge(null)
          setAttendeeToPrint({ index: 0, attendee: null })
        }, 400)
      }
    }

    return () => {
      if (!attendees) {
        if (attendeeTimeout.current) clearTimeout(attendeeTimeout.current)
        if (badgeTimeout.current) clearTimeout(badgeTimeout.current)
        if (lastBadgeTimeout.current) clearTimeout(lastBadgeTimeout.current)
      }
    }
  }, [badge])

  const checkVisibilityLogic = (element) => {
    let visibilityLogic = element.attrs.visibility

    // If `visibilityLogic` is the old format, convert to logic array
    if (_.isString(visibilityLogic)) {
      const [field, operator, value] = element.attrs.visibility.split(':')
      visibilityLogic = [
        {
          inclusionLogic: 'AND',
          mergeField: field,
          operator,
          value,
        },
      ]
    }

    return validateLogic(visibilityLogic, attendeeToPrint.attendee)
  }

  /**
   * Configures the visibility for the specified `element`.
   * - Layers can only be configured visible for a specific category.
   * - All other elements can be configured based on a custom merge field value.
   *   - Visibility for an element is stored as `<field>:<operator>:<value>`.
   *   - Visbility can be based on equality or contains.
   *   - Sessions must be verified by the session id.
   * @param {object} element
   * @returns {bool} whether to show the element or not
   */
  const configureVisibility = (element) => {
    // `base` and `always` elements are always visible, but not qr codes
    if (
      (element.attrs.id === 'base' || element.attrs.visibility === 'always') &&
      !element.attrs.qrImage
    )
      return true

    // Check if the element is a QR code and should be displayed
    if (
      element.className === 'Image' &&
      element.attrs.qrImage &&
      // Only if the QR code should alwasy be visible
      element.attrs.visibility === 'always' &&
      // If the attendee opted in to GDPR or the QR code is scrambled
      (attendeeToPrint.attendee.gdprOptIn || element.attrs.qrPrint === 'scrambled') &&
      // If the badge is not a quick badge
      !attendeeToPrint.attendee.quickBadge
    )
      return true
    // Check layer visibility. If the layer is marked for a specific category,
    // we need to make sure this badge is for that category.
    if (element.className === 'Layer' && categories) {
      // Pull out the category this layer is visible for (uses category id)
      const category = element.attrs.visibility.split(':')[2]

      // Only display if the attendee has this category
      if (attendeeToPrint.attendee.categoryId === category) {
        return true
      }
    }

    // Check element visibility. These are based on custom merge field values.
    if (element.attrs.id !== 'base' && element.attrs.visibility !== 'always') {
      const isVisible = checkVisibilityLogic(element)

      // Check if the element is a QR code and should not be printed based
      // on the attendee's GDPR opt-in status or if the badge is a quick badge
      if (
        isVisible &&
        element.className === 'Image' &&
        element.attrs.qrImage &&
        element.attrs.qrPrint === 'no-print' &&
        !attendeeToPrint.attendee.gdprOptIn &&
        !attendeeToPrint.attendee.quickBadge
      ) {
        return false
      }

      return isVisible
    }

    // Default to false if the visibility conditions are not met
    return false
  }

  /**
   * Renders child elements.
   * @param {object} child child element to render
   * @param {string} transformerId id of the transformer
   * @returns
   */
  const renderChild = (child, transformerId) => {
    const visible = configureVisibility(child)

    let base64 = null
    if (kiosk && child.className === 'Image' && !child.attrs.qrImage) {
      // Find the matching image from the `badgeImages`
      const match = _.find(badgeImages, (i) => child.attrs.badgeImagePath.includes(i.id))
      if (match) {
        base64 = match.base64
      }
    } else {
      base64 = _.has(badgeImages, child.attrs.id) ? badgeImages[child.attrs.id]?.base64 : null
    }

    switch (child.className) {
      case 'Image':
        return (
          <BadgeImage
            base64={base64}
            data={child}
            draggable={false}
            key={child.attrs.id}
            onChange={() => {}}
            transformerId={transformerId}
            visible={visible}
          />
        )
      case 'Rect':
        return (
          <BadgeRectangle
            data={child}
            draggable={false}
            key={child.attrs.id}
            onChange={() => {}}
            transformerId={transformerId}
            visible={visible}
          />
        )
      case 'Text':
        return (
          <BadgeText
            autoFontSize
            data={child}
            draggable={false}
            editable={false}
            font={config.defaultGoogleFont}
            key={child.attrs.id}
            onChange={() => {}}
            transformerId={transformerId}
            visible={visible}
          />
        )
      case 'Group':
        return (
          <BadgeGroup
            autoFontSize
            badgeImages={badgeImages}
            data={child}
            defaultFont={config.defaultGoogleFont}
            key={child.attrs.id}
            kiosk={kiosk}
            onChange={() => {}}
            transformerId={transformerId}
            visible={visible}
          />
        )
      default:
        return null
    }
  }

  return badge ? (
    <div className="hidden">
      {_.map(_.keys(badge), (panelIndex) => (
        <Stage
          id={badge[panelIndex].attrs.id}
          key={badge[panelIndex].attrs.id}
          height={badge[panelIndex].attrs.height}
          offsetX={panelIndex === '2' ? badge[panelIndex].attrs.width : 0}
          offsetY={panelIndex === '2' ? badge[panelIndex].attrs.height : 0}
          ref={refs[panelIndex - 1]}
          scaleY={panelIndex === '2' ? -1 : 1}
          scaleX={panelIndex === '2' ? -1 : 1}
          width={badge[panelIndex].attrs.width}
        >
          {/* All other layers */}
          {_.map(badge[panelIndex].children, (layer, index) => (
            <Layer
              visible={configureVisibility(layer)}
              id={layer.attrs.id}
              key={layer.attrs.id}
              pixelRatio={2}
            >
              {_.map(layer.children, (child) => renderChild(child, `layer:${index}`))}
            </Layer>
          ))}
        </Stage>
      ))}
    </div>
  ) : null
}

BadgePreview.defaultProps = {
  attendee: null,
  attendees: null,
  badgeImages: null,
  categories: null,
  endPreview: () => {},
  kiosk: false,
}

BadgePreview.propTypes = {
  attendee: PropTypes.object,
  attendees: PropTypes.array,
  badgeImages: PropTypes.array,
  categories: PropTypes.object,
  config: PropTypes.object.isRequired,
  endPreview: PropTypes.func,
  kiosk: PropTypes.bool,
}

export default BadgePreview
