import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Disclosure } from '@headlessui/react'
import {
  ArrowPathIcon,
  CheckCircleIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  XCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid'

// Store
import { TaskStoreContext } from '../../stores/TaskStore'

// Service
import { getAttendeeImportStatus } from '../../services/attendees.service'
import { getLeadsExportStatus } from '../../services/leads.service'
import { markExhibitorDownloadTaskComplete } from '../../services/events.service'
import {
  getExhibitorImportStatus,
  getExhibitorLeadExportStatus,
  getExhibitorsExportStatus,
} from '../../services/exhibitors.service'
import {
  getAdminSummaryExportStatus,
  getAdminTransactionExportStatus,
  getEventTransactionReportStatus,
} from '../../services/reports.service'

/**
 * TaskProgress
 */
const TaskProgress = observer(() => {
  // Context
  const { parent, task, type, status, setParent, setStatus, setTask, setType } =
    useContext(TaskStoreContext)

  // State
  const [loading, setLoading] = useState(false)

  const totalFiles = task ? task.totalFiles : 0

  /**
   * Set up hook to trigger when `task` changes
   * - This will start a timeout task (if `task` is not null and the interval is not already running)
   * - The interval task will check the status of the task every 3 seconds
   * - When the task is complete, the interval task will be cleared
   */
  useEffect(() => {
    let interval = null
    if (task) {
      setLoading(true)
      setStatus('in-progress')

      if (interval === null) {
        interval = setInterval(async () => {
          let updatedTask = null

          switch (parent?.type) {
            case 'exhibitor-import':
              updatedTask = await getExhibitorImportStatus(parent.id, task.id)
              setTask(updatedTask)
              break
            case 'attendee-import':
              updatedTask = await getAttendeeImportStatus(parent.id, task.id)
              setTask(updatedTask)
              break
            case 'event-transaction':
              updatedTask = await getEventTransactionReportStatus(parent.id, task.id)
              setTask(updatedTask)
              break
            case 'leads':
              updatedTask = await getLeadsExportStatus(
                parent.eventId,
                parent.eventExhibitorId,
                task.id,
              )
              setTask(updatedTask)
              break
            case 'exhibitors':
              updatedTask = await getExhibitorsExportStatus(parent.eventId, task.id)
              setTask(updatedTask)
              break
            case 'exhibitor-leads':
              updatedTask = await getExhibitorLeadExportStatus(parent.exhibitorId, task.id)
              setTask(updatedTask)
              break
            case 'admin-summary':
              updatedTask = await getAdminSummaryExportStatus(task.id)
              setTask(updatedTask)
              break
            case 'admin-transaction':
              updatedTask = await getAdminTransactionExportStatus(task.id)
              setTask(updatedTask)
              break
            default:
              break
          }
        }, 3000)
      }

      if (task.status !== 'Pending' && task.status !== 'Processing') {
        setLoading(false)
        clearInterval(interval)
      }
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [task])

  const isCsvExport = [
    'leads',
    'exhibitors',
    'exhibitor-leads',
    'admin-summary',
    'admin-transaction',
    'event-transaction',
  ].includes(parent?.type)

  /**
   * Set up hook to trigger when `task` changes
   * - If the `type` is `Download`
   *   - This will auto-download the zipped file when the task is complete
   * - If the `type` is `Upload`
   *   - This will set the status to `completed` when the task is complete
   */
  useEffect(() => {
    const downloadZippedFile = async () => {
      const response = await fetch(task.zippedFile)
      const blobUrl = await response.blob()
      const objectUrl = window.URL.createObjectURL(blobUrl)
      const downloadLink = document.createElement('a')
      downloadLink.href = objectUrl
      downloadLink.download = 'Exported_Documents.zip'
      downloadLink.target = '_blank'
      downloadLink.click()
      downloadLink.remove()

      // Track downloaded so that if the user leaves the listener open, it doesn't
      // continue to fire the download.
      setStatus('completed')
    }

    const downloadCSV = async () => {
      const response = await fetch(task.file)
      const blobUrl = await response.blob()
      const objectUrl = window.URL.createObjectURL(blobUrl)
      const downloadLink = document.createElement('a')
      downloadLink.href = objectUrl
      downloadLink.download = `${parent.fileName}.xlsx`
      downloadLink.target = '_blank'
      downloadLink.click()
      downloadLink.remove()

      // Track downloaded so that if the user leaves the listener open, it doesn't
      // continue to fire the download.
      setStatus('completed')

      // If this is the exhibitor lead summary, mark the tasklist item completed
      if (parent.label === 'Leads Summary') {
        markExhibitorDownloadTaskComplete(parent.eventId, parent.eventExhibitorId)
      }
    }

    if (
      task &&
      type === 'Download' &&
      isCsvExport &&
      status !== 'completed' &&
      task.status === 'Exported'
    ) {
      downloadCSV()
    } else if (task && type === 'Download' && task.zippedFile && status !== 'completed') {
      downloadZippedFile()
    } else if (task && type === 'Upload' && task.status === 'Imported') {
      setStatus('completed')
    }
  }, [task])

  const configureText = () => {
    if (loading && type === 'Download' && isCsvExport) return `Downloading ${parent.label}.`
    if (loading && type === 'Download')
      return `Downloading & Zipping ${totalFiles} File${totalFiles > 1 ? 's' : ''}`
    if (loading && type === 'Upload') return `Uploading File...`
    if (task && task.status === 'Expired') return `${type} Expired, Try Again`
    if (task && task.status === 'Failed') return `${type} Failed, Try Again`
    return `${type} finished.`
  }

  const renderCollapseOrCloseIcon = (open) => {
    if (loading) {
      return <div className="w-5">{!open ? <ChevronUpIcon /> : <ChevronDownIcon />}</div>
    }

    return (
      <button
        onClick={(e) => {
          e.stopPropagation()

          // Clear the interval and task to close the progress listener
          setParent(null)
          setType(null)
          setTask(null)
        }}
        type="button"
      >
        <XMarkIcon className="w-5 stroke-gray" />
      </button>
    )
  }

  const renderStatusIcon = () => {
    if (loading) {
      return (
        <div className="h-10 w-10">
          {/* eslint-disable-next-line tailwindcss/no-custom-classname, tailwindcss/classnames-order */}
          <svg className="h-10 w-10 motion-safe:animate-spin-slow" viewBox="0 0 40 40">
            <ArrowPathIcon className="h-10 w-10" aria-hidden="true" />
          </svg>
        </div>
      )
    }

    if (task && (task.status === 'Imported' || task.status === 'Exported')) {
      return (
        <div className="w-6">
          <CheckCircleIcon size={48} strokeWidth={2} className="text-success" />
        </div>
      )
    }

    return (
      <div className="flex w-full flex-col place-items-center gap-2 p-1">
        <div className="w-6">
          <XCircleIcon size={48} strokeWidth={2} className="text-error-icon" />
        </div>
        {task.statusMessage && (
          <span className="text-center text-xs text-error">{task.statusMessage}</span>
        )}
      </div>
    )
  }

  return task ? (
    <div className="absolute bottom-10 right-3 z-50 w-72 rounded-lg bg-gray-200 px-3 py-2 shadow-md">
      <Disclosure defaultOpen>
        {({ open }) => (
          <>
            <Disclosure.Button className="flex w-full flex-row justify-between">
              <span className="text-sm">{configureText()}</span>

              {renderCollapseOrCloseIcon(open)}
            </Disclosure.Button>
            <Disclosure.Panel>
              <div className="flex justify-center">{renderStatusIcon()}</div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  ) : null
})

export default TaskProgress
