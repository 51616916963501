import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { ArrowPathIcon, CameraIcon } from '@heroicons/react/20/solid'

// Components
import { Modal } from '../Modal'

// Icons
import { UserIcon } from '../UserIcon'

// Service & Utils
import { getAttendeePhoto } from '../../services/attendees.service'
import { toast } from '../../utils/helpers'

/**
 *
 * PhotoVerificationPopup
 *
 */
const PhotoVerificationPopup = ({ attendee, eventId, close, continueCheckIn }) => {
  // State
  const [loading, setLoading] = useState(true)
  const [verificationPhoto, setVerificationPhoto] = useState(null)

  const handleError = (m) => toast(m, 'error')

  useEffect(() => {
    const getUpdatedAttendeePhoto = async () => {
      const response = await getAttendeePhoto(
        eventId,
        attendee.id,
        () => handleError('Unable to load photo.'),
        setLoading,
      )
      if (response) setVerificationPhoto(response.photoVerificationImageUrl)
    }

    getUpdatedAttendeePhoto()
  }, [])

  const renderLoadingOrContent = () => {
    if (loading) {
      return (
        <div className="flex h-full w-full flex-col items-center justify-center space-y-2">
          <span className="text-2xl font-bold">Loading Photo...</span>

          <span className="flex items-center pr-3">
            <div className="h-5 w-5">
              {/* eslint-disable-next-line tailwindcss/no-custom-classname, tailwindcss/classnames-order */}
              <svg className="h-5 w-5 motion-safe:animate-spin-slow" viewBox="0 0 40 40">
                <ArrowPathIcon className="h-5 w-5" aria-hidden="true" />
              </svg>
            </div>
          </span>
        </div>
      )
    }

    return (
      <>
        <span className="text-sm text-gray-600">
          Please verify that the photo from registration matches the person checking in.
        </span>
        <div className="flex justify-center">
          {verificationPhoto ? (
            <img
              className="mb-4 mt-8 h-48 w-48 rounded-lg"
              src={verificationPhoto}
              alt="Attendee"
            />
          ) : (
            <div className="mb-4 mt-8 flex h-48 w-48 items-center justify-center rounded-lg bg-gray-200">
              <span className="text-sm text-gray-400">
                <UserIcon className="h-24 w-24 stroke-black" />
              </span>
            </div>
          )}
        </div>
      </>
    )
  }

  return (
    <Modal
      actions={[
        {
          type: 'cancel',
          label: 'Cancel',
          onClick: close,
        },
        {
          type: 'submit',
          label: 'Verify & Continue',
          onClick: () => continueCheckIn(),
        },
      ]}
      icon={<CameraIcon className="h-5 fill-white sm:h-6" />}
      content={<div className="mt-3 sm:mt-5">{renderLoadingOrContent()}</div>}
      open
      title="Photo Verification"
    />
  )
}

PhotoVerificationPopup.propTypes = {
  attendee: PropTypes.object.isRequired,
  eventId: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
  continueCheckIn: PropTypes.func.isRequired,
}

export { PhotoVerificationPopup }
