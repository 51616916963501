import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import {
  ArrowUpTrayIcon,
  ArrowDownTrayIcon,
  PlusIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
  DevicePhoneMobileIcon,
} from '@heroicons/react/20/solid'
import { ClipboardDocumentListIcon } from '@heroicons/react/24/outline'
import _ from 'lodash'
import { useParams, useNavigate } from 'react-router-dom'
import { twMerge as mergeClassNames } from 'tailwind-merge'

// Images
import Inbox from '../../assets/images/inbox.svg'

// Files
import template from '../../assets/files/exhibitor_import_template.xlsx'

// Components
import { AddExhibitorModal } from '../../components/AddExhibitorModal'
import { Button } from '../../components/Button'
import { DataTable } from '../../components/DataTable'
import { DataTile } from '../../components/DataTile'
import { EventHeader } from '../../components/EventHeader'
import { ExhibitorIcon } from '../../components/ExhibitorIcon'
import { FileUploader } from '../../components/FileUploader'
import { IdentificationIcon } from '../../components/IdentificationIcon'
import { Modal } from '../../components/Modal'
import { StateContainer } from '../../components/StateContainer'
import { TextInput } from '../../components/TextInput'

// Store
import { NavigationStoreContext } from '../../stores/NavigationStore'
import { TaskStoreContext } from '../../stores/TaskStore'
import { UserStoreContext } from '../../stores/UserStore'

// Service
import {
  addExhibitor,
  getExhibitors,
  updateExhibitor,
  getExhibitorsKPI,
  uploadExhibitorList,
  createExhibitorsExport,
} from '../../services/exhibitors.service'

// Utils & Styles
import { handlePagination, toast } from '../../utils/helpers'

/**
 *
 * EventExhibitors
 *
 */
const EventExhibitors = observer(() => {
  // Context
  const { status, setParent, setTask, setType } = useContext(TaskStoreContext)
  const { event, updateActiveEntity, organizationId } = useContext(NavigationStoreContext)
  const { isEEUser } = useContext(UserStoreContext)
  const { eventId } = useParams()
  const navigate = useNavigate()

  // State
  const [loadingExhibitors, setLoadingExhibitors] = useState(false)
  const [exhibitors, setExhibitors] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [filter, setFilter] = useState(null)
  const [showExhibitorModal, setShowExhibitorModal] = useState(false)
  const [loadingExhibitor, setLoadingExhibitor] = useState(false)
  const [editExhibitor, setEditExhibitor] = useState(false)
  const [showImportModal, setShowImportModal] = useState(false)
  const [loadingImport, setLoadingImport] = useState(false)
  const [uploadFile, setUploadFile] = useState(null)
  const [importedFile, setImportedFile] = useState(null)
  const [KPI, setKPI] = useState(null)
  const [loadingKPI, setLoadingKPI] = useState(true)
  const [error, setError] = useState(null)

  // Pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [totalRows, setTotalRows] = useState(0)
  const [perPage, setPerPage] = useState(20)
  const [pages, setPages] = useState(null)

  // Ref
  const searchInputRef = useRef(null)

  const handleErrors = (m) => toast(m, 'error')
  const handleSuccess = (m) => toast(m, 'success')

  /**
   * Gets the updated list of exhibitors; updates pagination.
   * @param {string} url
   * @returns list of results
   */
  const getUpdatedExhibitorList = async (url) => {
    const response = await getExhibitors(url, handleErrors, setLoadingExhibitors, () => {})

    if (response) {
      setTotalRows(response.count)
      setPages({ next: response.next, previous: response.previous })
      setExhibitors(response.results)
    }
  }

  /**
   * Gets the updated kpi.
   * @param {string} url
   * @returns kpi object
   */
  const getUpdatedKPI = async () => {
    const response = await getExhibitorsKPI(eventId, handleErrors, setLoadingKPI, () => {})
    if (response) setKPI(response)
  }

  useEffect(() => {
    getUpdatedKPI()
  }, [status])

  /**
   * When the filter or row count changes, get the updated list of exhibitors.
   */
  useEffect(() => {
    if (filter) {
      getUpdatedExhibitorList(
        `/events/${eventId}/exhibitors/?expand=exhibitor,limit=${perPage}&${filter}`,
      )
    } else {
      getUpdatedExhibitorList(`/events/${eventId}/exhibitors/?expand=exhibitor&limit=${perPage}`)
    }
  }, [filter, perPage])

  /**
   * Updates the search query based on `search`.
   * @param {string} search
   */
  const updateSearch = (search) => {
    let updatedFilter = ''
    if (search) updatedFilter = `q=${search}`

    setFilter(updatedFilter)
  }

  const filterExhibitors = useCallback(_.debounce(updateSearch, 500), [])

  /**
   * Handles submitting the add or edit exhibitor forms.
   * @param {object} data
   */
  const onSubmit = (data) => {
    // eslint-disable-next-line no-param-reassign
    if (data.numFreeLicensesOverride === '') data.numFreeLicensesOverride = null
    if (editExhibitor) {
      updateExhibitor(eventId, data, handleErrors, setLoadingExhibitor, (m) => {
        getUpdatedExhibitorList(`/events/${eventId}/exhibitors/?expand=exhibitor&limit=${perPage}`)
        handleSuccess(m)
        setShowExhibitorModal(false)
      })
    } else {
      addExhibitor(eventId, data, handleErrors, setLoadingExhibitor, (m) => {
        getUpdatedExhibitorList(`/events/${eventId}/exhibitors/?expand=exhibitor&limit=${perPage}`)
        handleSuccess(m)
        setShowExhibitorModal(false)
      })
    }
  }

  /**
   * Creates an exhibitor export and then gets that export when it's ready.
   * @param {object} data
   */
  const getExport = async () => {
    const task = await createExhibitorsExport(eventId)
    if (task) {
      setType('Download')
      setParent({
        type: 'exhibitors',
        label: 'Exhibitors',
        id: task.id,
        eventId,
        eventName: event.name,
        fileName: `${event.name}_Exhibitors`,
      })
      setTask(task)
    }
  }

  /**
   * Renders the `Status` cell in the DataTable
   * - Displays a pill label based on event `status`
   * @param {object} row
   */
  const renderStatusCell = (row) => (
    <div
      className={mergeClassNames(
        'shrink-0 rounded-full px-2.5 py-0.5',
        row.status === 'Active' ? 'bg-status-green' : 'bg-red-100',
      )}
    >
      <span
        className={mergeClassNames(
          'text-xs font-bold',
          row.status === 'Active' ? 'text-role-admin' : 'text-red',
        )}
      >
        {row.status}
      </span>
    </div>
  )

  const columns = [
    {
      id: 'name',
      grow: 1,
      name: 'Exhibitor',
      selector: (row) => row.exhibitor.name,
      cell: (row) => (
        <button
          className="text-black hover:font-bold hover:text-purple"
          key={`event:${row.id}`}
          onClick={() => {
            navigate(
              `/organization/${organizationId}/event/${eventId}/lead-retrieval/exhibitors/${row.id}/`,
            )
          }}
          type="button"
        >
          {row.exhibitor.name}
        </button>
      ),
      sortable: true,
      sortBy: 'exhibitor__name',
    },
    {
      id: 'contact',
      grow: 1,
      name: 'Contact',
      selector: (row) => `${row.contactFirstName} ${row.contactLastName}`,
      cell: (row) => (
        <div className="flex flex-col">
          <span className="text-sm font-bold text-dark">
            {row.contactFirstName} {row.contactLastName}
          </span>

          <span className="text-xs text-gray-600">{row.contactEmail}</span>
        </div>
      ),
      sortable: true,
      sortBy: 'contact_first_name',
    },
    {
      id: 'status',
      grow: 0.25,
      name: 'Status',
      selector: (row) => row.hasCompletedOnboarding,
      cell: (row) => renderStatusCell(row),
      sortable: true,
      sortBy: 'has_completed_onboarding',
    },
    {
      id: 'dashboard',
      grow: 0.25,
      name: '',
      cell: (row) => (
        <button
          className="font-bold text-purple-600 hover:rounded-full hover:bg-status-purple hover:px-2 hover:py-0.5"
          type="button"
          onClick={() => {
            updateActiveEntity('exhibitor', row.exhibitor.id)
            navigate(`/exhibitor/${row.exhibitor.id}/event/${eventId}/home`)
          }}
        >
          Dashboard
        </button>
      ),
      center: true,
      minWidth: '125px',
    },
  ]

  if (isEEUser) {
    columns.push({
      id: 'edit',
      grow: 0.25,
      name: '',
      cell: (row) => (
        <button
          className="font-bold text-blue-600 hover:rounded-full hover:bg-status-blue hover:px-2 hover:py-0.5"
          type="button"
          onClick={() => {
            setEditExhibitor({
              ...row,
              exhibitor: row.exhibitor.name,
              numFreeLicensesOverride: row.numFreeLicensesOverride || event.numFreeLicenses,
            })
            setShowExhibitorModal(true)
          }}
        >
          Edit
        </button>
      ),
      center: true,
    })
  }

  const renderFileUploader = () => {
    if (uploadFile) {
      return (
        <div className="flex flex-col">
          <FileUploader
            acceptedFileTypes={[
              'text/csv',
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            ]}
            handleUploadToServer={async (file) => {
              setImportedFile(file)
              setUploadFile(null)
            }}
            id="file"
            maxFiles={1}
            type={null}
          />

          <div className="-mt-4 mr-2 flex flex-row place-items-center justify-between">
            <span className="text-xs italic text-gray-500">
              Upload a single file that is .xlsx, .xlsm or .csv
            </span>

            <button className="place-self-end" type="button" onClick={() => setUploadFile(null)}>
              <span className="text-xs">Cancel Upload</span>
            </button>
          </div>
        </div>
      )
    }

    return (
      <div className="flex flex-row items-center space-x-4 self-center">
        <span className="text-sm text-gray-500">
          {importedFile ? importedFile.name : 'Upload File'}
        </span>

        <Button
          background="bg-white"
          icon={<img src={Inbox} alt="Upload" className="h-5" />}
          label={importedFile ? 'Change' : 'Upload'}
          onClick={() => setUploadFile(true)}
          outlined
        />
      </div>
    )
  }

  return (
    <div className="h-full w-full">
      <StateContainer loading={loadingKPI}>
        <div className="h-full w-full flex-col space-y-3 overflow-y-auto p-3">
          <div className="flex flex-col items-start justify-between space-y-1 sm:flex-row sm:items-center sm:space-y-0">
            <EventHeader event={event} />

            <div className="flex flex-col items-center gap-2 xl:flex-row">
              <Button
                background="bg-purple border-purple hover:bg-purple-600"
                icon={<ClipboardDocumentListIcon className="h-5 stroke-white sm:h-6" />}
                label="Copy Registration URL"
                onClick={() => {
                  navigator.clipboard.writeText(
                    `${window.location.origin}/event/${eventId}/register`,
                  )
                  handleSuccess('URL copied to clipboard!')
                }}
              />

              {isEEUser && (
                <div className="flex flex-row items-center space-x-1 sm:flex-col sm:items-end sm:space-y-1">
                  <span className="isolate inline-flex space-x-[1px] rounded-md shadow-sm">
                    <button
                      type="button"
                      className="relative inline-flex items-center gap-1 rounded-l-md border-purple bg-purple px-3 py-1 text-sm text-white ring-1 ring-inset ring-purple hover:bg-purple-light hover:ring-purple-light sm:py-2"
                      onClick={() => setShowImportModal(true)}
                    >
                      <ArrowUpTrayIcon className="h-3 stroke-white sm:h-4" />
                      Import
                    </button>
                    <button
                      type="button"
                      className="relative -ml-px inline-flex items-center gap-1 rounded-r-md border-purple bg-purple px-3 py-1 text-sm text-white ring-1 ring-inset ring-purple hover:bg-purple-light hover:ring-purple-light sm:py-2"
                      onClick={() => getExport()}
                    >
                      <ArrowDownTrayIcon className="h-3 stroke-white sm:h-4" />
                      Export
                    </button>
                  </span>
                </div>
              )}
            </div>
          </div>

          {KPI && (
            <div className="flex w-full flex-col space-y-4 pb-8 pt-6 md:flex-row md:space-x-4 md:space-y-0">
              <DataTile
                color="blue"
                icon={<ExhibitorIcon className="fill-white stroke-white" />}
                label="Total Exhibiting Companies"
                value={KPI.totalExhibitorCount}
              />

              <DataTile
                color="green"
                icon={<IdentificationIcon className="h-6 fill-white" />}
                label="Total Licenses Purchased"
                value={KPI.totalLicensesCount}
              />

              <DataTile
                color="purple"
                icon={<DevicePhoneMobileIcon className="h-6 fill-white" />}
                label="Total Devices Rented"
                value={KPI.totalRentalDevicesCount}
              />
            </div>
          )}

          <div>
            <span className="text-md font-bold">Exhibitors</span>
          </div>

          <div className="flex flex-row items-center justify-between space-x-2">
            <TextInput
              className="w-full rounded-full py-2.5 pl-10 pr-4  placeholder:font-normal placeholder:text-gray-600 md:w-[450px]"
              icon={<MagnifyingGlassIcon className="ml-2 h-5 text-gray-dark" aria-hidden="true" />}
              id="search"
              endIcon={
                searchTerm ? (
                  <button
                    type="button"
                    onClick={() => {
                      filterExhibitors('')
                      setSearchTerm('')
                      searchInputRef.current.value = ''
                    }}
                  >
                    <XMarkIcon className="mr-2 h-5 text-gray-dark" aria-hidden="true" />
                  </button>
                ) : null
              }
              name="search"
              onChange={(e) => {
                filterExhibitors(e.target.value)
                setSearchTerm(e.target.value)
              }}
              placeholder="Looking for something?"
              ref={searchInputRef}
              value={searchTerm}
            />

            {isEEUser && (
              <Button
                background="bg-purple border-purple hover:bg-purple-600"
                icon={<PlusIcon className="h-5 sm:h-6" />}
                label="Add Exhibitor"
                onClick={() => {
                  setEditExhibitor(false)
                  setShowExhibitorModal(true)
                }}
              />
            )}
          </div>

          <DataTable
            columns={columns}
            data={exhibitors}
            defaultSortFieldId="name"
            defaultSortAsc
            onChangePage={(page) =>
              handlePagination(
                page,
                currentPage,
                perPage,
                totalRows,
                pages,
                setCurrentPage,
                getUpdatedExhibitorList,
                `/events/${eventId}/exhibitors/?expand=exhibitor&limit=`,
                filter,
              )
            }
            onChangeRowsPerPage={async (currentRowsPerPage) => setPerPage(currentRowsPerPage)}
            onSort={(column, direction) => {
              const d = direction === 'asc' ? '' : '-'
              getUpdatedExhibitorList(
                `/events/${eventId}/exhibitors/?order_by=${d}${column.sortBy}&limit=${perPage}&expand=exhibitor`,
              )
            }}
            pagination
            paginationPerPage={perPage}
            paginationRowsPerPageOptions={[10, 15, 20, 30, 50]}
            paginationTotalRows={totalRows}
            paginationServer
            progressPending={loadingExhibitors}
            sortServer
          />
        </div>
      </StateContainer>

      {showExhibitorModal && (
        <AddExhibitorModal
          editExhibitor={editExhibitor || null}
          loading={loadingExhibitor}
          numFreeLicenses={event.numFreeLicenses || null}
          onSubmit={onSubmit}
          setShowExhibitorModal={setShowExhibitorModal}
        />
      )}

      <Modal
        actions={[
          {
            type: 'cancel',
            label: 'Cancel',
            onClick: () => {
              setUploadFile(null)
              setImportedFile(null)
            },
          },
          {
            type: 'submit',
            label: 'Import',
            onClick: async () => {
              if (!importedFile) {
                setError('Please upload a file to import.')
                return
              }

              const task = await uploadExhibitorList(
                eventId,
                { file: importedFile.file },
                handleErrors,
                setLoadingImport,
              )

              if (task) {
                // Clear & close the modal
                setUploadFile(null)
                setImportedFile(null)
                setShowImportModal(false)

                // Set up the task to watch
                setType('Upload')
                setParent({ type: 'exhibitor-import', id: task.event })
                setTask(task)
              }
            },
          },
        ]}
        icon={<ArrowUpTrayIcon className="h-5 fill-white stroke-white sm:h-6" />}
        content={
          <div className="mt-3 flex flex-col space-y-4 text-center sm:mt-5">
            <Button
              background="bg-green-50"
              className="self-center"
              label="Download Template"
              onClick={() => {
                // Download the template
                const downloadLink = document.createElement('a')
                downloadLink.href = template
                downloadLink.download = 'Executivevents Exhibitor Import Template.xlsx'
                downloadLink.target = '_blank'
                downloadLink.click()
                downloadLink.remove()
              }}
            />

            {renderFileUploader()}

            {error && (
              <div className="mt-1 w-full bg-error-light px-2 py-1">
                <p className="text-sm font-medium text-error-dark">{error}</p>
              </div>
            )}
          </div>
        }
        loading={loadingImport}
        onClose={() => {
          setShowImportModal(false)
        }}
        open={showImportModal}
        setOpen={setShowImportModal}
        title="Import"
      />
    </div>
  )
})

export default EventExhibitors
