import React, { useContext, useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { observer } from 'mobx-react'
import 'react-phone-number-input/style.css'

// Images
import Email from '../../assets/images/email.svg'
import User from '../../assets/images/user.svg'
import UserPlaceholder from '../../assets/images/user_placeholder.svg'

// Components
import { Button } from '../../components/Button'
import { FileUploader } from '../../components/FileUploader'
import { KeyIcon } from '../../components/KeyIcon'
import { LockIcon } from '../../components/LockIcon'
import { PasswordInput } from '../../components/PasswordInput'
import { PhoneNumberInput } from '../../components/PhoneNumberInput'
import { RadioInput } from '../../components/RadioInput'
import { StateContainer } from '../../components/StateContainer'
import { TextInput } from '../../components/TextInput'

// Stores
import { UserStoreContext } from '../../stores/UserStore'

// Utils & Service
import {
  confirmEmailChange,
  confirmPhoneChange,
  requestEmailChange,
  requestPhoneChange,
  updateUser,
  updateUserPassword,
} from '../../services/user.service'
import { toast, verifyPassword } from '../../utils/helpers'

/**
 *
 * UserProfile
 *
 */
const UserProfile = observer(() => {
  // Context
  const {
    error: loadingError,
    loading,
    getUpdatedUser,
    setCurrentUser,
    user,
  } = useContext(UserStoreContext)

  // State
  const [uploadLogo, setUploadLogo] = useState(false)
  const [loadingProfile, setLoadingProfile] = useState(false)
  const [loadingPassword, setLoadingPassword] = useState(false)
  const [loadingEmail, setLoadingEmail] = useState(false)
  const [loadingPhone, setLoadingPhone] = useState(false)
  const [loadingMfa, setLoadingMfa] = useState(false)
  const [verifyMfa, setVerifyMfa] = useState(null)

  useEffect(() => {
    getUpdatedUser(user.id)
  }, [])

  const {
    handleSubmit: handleProfileSubmit,
    formState: { errors: profileErrors, isDirty: profileIsDirty },
    register: profileRegister,
    reset: profileReset,
  } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
    },
  })

  const {
    control: passwordControl,
    handleSubmit: handlePasswordSubmit,
    formState: { errors: passwordErrors, isDirty: passwordIsDirty },
    reset: resetPasswordForm,
    watch,
  } = useForm()

  const {
    handleSubmit: handleEmailSubmit,
    formState: { errors: emailErrors, isDirty: emailIsDirty },
    getValues: getEmailValues,
    register: emailRegister,
    reset: emailReset,
  } = useForm({
    defaultValues: {
      email: '',
      code: '',
    },
  })

  const {
    control: phoneControl,
    handleSubmit: handlePhoneSubmit,
    formState: { errors: phoneErrors, isDirty: phoneIsDirty },
    getValues: getPhoneValues,
    register: phoneRegister,
    reset: phoneReset,
  } = useForm({
    defaultValues: {
      phoneNumber: '',
      code: '',
    },
  })

  const {
    control: multiFactorControl,
    handleSubmit: handleMultiFactorSubmit,
    formState: { isDirty: multiFactorIsDirty },
    reset: mfaReset,
  } = useForm({
    defaultValues: {
      mfaDevice: '',
    },
  })

  useEffect(() => {
    if (user) {
      profileReset({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phoneNumber: user.phoneNumber,
      })

      emailReset({
        email: user.email,
      })

      phoneReset({
        phoneNumber: user.phoneNumber,
      })

      mfaReset({
        mfaDevice: user.mfaDevice,
      })
    }
  }, [user])

  const handleSuccesses = (message) => toast(message, 'success')
  const handleErrors = (message) => toast(message, 'error')

  /**
   * Handles submitting changes to the user's profile.
   * - If the user is changing either their email or phone number, these get handled
   *  separately. Both of these changes require a verification code.
   * @param {object} data
   */
  const onProfileSubmit = async (data) =>
    updateUser(
      {
        id: user.id,
        ...data,
      },
      handleErrors,
      setLoadingProfile,
      (m) => handleSuccesses(m),
      setCurrentUser,
    )

  /**
   * Handles requesting a change to the user's email address, and sending the verification
   * code.
   * @param {object} data
   */
  const onEmailSubmit = async (data) => {
    if (verifyMfa === 'email') {
      await confirmEmailChange(
        data.code,
        handleErrors,
        setLoadingEmail,
        (m) => {
          handleSuccesses(m)
          setVerifyMfa(null)
        },
        setCurrentUser,
      )
    } else {
      await requestEmailChange(data.email, handleErrors, setLoadingEmail, (m) => {
        handleSuccesses(m)
        setVerifyMfa('email')
      })
    }
  }

  /**
   * Handles requesting a change to the user's phone number, and sending the verification
   * code.
   * @param {object} data
   */
  const onPhoneSubmit = async (data) => {
    if (verifyMfa === 'phone') {
      await confirmPhoneChange(
        data.code,
        handleErrors,
        setLoadingPhone,
        (m) => {
          handleSuccesses(m)
          setVerifyMfa(null)
        },
        setCurrentUser,
      )
    } else {
      await requestPhoneChange(data.phoneNumber, handleErrors, setLoadingPhone, (m) => {
        if (data.phoneNumber !== null && data.phoneNumber.length > 0) {
          handleSuccesses(m)
          setVerifyMfa('phone')
        } else {
          handleSuccesses('Phone number removed.')
        }
      })
    }
  }

  const renderUserAvatar = () => {
    if (uploadLogo) {
      return (
        <div>
          <FileUploader
            acceptedFileTypes={['image/*']}
            allowResize
            allowRevert
            handleUploadToServer={async (file) => {
              const update = await updateUser(
                {
                  id: user.id,
                  profileImageUrl: file.url,
                },
                handleErrors,
                () => {},
                () => handleSuccesses('Profile image updated.'),
              )

              setCurrentUser(update)
              setUploadLogo(null)
            }}
            id="profileImageUrl"
            imageCropAspectRatio="1:1"
            type="gcp"
          />

          <div className="-mt-4 mr-2 grid justify-items-end">
            <button className="place-self-end" type="button" onClick={() => setUploadLogo(null)}>
              <span className="text-xs">Cancel Upload</span>
            </button>
          </div>
        </div>
      )
    }

    return (
      <div className=" flex items-center gap-x-4">
        <img
          src={user.signedProfileImageUrl || UserPlaceholder}
          alt="User"
          className="h-24 w-24 flex-none rounded-full object-cover"
        />

        <Button
          background="bg-white"
          label="Change"
          onClick={() => setUploadLogo(true)}
          outlined
        />
      </div>
    )
  }

  return (
    <div className="flex h-full w-full justify-center justify-items-center overflow-y-auto bg-background">
      <StateContainer error={loadingError} loading={loading}>
        <div className="flex h-full w-full flex-col space-y-8 p-3 pt-8 md:w-[85%]  md:pt-20 ">
          <div className="grid grid-cols-1 gap-y-4 rounded-lg bg-white px-4 py-8 shadow-md sm:grid-cols-5 sm:gap-y-0">
            <div className="col-span-full sm:col-span-2">
              <h2 className="text-primary text-base font-semibold leading-7">
                Personal Information
              </h2>
              <p className="text-primary mt-1 text-sm leading-6">
                Update any profile information.
              </p>
            </div>

            <form className="col-span-full sm:col-span-3">
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                <div className="col-span-full">{renderUserAvatar()}</div>

                <div className="col-span-1">
                  <TextInput
                    className="rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                    icon={<img alt="User" className="ml-1.5 h-4" src={User} />}
                    data-testid="firstName"
                    error={profileErrors.firstName && 'This field is required'}
                    fullWidth
                    id="firstName"
                    inputStyles="rounded-none font-nunito"
                    name="firstName"
                    nunito
                    label="First Name"
                    placeholder="First Name"
                    {...profileRegister('firstName', { required: true })}
                  />
                </div>

                <div className="col-span-1">
                  <TextInput
                    className="rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                    icon={<img alt="User" className="ml-1.5 h-4" src={User} />}
                    data-testid="lastName"
                    error={profileErrors.lastName && 'This field is required'}
                    fullWidth
                    id="lastName"
                    inputStyles="rounded-none font-nunito"
                    name="lastName"
                    nunito
                    label="Last Name"
                    placeholder="Last Name"
                    {...profileRegister('lastName', { required: true })}
                  />
                </div>
              </div>

              <div className="mt-4 flex flex-col place-items-end space-y-2">
                <Button
                  disabled={loadingProfile || !profileIsDirty}
                  onClick={handleProfileSubmit(onProfileSubmit)}
                  label="Save Changes"
                  loading={loadingProfile}
                  type="button"
                />
              </div>
            </form>
          </div>

          <div className="grid grid-cols-1 gap-x-8 gap-y-4 rounded-lg bg-white px-4 py-8 shadow-md sm:grid-cols-5 sm:gap-y-0">
            <div className="col-span-full sm:col-span-2">
              <h2 className="text-primary text-base font-semibold leading-7">Change password</h2>
              <p className="text-primary mt-1 text-sm leading-6">
                Update the password associated with your account.
              </p>
            </div>

            <form className="col-span-full sm:col-span-3">
              <div className="grid grid-cols-2 gap-4 text-center">
                <div className="col-span-full">
                  <Controller
                    name="oldPassword"
                    control={passwordControl}
                    render={({ field: { onChange, value } }) => (
                      <PasswordInput
                        autoComplete="password"
                        className="rounded-2xl border-gray-550 py-2.5 pl-9 pr-4  placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                        data-testid="oldPassword"
                        icon={<LockIcon className="ml-1.5 h-4 stroke-purple" />}
                        error={passwordErrors.oldPassword && 'This field is required'}
                        fullWidth
                        id="oldPassword"
                        inputStyles="font-nunito"
                        nunito
                        label="Current Password"
                        placeholder="Current Password"
                        onChange={onChange}
                        value={value}
                      />
                    )}
                    rules={{ required: true }}
                  />
                </div>

                <div className="col-span-full">
                  <Controller
                    name="newPassword"
                    control={passwordControl}
                    render={({ field: { onChange, value } }) => (
                      <PasswordInput
                        autoComplete="password"
                        className="rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                        data-testid="newPassword"
                        icon={<LockIcon className="ml-1.5 h-4 stroke-purple" />}
                        error={
                          passwordErrors.newPassword &&
                          (passwordErrors.newPassword.message || 'This field is required')
                        }
                        fullWidth
                        id="newPassword"
                        inputStyles="rounded-none rounded-full font-nunito"
                        name="newPassword"
                        nunito
                        label="New Password"
                        placeholder="New Password"
                        onChange={onChange}
                        value={value}
                      />
                    )}
                    rules={{ required: true, validate: (value) => verifyPassword(value) }}
                  />
                </div>

                <div className="col-span-full">
                  <Controller
                    name="confirmPassword"
                    control={passwordControl}
                    render={({ field: { onChange, value } }) => (
                      <PasswordInput
                        autoComplete="password"
                        className="rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                        data-testid="confirmPassword"
                        icon={<LockIcon className="ml-1.5 h-4 stroke-purple" />}
                        error={
                          passwordErrors.confirmPassword &&
                          (passwordErrors.confirmPassword.message || 'This field is required')
                        }
                        fullWidth
                        id="confirmPassword"
                        inputStyles="rounded-none rounded-full font-nunito"
                        name="confirmPassword"
                        nunito
                        label="Confirm Password"
                        placeholder="Confirm Password"
                        onChange={onChange}
                        value={value}
                      />
                    )}
                    rules={{
                      required: true,
                      validate: (value) => {
                        const err = verifyPassword(value)
                        if (err) return err
                        if (watch('newPassword') !== value) {
                          return 'Passwords do not match'
                        }
                        return undefined
                      },
                    }}
                  />
                </div>
              </div>

              <div className="mt-4 flex flex-col place-items-end space-y-2">
                <Button
                  disabled={loadingPassword || !passwordIsDirty}
                  onClick={handlePasswordSubmit((data) =>
                    updateUserPassword(
                      {
                        id: user.id,
                        ...data,
                      },
                      handleErrors,
                      setLoadingPassword,
                      (m) => {
                        handleSuccesses(m)
                        resetPasswordForm()
                      },
                    ),
                  )}
                  label="Save Changes"
                  loading={loadingPassword}
                  type="button"
                />
              </div>
            </form>
          </div>

          <div className="grid grid-cols-1 gap-x-8 gap-y-4 rounded-lg bg-white px-4 py-8 shadow-md sm:grid-cols-5 sm:gap-y-0">
            <div className="col-span-full sm:col-span-2">
              <h2 className="text-primary text-base font-semibold leading-7">Email Address</h2>
              <p className="text-primary mt-1 text-sm leading-6">
                Update the email address associated with your account.
              </p>
            </div>

            <form className="col-span-full sm:col-span-3">
              <div className="grid grid-cols-2 gap-4">
                <div className="col-span-full">
                  <TextInput
                    className="rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                    icon={<img alt="Email" className="ml-1.5 h-4" src={Email} />}
                    data-testid="email"
                    error={emailErrors.email && 'This field is required'}
                    fullWidth
                    id="email"
                    inputStyles="rounded-none font-nunito"
                    name="email"
                    nunito
                    label="Email"
                    placeholder="Email"
                    {...emailRegister('email', { required: true })}
                  />
                </div>

                {verifyMfa === 'email' && (
                  <div className="col-span-full flex flex-col">
                    <TextInput
                      className="rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                      icon={<KeyIcon className="ml-1.5 h-4 stroke-purple" />}
                      data-testid="code"
                      error={profileErrors.code && 'This field is required'}
                      fullWidth
                      id="code"
                      inputStyles="rounded-none font-nunito"
                      name="code"
                      nunito
                      label="Verification Code"
                      placeholder="Verification Code"
                      {...emailRegister('code', { required: true })}
                    />

                    <button
                      className="mr-1 place-self-end"
                      type="button"
                      onClick={async () => {
                        await requestEmailChange(
                          getEmailValues().email,
                          handleErrors,
                          setLoadingEmail,
                          (m) => {
                            handleSuccesses(m)
                            setVerifyMfa('email')
                          },
                        )
                      }}
                    >
                      <span className="text-sm font-medium text-purple">
                        Resend Verification Code
                      </span>
                    </button>
                  </div>
                )}
              </div>

              <div className="mt-4 flex flex-row justify-end space-x-2">
                {verifyMfa === 'email' && (
                  <Button
                    background="bg-white"
                    loading={loading}
                    onClick={() => {
                      setVerifyMfa(null)
                      emailReset()
                    }}
                    outlined
                    label="Cancel"
                  />
                )}

                <Button
                  disabled={loadingEmail || !emailIsDirty}
                  onClick={handleEmailSubmit(onEmailSubmit)}
                  label={verifyMfa === 'email' ? 'Confirm Change' : 'Save Changes'}
                  loading={loadingEmail}
                  type="button"
                />
              </div>
            </form>
          </div>

          <div className="grid grid-cols-1 gap-x-8 gap-y-4 rounded-lg bg-white px-4 py-8 shadow-md sm:grid-cols-5 sm:gap-y-0">
            <div className="col-span-full sm:col-span-2">
              <h2 className="text-primary text-base font-semibold leading-7">Phone Number</h2>
              <p className="text-primary mt-1 text-sm leading-6">
                Update the phone number associated with your account.
              </p>
            </div>

            <form className="col-span-full sm:col-span-3">
              <div className="grid grid-cols-2 gap-4">
                <div className="col-span-full">
                  <PhoneNumberInput
                    control={phoneControl}
                    error={phoneErrors.phoneNumber}
                    name="phoneNumber"
                  />
                </div>

                {verifyMfa === 'phone' && (
                  <div className="col-span-full flex flex-col">
                    <TextInput
                      className="rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                      icon={<KeyIcon className="ml-1.5 h-4 stroke-purple" />}
                      data-testid="code"
                      error={phoneErrors.code && 'This field is required'}
                      fullWidth
                      id="code"
                      inputStyles="rounded-none font-nunito"
                      name="code"
                      nunito
                      label="Verification Code"
                      placeholder="Verification Code"
                      {...phoneRegister('code', { required: true })}
                    />

                    <button
                      className="mr-1 place-self-end"
                      type="button"
                      onClick={async () => {
                        const { phoneNumber } = getPhoneValues()
                        await requestPhoneChange(
                          phoneNumber,
                          handleErrors,
                          setLoadingPhone,
                          (m) => {
                            if (phoneNumber !== null && phoneNumber.length > 0) {
                              handleSuccesses(m)
                              setVerifyMfa('phone')
                            } else {
                              handleSuccesses('Phone number removed.')
                            }
                          },
                        )
                      }}
                    >
                      <span className="text-sm font-medium text-purple">
                        Resend Verification Code
                      </span>
                    </button>
                  </div>
                )}
              </div>

              <div className="mt-4 flex flex-row justify-end space-x-2">
                {verifyMfa === 'phone' && (
                  <Button
                    background="bg-white"
                    loading={loading}
                    onClick={() => {
                      setVerifyMfa(null)
                      phoneReset()
                    }}
                    outlined
                    label="Cancel"
                  />
                )}

                <Button
                  disabled={loadingPhone || !phoneIsDirty}
                  onClick={handlePhoneSubmit(onPhoneSubmit)}
                  label={verifyMfa === 'phone' ? 'Confirm Change' : 'Save Changes'}
                  loading={loadingPhone}
                  type="button"
                />
              </div>
            </form>
          </div>

          <div className="grid grid-cols-1 gap-x-8 gap-y-4 rounded-lg bg-white px-4 py-8 shadow-md sm:grid-cols-5 sm:gap-y-0">
            <div className="col-span-full sm:col-span-2">
              <h2 className="text-primary text-base font-semibold leading-7">
                Multi-Factor Authentication
              </h2>
              <p className="text-primary mt-1 text-sm leading-6">
                Configure where you would like to receive your authentication codes.
              </p>
            </div>

            <form className="col-span-full sm:col-span-3">
              <div className="grid grid-cols-2 gap-4">
                <div className="col-span-full">
                  <Controller
                    name="mfaDevice"
                    control={multiFactorControl}
                    render={({ field: { onChange, value, ref } }) => (
                      <RadioInput
                        className="mt-4 self-center"
                        horizontal
                        name="mfaDevice"
                        options={[
                          {
                            id: 'Phone',
                            label: 'Phone Number',
                            disabled: user.phoneNumber === null || user.phoneNumber === '',
                          },
                          { id: 'Email', label: 'Email Address' },
                        ]}
                        onChange={onChange}
                        value={value}
                        ref={ref}
                      />
                    )}
                  />
                </div>
              </div>

              <div className="mt-4 flex flex-col place-items-end space-y-2">
                <Button
                  disabled={loadingMfa || !multiFactorIsDirty}
                  onClick={handleMultiFactorSubmit((data) =>
                    updateUser(
                      {
                        id: user.id,
                        ...data,
                      },
                      handleErrors,
                      setLoadingMfa,
                      (m) => handleSuccesses(m),
                      setCurrentUser,
                    ),
                  )}
                  label="Save Changes"
                  loading={loadingMfa}
                  type="button"
                />
              </div>
            </form>
          </div>
          {/* TODO: add padding here without using an empty div */}
          <div className="bg-background py-2"></div>
        </div>
      </StateContainer>
    </div>
  )
})

export default UserProfile
