import React, { useMemo, useState } from 'react'
import { EyeIcon } from '@heroicons/react/20/solid'
import { Controller, useForm } from 'react-hook-form'
import _ from 'lodash'
import PropTypes from 'prop-types'

// Components
import { Modal } from '../../components/Modal'
import { TextInput } from '../../components/TextInput'
import PaginatedSelect from './PaginatedSelect'

// Images
import Email from '../../assets/images/email.svg'

// Service
import { getEventExhibitorTransactions, sendEmailPreview } from '../../services/events.service'
import { getAttendees } from '../../services/attendees.service'
import { getExhibitors } from '../../services/exhibitors.service'
import { getOrganizationUsers } from '../../services/organizations.service'

// Utils
import { toast } from '../../utils/helpers'

const PreviewEmailModal = ({ email, eventId, onClose }) => {
  // State
  const [loadingPreview, setLoadingPreview] = useState(false)

  const handleError = (m) => toast(m, 'error')
  const handleSuccess = (m) => toast(m, 'success')

  const {
    control: previewControl,
    handleSubmit: handlePreviewSubmit,
    formState: { errors: previewErrors },
    register: previewRegister,
    reset: resetPreview,
    setError: setPreviewError,
    watch,
  } = useForm({
    defaultValues: {
      toEmailOverride: null,
      recipient: null,
    },
  })

  const contextConfigurationOptions = (context) => {
    switch (context) {
      case 'Attendee':
        return {
          dependent: false,
          request: getAttendees,
          baseUrl: `events/${eventId}/attendees/?limit=100`,
          transformOption: (o) => ({
            id: o.id,
            label: `${o.firstName} ${o.lastName}`,
          }),
        }
      case 'Assigned By':
      case 'Scanned By':
        return {
          dependent: 'eventExhibitor',
          request: getExhibitors,
          baseUrl: `/organizations/${
            watch('eventExhibitor')?.exhibitorId
          }/roles/?expand=user&limit=100`,
          transformOption: (o) => ({
            id: o.user.id,
            label: `${o.user.firstName} ${o.user.lastName}`,
          }),
        }
      case 'Event Exhibitor':
        return {
          dependent: false,
          request: getExhibitors,
          baseUrl: `/events/${eventId}/exhibitors/?expand=exhibitor&limit=100`,
          transformOption: (o) => ({
            id: o.id,
            exhibitorId: o.exhibitor.id,
            label: o.exhibitor.name,
          }),
        }
      case 'Transaction':
        return {
          dependent: 'eventExhibitor',
          request: getEventExhibitorTransactions,
          baseUrl: `/events/${eventId}/exhibitors/${
            watch('eventExhibitor')?.id
          }/transactions/?limit=100`,
          transformOption: (o) => ({
            id: o.id,
            label: `${o.transId}`,
          }),
        }
      default:
        return null
    }
  }

  const configureRecipientField = useMemo(() => {
    let config = null

    switch (email.recipients) {
      case 'Attendee':
      case 'Attendees (Dynamic)':
        config = {
          dependent: false,
          request: getAttendees,
          baseUrl: `events/${eventId}/attendees/?limit=100`,
          transformOption: (o) => ({
            id: o.id,
            label: `${o.firstName} ${o.lastName}`,
          }),
        }
        break
      case 'Exhibitor':
      case 'Licensees (Dynamic)':
        config = {
          dependent: 'eventExhibitor',
          request: getOrganizationUsers,
          baseUrl: `/organizations/${
            watch('eventExhibitor')?.exhibitorId
          }/roles/?expand=user&limit=100`,
          transformOption: (o) => ({
            id: o.user.id,
            label: `${o.user.firstName} ${o.user.lastName}`,
          }),
        }
        break
      default:
        break
    }

    if (!config) return null
    if (config?.dependent === 'eventExhibitor' && !watch('eventExhibitor')) return null

    return (
      <Controller
        name="recipient"
        control={previewControl}
        render={({ field: { onChange, value } }) => (
          <PaginatedSelect
            baseUrl={config.baseUrl}
            disabled={loadingPreview}
            error={previewErrors.recipient}
            label="Recipient"
            name="recipient"
            onChange={onChange}
            placeholder="Select Recipient"
            request={config.request}
            transformOption={config.transformOption}
            value={value}
          />
        )}
        rules={{ required: true }}
      />
    )
  }, [email, loadingPreview, watch('eventExhibitor')])

  return (
    <Modal
      actions={[
        {
          type: 'cancel',
          label: 'Close',
          onClick: () => {
            resetPreview()
            onClose()
          },
        },
        {
          type: 'submit',
          label: 'Send Preview',
          onClick: handlePreviewSubmit(async (data) => {
            if (data.recipient === null) {
              setPreviewError('recipient')
              return
            }

            const payload = {
              toEmailOverride: data.toEmailOverride,
            }
            const recipient = data.recipient.id

            // eslint-disable-next-line no-param-reassign
            delete data.toEmailOverride
            // eslint-disable-next-line no-param-reassign
            delete data.recipient
            payload.additionalContextVars = _.mapValues(data, (v) => v?.id || null)

            if (email.recipients === 'Attendee' || email.recipients === 'Attendees (Dynamic)') {
              payload.additionalContextVars.attendee = recipient
            } else {
              payload.recipient = recipient
            }

            await sendEmailPreview(
              eventId,
              email.id,
              payload,
              handleError,
              setLoadingPreview,
              (m) => {
                handleSuccess(m)
                resetPreview()
                onClose()
              },
            )
          }),
        },
      ]}
      icon={<EyeIcon className="h-5 fill-white sm:h-6" />}
      content={
        <div className="mt-3 flex flex-col space-y-4 sm:mt-5">
          <div className="flex flex-col justify-center space-y-2">
            <span className="self-center text-center text-lg font-bold">{email.name}</span>

            {email.identifier === 'attendees-scan-and-go' && (
              <span className="self-center text-center text-sm italic text-gray-900">
                Attendee QR code will be attached to the email.
              </span>
            )}
          </div>

          <TextInput
            className="rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
            error={previewErrors.toEmailOverride && 'This field is required'}
            icon={<img alt="Email" className="ml-1.5 h-4" src={Email} />}
            inputStyles="rounded-none rounded-t-md font-nunito"
            label="Recipient Override"
            name="toEmailOverride"
            nunito
            placeholder="Enter Recipient Override"
            fullWidth
            {...previewRegister('toEmailOverride', { required: true })}
          />

          {configureRecipientField}

          {_.map(email.availableContext, (context) => {
            const config = contextConfigurationOptions(context)
            if (!config) return null
            if (config?.dependent === 'eventExhibitor' && !watch('eventExhibitor')) return null

            return (
              <Controller
                name={_.camelCase(context)}
                control={previewControl}
                render={({ field: { onChange, value } }) => (
                  <PaginatedSelect
                    baseUrl={config.baseUrl}
                    disabled={loadingPreview}
                    error={previewErrors[_.camelCase(context)]}
                    label={context}
                    name={_.camelCase(context)}
                    onChange={onChange}
                    placeholder={`Select ${context}`}
                    request={config.request}
                    transformOption={config.transformOption}
                    value={value}
                  />
                )}
                rules={{ required: context === 'Event Exhibitor' }}
              />
            )
          })}
        </div>
      }
      loading={loadingPreview}
      open
      title="Preview Email"
    />
  )
}

PreviewEmailModal.propTypes = {
  email: PropTypes.object.isRequired,
  eventId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default PreviewEmailModal
