import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import PropTypes from 'prop-types'

// Icons
import { UserIcon } from '../UserIcon'

// Components
import { Modal } from '../Modal'
import { TextInput } from '../TextInput'

// Utils & Service
import { createAttendeeSync, getAttendeeSyncStatus } from '../../services/attendees.service'
import { toast } from '../../utils/helpers'

const SyncExternalAttendeeModal = ({ onClose, eventId }) => {
  // State
  const [loading, setLoading] = useState(false)
  const [task, setTask] = useState(null)

  const handleSuccess = (m) => toast(m, 'success')
  const handleError = (m) => toast(m, 'error')

  const {
    handleSubmit: handlePreviewSubmit,
    formState: { errors },
    register,
  } = useForm({
    defaultValues: {
      externalId: '',
    },
  })

  useEffect(() => {
    let interval = null

    if (task) {
      interval = setInterval(async () => {
        const response = await getAttendeeSyncStatus(eventId, task.id, handleError, () => {})
        if (response) {
          switch (response.status) {
            case 'Pending':
            case 'Processing':
            case 'Retrying':
              setTask(response)
              break
            case 'Failed':
              setTask(null)
              handleError('Failed to sync attendee')
              clearInterval(interval)
              break
            case 'Completed':
              setTask(null)
              handleSuccess('Attendee synced successfully')
              clearInterval(interval)
              onClose(true)
              break
            default:
              setTask(null)
              handleError('Unknown status')
              clearInterval(interval)
              break
          }
        } else {
          clearInterval(interval)
        }
      }, 2000)
    }

    return () => {
      if (interval) clearInterval(interval)
    }
  }, [task])

  return (
    <Modal
      actions={[
        {
          type: 'cancel',
          label: task ? 'Close' : 'Cancel',
          onClick: onClose,
          disabled: loading || !!task,
        },
        {
          type: 'submit',
          label: task ? 'Syncing Attendee..' : 'Sync Attendee',
          disabled: loading || !!task,
          onClick: handlePreviewSubmit(async (data) => {
            const response = await createAttendeeSync(
              eventId,
              { syncType: 'Attendee - Single', externalId: data.externalId },
              handleError,
              setLoading,
            )
            if (response) setTask(response)
          }),
        },
      ]}
      icon={<UserIcon className="h-5 fill-white stroke-white sm:h-6" />}
      content={
        <div className="mt-3 flex flex-col gap-5 text-center sm:mt-5">
          <TextInput
            className="ph-4 w-full rounded-2xl border-gray-550 py-2.5 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
            data-testid="externalId"
            disabled={loading || !!task}
            error={errors?.externalId && 'This field is required'}
            fullWidth
            id="externalId"
            inputStyles="rounded-none rounded-t-md font-nunito"
            name="externalId"
            nunito
            placeholder="Enter Attendee External ID"
            {...register('externalId', { required: true })}
          />

          {task && task.statusMessage && (
            <span className="text-sm text-gray-600">Status: {task.statusMessage}</span>
          )}
        </div>
      }
      open
      title="Sync External Attendee"
    />
  )
}

SyncExternalAttendeeModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  eventId: PropTypes.string.isRequired,
}

export default SyncExternalAttendeeModal
