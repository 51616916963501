import _ from 'lodash'

// Utils
import { formatPhoneNumber } from '../../utils/formatters'

const SKIP_KEYS = [
  'custom_data',
  'admission_items',
  'available_custom_fields',
  'attendee_categories',
  'sessions',
  'check_ins_by_category',
  'check_ins_by_location',
]

/**
 * Convert keys from lower_snake_case to camelCase
 *
 * @param {*} data Data to be transformed
 * @param {bool} formatData Whether to apply additional data formatting or not
 */
const convertIncomingData = (data, formatData = false) => {
  const formattedData = _.isArray(data) ? [] : {}

  _.forEach(data, (value, key) => {
    let formattedValue = value

    // If the value is also an object or array, recursively update keys
    // Manually override converter to skip `custom_data` key to preserve formatting of nested data
    if (
      (_.isPlainObject(formattedValue) || _.isArray(formattedValue)) &&
      !SKIP_KEYS.includes(key)
    ) {
      formattedValue = convertIncomingData(formattedValue)
    }

    if (formatData && key === 'phone') {
      formattedData[_.camelCase(key)] = formatPhoneNumber(formattedValue)
    } else {
      formattedData[_.camelCase(key)] = formattedValue
    }
  })
  return formattedData
}

export default convertIncomingData
