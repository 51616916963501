// Utils and Service
import axios from './instance'
import { getErrorMessage } from '../utils/helpers'

/** ------------------------------- Events ------------------------------- */

/**
 * Gets the list of events the authenticated user has access to.
 * @param {string} url
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` results
 */
export const getEvents = async (
  url,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.get(url || '/events/?expand=organizer&limit=20')

    setLoading(false)
    setSuccess(true)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Gets the list of events for the specified `orgId`.
 * @param {string} url
 * @param {string} orgId
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` results
 */
export const getEventsForOrganization = async (
  url,
  orgId,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.get(url || `/organizations/${orgId}/events/&limit=20`)

    setLoading(false)
    setSuccess(true)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Gets the events kpis.
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data`
 */
export const getEventsKpis = async (
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.get('/events/kpis/')

    setLoading(false)
    setSuccess(true)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/** ------------------------------- Event ------------------------------- */

/**
 * Gets the event kpis using the specified `eventId`.
 * @param {string} eventId
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data`
 */
export const getEventKpis = async (
  eventId,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.get(`/events/${eventId}/kpis/`)

    setLoading(false)
    setSuccess(true)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Adds the specified event from the `payload`.
 * @param {*} payload event details
 * @param {*} setError
 * @param {*} setLoading
 * @param {*} setSuccess
 * @returns `data` created event
 */
export const addEvent = async (
  payload,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.post('/events/', payload)

    setLoading(false)
    setSuccess(`${data.name} created.`)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Updates the specified event using data from the `payload`.
 * @param {*} payload event details
 * @param {*} setError
 * @param {*} setLoading
 * @param {*} setSuccess
 * @returns `data` created event
 */
export const updateEvent = async (
  payload,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.patch(
      `/events/${payload.id}/?expand=tasklist_items,badge_configuration,kiosk_configuration`,
      payload,
    )

    setLoading(false)
    setSuccess(`${data.name} updated.`)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Gets the event with the specified `id`.
 * @param {string} organizationId
 * @param {string} id
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` results
 */
export const getEvent = async (
  organizationId,
  id,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.get(
      `/organizations/${organizationId}/events/${id}/?expand=tasklist_items,badge_configuration,kiosk_configuration`,
    )

    setLoading(false)
    setSuccess(true)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/** ------------------------------- Event - Emails ------------------------------- */

/**
 * Gets the event emails using the specified `eventId`.
 * @param {string} eventId
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data`
 */
export const getEventEmails = async (
  url,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.get(url)

    setLoading(false)
    setSuccess(true)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Updates the event email using the specified `eventId` and `payload`.
 * @param {string} eventId
 * @param {object} payload
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data`
 */
export const updateEventEmail = async (
  eventId,
  payload,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.patch(`/events/${eventId}/emails/${payload.id}/`, payload)

    setLoading(false)
    setSuccess('Email updated.')
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Sends the email preview using the specified `eventId`, `templateId` and `payload`
 * @param {string} eventId
 * @param {string} templateId
 * @param {object} payload
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data`
 */
export const sendEmailPreview = async (
  eventId,
  templateId,
  payload,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.post(`/events/${eventId}/emails/${templateId}/preview/`, payload)

    setLoading(false)
    setSuccess('Preview sent successfully.')
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Sends the email using the specified `eventId`, `templateId` and `payload`
 * @param {string} eventId
 * @param {string} templateId
 * @param {object} payload
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data`
 */
export const sendEmail = async (
  eventId,
  templateId,
  payload,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.post(`/events/${eventId}/emails/${templateId}/send/`, payload)

    setLoading(false)
    setSuccess('Email successfully triggered.')
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/** ------------------------------- Event - Transations ------------------------------- */

/**
 * Gets all transactions for an event and event exhibitor.
 * @param {string} eventId
 * @param {string} eventExhibitorId
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data`
 */
export const getEventExhibitorTransactions = async (
  url,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.get(url)

    setLoading(false)
    setSuccess(true)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Gets all transactions for an event and event exhibitor.
 * @param {string} eventId
 * @param {string} eventExhibitorId
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data`
 */
export const markExhibitorDownloadTaskComplete = async (
  eventId,
  eventExhibitorId,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.post(
      `/events/${eventId}/exhibitors/${eventExhibitorId}/complete-download-task/`,
    )

    setLoading(false)
    setSuccess(true)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/** ------------------------------- Event - Registration ------------------------------- */

/**
 * Gets all transactions for an event and event exhibitor.
 * @param {string} eventId
 * @param {string} email
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data`
 */
export const getEventRegistration = async (
  eventId,
  email,
  setError = () => {},
  setLoading = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.get(
      `/register/${eventId}/${email ? `?email=${encodeURIComponent(email)}` : ''}`,
    )

    setLoading(false)
    return data
  } catch (err) {
    if (getErrorMessage(err).includes('User exists')) {
      setLoading(false)
      return 'User exists'
    }

    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Creates an event registration (either with an existing account or requesting access depending on the provided `payload`).
 * @param {string} eventId
 * @param {object} payload
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data`
 */
export const createEventRegistration = async (
  eventId,
  payload,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.post(`/register/${eventId}/`, payload)

    setLoading(false)
    setSuccess(data)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/** ------------------------------- Event - Sessions ------------------------------- */

/**
 * Gets the list of sessions for an event.
 * @param {string} url
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data`
 */
export const getSessionsList = async (url, setError = () => {}, setLoading = () => {}) => {
  setLoading(true)

  try {
    const data = await axios.get(url)

    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Gets the session with the specified `sessionId`.
 * @param {string} eventId
 * @param {string} sessionId
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` results
 */
export const getSession = async (
  eventId,
  sessionId,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.get(`/events/${eventId}/sessions/${sessionId}/`)

    setLoading(false)
    setSuccess(true)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Creates a session with the provided `eventId` and `payload`.
 * @param {string} eventId
 * @param {object} payload
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data`
 */
export const createSession = async (
  eventId,
  payload,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.post(`/events/${eventId}/sessions/`, payload)

    setLoading(false)
    setSuccess(data)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Updates the specified session using data from the `payload`.
 * @param {*} payload session details
 * @param {*} setError
 * @param {*} setLoading
 * @param {*} setSuccess
 * @returns `data` updated session
 */
export const updateSession = async (
  payload,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.patch(`/events/${payload.eventId}/sessions/${payload.id}/`, payload)

    setLoading(false)
    setSuccess(`${data.name} updated.`)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Triggers a refresh of the Trackr token using the provided `eventId`.
 * @param {*} eventId session details
 * @param {*} setError
 * @param {*} setLoading
 * @param {*} setSuccess
 * @returns `data` updated event
 */
export const triggerTrackrTokenRefresh = async (
  eventId,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.patch(`/events/${eventId}/`, { trigger_trackr_token_refresh: true })

    setLoading(false)
    setSuccess(`Access reset for ${data.name}.`)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Gets the list of attendees for the specified `eventId` and `sessionId`.
 * @param {string} eventId
 * @param {string} sessionId
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` results
 */
export const getSessionAttendees = async (
  url,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.get(url)

    setLoading(false)
    setSuccess(true)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Gets KPIs for the specified `eventId` and `sessionId`
 * @param {object} payload
 * @returns results or error
 */
export const getSessionKPIs = async (
  eventId,
  sessionId,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.get(`/events/${eventId}/sessions/${sessionId}/trackr-kpis/`)

    setLoading(false)
    setSuccess(true)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}
