import React, { useContext, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import {
  ExclamationTriangleIcon,
  MagnifyingGlassIcon,
  XCircleIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid'
import { ArrowPathIcon, ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/24/outline'
import { observer } from 'mobx-react-lite'
import _ from 'lodash'
import { twMerge as mergeClassNames } from 'tailwind-merge'
import dayjs from 'dayjs'
import { collection, onSnapshot, query, where } from 'firebase/firestore'
import { v4 as uuid4 } from 'uuid'
import { useVirtualizer } from '@tanstack/react-virtual'
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'

// Components
import { AddUserIcon } from '../../components/AddUserIcon'
import { Button } from '../../components/Button'
import { CheckBox } from '../../components/CheckBox'
import { CircleCheckIconSolid } from '../../components/CircleCheckIconSolid'
import { EditAttendeeModal } from '../../components/EditAttendeeModal'
import { PrinterIcon } from '../../components/PrinterIcon'
import { QRIcon } from '../../components/QRIcon'
import { Select } from '../../components/Select'
import { SyncExternalAttendeeModal } from '../../components/SyncExternalAttendeeModal'
import { TextInput } from '../../components/TextInput'
import QuickBadgeModal from './QuickBadgeModal'

// Store
import { KioskStoreContext } from '../../stores/KioskStore'

// Services & Utils
import { createAttendeeSync } from '../../services/attendees.service'
import { convertIncomingData } from '../../services/transforms'
import { instance } from '../../services/firestore/instance'
import { addBadgePrintLog, updateAttendee } from '../../services/firestore/firestore.service'
import { GDPR_COUNTRIES } from '../../utils/constants'
import colors from '../../utils/colors'
import { toast } from '../../utils/helpers'

const SEARCH_BY_OPTIONS = [
  {
    label: 'First Name',
    id: 'first_name_lower',
  },
  {
    label: 'Last Name',
    id: 'last_name_lower',
  },
  {
    label: 'Email',
    id: 'email_lower',
  },
  {
    label: 'Company',
    id: 'company_name_lower',
  },
]

const CATEGORY_COLORS = [
  { background: colors.purple.DEFAULT, label: colors.white.DEFAULT },
  { background: colors.teal.DEFAULT, label: colors.white.DEFAULT },
  { background: colors.blue.DEFAULT, label: colors.white.DEFAULT },
  { background: colors.purple[900], label: colors.white.DEFAULT },
  { background: colors.teal[900], label: colors.white.DEFAULT },
  { background: colors.blue[900], label: colors.white.DEFAULT },
  { background: colors.purple.light, label: colors.gray.dark },
  { background: colors.teal.light, label: colors.gray.dark },
  { background: colors.blue.light, label: colors.gray.dark },
  { background: colors.purple.dark, label: colors.white.DEFAULT },
  { background: colors.teal.dark, label: colors.white.DEFAULT },
  { background: colors.blue.dark, label: colors.white.DEFAULT },
]

const BUTTON_STYLES =
  'font-bold text-sm text-blue-600 hover:rounded-full hover:bg-status-blue px-2 py-0.5 disabled:cursor-not-allowed disabled:opacity-50'

const configureInvalidRegistrationStatus = (a) =>
  a.registrationStatus !== 'Confirmed' &&
  a.registrationStatus !== 'Attended' &&
  a.registrationStatus !== 'Accepted'

const NameCell = ({ attendee, activeTab }) => {
  let status = <CircleCheckIconSolid className="h-4 fill-purple" />
  let tooltip = 'Confirmed'
  let background = 'bg-purple'

  if (attendee.registrationStatus === 'In-progress') {
    status = <ExclamationTriangleIcon className="h-4 fill-orange" />
    tooltip = 'In Progress'
    background = 'bg-orange'
  } else if (configureInvalidRegistrationStatus(attendee)) {
    status = <XCircleIcon className="h-4 fill-red" />
    tooltip = 'Registration Error'
    background = 'bg-red'
  } else if (attendee.customData && attendee.customData?.balance_due > 0) {
    status = <ExclamationTriangleIcon className="h-4 fill-blue" />
    tooltip = 'Balance Due'
    background = 'bg-blue'
  } else if (
    !attendee.isCheckedIn &&
    attendee.country &&
    GDPR_COUNTRIES.includes(attendee.country)
  ) {
    status = <ExclamationTriangleIcon className="h-4 fill-blue" />
    tooltip = 'GDPR'
    background = 'bg-blue'
  }

  return (
    <div className="flex flex-col py-1" key={`attendee:${attendee.id}`}>
      <div className="flex flex-row gap-1">
        {activeTab === 'attendee' && (
          <div className="has-tooltip">
            <div className="tooltip -ml-2 -mt-8 flex rounded shadow-lg">
              <span
                className={mergeClassNames(
                  'rounded-lg px-2 py-1 text-xs text-white shadow-lg',
                  background,
                )}
              >
                {tooltip}
              </span>
            </div>
            {status}
          </div>
        )}

        <span className="line-clamp-1 text-sm font-medium text-gray-dark">
          {attendee.firstName} {attendee.lastName}
        </span>
      </div>

      {activeTab === 'attendee' && (
        <span className="line-clamp-1 truncate text-sm text-gray-600">{attendee.email}</span>
      )}
    </div>
  )
}

NameCell.propTypes = {
  attendee: PropTypes.object.isRequired,
  activeTab: PropTypes.string.isRequired,
}

const Staff = observer(
  ({ configuration, handleBulkPrint, event, registrationSyncData, setAttendeeToCheckIn }) => {
    // Context
    const { eventId, location, name } = useContext(KioskStoreContext)

    // State
    const [searchBy, setSearchBy] = useState('last_name_lower')
    const [searchTerm, setSearchTerm] = useState('')
    const [attendeeQuery, setAttendeeQuery] = useState(
      query(collection(instance, `events/${eventId}/kiosk-attendees`)),
    )
    const [quickBadgeQuery, setQuickBadgeQuery] = useState(
      query(collection(instance, `events/${eventId}/kiosk-quick-badges`)),
    )
    const [sorting, setSorting] = useState([{ id: 'name', desc: false }])
    const [firstLoad, setFirstLoad] = useState(true)

    // Attendees
    const [attendees, setAttendees] = useState([])
    const [selectedAttendees, setSelectedAttendees] = useState([])
    const [activeTab, setActiveTab] = useState('attendee')
    const [attendeeLastUpdated, setAttendeeLastUpdated] = useState('')
    const [attendeeToEdit, setAttendeeToEdit] = useState(null)

    // Attendee Sync
    const [showSyncExternalAttendeeModal, setShowSyncExternalAttendeeModal] = useState(false)

    // Quick Badges
    const [quickBadges, setQuickBadges] = useState([])
    const [selectedQuickBadges, setSelectedQuickBadges] = useState([])
    const [quickBadgeLastUpdated, setQuickBadgeLastUpdated] = useState('')
    const [showQuickBadgeModal, setShowQuickBadgeModal] = useState(false)
    const [quickBadgeDuplicate, setQuickBadgeDuplicate] = useState(null)

    // Ref
    const searchInputRef = useRef()
    const parentRef = useRef()

    const handleSuccess = (m) => toast(m, 'success')
    const handleErrors = (m) => toast(m, 'error')

    const addLog = (m, extra = null) =>
      console.log(`(Kiosk) [${dayjs().format('HH:mm:ss')}] ${m}`, extra || '')

    /**
     * Sets up the snapshot listener for the attendee queries.
     * - This will fire every time the attendee query changes.
     */
    useEffect(() => {
      addLog('Attendee query changed, starting new snapshot:', { attendeeQuery })
      const unsubscribeAttendeeSnapshot = onSnapshot(attendeeQuery, (snapshot) => {
        const filteredResults = snapshot.docs.map((d) => convertIncomingData(d.data()))
        setAttendees(filteredResults)
        setAttendeeLastUpdated(new Date())
        addLog('Attendee snapshot recieved.')
        if (firstLoad) setFirstLoad(false)
      })

      return () => {
        if (unsubscribeAttendeeSnapshot) {
          addLog('Attendee snapshot unsubscribed.')
          unsubscribeAttendeeSnapshot()
        }
      }
    }, [attendeeQuery])

    /**
     * Sets up the snapshot listener for the quick badge queries.
     * - This will fire every time the quick badge query changes.
     */
    useEffect(() => {
      addLog('Quick badge query changed, starting new snapshot:', { attendeeQuery })
      const unsubscribeQuickBadgeSnapshot = onSnapshot(quickBadgeQuery, (snapshot) => {
        const filteredResults = snapshot.docs.map((d) => convertIncomingData(d.data()))
        setQuickBadges(filteredResults)
        setQuickBadgeLastUpdated(new Date())
        addLog('Quick badge snapshot recieved.')
      })

      return () => {
        if (unsubscribeQuickBadgeSnapshot) {
          addLog('Quick badge snapshot unsubscribed.')
          unsubscribeQuickBadgeSnapshot()
        }
      }
    }, [quickBadgeQuery])

    /**
     * Sets up the query for the attendees and quickbadges, depending on the `activeTab`.
     * - Default to ordering by first name.
     */
    useEffect(() => {
      if (activeTab === 'attendee') {
        const options = []
        if (searchTerm) {
          options.push(where(searchBy, '>=', searchTerm.toLowerCase()))
          options.push(where(searchBy, '<=', `${searchTerm.toLowerCase()}~`))
        }

        const searchQuery = query(
          collection(instance, `events/${eventId}/kiosk-attendees`),
          ...options,
        )

        setAttendeeQuery(searchQuery)
      } else if (activeTab === 'quickBadges') {
        const options = []
        if (searchTerm) {
          options.push(where(searchBy, '>=', searchTerm.toLowerCase()))
          options.push(where(searchBy, '<=', `${searchTerm.toLowerCase()}~`))
        }

        const searchQuery = query(
          collection(instance, `events/${eventId}/quick-badges`),
          ...options,
        )

        setQuickBadgeQuery(searchQuery)
      }
    }, [searchTerm, activeTab, searchBy])

    const configureBulkPrintLabel = () => {
      if (activeTab === 'attendee') {
        return `Bulk Print Badge ${
          selectedAttendees.length > 1 ? `(${selectedAttendees.length})` : ''
        }`
      }

      return `Bulk Print Badge ${
        selectedQuickBadges.length > 1 ? `(${selectedQuickBadges.length})` : ''
      }`
    }

    const sortedCategoryNames = _.sortBy(_.values(registrationSyncData?.attendeeCategories))

    const columns = [
      {
        id: 'select',
        header: (
          <div className="flex w-full justify-center">
            <CheckBox
              className="ml-2"
              onChange={() => {
                // If viewing attendees, select all attendees
                if (
                  activeTab === 'attendee' &&
                  (selectedAttendees?.length === 0 ||
                    selectedAttendees?.length < attendees?.length)
                ) {
                  setSelectedAttendees(attendees)
                }
                // Otherwise, clear all selected attendees
                else if (activeTab === 'attendee') {
                  setSelectedAttendees([])
                }
                // If viewing quick badges, select all quick badges
                else if (
                  selectedQuickBadges?.length === 0 ||
                  selectedQuickBadges?.length < quickBadges?.length
                ) {
                  setSelectedQuickBadges(quickBadges)
                }
                // Otherwise, clear all selected quick badges
                else {
                  setSelectedQuickBadges([])
                }
              }}
              value={selectedAttendees?.length === attendees?.length}
            />
          </div>
        ),
        cell: ({ row }) => {
          let selected = null
          if (activeTab === 'attendee') {
            selected = _.find(selectedAttendees, (a) => a.id === row.original.id)
          } else {
            selected = _.find(selectedQuickBadges, (a) => a.id === row.original.id)
          }

          return (
            <div className="flex w-full justify-center">
              <CheckBox
                className="ml-2"
                onChange={() => {
                  if (activeTab === 'attendee' && selected) {
                    setSelectedAttendees(selectedAttendees.filter((a) => a.id !== row.original.id))
                  } else if (activeTab === 'attendee') {
                    setSelectedAttendees([...selectedAttendees, row.original])
                  } else if (selected) {
                    setSelectedQuickBadges(
                      selectedQuickBadges.filter((a) => a.id !== row.original.id),
                    )
                  } else {
                    setSelectedQuickBadges([...selectedQuickBadges, row.original])
                  }
                }}
                value={selected}
              />
            </div>
          )
        },
        size: 35,
      },
      {
        id: 'print',
        cell: ({ row }) => (
          <div className="flex w-full justify-center">
            <Button
              background={
                row.original.isCheckedIn || activeTab === 'quickBadges' ? 'bg-teal' : 'bg-purple'
              }
              disabled={
                row.original.customData &&
                configureInvalidRegistrationStatus(row.original) &&
                activeTab !== 'quickBadges'
              }
              label={
                row.original.isCheckedIn || activeTab === 'quickBadges'
                  ? 'Re-Print'
                  : 'Print Badge'
              }
              onClick={() => setAttendeeToCheckIn(row.original)}
            />
          </div>
        ),
        size: 120,
      },
      {
        id: 'name',
        header: 'NAME',
        accessorKey: 'firstName',
        cell: ({ row }) => <NameCell attendee={row.original} activeTab={activeTab} />,
        sortingFn: 'alphanumeric',
        size: 300,
        minSize: 300,
        maxSize: 300,
      },
      {
        id: 'companyAndTitle',
        header: 'COMPANY/TITLE',
        accessorKey: 'companyName',
        cell: ({ row }) => (
          <div className="flex w-full flex-col gap-0.5 text-sm text-gray-dark">
            <span className="line-clamp-1">{row.original.companyName}</span>
            <span className="line-clamp-1 text-gray-600">{row.original.title}</span>
          </div>
        ),
        sortingFn: 'alphanumeric',
        size: 200,
      },
      {
        id: 'category',
        header: () => (
          <div className="flex w-full justify-center">
            <span className="text-xs font-medium text-gray-dark">CATEGORY</span>
          </div>
        ),
        accessorKey: 'categoryId',
        sortingFn: (rowA, rowB) => {
          // If there's no category id, sort to the bottom
          if (!rowA.original.categoryId) {
            return 1
          }

          if (!rowB.original.categoryId) {
            return -1
          }

          const a =
            registrationSyncData?.attendeeCategories[rowA.original.categoryId]?.toLowerCase()
          const b =
            registrationSyncData?.attendeeCategories[rowB.original.categoryId]?.toLowerCase()

          // If there's no category, sort to the bottom
          if (!a) {
            return 1
          }

          if (!b) {
            return -1
          }

          if (a > b) {
            return 1
          }

          if (b > a) {
            return -1
          }

          return 0
        },
        cell: ({ row }) => {
          if (!registrationSyncData?.attendeeCategories || !row.original.categoryId) return null

          const category = registrationSyncData?.attendeeCategories[row.original.categoryId]
          const index = _.findIndex(sortedCategoryNames, (k) => k === category)

          let displayColors
          if (index === -1) {
            displayColors = { background: colors.gray.light, label: colors.gray.dark }
          } else {
            displayColors = CATEGORY_COLORS[index] || {
              background: colors.gray.light,
              label: colors.gray.dark,
            }
          }

          return (
            <div className="flex w-full items-center justify-center">
              <span
                className="rounded-full px-2.5 py-1 text-xs font-medium"
                style={{ backgroundColor: displayColors.background, color: displayColors.label }}
              >
                {_.capitalize(category) || 'No Category'}
              </span>
            </div>
          )
        },
        sortable: true,
        size: 190,
      },
      {
        id: 'sync',
        cell: ({ row }) => {
          let { lastSyncedAt } = row.original
          if (lastSyncedAt) {
            lastSyncedAt = dayjs(lastSyncedAt.toDate()).format('MM/DD/YYYY HH:mm A')
          }

          return (
            <div className="flex flex-col items-center justify-center gap-0.5">
              <button
                className={BUTTON_STYLES}
                type="button"
                onClick={async () => {
                  const response = await createAttendeeSync(
                    eventId,
                    {
                      syncType: 'Attendee - Single',
                      externalId: row.original.externalId,
                    },
                    handleErrors,
                    () => {},
                    () => {},
                  )

                  if (response) {
                    handleSuccess(
                      'Attendee sync triggered. Attendee will refresh automatically when available.',
                    )
                  }
                }}
              >
                Resync
              </button>

              {lastSyncedAt && (
                <span className="text-[10px] text-gray-600">Last Synced: {lastSyncedAt}</span>
              )}
            </div>
          )
        },
        size: 180,
      },
      {
        id: 'edit',
        cell: ({ row }) => (
          <div className="flex w-full justify-center">
            <button
              className={mergeClassNames(
                BUTTON_STYLES,
                row.original.registrationStatus === 'In-progress' &&
                  'cursor-not-allowed opacity-25',
              )}
              disabled={row.original.registrationStatus === 'In-progress'}
              type="button"
              onClick={() => {
                if (activeTab === 'attendee') setAttendeeToEdit(row.original)
                else setQuickBadgeDuplicate(row.original)
              }}
            >
              {activeTab === 'attendee' ? 'Edit' : 'Duplicate'}
            </button>
          </div>
        ),
        size: 100,
      },
    ]

    const table = useReactTable({
      data: activeTab === 'attendee' ? attendees : quickBadges,
      columns,
      state: {
        sorting,
      },
      onSortingChange: setSorting,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      initialState: {
        columnVisibility: {
          sync: event?.enableRegistrationSync,
        },
      },
    })

    const { rows } = table.getRowModel()

    const virtualizer = useVirtualizer({
      count: rows.length,
      getScrollElement: () => parentRef.current,
      estimateSize: () => 48,
      overscan: 50,
    })

    const renderLastUpdated = () => {
      if (attendeeLastUpdated === '' || quickBadgeLastUpdated === '') return null

      if (activeTab === 'attendee') {
        return (
          <div className="mr-2 mt-2 text-right text-xs text-gray-600">
            Last Update: {dayjs(attendeeLastUpdated).format('M/D/YY h:mm A')}
          </div>
        )
      }

      return (
        <div className="mr-2 mt-2 text-right text-xs text-gray-600">
          Last Update: {dayjs(quickBadgeLastUpdated).format('M/D/YY h:mm A')}
        </div>
      )
    }

    const configureBulkPrintButtonIsDisabled = () => {
      if (configuration?.requireKioskPhotoVerification) {
        return true
      }

      if (activeTab === 'attendee') {
        return selectedAttendees.length < 2
      }

      return selectedQuickBadges.length < 2
    }

    const renderSortedIcon = (sorted) => {
      if (sorted === 'asc') {
        return <ArrowUpIcon className="h-3 fill-black" />
      }

      if (sorted === 'desc') {
        return <ArrowDownIcon className="h-3 fill-gray" />
      }

      return null
    }

    return (
      <div className="flex h-full max-h-[600px] flex-col">
        <div className="mb-2 flex justify-between">
          <div className="flex items-center gap-4">
            <button
              className={mergeClassNames(
                'text-md border-b-2 pb-2 font-medium text-purple',
                activeTab === 'attendee'
                  ? 'border-purple'
                  : 'border-b-transparent text-gray-600 hover:border-b-purple hover:text-purple',
              )}
              type="button"
              onClick={() => setActiveTab('attendee')}
            >
              Attendee
            </button>

            <button
              className={mergeClassNames(
                'text-md border-b-2 pb-2 font-medium text-purple',
                activeTab === 'quickBadges'
                  ? 'border-purple'
                  : 'border-b-transparent text-gray-600 hover:border-b-purple hover:text-purple',
              )}
              type="button"
              onClick={() => setActiveTab('quickBadges')}
            >
              Quick Badges
            </button>
          </div>

          <div className="flex items-center gap-4 self-start">
            {event?.enableRegistrationSync && (
              <Button
                background="bg-purple border-purple right-0 hover:bg-purple-600"
                label="Sync External Attendee"
                onClick={() => setShowSyncExternalAttendeeModal(true)}
              />
            )}

            <Button
              background="bg-teal"
              icon={<AddUserIcon className="h-5 fill-black sm:h-6" />}
              label="Add Quick Badge"
              onClick={() => setShowQuickBadgeModal(true)}
            />

            <Button
              disabled={configureBulkPrintButtonIsDisabled()}
              icon={<PrinterIcon className="h-5 fill-white" />}
              label={configureBulkPrintLabel()}
              onClick={() => {
                let filteredAttendees = []

                if (activeTab === 'attendee') {
                  // Filter out attendees that can't be checked in with a bulk print
                  filteredAttendees = _.filter(selectedAttendees, (a) => {
                    if (configureInvalidRegistrationStatus(a)) return false
                    if (a.customData?.balance_due > 0) return false
                    // Attendees needing GDPR consent don't need to answer it again after being checked in
                    if (!a.isCheckedIn && a.country && GDPR_COUNTRIES.includes(a.country))
                      return false
                    return true
                  })

                  _.forEach(filteredAttendees, (a) => {
                    // Only update check-in status if the attendee hasn't checked-in already
                    if (!a.isCheckedIn && !a.quickBadge) {
                      // Mark attendee as checked in
                      updateAttendee(eventId, a.verificationId, {
                        isCheckedIn: true,
                        checkInLocation: location.id,
                        checkInLocationName: name,
                        checkedInAt: new Date(),
                      })
                    }

                    // Log Badge Print
                    const printLog = {
                      printedAt: new Date(),
                      id: `${uuid4()}`,
                      printedAtLocation: location.id,
                      printedAtLocationName: name,
                    }
                    if (a.quickBadge) {
                      printLog.quickBadge = a.id
                    } else {
                      printLog.attendee = a.id
                    }

                    addBadgePrintLog(eventId, printLog)
                  })
                } else {
                  filteredAttendees = selectedQuickBadges
                }

                handleBulkPrint(filteredAttendees)

                // Clear selected attendees
                if (activeTab === 'attendee') setSelectedAttendees([])
                else setSelectedQuickBadges([])
              }}
            />
          </div>
        </div>

        <div className="flex flex-row gap-4">
          <TextInput
            ref={searchInputRef}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-[420px] rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
            icon={<MagnifyingGlassIcon className="ml-2 h-5 text-gray-dark" aria-hidden="true" />}
            placeholder="Search"
            endIcon={
              searchTerm ? (
                <button
                  type="button"
                  onClick={() => {
                    setSearchTerm('')
                    searchInputRef.current.value = ''
                  }}
                >
                  <XMarkIcon className="mr-2 h-5 text-gray-dark" aria-hidden="true" />
                </button>
              ) : null
            }
          />

          <Select
            className="rounded-2xl border-gray-550 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
            options={SEARCH_BY_OPTIONS}
            onChange={(o) => setSearchBy(o.id)}
            value={_.find(SEARCH_BY_OPTIONS, (o) => o.id === searchBy)}
            style={{ flex: true, width: '150px' }}
          />
        </div>

        <div
          ref={parentRef}
          className={mergeClassNames(
            'mt-4 flex min-h-[0px] flex-col overflow-auto rounded-lg border-[1px] border-solid border-gray-300',
            virtualizer.getTotalSize() !== 0 && 'h-full',
          )}
        >
          {firstLoad ? (
            <div className="flex h-[200px] w-full flex-col items-center justify-center space-y-2">
              <span className="text-2xl font-bold">Loading...</span>

              <span className="flex items-center pr-3">
                <div className="h-10 w-10">
                  {/* eslint-disable-next-line tailwindcss/no-custom-classname, tailwindcss/classnames-order */}
                  <svg className="h-10 w-10 motion-safe:animate-spin-slow" viewBox="0 0 40 40">
                    <ArrowPathIcon className="h-10 w-10" aria-hidden="true" />
                  </svg>
                </div>
              </span>
            </div>
          ) : (
            <>
              <div className="w-full" style={{ height: `${virtualizer.getTotalSize()}px` }}>
                <table className="w-full">
                  <thead className="sticky border-b-[1px] bg-gray-100">
                    {table.getHeaderGroups().map((headerGroup) => (
                      <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                          <th
                            key={header.id}
                            colSpan={header.colSpan}
                            style={{ width: header.getSize() }}
                          >
                            {header.isPlaceholder ? null : (
                              // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                              <div
                                className={mergeClassNames(
                                  'flex h-[40px] flex-row items-center gap-1 text-xs font-medium capitalize text-black',
                                  header.column.getCanSort() && 'cursor-pointer select-none',
                                )}
                                onClick={header.column.getToggleSortingHandler()}
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter') {
                                    header.column.getToggleSortingHandler()()
                                  }
                                }}
                              >
                                {flexRender(header.column.columnDef.header, header.getContext())}
                                {renderSortedIcon(header.column.getIsSorted())}
                              </div>
                            )}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>

                  <tbody className="w-full">
                    {virtualizer.getVirtualItems().map((virtualRow, index) => {
                      const row = rows[virtualRow.index]
                      return (
                        <tr
                          className="w-full border-b-[1px] border-solid border-gray-200 px-1 py-0.5"
                          key={row.id}
                          style={{
                            height: `${virtualRow.size}px`,
                            transform: `translateY(${
                              virtualRow.start - index * virtualRow.size
                            }px)`,
                          }}
                        >
                          {row.getVisibleCells().map((cell) => (
                            <td
                              className="px-1"
                              key={cell.id}
                              style={{ width: cell.column.getSize() }}
                            >
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                          ))}
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
              {virtualizer.getTotalSize() === 0 && (
                <div className="mt-10 flex h-20 w-full items-center justify-center">
                  <span className="text-md text-center">There are no records to display</span>
                </div>
              )}
            </>
          )}
        </div>

        {!firstLoad && (
          <div className="mb-2 flex justify-between">
            <span className="ml-2 mt-2 text-xs text-gray-600">
              {activeTab === 'attendee'
                ? `Total Attendees: ${attendees.length}`
                : `Total Quick Badges: ${quickBadges.length}`}
            </span>
            {renderLastUpdated()}
          </div>
        )}

        <div className="absolute bottom-3 mt-8 flex items-center gap-2">
          <QRIcon className="h-4 fill-black" />
          {name}
        </div>

        {(showQuickBadgeModal || quickBadgeDuplicate) && (
          <QuickBadgeModal
            baseQuickBadge={quickBadgeDuplicate}
            handleCheckIn={setAttendeeToCheckIn}
            eventId={eventId}
            registrationSyncData={registrationSyncData}
            closeModal={() => {
              setQuickBadgeDuplicate(null)
              setShowQuickBadgeModal(false)
            }}
          />
        )}

        {attendeeToEdit && (
          <EditAttendeeModal
            attendeeToEdit={attendeeToEdit}
            closeModal={() => setAttendeeToEdit(null)}
            deviceLocations={configuration?.kioskDeviceLocations}
            handleCheckIn={(attendee) => {
              setAttendeeToCheckIn(attendee)
              setAttendeeToEdit(null)
            }}
            externalPopupField={configuration?.externalPopupField}
            registrationSyncData={registrationSyncData}
          />
        )}

        {showSyncExternalAttendeeModal && (
          <SyncExternalAttendeeModal
            eventId={eventId}
            onClose={() => {
              setShowSyncExternalAttendeeModal(false)
            }}
          />
        )}
      </div>
    )
  },
)

Staff.propTypes = {
  handleBulkPrint: PropTypes.func.isRequired,
  registrationSyncData: PropTypes.object.isRequired,
  registrationSyncEnabled: PropTypes.bool.isRequired,
  setAttendeeToCheckIn: PropTypes.func.isRequired,
}

export { Staff }
