import React from 'react'
import { useForm } from 'react-hook-form'
import PropTypes from 'prop-types'

// Images
import Add from '../../assets/images/add.svg'
import Edit from '../../assets/images/editCircle.svg'
import Email from '../../assets/images/email.svg'
import Present from '../../assets/images/present.svg'
import Suitcase from '../../assets/images/suitcase.svg'
import User from '../../assets/images/user.svg'

// Components
import { ExhibitorIcon } from '../ExhibitorIcon'
import { Modal } from '../Modal'
import { TextInput } from '../TextInput'
import { PhoneNumberInput } from '../PhoneNumberInput'
import { LocationIcon } from '../LocationIcon'

const AddExhibitorModal = ({
  numFreeLicenses,
  editExhibitor,
  loading,
  onSubmit,
  setShowExhibitorModal,
}) => {
  const DEFAULT = {
    exhibitor: null,
    numFreeLicensesOverride: numFreeLicenses !== null ? numFreeLicenses : null,
    boothNumber: null,
    contactFirstName: null,
    contactLastName: null,
    contactEmail: null,
    contactPhone: null,
    contactAddress: null,
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
    reset,
  } = useForm({
    defaultValues: editExhibitor || DEFAULT,
  })

  return (
    <Modal
      actions={[
        {
          type: 'cancel',
          label: 'Cancel',
          onClick: () => {
            setTimeout(() => {
              reset(DEFAULT)
            }, 500)
          },
        },
        {
          type: 'submit',
          label: editExhibitor ? 'Save' : 'Add Exhibitor',
          onClick: handleSubmit(onSubmit),
        },
      ]}
      icon={editExhibitor ? <img src={Edit} alt="Icon" /> : <img src={Add} alt="Icon" />}
      content={
        <div className="mt-3 flex flex-col space-y-4 text-center sm:mt-5">
          <TextInput
            className="rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
            icon={<img alt="Company" className="ml-1.5 h-4" src={Suitcase} />}
            data-testid="company"
            disabled={loading || editExhibitor}
            error={errors.exhibitor && 'This field is required'}
            fullWidth
            id="exhibitor"
            inputStyles="rounded-none rounded-t-md font-nunito"
            name="exhibitor"
            nunito
            label="Company"
            placeholder="Company"
            {...register('exhibitor', { required: true })}
          />

          <div className="flex flex-col sm:flex-row sm:space-x-2">
            <TextInput
              className="w-full rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
              icon={<img alt="Present" className="ml-2 h-4" src={Present} />}
              data-testid="numFreeLicensesOverride"
              disabled={loading}
              fullWidth
              id="numFreeLicensesOverride"
              inputStyles="rounded-none rounded-t-md font-nunito"
              name="numFreeLicensesOverride"
              nunito
              label="Free Licenses"
              placeholder="Number of Licenses"
              type="number"
              {...register('numFreeLicensesOverride')}
            />

            <TextInput
              className="w-full rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
              icon={<ExhibitorIcon className="ml-1 h-4 stroke-purple" />}
              data-testid="boothNumber"
              disabled={loading}
              fullWidth
              id="boothNumber"
              inputStyles="rounded-none rounded-t-md font-nunito"
              name="boothNumber"
              nunito
              label="Booth Number"
              placeholder="Booth Number"
              {...register('boothNumber')}
            />
          </div>

          <div className="flex flex-col sm:flex-row sm:space-x-2">
            <TextInput
              className="w-full rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
              icon={<img alt="User" className="ml-2 h-4" src={User} />}
              data-testid="contactFirstName"
              disabled={loading}
              fullWidth
              id="contactFirstName"
              inputStyles="rounded-none rounded-t-md font-nunito"
              name="contactFirstName"
              nunito
              label="Contact First Name"
              placeholder="First Name"
              {...register('contactFirstName')}
            />

            <TextInput
              className="w-full rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
              icon={<img alt="User" className="ml-2 h-4" src={User} />}
              data-testid="contactLastName"
              disabled={loading}
              fullWidth
              id="contactLastName"
              inputStyles="rounded-none rounded-t-md font-nunito"
              name="contactLastName"
              nunito
              label="Contact Last Name"
              placeholder="Last Name"
              {...register('contactLastName')}
            />
          </div>

          <TextInput
            className="rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
            icon={<img alt="Email" className="ml-1.5 h-4" src={Email} />}
            data-testid="contactEmail"
            disabled={loading}
            error={errors.contactEmail && 'This field is required'}
            fullWidth
            id="contactEmail"
            inputStyles="rounded-none rounded-t-md font-nunito"
            name="contactEmail"
            nunito
            label="Email"
            placeholder="Email Address"
            {...register('contactEmail', { required: true })}
          />

          <PhoneNumberInput
            control={control}
            disabled={loading}
            error={errors.contactPhoneNumber}
            label="Phone"
            name="contactPhoneNumber"
          />

          <TextInput
            className="rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
            icon={<LocationIcon className="ml-1 h-5 stroke-purple" />}
            data-testid="contactAddress"
            disabled={loading}
            fullWidth
            id="contactAddress"
            inputStyles="rounded-none rounded-t-md font-nunito"
            name="contactAddress"
            nunito
            label="Address"
            placeholder="Address"
            {...register('contactAddress')}
          />
        </div>
      }
      loading={loading}
      onClose={() => {
        setShowExhibitorModal(false)

        setTimeout(() => {
          reset(DEFAULT)
        }, 500)
      }}
      open
      setOpen={setShowExhibitorModal}
      title={editExhibitor ? 'Edit' : 'Add Exhibitor'}
    />
  )
}

AddExhibitorModal.defaultProps = {
  editExhibitor: null,
  numFreeLicenses: null,
}

AddExhibitorModal.propTypes = {
  numFreeLicenses: PropTypes.number,
  editExhibitor: PropTypes.object,
  handleDelete: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  setShowExhibitorModal: PropTypes.func.isRequired,
}

export default AddExhibitorModal
