import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useParams } from 'react-router-dom'
import { twMerge as mergeClassNames } from 'tailwind-merge'
import {
  DevicePhoneMobileIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/20/solid'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'

// Components
import { ExhibitorIcon } from '../../components/ExhibitorIcon'
import { CircleCheckIconSolidInverted } from '../../components/CircleCheckIconSolidInverted'
import { CircleCheckIconSolid } from '../../components/CircleCheckIconSolid'
import { DataTable } from '../../components/DataTable'
import { DataTile } from '../../components/DataTile'
import { IdentificationIcon } from '../../components/IdentificationIcon'
import { PlusIcon } from '../../components/PlusIcon'

// Service
import { getSessionAttendees, getSessionKPIs } from '../../services/events.service'

// Store
import { NavigationStoreContext } from '../../stores/NavigationStore'

// Utils
import { handlePagination, toast } from '../../utils/helpers'

const ExpandedAttendee = ({ data }) => {
  const renderActionIcon = (action) => {
    switch (action) {
      case 'Added to Session and Checked In':
        return (
          <div className="rounded-full bg-teal">
            <PlusIcon className="h-4 w-4 fill-white" />
          </div>
        )
      case 'Verified Photo and Checked In':
      case 'Checked In':
        return <CircleCheckIconSolid className="h-4 fill-teal" />
      case 'Checked Out':
        return <CircleCheckIconSolid className="h-4 fill-purple" />
      case 'Photo Rejected':
        return (
          <div className="rounded-full bg-red p-0.5">
            <ExclamationTriangleIcon className="h-3 fill-white" />
          </div>
        )
      default:
        return null
    }
  }

  return (
    <div
      className="flex items-center gap-2 bg-gray-200 py-4 pl-[144px] pr-4"
      style={{
        borderBottom: '1px solid #E5E7EB',
        minHeight: '52px',
      }}
    >
      <span className="basis-1/3 text-sm font-bold">Attendee Log</span>

      <span className="flex flex-col gap-1 text-xs text-gray-500">
        {data.sessionCheckInLogs.length > 0 &&
          data.sessionCheckInLogs.map((log) => (
            <div key={log.id} className="flex gap-1 text-gray-700">
              {renderActionIcon(log.action)}
              <span>{log.action}</span>
              <span>{dayjs(log.createdAt).format('h:mm A')}</span>
              <span className="ml-2">{log.status}</span>
            </div>
          ))}
      </span>
    </div>
  )
}

ExpandedAttendee.propTypes = {
  data: PropTypes.object.isRequired,
}

const Attendees = observer(({ session }) => {
  // Context
  const { eventId } = useContext(NavigationStoreContext)
  const { sessionId } = useParams()

  // State
  const [attendees, setAttendees] = useState([])
  const [loading, setLoading] = useState(false)
  const [KPI, setKPI] = useState({
    registered: 0,
    attended: 0,
  })

  // Pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [totalRows, setTotalRows] = useState(0)
  const [perPage, setPerPage] = useState(20)
  const [pages, setPages] = useState(null)

  const handleErrors = (m) => toast(m, 'error')

  const BASE_URL = `/events/${eventId}/sessions/${sessionId}/attendees/?expand=session_check_in_logs&`
  const startsAt = `${session.date} ${session.startsAt}`
  const endsAt = `${session.date} ${session.endsAt}`

  const getUpdatedAttendees = async (url) => {
    const attendeeResponse = await getSessionAttendees(url, handleErrors, setLoading, () => {})

    if (attendeeResponse) {
      setTotalRows(attendeeResponse.count)
      setPages({ next: attendeeResponse.next, previous: attendeeResponse.previous })
      setAttendees(attendeeResponse.results)
    }
  }

  useEffect(() => {
    const getUpdatedData = async () => {
      const response = await getSessionKPIs(eventId, sessionId, handleErrors, setLoading, () => {})

      if (response) {
        setKPI(response)
      }
    }
    getUpdatedData()
  }, [])

  /**
   * When the row count changes, get the updated list of attendees.
   */
  useEffect(() => {
    getUpdatedAttendees(`${BASE_URL}limit=${perPage}`)
  }, [perPage])

  const renderMetrics = () => {
    // After start time but before the 30-minute window after the session ends
    if (dayjs().isAfter(dayjs(startsAt)) && dayjs().isBefore(dayjs(endsAt).add(30, 'minutes'))) {
      return (
        <>
          <DataTile
            color="green"
            icon={<CircleCheckIconSolidInverted className="h-6 fill-teal" />}
            label="Checked-In"
            value={KPI.checkedInAttendeeCount}
          />

          <DataTile
            color="purple"
            icon={<CircleCheckIconSolidInverted className="h-6 fill-purple" />}
            label="Checked-Out"
            value={KPI.checkedOutAttendeeCount}
          />
        </>
      )
    }

    // After the 30-minute window after the session ends
    if (dayjs().isAfter(dayjs(endsAt).add(30, 'minutes'))) {
      return (
        <>
          <DataTile
            color="green"
            icon={<IdentificationIcon className="h-6 fill-white" />}
            label="Registered"
            value={KPI.checkedInAttendeeCount}
          />

          <DataTile
            color="purple"
            icon={<DevicePhoneMobileIcon className="h-6 fill-white" />}
            label="Attended"
            value={KPI.checkedOutAttendeeCount}
          />
        </>
      )
    }

    // Before the session starts
    return (
      <>
        <DataTile
          color="green"
          icon={<IdentificationIcon className="h-6 fill-white" />}
          label="Registered"
          value={KPI.totalAttendeeCount || 0}
        />

        <DataTile
          color="purple"
          icon={<ExhibitorIcon className="h-6 fill-white stroke-white" />}
          label="Attended"
          value={0}
        />
      </>
    )
  }

  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full flex-col space-y-4 pb-4 pt-6 md:flex-row md:space-x-4 md:space-y-0">
        {renderMetrics()}
      </div>

      <DataTable
        data={attendees}
        columns={[
          {
            id: 'status',
            name: 'Status',
            selector: (row) => row.status,
            cell: (row) => {
              const checkedIn = row.sessionCheckInLogs.some((log) =>
                log.action.includes('Checked In'),
              )
              return (
                <CircleCheckIconSolid
                  className={mergeClassNames('h-6', checkedIn ? 'fill-teal' : 'fill-purple')}
                />
              )
            },
            width: '80px',
            center: true,
          },
          {
            id: 'name',
            name: 'Name',
            selector: (row) => `${row.firstName} ${row.lastName}`,
            cell: (row) => (
              <div className="flex w-full items-center gap-2">
                <div className="font-bold text-black">{`${row.firstName} ${row.lastName}`}</div>
                {row.sessionCheckInLogs?.some((log) =>
                  log.action.includes('Added to Session'),
                ) && (
                  <div className="ml-2 shrink-0 rounded-full bg-red-100 px-2.5 py-0.5">
                    <span className="text-xs font-bold text-red">Admin Bypass</span>
                  </div>
                )}
              </div>
            ),
            grow: 0.75,
            minWidth: '180px',
          },
          {
            id: 'company',
            name: 'Company',
            selector: (row) => row.companyName,
            grow: 0.75,
            minWidth: '150px',
          },
          {
            id: 'email',
            name: 'Email',
            selector: (row) => row.email,
            grow: 0.75,
            minWidth: '250px',
          },
        ]}
        defaultPageSize={10}
        onChangePage={(page) =>
          handlePagination(
            page,
            currentPage,
            perPage,
            totalRows,
            pages,
            setCurrentPage,
            getUpdatedAttendees,
            `${BASE_URL}limit=`,
          )
        }
        onChangeRowsPerPage={async (currentRowsPerPage) => setPerPage(currentRowsPerPage)}
        pagination
        paginationPerPage={perPage}
        paginationRowsPerPageOptions={[10, 15, 20, 30, 50]}
        paginationTotalRows={totalRows}
        paginationServer
        progressPending={loading}
        className={mergeClassNames('w-full', 'mt-4', 'attendees-table')}
        expandableIcon={{
          collapsed: <ChevronDownIcon className="h-5 stroke-dark" />,
          expanded: <ChevronUpIcon className="h-5 stroke-dark" />,
        }}
        expandableRows
        expandableRowsComponent={ExpandedAttendee}
      />
    </div>
  )
})

export { Attendees }
