import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { twMerge as mergeClassNames } from 'tailwind-merge'
import PropTypes from 'prop-types'
import _ from 'lodash'

// Images
import Add from '../../assets/images/add.svg'
import Edit from '../../assets/images/editCircle.svg'
import Email from '../../assets/images/email.svg'
import Memo from '../../assets/images/memo.svg'
import User from '../../assets/images/user.svg'
import Suitcase from '../../assets/images/suitcase.svg'

// Components
import { Modal } from '../../components/Modal'
import { PhoneNumberInput } from '../../components/PhoneNumberInput'
import { Select } from '../../components/Select'
import { TextInput } from '../../components/TextInput'
import { UserIcon } from '../../components/UserIcon'

// Service
import { addAttendee, deleteAttendee, updateAttendee } from '../../services/attendees.service'

// Utils & Styles
import { NON_SYNCED_FIELDS } from '../../utils/constants'
import { toast } from '../../utils/helpers'

const TEXT_FIELD_STYLES =
  'rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple'

const DEFAULT = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  companyName: '',
  title: '',
}

const AttendeeModal = ({
  admissionItems = null,
  attendee = null,
  availableCustomFields = null,
  categories = null,
  eventId,
  enableRegistrationSync,
  enabledCustomFields = null,
  onClose,
  sessions = null,
}) => {
  // State
  const [loading, setLoading] = useState(false)
  const [customFieldKeys, setCustomFieldKeys] = useState([])
  const [customFields, setCustomFields] = useState(null)

  const handleErrors = (m) => toast(m, 'error')
  const handleSuccesses = (m) => toast(m, 'success')

  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
    reset,
  } = useForm({
    defaultValues: DEFAULT,
  })

  useEffect(() => {
    // Configure additional custom fields if this is not a registration sync event
    if (!enableRegistrationSync && enabledCustomFields) {
      // Filter out custom fields that are on the base attendee object
      // and are available in the custom fields
      const additionalFields = _.filter(
        enabledCustomFields,
        (f) => !NON_SYNCED_FIELDS.includes(f) && availableCustomFields[f],
      )
      // Remove the fields that have options
      const fieldsWithOptions = _.remove(additionalFields, (f) => availableCustomFields[f].options)

      // Create an object with the custom fields that have options
      // We need to convert the options to an array of objects with id and label
      const customFieldsWithOptions = {}
      _.forEach(fieldsWithOptions, (f) => {
        customFieldsWithOptions[f] = _.map(_.values(availableCustomFields[f].options), (o) => ({
          id: o,
          label: o,
        }))
      })

      setCustomFields({ withoutOptions: additionalFields, withOptions: customFieldsWithOptions })
    }
  }, [])

  useEffect(() => {
    const updatedAttendeeData = { ...attendee }

    if (attendee && customFields) {
      // Extract custom data
      const customData = { ...updatedAttendeeData.customData }
      delete updatedAttendeeData.customData

      // Set custom data fields (minus category)
      _.forEach(customData, (v, k) => {
        if (customFields.withOptions[k]) {
          if (availableCustomFields[k].type === 'MultiChoice') {
            const matchingOptions = _.filter(customFields.withOptions[k], (o) => v?.includes(o.id))
            updatedAttendeeData[k] = matchingOptions || []
          } else {
            const matchingOption = _.find(customFields.withOptions[k], (o) => o.id === v)
            updatedAttendeeData[k] = matchingOption
          }
        } else {
          updatedAttendeeData[k] = v
        }
      })

      // Find matching category object for prefilling
      if (categories && attendee.categoryId) {
        updatedAttendeeData.category = _.find(categories, (c) => c.id === attendee.categoryId)
      }

      // Find matching sessions objects for prefilling
      if (sessions && attendee.sessions) {
        const matchingSessions = _.filter(sessions, (s) => attendee.sessions.includes(s.id))
        updatedAttendeeData.sessions = matchingSessions
      }

      // Find matching admission items objects for prefilling
      if (admissionItems && attendee.admissionItem) {
        const matchingAdmissionItems = _.filter(
          admissionItems,
          (a) => attendee.admissionItem === a.id,
        )
        updatedAttendeeData.admissionItem = matchingAdmissionItems
      }

      setCustomFieldKeys(_.keys(customData))
      reset(updatedAttendeeData)
    } else if (attendee) {
      // Find matching category object for prefilling
      if (categories && attendee.categoryId) {
        updatedAttendeeData.category = _.find(categories, (c) => c.id === attendee.categoryId)
      }

      // Find matching sessions objects for prefilling
      if (sessions && attendee.sessions) {
        const matchingSessions = _.filter(sessions, (s) => attendee.sessions.includes(s.id))
        updatedAttendeeData.sessions = matchingSessions
      }

      reset(updatedAttendeeData)
    }
  }, [customFields, attendee])

  const configureModalIcon = () => {
    if (enableRegistrationSync) return <UserIcon className="stroke-white" />
    return attendee ? <img src={Edit} alt="Icon" /> : <img src={Add} alt="Icon" />
  }

  const configureModalTitle = () => {
    if (enableRegistrationSync) return 'View'
    return attendee ? 'Edit' : 'Add Attendee'
  }

  /**
   * Handles submitting the add or edit attendee forms.
   * @param {object} data
   */
  const onSubmit = (data) => {
    // Extract custom data from the form data so we can transform any values as needed
    const customDataKeys = _.keys(data).filter(
      (k) =>
        customFieldKeys.includes(k) &&
        k !== 'sessions' &&
        k !== 'category' &&
        k !== 'admissionItem',
    )
    const customData = _.pick(data, customDataKeys)

    // Any values that are objects need to be converted
    const updatedCustomData = _.mapValues(customData, (c) => {
      if (_.isArray(c)) _.map(c, (o) => o.id)
      if (_.isObject(c)) return c.id
      return c
    })

    // Manually handle sessions since this is a multi-select
    const sessionIds = _.map(data.sessions, 'id')

    // Custom data gets nested on the attendee
    const baseData = _.pick(
      data,
      _.keys(data).filter((k) => NON_SYNCED_FIELDS.includes(k)),
    )
    const payload = {
      ...attendee,
      ...baseData,
      categoryId: data.category?.id || '',
      categoryName: data.category?.label || '',
      customData: updatedCustomData,
      sessions: sessionIds,
      admissionItem: data.admissionItem?.id || '',
    }
    delete payload.category

    if (attendee) {
      updateAttendee(eventId, payload, handleErrors, setLoading, (m) => {
        handleSuccesses(m)
        onClose(true)
      })
    } else {
      addAttendee(eventId, payload, handleErrors, setLoading, (m) => {
        handleSuccesses(m)
        onClose(true)
      })
    }
  }

  const actions = [
    {
      type: 'cancel',
      label: 'Close',
      onClick: onClose,
    },
  ]

  if (!enableRegistrationSync) {
    actions.push({
      type: 'submit',
      label: attendee ? 'Save' : 'Add Attendee',
      onClick: handleSubmit(onSubmit),
    })
  }
  if (!enableRegistrationSync && attendee) {
    actions.push({
      type: 'delete',
      label: 'Delete',
      onClick: () =>
        deleteAttendee(eventId, attendee.id, handleErrors, setLoading, (m) => {
          handleSuccesses(m)
          onClose()
        }),
    })
  }

  return (
    <Modal
      actions={actions}
      icon={configureModalIcon()}
      content={
        <div className="mt-3 flex h-[50vh] flex-col space-y-4 overflow-y-auto text-center sm:mt-5 tall:max-h-[600px]">
          <div className="flex flex-col sm:flex-row sm:space-x-2">
            <TextInput
              className={mergeClassNames('w-full', TEXT_FIELD_STYLES)}
              icon={<img alt="User" className="ml-2 h-4" src={User} />}
              data-testid="firstName"
              disabled={enableRegistrationSync || loading}
              error={errors.firstName && 'This field is required'}
              fullWidth
              id="firstName"
              inputStyles="rounded-none rounded-t-md font-nunito"
              name="firstName"
              nunito
              label="First Name"
              placeholder="First Name"
              {...register('firstName', { required: true })}
            />

            <TextInput
              className={mergeClassNames('w-full', TEXT_FIELD_STYLES)}
              icon={<img alt="User" className="ml-2 h-4" src={User} />}
              data-testid="lastName"
              disabled={enableRegistrationSync || loading}
              error={errors.lastName && 'This field is required'}
              fullWidth
              id="lastName"
              inputStyles="rounded-none rounded-t-md font-nunito"
              name="lastName"
              nunito
              label="Last Name"
              placeholder="Last Name"
              {...register('lastName', { required: true })}
            />
          </div>

          <div className="flex flex-col sm:flex-row sm:space-x-2">
            <TextInput
              fullWidth
              className={mergeClassNames('w-full', TEXT_FIELD_STYLES, 'pl-3')}
              disabled={enableRegistrationSync || loading}
              error={errors.confirmationCode && 'This field is required'}
              id="confirmationCode"
              inputStyles="rounded-none rounded-t-md font-nunito mt-0.5"
              label="Confirmation Code"
              name="confirmationCode"
              nunito
              placeholder="Confirmation code"
              {...register('confirmationCode')}
            />

            <div className="w-full">
              <Controller
                name="category"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    className={mergeClassNames('w-full', TEXT_FIELD_STYLES, 'pl-3')}
                    disabled={enableRegistrationSync || loading}
                    label="Category"
                    onChange={onChange}
                    options={categories}
                    value={value}
                  />
                )}
              />
            </div>
          </div>

          <TextInput
            className={TEXT_FIELD_STYLES}
            icon={<img alt="Company" className="ml-1.5 h-4" src={Suitcase} />}
            data-testid="company"
            disabled={enableRegistrationSync || loading}
            error={errors.companyName && 'This field is required'}
            fullWidth
            id="company"
            inputStyles="rounded-none rounded-t-md font-nunito"
            name="companyName"
            nunito
            label="Company"
            placeholder="Company"
            {...register('companyName')}
          />

          <TextInput
            className={TEXT_FIELD_STYLES}
            icon={<img alt="Title" className="ml-1.5 h-4" src={Memo} />}
            data-testid="title"
            disabled={enableRegistrationSync || loading}
            error={errors.title && 'This field is required'}
            fullWidth
            id="title"
            inputStyles="rounded-none rounded-t-md font-nunito"
            name="title"
            nunito
            label="Title"
            placeholder="Job Title"
            {...register('title')}
          />

          <TextInput
            fullWidth
            className={mergeClassNames('w-full', TEXT_FIELD_STYLES, 'pl-3')}
            disabled={enableRegistrationSync || loading}
            error={errors.address1 && 'This field is required'}
            label="Address"
            name="address1"
            nunito
            placeholder="Address"
            {...register('address1')}
          />

          <TextInput
            fullWidth
            className={mergeClassNames('w-full', TEXT_FIELD_STYLES, 'pl-3')}
            disabled={enableRegistrationSync || loading}
            error={errors.address2 && 'This field is required'}
            label="Apartment, Suite, etc."
            inputStyles="rounded-none rounded-t-md font-nunito"
            name="address2"
            nunito
            placeholder="Apartment, Suite, etc."
            {...register('address2')}
          />

          <div className="flex flex-col sm:flex-row sm:space-x-2">
            <TextInput
              fullWidth
              className={mergeClassNames('w-full', TEXT_FIELD_STYLES, 'pl-3')}
              disabled={enableRegistrationSync || loading}
              error={errors.city && 'This field is required'}
              label="City"
              inputStyles="rounded-none rounded-t-md font-nunito"
              name="city"
              nunito
              placeholder="City"
              {...register('city')}
            />
            <TextInput
              fullWidth
              className={mergeClassNames('w-full', TEXT_FIELD_STYLES, 'pl-3')}
              disabled={enableRegistrationSync || loading}
              error={errors.state && 'This field is required'}
              label="State"
              inputStyles="rounded-none rounded-t-md font-nunito"
              name="state"
              nunito
              placeholder="State"
              {...register('state')}
            />
          </div>
          <div className="flex flex-col sm:flex-row sm:space-x-2">
            <TextInput
              fullWidth
              className={mergeClassNames('w-full', TEXT_FIELD_STYLES, 'pl-3')}
              disabled={enableRegistrationSync || loading}
              error={errors.country && 'This field is required'}
              label="Country"
              inputStyles="rounded-none rounded-t-md font-nunito"
              name="country"
              nunito
              placeholder="Country"
              {...register('country')}
            />

            <TextInput
              fullWidth
              className={mergeClassNames('w-full', TEXT_FIELD_STYLES, 'pl-3')}
              disabled={enableRegistrationSync || loading}
              error={errors.zipCode && 'This field is required'}
              label="Zip Code"
              inputStyles="rounded-none rounded-t-md font-nunito"
              name="zipCode"
              nunito
              placeholder="Zip Code"
              {...register('zipCode')}
            />
          </div>

          <TextInput
            className={TEXT_FIELD_STYLES}
            icon={<img alt="Email" className="ml-1.5 h-4" src={Email} />}
            data-testid="email"
            disabled={enableRegistrationSync || loading}
            error={errors.email && 'This field is required'}
            fullWidth
            id="email"
            inputStyles="rounded-none rounded-t-md font-nunito"
            name="email"
            nunito
            label="Email"
            placeholder="Email"
            {...register('email')}
          />

          <PhoneNumberInput
            control={control}
            disabled={enableRegistrationSync || loading}
            error={errors.phoneNumber}
            label="Phone Number"
            name="phoneNumber"
          />

          {sessions && (
            <Controller
              key="sessions"
              name="sessions"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  className={mergeClassNames('w-full', TEXT_FIELD_STYLES, 'pl-3')}
                  disabled={enableRegistrationSync || loading}
                  fullWidth
                  name="sessions"
                  onChange={onChange}
                  multiSelect
                  options={sessions}
                  label="Sessions"
                  placeholder="Select Sessions"
                  style={{ flex: true, width: '100%' }}
                  value={value}
                />
              )}
            />
          )}

          {customFields?.withoutOptions?.length > 0 &&
            _.map(customFields.withoutOptions, (field) => (
              <TextInput
                key={field}
                className={mergeClassNames('w-full', TEXT_FIELD_STYLES, 'pl-3')}
                fullWidth
                inputStyles="rounded-none rounded-t-md font-nunito"
                name={field}
                nunito
                label={_.capitalize(_.startCase(availableCustomFields[field].text))}
                {...register(field)}
              />
            ))}

          {customFields?.withOptions &&
            _.keys(customFields.withOptions).length > 0 &&
            _.map(customFields.withOptions, (options, field) => {
              const multiSelect = availableCustomFields[field].type === 'MultiChoice'
              return (
                <Controller
                  key={field}
                  name={field}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      className={mergeClassNames('w-full', TEXT_FIELD_STYLES, 'pl-3')}
                      fullWidth
                      multiSelect={multiSelect}
                      name={field}
                      onChange={onChange}
                      options={options}
                      label={_.capitalize(_.startCase(availableCustomFields[field].text))}
                      placeholder="Select an Option"
                      style={{ flex: true, width: '100%' }}
                      value={multiSelect && !value ? [] : value}
                    />
                  )}
                />
              )
            })}
        </div>
      }
      loading={loading}
      onClose={onClose}
      open
      setOpen={onClose}
      title={configureModalTitle()}
    />
  )
}

AttendeeModal.propTypes = {
  admissionItems: PropTypes.object,
  attendee: PropTypes.object,
  availableCustomFields: PropTypes.object,
  categories: PropTypes.array,
  eventId: PropTypes.string.isRequired,
  enableRegistrationSync: PropTypes.bool.isRequired,
  enabledCustomFields: PropTypes.array,
  onClose: PropTypes.func.isRequired,
  sessions: PropTypes.array,
}

export default AttendeeModal
