import React, { useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'

// Components
import { Select } from '../../components/Select'

const PaginatedSelect = ({
  baseUrl,
  disabled = false,
  error = false,
  label,
  name,
  onChange,
  options: defaultOptions,
  placeholder,
  request,
  transformOption,
  value = null,
}) => {
  // State
  const [firstLoad, setFirstLoad] = useState(true)
  const [errorLoadingInitialData, setErrorLoadingInitialData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState(defaultOptions)
  const [searchTerm, setSearchTerm] = useState('')
  const [loadingNextPage, setLoadingNextPage] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [nextPage, setNextPage] = useState(null)
  const [totalCount, setTotalCount] = useState(null)

  /**
   * Gets the updated list. If we are performing a search, we reset the list the first time
   * to prevent concatenating with stale data.
   * @param {string} url
   * @param {boolean} firstPage
   */
  const getUpdatedList = async (url, firstPage = false) => {
    const response = await request(
      url,
      setErrorLoadingInitialData,
      firstLoad ? setLoading : setLoadingNextPage,
      () => {},
    )

    if (response) {
      setTotalCount(response.count)
      setNextPage(response.next ? response.next.split('page=')[1] : null)

      const updatedOptions = _.map(response.results, transformOption)

      // Use just the results from the request if we are on the first page, otherwise concatenate the results
      if (firstPage) {
        setOptions(updatedOptions)
      } else if (currentPage > 1) {
        setOptions([...options, ...updatedOptions])
      }
    }

    // Reset first load state
    if (firstLoad) setFirstLoad(false)
  }

  useEffect(() => {
    let timeout
    if (searchTerm) {
      // Reset to the first page
      setCurrentPage(1)

      // Wait for current page state change to process
      timeout = setTimeout(() => {
        getUpdatedList(`${baseUrl}&page=1&q=${searchTerm}`, true)
      }, [650])
    } else {
      getUpdatedList(`${baseUrl}&page=${currentPage}`, true)
    }

    return () => {
      if (timeout) clearTimeout(timeout)
    }
  }, [searchTerm])

  const debounceSearch = useCallback(_.debounce(setSearchTerm, 650), [])

  const configureError = () => {
    if (errorLoadingInitialData) {
      return 'Unable to load options.'
    }

    if (error) {
      return 'This field is required'
    }

    return null
  }

  return (
    <Select
      className="rounded-2xl border-gray-550 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
      disabled={loading || disabled}
      error={configureError()}
      fullWidth
      id={name}
      name={name}
      nunito
      onChange={onChange}
      options={options}
      label={label}
      pagination={{
        attemptToLoadNextPage: async () => {
          if (!loadingNextPage && nextPage && nextPage !== currentPage) {
            setCurrentPage(nextPage)

            getUpdatedList(`${baseUrl}&page=${nextPage}`)
          }
        },
        hasNext: nextPage !== null,
        loading: loadingNextPage,
        perPage: 100,
        totalCount,
      }}
      placeholder={placeholder}
      search
      setSearchTerm={debounceSearch}
      style={{ flex: true, width: '100%' }}
      value={value}
    />
  )
}

PaginatedSelect.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  request: PropTypes.func.isRequired,
  transformOption: PropTypes.func.isRequired,
  value: PropTypes.array,
}

export default PaginatedSelect
