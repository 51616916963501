import React, { useCallback, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { observer } from 'mobx-react'
import _ from 'lodash'
import {
  AtSymbolIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ClipboardDocumentListIcon,
} from '@heroicons/react/24/outline'
import { EyeIcon } from '@heroicons/react/20/solid'
import { twMerge as mergeClassNames } from 'tailwind-merge'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'

// Components
import { Button } from '../../components/Button'
import { CogIcon } from '../../components/CogIcon'
import { DataTable } from '../../components/DataTable'
import { Modal } from '../../components/Modal'
import { PasswordInput } from '../../components/PasswordInput'
import { PlusIcon } from '../../components/PlusIcon'
import { StateContainer } from '../../components/StateContainer'
import { FileUploader } from '../../components/FileUploader'
import { Popup } from '../../components/Popup'
import { RichTextInput } from '../../components/RichTextInput'
import { Select } from '../../components/Select'
import { TextInput } from '../../components/TextInput'

// Images
import Add from '../../assets/images/add.svg'
import Edit from '../../assets/images/editCircle.svg'
import Email from '../../assets/images/email.svg'

// Service
import { getEvents, getEventExhibitorTransactions } from '../../services/events.service'
import {
  addGlobalFont,
  addGlobalStockSize,
  getGlobalFonts,
  getGlobalDefaultKioskSettings,
  getGlobalEmails,
  getGlobalPopups,
  getGlobalStockSizes,
  sendEmailPreview,
  updateGlobalEmail,
  updateGlobalFont,
  updateGlobalDefaultKioskSettings,
  updateGlobalStockSize,
} from '../../services/global.service'
import { getAttendees } from '../../services/attendees.service'
import { getOrganizations, getOrganizationUsers } from '../../services/organizations.service'
import { getUsers } from '../../services/users.service'

// Utils
import { handlePagination, toast } from '../../utils/helpers'
import {
  BASE_RECIPIENT_FIELDS,
  CONTEXT_MERGE_FIELDS,
  NON_SYNCED_FIELDS,
} from '../../utils/constants'

const TABS = [
  {
    id: 0,
    name: 'Attendee',
  },
  {
    id: 1,
    name: 'Staff',
  },
]

/**
 *
 * AdminSettings
 *
 */
const AdminSettings = observer(() => {
  // Emails
  const [emails, setEmails] = useState([])
  const [editEmail, setEditEmail] = useState(null)
  const [loadingEmail, setLoadingEmail] = useState(null)
  const [emailCurrentPage, setEmailCurrentPage] = useState(1)
  const [emailTotalRows, setEmailTotalRows] = useState(0)
  const [emailPerPage, setEmailPerPage] = useState(20)
  const [emailPages, setEmailPages] = useState(null)
  const [showPreviewModal, setShowPreviewModal] = useState(false)
  const [previewEmail, setPreviewEmail] = useState(null)
  const [loadingPreview, setLoadingPreview] = useState(false)

  // Fonts
  const [fontCurrentPage, setFontCurrentPage] = useState(1)
  const [fontTotalRows, setFontTotalRows] = useState(0)
  const [fontPerPage, setFontPerPage] = useState(10)
  const [fontPages, setFontPages] = useState(null)
  const [editFont, setEditFont] = useState(false)
  const [fonts, setFonts] = useState([])
  const [loadingFonts, setLoadingFonts] = useState(false)
  const [showFontModal, setShowFontModal] = useState(false)
  const [uploadFont, setUploadFont] = useState(null)
  const [newFontUrl, setNewFontUrl] = useState(null)

  // Badging
  const [editPaperStockSize, setEditPaperStockSize] = useState(false)
  const [loadingPaperStockSizes, setLoadingPaperStockSizes] = useState(false)
  const [paperStockSizes, setPaperStockSizes] = useState([])
  const [showPaperStockSizeModal, setShowPaperStockSizeModal] = useState(false)
  const [paperCurrentPage, setPaperCurrentPage] = useState(1)
  const [paperTotalRows, setPaperTotalRows] = useState(0)
  const [paperPerPage, setPaperPerPage] = useState(10)
  const [paperPages, setPaperPages] = useState(null)

  // Kiosk
  const [originalAdminPassword, setOriginalAdminPassword] = useState()
  const [adminPassword, setAdminPassword] = useState()
  const [passwordError, setPasswordError] = useState(false)
  const [globalPopups, setGlobalPopups] = useState([])
  const [activePopupTab, setActivePopupTab] = useState(TABS[0])
  const [showMergeFieldModal, setShowMergeFieldModal] = useState(false)

  const handleSuccess = (m) => toast(m, 'success')
  const handleError = (m) => toast(m, 'error')

  const {
    control: emailControl,
    handleSubmit: emailHandleSubmit,
    formState: { errors: emailErrors },
    register: emailRegister,
    reset: emailReset,
    setError: setEmailError,
  } = useForm({
    defaultValues: {
      subject: null,
      body: null,
    },
  })

  const {
    control: previewControl,
    handleSubmit: handlePreviewSubmit,
    formState: { errors: previewErrors },
    getValues,
    register: previewRegister,
    reset: resetPreview,
    setError: setPreviewError,
    watch,
  } = useForm({
    defaultValues: {
      toEmailOverride: null,
      recipient: null,
    },
  })

  const {
    handleSubmit: fontHandleSubmit,
    formState: { errors: fontErrors },
    register: fontRegister,
    reset: fontReset,
  } = useForm({
    defaultValues: {
      name: null,
      url: null,
    },
  })

  const {
    handleSubmit: paperHandleSubmit,
    formState: { errors: paperErrors },
    register: paperRegister,
    reset: paperReset,
  } = useForm({
    defaultValues: { name: null, widthInInches: null, heightInInches: null },
  })

  useEffect(() => {
    const event = getValues('event')

    if (event) {
      const getEventDependentData = async () => {
        const { options } = previewEmail
        const eventExhibitor = _.includes(previewEmail.email.availableContext, 'Event Exhibitor')

        if (eventExhibitor) {
          const response = await getOrganizations(
            `/events/${event.id}/exhibitors/?expand=exhibitor,limit=99999`,
            handleError,
            () => {},
            () => {},
          )

          if (response) {
            options['Event Exhibitor'] = _.map(response.results, (e) => ({
              id: e.id,
              exhibitorId: e.exhibitor.id,
              label: e.exhibitor.name,
            }))
          }
        }

        if (
          previewEmail.email.recipients === 'Attendee' ||
          previewEmail.email.recipients === 'Attendees (Dynamic)'
        ) {
          const response = await getAttendees(
            `/events/${event.id}/attendees/?expand=user&limit=99999`,
            handleError,
            () => {},
            () => {},
          )

          if (response) {
            options.recipients = _.map(response.results, (a) => ({
              id: a.id,
              label: `${a.firstName} ${a.lastName}`,
            }))
          }
        }

        setPreviewEmail({ ...previewEmail, options })
      }

      getEventDependentData()
    }

    const eventExhibitor = getValues('eventExhibitor')

    if (eventExhibitor) {
      const getExhibitorDependentData = async () => {
        const { options } = previewEmail
        const scannedBy = _.includes(previewEmail.email.availableContext, 'Scanned By')
        const assignedBy = _.includes(previewEmail.email.availableContext, 'Assigned By')
        const transactions = _.includes(previewEmail.email.availableContext, 'Transaction')

        // scannedBy and assignedBy are both exhibitor users
        if (
          scannedBy ||
          assignedBy ||
          previewEmail.email.recipients === 'Exhibitor' ||
          previewEmail.email.recipients === 'Licensees (Dynamic)'
        ) {
          const response = await getOrganizationUsers(
            `/organizations/${eventExhibitor.exhibitorId}/roles/?expand=user&limit=99999`,
            handleError,
            () => {},
            () => {},
          )

          if (response && (scannedBy || assignedBy)) {
            options[scannedBy ? 'Scanned By' : 'Assigned By'] = _.map(response.results, (e) => ({
              id: e.user.id,
              label: `${e.user.firstName} ${e.user.lastName}`,
            }))
          } else if (response) {
            options.recipients = _.map(response.results, (r) => ({
              id: r.user.id,
              label: `${r.user.firstName} ${r.user.lastName}`,
            }))
          }
        }

        // Get transactions for exhibitor
        if (transactions) {
          const response = await getEventExhibitorTransactions(
            event.id,
            eventExhibitor.id,
            handleError,
            () => {},
            () => {},
          )

          if (response) {
            options.Transaction = _.map(response.results, (t) => ({
              id: t.id,
              label: `${t.transId}`,
            }))
          }
        }

        // Get exhibitor users for recipients
        if (previewEmail.email.recipients === 'Exhibitor') {
          const response = await getOrganizationUsers(
            `/organizations/${eventExhibitor.exhibitorId}/roles/?expand=user&limit=99999`,
            handleError,
            () => {},
            () => {},
          )

          if (response) {
            options.recipients = _.map(response.results, (r) => ({
              id: r.user.id,
              label: `${r.user.firstName} ${r.user.lastName}`,
            }))
          }
        }

        setPreviewEmail({ ...previewEmail, options })
      }

      getExhibitorDependentData()
    }
  }, [watch('event'), watch('eventExhibitor')])

  /**
   * Gets the updated default emails using the specified `url`.
   */
  const getUpdatedEmails = async (url) => {
    const response = await getGlobalEmails(url, handleError, setLoadingEmail)

    if (response) {
      setEmails(response.results)
      setEmailTotalRows(response.count)
      setEmailPages({ next: response.next, previous: response.previous })
    }
  }

  /**
   * Gets the updated paper stock sizes using the specified `url`.
   */
  const getUpdatedPaperStockSizes = async (url) => {
    const response = await getGlobalStockSizes(url, handleError, setLoadingPaperStockSizes)

    if (response) {
      setPaperStockSizes(response.results)
      setPaperTotalRows(response.count)
      setPaperPages({ next: response.next, previous: response.previous })
    }
  }

  /**
   * Gets the updated fonts using the specified `url`.
   */
  const getUpdatedFonts = async (url) => {
    const response = await getGlobalFonts(url, handleError, setLoadingFonts)

    if (response) {
      setFonts(response.results)
      setFontTotalRows(response.count)
      setFontPages({ next: response.next, previous: response.previous })
    }
  }

  useEffect(() => {
    const getUpdatedData = async () => {
      const popups = await getGlobalPopups(
        () => {},
        () => {},
      )

      if (popups) {
        setGlobalPopups(popups)
      }

      const kioskSettings = await getGlobalDefaultKioskSettings()

      if (kioskSettings) {
        setAdminPassword(kioskSettings.adminPassword)
        setOriginalAdminPassword(kioskSettings.adminPassword)
      }
    }

    getUpdatedData()

    getUpdatedEmails(
      `/global-default-email-templates/?limit=${emailPerPage}&page=${emailCurrentPage}`,
    )
    getUpdatedFonts(`/global-fonts/?limit=${fontPerPage}&page=${fontCurrentPage}`)
    getUpdatedPaperStockSizes(
      `/global-paper-stock-size/?limit=${paperPerPage}&page=${paperCurrentPage}`,
    )
  }, [])

  const resetFontForm = () => {
    setNewFontUrl(null)
    setUploadFont(false)
    setShowFontModal(false)
    fontReset()

    getUpdatedFonts()
  }

  /**
   * Handles submitting the update email form.
   * @param {object} data
   */
  const onEmailSubmit = async (data) => {
    if (data.body === null || data.body === '<p><br></p>') {
      setEmailError('body')
      return
    }

    const response = await updateGlobalEmail(
      { id: editEmail.id, subject: data.subject, body: data.body },
      handleError,
      setLoadingEmail,
      handleSuccess,
    )

    if (response) {
      getUpdatedEmails(
        `/global-default-email-templates/?limit=${emailPerPage}&page=${emailCurrentPage}`,
      )

      setEditEmail(null)
      emailReset()
    }
  }

  /**
   * Handles submitting the font form - either adding or updating.
   * @param {object} data
   */
  const onFontSubmit = async (data) => {
    if (!newFontUrl && !editFont) {
      handleError('Please upload a font file.')
      return
    }

    if (editFont) {
      const url = newFontUrl || editFont.url
      await updateGlobalFont(editFont.id, { ...data, url }, handleError, setLoadingFonts, (m) => {
        handleSuccess(m)
        setEditFont(false)
        resetFontForm()
      })
    } else {
      await addGlobalFont({ ...data, url: newFontUrl }, handleError, setLoadingFonts, (m) => {
        handleSuccess(m)
        resetFontForm()
      })
    }
  }

  /**
   * Saves the password to BE
   */
  const savePassword = async (newPassword) => {
    const response = await updateGlobalDefaultKioskSettings(
      newPassword,
      handleError,
      () => {},
      handleSuccess,
    )

    setOriginalAdminPassword(response.adminPassword)
    setAdminPassword(response.adminPassword)
  }

  /**
   * Debounce saving the password to BE
   */
  const saveUpdatedPassword = useCallback(_.debounce(savePassword, 500), [])

  const renderPopups = () => {
    const filteredPopups = _.filter(globalPopups, (p) =>
      _.includes(p.identifier, _.lowerCase(activePopupTab.name)),
    )

    return _.map(filteredPopups, (popup, i) => (
      <div className="py-2">
        <Popup
          adminSettings
          defaultPopup={_.find(globalPopups, { identifier: popup.identifier })}
          key={`${popup.id}`}
          kioskId={popup.id}
          popup={popup}
          updateData={(updatedPopup) => {
            const updatedPopups = _.map(globalPopups, (p) =>
              p.id === updatedPopup.id ? updatedPopup : p,
            )

            setGlobalPopups(updatedPopups)
          }}
          handleError={handleError}
          handleSuccess={handleSuccess}
        />

        {i !== filteredPopups.length - 1 && <hr className="mt-6 border-gray-300" />}
      </div>
    ))
  }

  return (
    <div className="h-full">
      <StateContainer stripedBackground>
        <div className="relative flex h-full w-full flex-col space-y-2 overflow-y-auto rounded-lg bg-background p-3 shadow-md md:h-[85%] md:w-[90%] md:p-4">
          <div className="mt-1.5 flex flex-col">
            <div className="flex flex-row items-center space-x-1">
              <CogIcon className="w-4 fill-black" />
              <span className="text-md font-bold">Global Settings</span>
            </div>

            <span className="text-xs">Manage global default and options.</span>
          </div>

          <Disclosure defaultOpen>
            <DisclosureButton>
              {({ open }) => (
                <div className="flex w-full justify-between">
                  <span className="text-md self-start font-bold">General Settings</span>
                  {open ? (
                    <ChevronUpIcon className="h-6 stroke-black" />
                  ) : (
                    <ChevronDownIcon className="h-6 stroke-black" />
                  )}
                </div>
              )}
            </DisclosureButton>

            <DisclosurePanel>
              <div className="flex flex-col space-y-2">
                <span className="font-medium">Default Emails</span>

                <DataTable
                  columns={[
                    {
                      id: 'name',
                      grow: 2,
                      name: 'Name',
                      selector: (row) => row.name,
                      cell: (row) => (
                        <span className="text-sm font-bold text-dark">{row.name}</span>
                      ),
                      minWidth: '250px',
                    },
                    {
                      id: 'edit',
                      grow: 0.2,
                      name: '',
                      cell: (row) => (
                        <button
                          className="font-bold text-blue-600 hover:rounded-full hover:bg-status-blue hover:px-2 hover:py-0.5"
                          type="button"
                          onClick={() => {
                            emailReset(row)
                            setEditEmail(row)
                          }}
                        >
                          Edit
                        </button>
                      ),
                      center: true,
                      width: '100px',
                    },
                    {
                      id: 'preview',
                      grow: 0.2,
                      name: '',
                      cell: (row) => (
                        <button
                          className="font-bold text-blue-600 hover:rounded-full hover:bg-status-blue hover:px-2 hover:py-0.5"
                          type="button"
                          onClick={async () => {
                            const preview = {
                              email: row,
                              options: {},
                            }

                            // Request context data to populate preview options
                            await Promise.all(
                              _.map(row.availableContext, async (context) => {
                                if (context === 'Event') {
                                  const response = await getEvents(
                                    '/events/?expand=organizer&limit=99999',
                                    handleError,
                                    () => {},
                                    () => {},
                                  )

                                  if (response) {
                                    preview.options[context] = _.map(response.results, (e) => ({
                                      id: e.id,
                                      label: e.name,
                                    }))
                                  }
                                } else if (context === 'Organizer') {
                                  const response = await getOrganizations(
                                    '/organizations/?limit=99999&type=Organizer',
                                    handleError,
                                    () => {},
                                    () => {},
                                  )

                                  if (response) {
                                    preview.options[context] = _.map(response.results, (o) => ({
                                      id: o.id,
                                      label: o.name,
                                    }))
                                  }
                                }
                              }),
                            )

                            // Request recipient data to populate options
                            if (row.recipients === 'User') {
                              const response = await getUsers(
                                null,
                                handleError,
                                () => {},
                                () => {},
                              )

                              if (response) {
                                preview.options.recipients = _.map(response.results, (u) => ({
                                  id: u.id,
                                  label: `${u.firstName} ${u.lastName}`,
                                }))
                              }
                            }

                            setPreviewEmail(preview)
                            setShowPreviewModal(true)
                          }}
                        >
                          Preview
                        </button>
                      ),
                      center: true,
                      minWidth: '110px',
                    },
                  ]}
                  data={emails}
                  onChangePage={(page) =>
                    handlePagination(
                      page,
                      emailCurrentPage,
                      emailPerPage,
                      emailTotalRows,
                      emailPages,
                      setEmailCurrentPage,
                      getUpdatedEmails,
                      '/global-default-email-templates/?limit=',
                    )
                  }
                  onChangeRowsPerPage={(currentRowsPerPage) => {
                    setEmailCurrentPage(1)
                    setEmailPerPage(currentRowsPerPage)
                    getUpdatedEmails(
                      `/global-default-email-templates/?limit=${currentRowsPerPage}&page=1`,
                    )
                  }}
                  pagination
                  paginationPerPage={emailPerPage}
                  paginationRowsPerPageOptions={[10, 15, 20, 30, 50]}
                  paginationTotalRows={emailTotalRows}
                  paginationServer
                  progressPending={loadingEmail}
                />

                <div className="flex w-full items-center justify-between">
                  <div className="flex flex-col">
                    <span className="font-medium">Custom Fonts</span>
                    <span className="text-xs">
                      Upload custom fonts to be used in the Badge Builder or Event Kiosk.
                    </span>
                  </div>

                  <Button
                    icon={<PlusIcon className="h-5 fill-white" />}
                    label="Add Font"
                    onClick={() => setShowFontModal(true)}
                  />
                </div>

                <DataTable
                  columns={[
                    {
                      id: 'name',
                      grow: 2,
                      name: 'Name',
                      selector: (row) => row.name,
                      cell: (row) => (
                        <span className="text-sm font-bold text-dark">{row.name}</span>
                      ),
                      minWidth: '250px',
                    },
                    {
                      id: 'edit',
                      grow: 0.25,
                      name: '',
                      cell: (row) => (
                        <button
                          className="font-bold text-blue-600 hover:rounded-full hover:bg-status-blue hover:px-2 hover:py-0.5"
                          type="button"
                          onClick={() => {
                            fontReset(row)
                            setEditFont(row)
                            setShowFontModal(true)
                          }}
                        >
                          Edit
                        </button>
                      ),
                      center: true,
                    },
                  ]}
                  data={fonts}
                  onChangePage={(page) =>
                    handlePagination(
                      page,
                      fontCurrentPage,
                      fontPerPage,
                      fontTotalRows,
                      fontPages,
                      setFontCurrentPage,
                      getUpdatedFonts,
                      '/global-fonts/?limit=',
                    )
                  }
                  onChangeRowsPerPage={async (currentRowsPerPage) => {
                    setFontCurrentPage(1)
                    setFontPerPage(currentRowsPerPage)
                    getUpdatedFonts(`/global-fonts/?limit=${currentRowsPerPage}&page=1`)
                  }}
                  pagination
                  paginationPerPage={fontPerPage}
                  paginationRowsPerPageOptions={[10, 15, 20, 30, 50]}
                  paginationTotalRows={fontTotalRows}
                  paginationServer
                  progressPending={loadingFonts}
                />
              </div>
            </DisclosurePanel>
          </Disclosure>

          <Disclosure defaultOpen>
            <DisclosureButton>
              {({ open }) => (
                <div className="flex w-full justify-between">
                  <span className="text-md self-start font-bold">Badge Builder</span>
                  {open ? (
                    <ChevronUpIcon className="h-6 stroke-black" />
                  ) : (
                    <ChevronDownIcon className="h-6 stroke-black" />
                  )}
                </div>
              )}
            </DisclosureButton>

            <DisclosurePanel>
              <div className="flex flex-col space-y-2">
                <div className="flex w-full items-center justify-between">
                  <span className="font-medium">Stock Sizes</span>

                  <Button
                    icon={<PlusIcon className="h-5 fill-white" />}
                    label="Add Stock Size"
                    onClick={() => setShowPaperStockSizeModal(true)}
                  />
                </div>

                <DataTable
                  columns={[
                    {
                      id: 'name',
                      grow: 2,
                      name: 'Name',
                      selector: (row) => row.name,
                      cell: (row) => (
                        <span className="text-sm font-bold text-dark">{row.name}</span>
                      ),

                      minWidth: '250px',
                    },
                    {
                      id: 'width',
                      grow: 2,
                      name: 'Width',
                      selector: (row) => row.widthInInches,
                      sortable: true,
                      sortBy: 'width_in_inches',
                      minWidth: '200px',
                    },
                    {
                      id: 'height',
                      grow: 2,
                      name: 'Height',
                      selector: (row) => row.heightInInches,
                      sortable: true,
                      sortBy: 'height_in_inches',
                      minWidth: '200px',
                    },
                    {
                      id: 'edit',
                      grow: 0.25,
                      name: '',
                      cell: (row) => (
                        <button
                          className="font-bold text-blue-600 hover:rounded-full hover:bg-status-blue hover:px-2 hover:py-0.5"
                          type="button"
                          onClick={() => {
                            paperReset(row)
                            setEditPaperStockSize(row)
                            setShowPaperStockSizeModal(true)
                          }}
                        >
                          Edit
                        </button>
                      ),
                      center: true,
                    },
                  ]}
                  data={paperStockSizes}
                  defaultSortFieldId="width"
                  defaultSortAsc
                  onChangePage={(page) =>
                    handlePagination(
                      page,
                      paperCurrentPage,
                      paperPerPage,
                      paperTotalRows,
                      paperPages,
                      setPaperCurrentPage,
                      getUpdatedPaperStockSizes,
                      '/global-paper-stock-size/?limit=',
                    )
                  }
                  onChangeRowsPerPage={async (currentRowsPerPage) => {
                    setPaperCurrentPage(1)
                    setPaperPerPage(currentRowsPerPage)
                    getUpdatedPaperStockSizes(
                      `/global-paper-stock-size/?limit=${currentRowsPerPage}&page=1`,
                    )
                  }}
                  onSort={(column, direction) => {
                    const d = direction === 'asc' ? '' : '-'
                    const url = `/global-paper-stock-size/?order_by=${d}${column.sortBy}&limit=${paperPerPage}`
                    getUpdatedPaperStockSizes(url)
                  }}
                  pagination
                  paginationPerPage={paperPerPage}
                  paginationRowsPerPageOptions={[10, 15, 20, 30, 50]}
                  paginationTotalRows={paperTotalRows}
                  paginationServer
                  progressPending={loadingPaperStockSizes}
                  sortServer
                />
              </div>
            </DisclosurePanel>
          </Disclosure>

          <Disclosure defaultOpen>
            <DisclosureButton>
              {({ open }) => (
                <div className="flex w-full justify-between">
                  <span className="text-md self-start font-bold">Kiosk</span>
                  {open ? (
                    <ChevronUpIcon className="h-6 stroke-black" />
                  ) : (
                    <ChevronDownIcon className="h-6 stroke-black" />
                  )}
                </div>
              )}
            </DisclosureButton>

            <DisclosurePanel>
              <div className="pb-3">
                <span className="font-medium">Default Kiosk Admin Password</span>

                <PasswordInput
                  disableAutoComplete
                  className="w-80 rounded-2xl border-gray-550 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                  hideTooltip
                  id="admin-password"
                  name="adminPassword"
                  error={passwordError && 'Password must be exactly 4 digits (no characters).'}
                  type="number"
                  pattern="\d{4}"
                  minLength={4}
                  maxLength={4}
                  onChange={(e) => setAdminPassword(e.target.value)}
                  onBlur={async (e) => {
                    if (e.target.value === originalAdminPassword) return
                    // if the value is four digits it's valid
                    if (/^\d{4}$/.test(e.target.value)) {
                      await saveUpdatedPassword(e.target.value)
                      setPasswordError(false)
                    } else {
                      setPasswordError(true)
                    }
                  }}
                  value={adminPassword}
                />
              </div>

              <span className="font-medium">Default Kiosk Pop-Up Copy</span>
              <div className="flex h-full flex-col">
                <div className="flex w-full flex-row items-start justify-between">
                  <div className="flex flex-row space-x-6">
                    <nav className="flex space-x-2 sm:space-x-6" aria-label="Tabs">
                      {TABS.map((tab) => {
                        const activeTab = activePopupTab.id === tab.id

                        return (
                          <button
                            type="button"
                            key={tab.name}
                            onClick={() => setActivePopupTab(tab)}
                            className={mergeClassNames(
                              activeTab
                                ? 'border-purple-700 text-purple-700'
                                : 'border-transparent text-black hover:border-purple-700 hover:text-purple-700',
                              'border-b-2 text-sm font-medium sm:px-2',
                            )}
                            aria-current={activeTab ? 'page' : undefined}
                          >
                            {tab.name}
                          </button>
                        )
                      })}
                    </nav>
                  </div>

                  <Button
                    background="bg-purple-100"
                    className="border-purple-100 text-purple-700"
                    label="Merge Fields"
                    onClick={() => setShowMergeFieldModal(true)}
                  />
                </div>

                <div className="flex h-full flex-col">{renderPopups()}</div>
              </div>
            </DisclosurePanel>
          </Disclosure>
        </div>
      </StateContainer>

      <Modal
        actions={[
          {
            type: 'cancel',
            label: 'Cancel',
            onClick: () => setEditEmail(null),
          },
          {
            type: 'submit',
            label: 'Update Email',
            onClick: emailHandleSubmit(onEmailSubmit),
          },
        ]}
        icon={<AtSymbolIcon className="h-8 stroke-white" />}
        containerClassName="h-[90%]"
        content={
          <div className="flex h-full w-full flex-row space-x-4 py-3 text-center sm:py-5">
            <div className="flex w-3/4 flex-col space-y-4">
              <TextInput
                className="rounded-2xl border-gray-550 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                data-testid="subject"
                disabled={loadingEmail}
                error={emailErrors.subject && 'This field is required'}
                fullWidth
                id="subject"
                inputStyles="rounded-none"
                name="subject"
                nunito
                label="Subject"
                placeholder="Subject"
                {...emailRegister('subject', { required: true })}
              />

              <div className="flex flex-col justify-start tall:h-[90%] short:h-[80%]">
                <label
                  htmlFor="body"
                  className="w-fit pb-1 font-nunito text-sm font-medium text-gray-700"
                >
                  Body
                </label>

                <Controller
                  name="body"
                  control={emailControl}
                  render={({ field: { onChange, value } }) => (
                    <div className="tall:h-[95%] short:h-[90%]">
                      <RichTextInput
                        className="sm:h-full"
                        historyEnabled
                        id="body"
                        inputStyles="tall:h-[95%] short:h-[90%]"
                        disabled={loadingEmail}
                        onChange={onChange}
                        placeholder="Body"
                        value={value}
                      />

                      {emailErrors.body && (
                        <div className="mt-1 w-full bg-error-light px-2 py-1">
                          <p className="text-sm font-medium text-error-dark" id="error-rte">
                            This field is required
                          </p>
                        </div>
                      )}
                    </div>
                  )}
                />
              </div>
            </div>

            <div className="h-[95%] w-[1px] bg-black" />

            <div className="flex w-1/4 flex-col">
              <span className="w-fit pb-1 font-nunito text-sm font-medium text-gray-700">
                Merge Fields
              </span>

              {editEmail?.availableContext?.length > 0 ? (
                _.map(
                  _.concat(
                    BASE_RECIPIENT_FIELDS,
                    _.flatMap(editEmail?.availableContext, (c) => CONTEXT_MERGE_FIELDS[c]),
                  ),
                  (f) => (
                    <button
                      className="group flex cursor-pointer flex-row"
                      key={`${f}`}
                      onClick={() => {
                        navigator.clipboard.writeText(`{{ ${f} }}`)
                        handleSuccess('Merge field copied to clipboard!')
                      }}
                      type="button"
                    >
                      <span className="group-hover:text-purple">{f}</span>

                      <ClipboardDocumentListIcon className="hidden h-6 group-hover:flex group-hover:stroke-black" />
                    </button>
                  ),
                )
              ) : (
                <span className="self-start text-sm text-gray-800">
                  No Merge Fields Available.
                </span>
              )}
            </div>
          </div>
        }
        className="sm:h-[90vh] sm:max-h-[1200px] sm:w-[90vw] sm:max-w-screen-xl"
        loading={loadingEmail}
        open={!!editEmail}
        title="Update Email"
      />

      <Modal
        actions={[
          {
            label: 'Cancel',
            onClick: () => {
              setTimeout(() => {
                setEditPaperStockSize(false)
                paperReset()
              }, 500)
            },
            type: 'cancel',
          },
          {
            label: editPaperStockSize ? 'Save' : 'Add Stock Size',
            onClick: paperHandleSubmit(async (data) => {
              if (editPaperStockSize) {
                await updateGlobalStockSize(
                  { ...data, id: editPaperStockSize.id },
                  handleError,
                  setLoadingPaperStockSizes,
                  (m) => {
                    handleSuccess(m)
                    setShowPaperStockSizeModal(false)
                    setEditPaperStockSize(false)
                    getUpdatedPaperStockSizes('/global-paper-stock-size/')
                  },
                )
              } else {
                await addGlobalStockSize(data, handleError, setLoadingPaperStockSizes, (m) => {
                  handleSuccess(m)
                  setShowPaperStockSizeModal(false)
                  getUpdatedPaperStockSizes('/global-paper-stock-size/')
                })
              }
              paperReset()
            }),
            type: 'submit',
          },
        ]}
        icon={
          <img src={editPaperStockSize ? Edit : Add} alt={editPaperStockSize ? 'Edit' : 'Add'} />
        }
        title={editPaperStockSize ? 'Edit Stock Size' : 'Add a Stock Size'}
        open={showPaperStockSizeModal}
        setOpen={setShowPaperStockSizeModal}
        onClose={() => {
          setShowPaperStockSizeModal(false)
          paperReset()
        }}
        content={
          <div className="mt-3 flex flex-col text-center sm:mt-5">
            <div className="mb-1 flex w-full flex-col space-y-2 sm:mb-4">
              <TextInput
                className="rounded-2xl border-gray-550 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                error={paperErrors.name && 'This field is required'}
                inputStyles="rounded-none rounded-t-md font-nunito"
                label="Name"
                name="name"
                placeholder="Enter Name"
                fullWidth
                {...paperRegister('name', { required: true })}
              />
              <div className="flex flex-col sm:flex-row sm:space-x-2">
                <TextInput
                  className="rounded-2xl border-gray-550 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                  error={paperErrors.widthInInches && 'This field is required'}
                  inputStyles="rounded-none rounded-t-md font-nunito"
                  label="Width"
                  name="widthInInches"
                  placeholder="Enter Width"
                  fullWidth
                  type="number"
                  {...paperRegister('widthInInches', { required: true })}
                />
                <TextInput
                  className="rounded-2xl border-gray-550 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                  error={paperErrors.heightInInches && 'This field is required'}
                  inputStyles="rounded-none rounded-t-md font-nunito"
                  label="Height"
                  name="heightInInches"
                  placeholder="Enter Height"
                  fullWidth
                  type="number"
                  {...paperRegister('heightInInches', { required: true })}
                />
              </div>
            </div>
          </div>
        }
      />

      <Modal
        actions={[
          {
            label: 'Cancel',
            onClick: () => {
              setTimeout(() => {
                setEditFont(false)
                setUploadFont(false)
                fontReset()
              }, 500)
            },
            type: 'cancel',
          },
          {
            label: editFont ? 'Save' : 'Add Font',
            onClick: fontHandleSubmit(onFontSubmit),
            type: 'submit',
          },
        ]}
        icon={<img src={editFont ? Edit : Add} alt={editFont ? 'Edit' : 'Add'} />}
        title={editFont ? 'Edit Custom Font' : 'Add Custom Font'}
        open={showFontModal}
        setOpen={setShowFontModal}
        onClose={() => {
          setShowFontModal(false)
          setUploadFont(false)
          fontReset()
        }}
        content={
          <div className="mt-3 flex flex-col text-center sm:mt-5">
            <div className="mb-1 flex w-full flex-col space-y-2">
              <TextInput
                className="rounded-2xl border-gray-550 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                error={fontErrors.name && 'This field is required'}
                inputStyles="rounded-none rounded-t-md font-nunito"
                label="Font Name"
                name="name"
                placeholder="Enter Font Name"
                fullWidth
                {...fontRegister('name', { required: true })}
              />

              <div>
                {editFont && !uploadFont ? (
                  <button
                    className="mt-2 self-start text-sm text-purple disabled:cursor-not-allowed"
                    type="button"
                    onClick={() => setUploadFont(true)}
                  >
                    Replace
                  </button>
                ) : (
                  <FileUploader
                    showImagePreviewAfterUpload
                    acceptedFileTypes={['font/*']}
                    allowRevert
                    handleUploadToServer={async (file) => {
                      setNewFontUrl(file.url)
                    }}
                    id="font"
                    type="gcp"
                  />
                )}
              </div>
            </div>
          </div>
        }
      />

      <Modal
        actions={[
          {
            type: 'cancel',
            label: 'Close',
            onClick: () => {
              setShowMergeFieldModal(false)
            },
          },
        ]}
        icon={<ClipboardDocumentListIcon className="h-5 stroke-white sm:h-6" />}
        content={
          <div className="mt-3 flex max-h-56 flex-col sm:mt-5">
            <div className="mx-8 mt-2 flex flex-col overflow-y-scroll">
              {_.map(NON_SYNCED_FIELDS, (f) => (
                <button
                  className="group flex cursor-pointer flex-row"
                  key={`${f}`}
                  onClick={() => {
                    if (f === 'category') {
                      navigator.clipboard.writeText(`{{ categoryName }}`)
                    } else {
                      navigator.clipboard.writeText(`{{ ${f} }}`)
                    }

                    handleSuccess('Merge field copied to clipboard!')
                  }}
                  type="button"
                >
                  <span className="group-hover:text-purple">{f}</span>

                  <ClipboardDocumentListIcon className="hidden h-6 group-hover:flex group-hover:stroke-black" />
                </button>
              ))}
            </div>
          </div>
        }
        open={showMergeFieldModal}
        title="Copy Merge Field"
      />

      <Modal
        actions={[
          {
            type: 'cancel',
            label: 'Close',
            onClick: () => {
              resetPreview()
              setPreviewEmail(null)
              setShowPreviewModal(false)
            },
          },
          {
            type: 'submit',
            label: 'Send Preview',
            onClick: handlePreviewSubmit(async (data) => {
              if (data.recipient === null) {
                setPreviewError('recipient')
                return
              }

              const payload = {
                toEmailOverride: data.toEmailOverride,
              }
              const recipient = data.recipient.id

              // eslint-disable-next-line no-param-reassign
              delete data.toEmailOverride
              // eslint-disable-next-line no-param-reassign
              delete data.recipient

              // Only add event if its in the data
              if (data.event) {
                payload.event = data.event.id
                // eslint-disable-next-line no-param-reassign
                delete data.event
              }

              payload.additionalContextVars = _.mapValues(data, (v) => v?.id || null)

              if (
                previewEmail.email.recipients === 'Attendee' ||
                previewEmail.email.recipients === 'Attendees (Dynamic)'
              ) {
                payload.additionalContextVars.attendee = recipient
              } else {
                payload.recipient = recipient
              }

              await sendEmailPreview(
                previewEmail.email.id,
                payload,
                handleError,
                setLoadingPreview,
                (m) => {
                  handleSuccess(m)
                  resetPreview()
                  setPreviewEmail(null)
                  setShowPreviewModal(false)
                },
              )
            }),
          },
        ]}
        icon={<EyeIcon className="h-5 fill-white sm:h-6" />}
        content={
          <div className="mt-3 flex flex-col space-y-4 sm:mt-5">
            <div className="flex flex-col justify-center space-y-2">
              <span className="self-center text-center text-lg font-bold">
                {previewEmail?.email.name}
              </span>

              {previewEmail?.email.identifier === 'attendees-scan-and-go' && (
                <span className="self-center text-center text-sm italic text-gray-900">
                  Attendee QR code will be attached to the email.
                </span>
              )}
            </div>

            <TextInput
              className="rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
              error={previewErrors.toEmailOverride && 'This field is required'}
              icon={<img alt="Email" className="ml-1.5 h-4" src={Email} />}
              inputStyles="rounded-none rounded-t-md font-nunito"
              label="Recipient Override"
              name="toEmailOverride"
              placeholder="Enter Recipient Override"
              fullWidth
              {...previewRegister('toEmailOverride', { required: true })}
            />

            {previewEmail?.options.recipients && (
              <Controller
                name="recipient"
                control={previewControl}
                render={({ field: { onChange, value } }) => (
                  <Select
                    className="rounded-2xl border-gray-550 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                    disabled={loadingPreview || !previewEmail?.options.recipients}
                    error={previewErrors.recipient && 'This field is required'}
                    fullWidth
                    id="recipient"
                    name="recipient"
                    nunito
                    onChange={onChange}
                    options={previewEmail.options.recipients || []}
                    label="Recipient"
                    placeholder="Select Recipient"
                    style={{ flex: true, width: '100%' }}
                    value={value}
                  />
                )}
                rules={{ required: true }}
              />
            )}

            {_.map(
              _.filter(previewEmail?.email.availableContext, (c) => previewEmail.options[c]),
              (context) => (
                <Controller
                  name={_.camelCase(context)}
                  control={previewControl}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      className="rounded-2xl border-gray-550 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                      disabled={loadingPreview || !previewEmail?.options[context]}
                      error={previewErrors[_.camelCase(context)] && 'This field is required'}
                      fullWidth
                      id={_.camelCase(context)}
                      name={_.camelCase(context)}
                      nunito
                      onChange={onChange}
                      options={previewEmail.options[context] || []}
                      label={context}
                      placeholder={`Select ${context}`}
                      style={{ flex: true, width: '100%' }}
                      value={value}
                    />
                  )}
                  rules={{ required: context === 'Event' || context === 'Event Exhibitor' }}
                />
              ),
            )}
          </div>
        }
        loading={loadingPreview}
        open={showPreviewModal}
        title="Preview Email"
      />
    </div>
  )
})

export default AdminSettings
