import React, { useCallback, useEffect, useState } from 'react'
import { ArrowPathIcon } from '@heroicons/react/20/solid'
import { Controller, useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import _ from 'lodash'

// Icons
import { UsersIcon } from '../../components/UsersIcon'

// Components
import { Modal } from '../../components/Modal'
import { Select } from '../../components/Select'

// Service
import { getAttendees } from '../../services/attendees.service'

const PreviewBadgeModal = ({ closeModal, eventId, setSelectedAttendee }) => {
  // State
  const [firstLoad, setFirstLoad] = useState(true)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [attendees, setAttendees] = useState([])
  const [searchTerm, setSearchTerm] = useState(null)

  // Pagination
  const [loadingNextPage, setLoadingNextPage] = useState(false)
  const [perPage] = useState(100)
  const [currentPage, setCurrentPage] = useState(1)
  const [nextPage, setNextPage] = useState(null)
  const [totalCount, setTotalCount] = useState(null)

  const BASE_URL = `events/${eventId}/attendees/?limit=${perPage}`

  const {
    control: previewControl,
    handleSubmit: handlePreviewSubmit,
    formState: { errors: previewErrors },
  } = useForm({
    defaultValues: {
      attendees: [],
    },
  })

  /**
   * Gets the updated attendee list. If we are performing a search, we reset the list the first time
   * to prevent concatenating with stale data.
   * @param {string} url
   * @param {boolean} firstPage
   */
  const getAttendeesList = async (url, firstPage = false) => {
    const response = await getAttendees(
      url,
      setError,
      firstLoad ? setLoading : setLoadingNextPage,
      () => {},
    )

    if (response) {
      setTotalCount(response.count)
      setNextPage(response.next ? response.next.split('page=')[1] : null)

      const updatedAttendees = _.map(response.results, (a) => ({
        ...a,
        label: `${a.firstName} ${a.lastName}`,
      }))

      // Use just the results from the request if we are on the first page, otherwise concatenate the results
      if (firstPage) {
        setAttendees(updatedAttendees)
      } else if (currentPage > 1) {
        setAttendees([...attendees, ...updatedAttendees])
      }
    }

    // Reset first load state
    if (firstLoad) setFirstLoad(false)
  }

  useEffect(() => {
    let timeout
    if (searchTerm) {
      // Reset to the first page
      setCurrentPage(1)

      // Wait for current page state change to process
      timeout = setTimeout(() => {
        getAttendeesList(`${BASE_URL}&page=1&q=${searchTerm}`, true)
      }, [650])
    } else {
      getAttendeesList(`${BASE_URL}&page=${currentPage}`, true)
    }

    return () => {
      if (timeout) clearTimeout(timeout)
    }
  }, [searchTerm])

  const debounceSearch = useCallback(_.debounce(setSearchTerm, 650), [])

  /**
   * Renders the preview content based on the state of the preview settings.
   */
  const renderPreviewContent = () => {
    if (error) {
      return (
        <div className="flex flex-col items-center justify-center space-y-2">
          <span className="text-lg font-bold text-red">Error Loading Attendees</span>
          <span className="text-sm text-red-500">{error}</span>
        </div>
      )
    }

    if (loading) {
      return (
        <div className="flex flex-col items-center justify-center space-y-2">
          <span className="text-lg font-bold">Loading Attendees...</span>

          <span className="flex items-center pr-3">
            <div className="h-5 w-5">
              {/* eslint-disable-next-line tailwindcss/no-custom-classname, tailwindcss/classnames-order */}
              <svg className="h-5 w-5 motion-safe:animate-spin-slow" viewBox="0 0 40 40">
                <ArrowPathIcon className="h-5 w-5" aria-hidden="true" />
              </svg>
            </div>
          </span>
        </div>
      )
    }

    return (
      <div className="flex flex-col">
        <span className="mx-2 text-sm">
          Search and select one or more Attendees from the list below to preview badges.
        </span>

        <Controller
          name="attendees"
          control={previewControl}
          render={({ field: { onChange, value } }) => (
            <Select
              pagination={{
                attemptToLoadNextPage: async () => {
                  if (!loadingNextPage && nextPage && nextPage !== currentPage) {
                    setCurrentPage(nextPage)

                    getAttendeesList(`${BASE_URL}&page=${nextPage}`)
                  }
                },
                hasNext: nextPage !== null,
                loading: loadingNextPage,
                perPage,
                totalCount,
              }}
              className="rounded-2xl border-gray-550 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
              data-testid="attendees"
              error={previewErrors.attendees && 'This field is required'}
              fullWidth
              id="attendees"
              name="attendees"
              nunito
              onChange={onChange}
              options={attendees}
              label="Attendees"
              placeholder="Select Attendees"
              search
              setSearchTerm={debounceSearch}
              style={{ flex: true, width: '100%' }}
              value={value || []}
            />
          )}
          rules={{ required: true }}
        />
      </div>
    )
  }

  return (
    <Modal
      actions={[
        {
          type: 'cancel',
          label: 'Cancel',
          onClick: closeModal,
        },
        {
          disabled: error,
          type: 'submit',
          label: 'Open Preview',
          onClick: handlePreviewSubmit((data) => {
            setSelectedAttendee(data.attendees)
            closeModal()
          }),
        },
      ]}
      disabled={loading}
      icon={<UsersIcon className="h-5 fill-white stroke-white sm:h-6" />}
      content={<div className="mt-3 text-center sm:mt-5">{renderPreviewContent()}</div>}
      open
      title="Attendees to Preview"
    />
  )
}

PreviewBadgeModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  eventId: PropTypes.string.isRequired,
  setSelectedAttendee: PropTypes.func.isRequired,
}

export default PreviewBadgeModal
