import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import {
  Bars2Icon,
  Cog6ToothIcon,
  PencilSquareIcon,
  PlusIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/24/outline'
import { PlusCircleIcon } from '@heroicons/react/24/solid'
import { Controller, useForm } from 'react-hook-form'
import { twMerge as mergeClassNames } from 'tailwind-merge'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import _ from 'lodash'

// Components
import { Button } from '../../components/Button'
import { EventHeader } from '../../components/EventHeader'
import { Modal } from '../../components/Modal'
import { Select } from '../../components/Select'
import { StarsIcon } from '../../components/StarsIcon'
import { StateContainer } from '../../components/StateContainer'
import { TextInput } from '../../components/TextInput'
import { Tooltip } from '../../components/Tooltip'
import { TrashIcon } from '../../components/TrashIcon'
import { Toggle } from '../../components/Toggle'

// Images
import Add from '../../assets/images/add.svg'
import Edit from '../../assets/images/editCircle.svg'

// Stores
import { NavigationStoreContext } from '../../stores/NavigationStore'

// Service
import { updateExhibitor } from '../../services/exhibitors.service'
import {
  addEventQualifier,
  deleteEventQualifier,
  getEventQualifiers,
  reorderEventQualifiers,
  updateEventQualifier,
} from '../../services/qualifiers.service'

// Utils & Style
import { move, toast } from '../../utils/helpers'

const QUESTION_TYPES = [
  { id: 'Text', label: 'Text' },
  { id: 'Single Select', label: 'Single Select' },
  { id: 'Multiple Select', label: 'Multiple Select' },
]

/**
 *
 * EventQualifiers
 *
 */
const EventQualifiers = observer(() => {
  // Context
  const { event, eventId, setEvent } = useContext(NavigationStoreContext)

  // State
  const [loading, setLoading] = useState(true)
  const [qualifiers, setQualifiers] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [loadingQualifier, setLoadingQualifier] = useState(false)
  const [editQualifier, setEditQualifier] = useState(null)
  const [enableQualifiers, setEnableQualifiers] = useState(event?.eventExhibitor?.enableQualifiers)
  const [showFirstTimeModal, setShowFirstTimeModal] = useState(
    event?.eventExhibitor?.enableQualifiers === null,
  )

  const handleErrors = (m) => toast(m, 'error')
  const handleSuccesses = () => toast(`${event.name} updated.`, 'success')

  const DEFAULT = {
    type: null,
    question: null,
    options: null,
  }

  const {
    clearErrors,
    control,
    getValues,
    handleSubmit,
    formState: { errors },
    register,
    reset,
    setError,
    watch,
  } = useForm({
    defaultValues: DEFAULT,
  })

  /**
   * Gets the updated list of qualifiers.
   * @param {string} url
   */
  const getUpdatedQualifiers = async (url) => {
    const response = await getEventQualifiers(url, handleErrors, setLoading)

    if (response) {
      setQualifiers(response.results)
    }
  }

  useEffect(() => {
    getUpdatedQualifiers(`/events/${eventId}/exhibitors/${event?.eventExhibitor?.id}/qualifiers/`)
  }, [])

  const resetForm = () => {
    // Clear & close the modal
    setShowModal(false)
    setEditQualifier(null)
    // Get updated qualifiers list
    getUpdatedQualifiers(`/events/${eventId}/exhibitors/${event.eventExhibitor.id}/qualifiers/`)
  }

  /**
   * Handles the submission for adding or updating a qualifier.
   * @param {object} data
   */
  const onSubmit = async (data) => {
    // If the question `type` is not `Text`, verify that at least 2 options are provided
    if (data.type.id !== 'Text' && data.options.length < 3) {
      // Get all of the option labels and filter out the unset ones
      const options = _.filter(
        _.map(data.options, (o) => o.label),
        (o) => o !== null,
      )

      // If there are less than 2 options set, display an error
      if (options.length < 2) {
        setError('options', {
          type: 'manual',
          message: 'At least 2 options are required',
        })
      }
    }

    if (_.keys(errors).length === 0) {
      const updatedQualifier = { ...data }
      updatedQualifier.type = data.type.id

      if (editQualifier) {
        // Clear out any potential options only for `Text` questions
        if (updatedQualifier.type === 'Text') updatedQualifier.options = null

        // Update qualifier
        await updateEventQualifier(
          eventId,
          event.eventExhibitor.id,
          updatedQualifier,
          handleErrors,
          setLoadingQualifier,
          (m) => {
            handleSuccesses(m)
            resetForm()
          },
        )
      } else {
        updatedQualifier.order = qualifiers.length + 1

        // Create qualifier
        await addEventQualifier(
          eventId,
          event.eventExhibitor.id,
          updatedQualifier,
          handleErrors,
          setLoadingQualifier,
          (m) => {
            handleSuccesses(m)
            resetForm()
          },
        )
      }
    }
  }

  /**
   * Handles sumitting the delete request to the API.
   * @param {string} id
   */
  const deleteQualifier = async (id) =>
    deleteEventQualifier(
      eventId,
      event.eventExhibitor.id,
      id,
      handleErrors,
      setLoadingQualifier,
      (m) => {
        getUpdatedQualifiers(
          `/events/${eventId}/exhibitors/${event.eventExhibitor.id}/qualifiers/`,
        )
        handleSuccesses(m)
        resetForm()
      },
    )

  const actions = [
    {
      type: 'cancel',
      label: 'Cancel',
      onClick: () => {
        setEditQualifier(null)
      },
    },
    {
      type: 'submit',
      label: 'Save',
      onClick: handleSubmit(onSubmit),
    },
  ]

  if (editQualifier) {
    actions.push({
      type: 'delete',
      label: 'Delete',
      onClick: () => deleteQualifier(editQualifier.id),
    })
  }

  /**
   * Handles the drag and drop reordering of the qualifiers.
   * - Submits the new order to the API.
   * @param {object} result
   */
  const onDragEnd = async (result) => {
    // Dropped outside the list
    if (!result.destination) {
      return
    }

    // Update the order of the qualifiers, swapping the source and destination
    const qualifiersToUpdate = _.map(
      move(qualifiers, result.source.index, result.destination.index),
      (q, i) => ({
        id: q.id,
        order: i,
      }),
    )

    // Update the list
    const updatedQualifiers = await reorderEventQualifiers(
      eventId,
      event.eventExhibitor.id,
      qualifiersToUpdate,
      handleErrors,
      setLoading,
      handleSuccesses,
    )

    setQualifiers(updatedQualifiers)
  }

  /**
   * Renders the question options within the table display.
   * @param {object} question
   */
  const renderQuestionOptions = (question) => {
    if (question.type === 'Text') return <span className="font-base text-xs">Open Text</span>
    return (
      <span className="font-base truncate text-xs">
        {question.options.map((o, i) => `${i + 1}. ${o.label} `)}
      </span>
    )
  }

  /**
   * Renders the list of options for the question.
   * @param {array} options
   */
  const renderOptionList = (options, type) => {
    const currentValues = getValues()
    if (options === null || type?.id === 'Text') return null

    return (
      <div className="space-y-4">
        <Controller
          name="options"
          control={control}
          render={({ field: { onChange, value } }) =>
            _.map(value, (o, i) => (
              <TextInput
                className="rounded-2xl border-gray-550 py-2.5 pl-9 pr-4  placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                disabled={loadingQualifier}
                id={o.id}
                icon={<PencilSquareIcon className="ml-1.5 h-5 stroke-purple" />}
                label={`Option ${i + 1}`}
                name={`option-${i}`}
                nunito
                onChange={(e) => {
                  clearErrors()

                  onChange(
                    _.map(value, (v) => {
                      if (v.id === o.id) {
                        return { ...v, label: e.target.value }
                      }
                      return v
                    }),
                  )
                }}
                placeholder={`Type Option ${i + 1}`}
                value={o.label}
              />
            ))
          }
        />

        {errors.options && (
          <div className="mt-1 w-full bg-error-light px-2 py-1">
            <p className="text-sm font-medium text-error-dark">Two options are required.</p>
          </div>
        )}

        {currentValues.options.length <= 100 && (
          <button
            type="button"
            className="space-between flex items-center gap-1 text-sm font-medium text-black"
            onClick={() => {
              reset({
                ...currentValues,
                options: [
                  ...currentValues.options,
                  {
                    id: currentValues.options.length,
                    label: null,
                  },
                ],
              })
            }}
          >
            <PlusCircleIcon className="h-5 fill-purple" />
            Add Option
          </button>
        )}
      </div>
    )
  }

  const handleToggleChange = async (val) => {
    const payload = {
      enableQualifiers: val,
      id: event.eventExhibitor.id,
    }

    const exhibitor = await updateExhibitor(
      eventId,
      payload,
      handleErrors,
      setLoading,
      handleSuccesses,
    )

    setEnableQualifiers(exhibitor.enableQualifiers)
    setEvent({
      ...event,
      eventExhibitor: exhibitor,
    })
  }

  return (
    <div className="h-full w-full">
      <StateContainer>
        <div className="relative flex h-full w-full flex-col space-y-3 overflow-y-auto p-3">
          <div className="flex flex-col space-y-3 sm:flex-row sm:justify-between sm:space-y-0">
            <EventHeader event={event} />

            <Toggle
              inter
              disabled={loading}
              label={<span>Enable Qualifiers</span>}
              name="enableQualifiers"
              onChange={(e) => {
                handleToggleChange(e)
              }}
              checked={enableQualifiers}
            />
          </div>

          <div className="mb-4 flex w-full flex-row place-items-center justify-between">
            <span className="text-md font-semibold">Qualifiers</span>

            <Tooltip
              content={
                <div className="rounded-lg bg-white px-2 pb-0.5">
                  <span className="text-xs">Maximum of 10 questions.</span>
                </div>
              }
              display={qualifiers.length >= 10}
              placement="left"
            >
              <Button
                background="bg-purple border-purple hover:bg-purple-600"
                disabled={qualifiers.length >= 10 || !enableQualifiers}
                icon={<PlusIcon className="h-5 fill-white sm:h-6" />}
                label="Add Question"
                onClick={() => {
                  reset(DEFAULT)
                  setShowModal(true)
                }}
              />
            </Tooltip>
          </div>

          {enableQualifiers ? (
            <div className="grid">
              {qualifiers.length > 0 ? (
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided) => {
                      const height = qualifiers.length * 52 + 40

                      return (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          className="w-full overflow-hidden rounded-lg border border-gray-light bg-white"
                          style={{
                            boxShadow:
                              '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)',
                            height: `${height}px`,
                          }}
                        >
                          <div className="grid h-[40px] grid-cols-3 border border-b-gray-light bg-gray-100 px-4 text-xs font-medium uppercase text-black sm:grid-cols-5">
                            <div className="col-span-1 flex flex-wrap content-center sm:col-span-3">
                              Question
                            </div>
                            <div className="col-span-1 flex flex-wrap content-center">Type</div>
                            <div className="col-span-1" />
                          </div>

                          {qualifiers.map((item, index) => (
                            <Draggable key={item.id} draggableId={item.id} index={index}>
                              {(p, s) => (
                                <div
                                  ref={p.innerRef}
                                  {...p.draggableProps}
                                  {...p.dragHandleProps}
                                  className={mergeClassNames(
                                    'group grid h-[52px] grid-cols-3 grid-rows-1 bg-white px-4 text-sm text-gray-600 hover:bg-gray-200 sm:grid-cols-5',
                                    s.isDragging && 'bg-gray-200',
                                    index < qualifiers.length - 1 && 'border-b border-gray-200',
                                  )}
                                  style={{
                                    ...p.draggableProps.style,
                                  }}
                                >
                                  <div className="col-span-1 flex flex-col justify-center sm:col-span-3">
                                    <span className="font-medium text-black">{item.question}</span>
                                    {renderQuestionOptions(item)}
                                  </div>

                                  <div className="col-span-1 flex flex-wrap content-center">
                                    <span>{item.type}</span>
                                  </div>

                                  <div className="col-span-1 flex flex-wrap content-center justify-between">
                                    <button
                                      className="px-2 font-bold text-blue-600 hover:rounded-full hover:bg-status-blue hover:py-0.5"
                                      type="button"
                                      onClick={() => {
                                        setEditQualifier(item)

                                        const type = _.find(
                                          QUESTION_TYPES,
                                          (t) => t.id === item.type,
                                        )
                                        reset({ ...DEFAULT, ...item, type })
                                        setShowModal(true)
                                      }}
                                    >
                                      Edit
                                    </button>

                                    <button
                                      className="px-2 font-bold text-blue-600"
                                      data-testid={`delete-${item.id}`}
                                      type="button"
                                      onClick={() => deleteQualifier(item.id)}
                                    >
                                      <TrashIcon className="h-6 fill-gray-600 hover:fill-purple" />
                                    </button>

                                    <Bars2Icon className="h-6 stroke-gray-400 group-hover:stroke-gray-800" />
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                        </div>
                      )
                    }}
                  </Droppable>
                </DragDropContext>
              ) : (
                <div
                  className="flex h-[70px] w-full place-content-center items-center rounded-lg bg-white"
                  style={{
                    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)',
                  }}
                >
                  {loading ? (
                    <span className="text-2xl font-bold">Loading...</span>
                  ) : (
                    'There are no records to display'
                  )}
                </div>
              )}
            </div>
          ) : (
            <span className="mt-6 text-center text-sm font-medium">
              When enabled, Qualifiers can be managed and are available on the mobile application.
              When disabled, no Qualifiers are available on the mobile application.
            </span>
          )}
        </div>
      </StateContainer>

      <Modal
        actions={actions}
        icon={<img src={editQualifier ? Edit : Add} alt={editQualifier ? 'Edit' : 'Add'} />}
        content={
          <div className="mt-3 flex max-h-96 flex-col space-y-4 overflow-y-auto text-center sm:mt-5">
            <Controller
              name="type"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  className="rounded-2xl border-gray-550 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                  data-testid="type"
                  disabled={loadingQualifier}
                  error={errors.type && 'This field is required'}
                  fullWidth
                  icon={<Cog6ToothIcon className="ml-1.5 h-5 stroke-purple" />}
                  id="type"
                  name="type"
                  nunito
                  onChange={(e) => {
                    onChange(e)

                    // If `type` is not text, auto create two options
                    const currentValues = getValues()
                    if (e.id !== 'Text' && currentValues.options === null) {
                      reset({
                        ...currentValues,
                        options: [
                          { id: 0, label: null },
                          { id: 1, label: null },
                        ],
                      })
                    }
                  }}
                  options={QUESTION_TYPES}
                  label="Type"
                  placeholder="Select a type"
                  style={{ flex: true, width: '100%' }}
                  value={value}
                />
              )}
              rules={{ required: true }}
            />

            <TextInput
              className="rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
              data-testid="question"
              disabled={loadingQualifier}
              id="question"
              error={errors.question && 'This field is required'}
              icon={<QuestionMarkCircleIcon className="ml-1.5 h-5 stroke-purple" />}
              label="Question"
              name="question"
              nunito
              placeholder="Type your question here"
              {...register('question', { required: true })}
            />

            {renderOptionList(watch('options'), watch('type'))}
          </div>
        }
        loading={loadingQualifier}
        onClose={() => {
          setShowModal(false)
          reset(DEFAULT)
        }}
        open={showModal}
        setOpen={setShowModal}
        title={editQualifier ? 'Edit' : 'Add A Question'}
      />

      <Modal
        actions={[
          {
            type: 'cancel',
            label: 'Disable Qualifiers',
            onClick: () => {
              handleToggleChange(false)
              setShowFirstTimeModal(false)
            },
          },
          {
            type: 'submit',
            label: 'Enable Qualifiers',
            onClick: () => {
              handleToggleChange(true)
              setShowFirstTimeModal(false)
            },
          },
        ]}
        content={
          <div className="mt-3 max-h-96 flex-col space-y-4 overflow-y-auto sm:mt-5">
            <p>
              This is where you will manage Qualifiers. If you <strong>enable</strong> Qualifiers,
              users of the mobile Lead Retrieval application will be able to respond to the
              questions you configure. If you <strong>disable</strong> Qualifiers, no questions
              will be available in the mobile application.
            </p>
            <p>
              You can change the setting at any time with the toggle in the top right. Would you
              like to start with Qualifiers enabled or disabled?
            </p>
          </div>
        }
        icon={<StarsIcon className="w-[18px] stroke-white" />}
        loading={loading}
        open={showFirstTimeModal}
        title="Enable Qualifiers?"
      />
    </div>
  )
})

export default EventQualifiers
