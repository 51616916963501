/** @todo Fix import cycle */
/* eslint-disable import/no-cycle */

import { action } from 'mobx'
import { createContext } from 'react'
import * as Sentry from '@sentry/react'

// Stores
import { kiosk } from './KioskStore'
import { navigation } from './NavigationStore'
import { task } from './TaskStore'
import { user } from './UserStore'
import { refreshAccessToken as refreshAccessTokenService } from '../services/user.service'

export class RootStore {
  user = user

  task = task

  navigation = navigation

  kiosk = kiosk

  isExhibitor = false

  clearStore = action((isExhibitor = false) => {
    this.task.reset()
    this.navigation.reset()
    this.user.reset()
    this.kiosk.reset()

    this.isExhibitor = isExhibitor

    Sentry.setUser(null)
  })

  triggerRefreshToken = action(async (failedRequest) => {
    try {
      const { access, refresh, offline } = await refreshAccessTokenService(this.user.refreshToken)
      this.user.accessToken = access
      this.user.refreshToken = refresh
      this.user.offlineToken = offline
      // Reassign to `failedRequest` as this object is used by the token
      // interceptor to retry the request
      // eslint-disable-next-line no-param-reassign
      failedRequest.response.config.headers.Authorization = `Bearer ${access}`
    } catch (err) {
      this.clearStore()
      throw err
    }
  })
}

export const store = new RootStore()
export const RootStoreContext = createContext(store)
