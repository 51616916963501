export const formatPhoneNumber = (phoneNumberString) => {
  if (phoneNumberString) {
    const cleaned = ('', phoneNumberString).replace(/\D/g, '')
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      const intlCode = match[1] ? match[1] : ''
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
    }
  }
  return ''
}

export const formatDuration = (minutes) => {
  const hours = Math.floor(minutes / 60)
  const remainingMinutes = minutes % 60

  if (hours > 0 && remainingMinutes > 0) {
    return `${hours} hour${hours > 1 ? 's' : ''} ${remainingMinutes} minute${
      remainingMinutes > 1 ? 's' : ''
    }`
  }
  if (hours > 0) {
    return `${hours} hour${hours > 1 ? 's' : ''}`
  }
  return `${remainingMinutes} minute${remainingMinutes > 1 ? 's' : ''}`
}
