import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { useForm } from 'react-hook-form'

// Components
import { LockIcon } from '../LockIcon'
import { Modal } from '../Modal'
import { PhoneNumberInput } from '../PhoneNumberInput'
import { TextInput } from '../TextInput'

/**
 *
 * VerificationPopup
 *
 */
const VerificationPopup = ({ attendee, close, continueCheckIn, verificationField }) => {
  // State
  const [error, setError] = useState(null)
  const [placeholder, setPlaceholder] = useState('')

  const {
    control,
    formState: { errors },
    register,
    handleSubmit,
    setError: setFieldError,
    reset,
  } = useForm({
    defaultValues: {
      [verificationField]: '',
    },
  })

  useEffect(() => {
    // Obfuscate the verification field to set as the placeholder
    const field = attendee[verificationField]
    let obfuscated = ''

    if (!field) {
      setError(true)
    }
    // Verify email field for the attendee, obfuscating part of the email
    else if (verificationField === 'email') {
      const split = field.split('@')
      obfuscated = `${_.replace(split[0], split[0].substring(3), '***')}@${split[1]}`
    }
    // Verify phone number field for the attendee, obfuscating part of the phone number
    else if (verificationField === 'phoneNumber' && field) {
      obfuscated = _.replace(
        field,
        field.substring(0, field.length - 4),
        _.repeat('*', field.length - 4),
      )
      reset({ phoneNumber: obfuscated })
    }
    // Verify confirmation code for the attendee, obfuscating part of the confirmation code
    else if (verificationField === 'confirmationCode' && field) {
      obfuscated = _.replace(field, field.substring(3), '***')
    }

    setPlaceholder(obfuscated)
  }, [])

  if (error) {
    return (
      <Modal
        actions={[
          {
            type: 'cancel',
            label: 'Cancel',
            onClick: close,
          },
        ]}
        content={
          <div className="mt-4 flex flex-col items-center">
            <span className="text-center text-2xl font-bold">Error Getting Attendee Data</span>

            <p className="my-4 text-center text-gray">Please see a staff member for assistance.</p>
          </div>
        }
        icon={<ExclamationTriangleIcon className="h-6 w-6 stroke-white" />}
        iconBackground="bg-red"
        open
      />
    )
  }

  return (
    <Modal
      actions={[
        {
          type: 'cancel',
          label: 'Cancel',
          onClick: close,
        },
        {
          type: 'submit',
          label: 'Verify & Print Badge',
          onClick: handleSubmit((data) => {
            // Verify inputted data matches attendee data
            if (data[verificationField] === attendee[verificationField]) {
              continueCheckIn()
            } else {
              setFieldError(verificationField, {
                type: 'manual',
                message: 'Verification failed. Please try again.',
              })
            }
          }),
        },
      ]}
      content={
        <div className="mt-4 flex flex-col items-center">
          <span className="text-center text-2xl font-bold">
            Hi {attendee.firstName} {attendee.lastName}!
          </span>
          <span className="text-md text-center text-gray">{attendee.companyName}</span>

          <p className="my-4 text-center text-gray">
            Please verify your{' '}
            {verificationField !== 'confirmationCode'
              ? _.startCase(verificationField)
              : 'Confirmation Code'}{' '}
            below to check-in and print your badge.
          </p>

          {verificationField === 'phoneNumber' ? (
            <PhoneNumberInput
              control={control}
              error={errors.phoneNumber}
              hideIcon
              name="phoneNumber"
              placeholder={placeholder}
            />
          ) : (
            <TextInput
              className="rounded-2xl border-gray-550 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
              error={
                errors[verificationField] &&
                (errors[verificationField].message || 'This field is required')
              }
              fullWidth
              inputStyles="rounded-none"
              name={verificationField}
              placeholder={placeholder}
              {...register(verificationField, { required: true })}
            />
          )}
        </div>
      }
      icon={<LockIcon className="h-6 w-6 stroke-white" />}
      open
      title="Please Verify"
    />
  )
}

VerificationPopup.propTypes = {
  attendee: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  continueCheckIn: PropTypes.func.isRequired,
  verificationField: PropTypes.string,
}

export { VerificationPopup }
