import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import { PlusIcon, MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/20/solid'
import dayjs from 'dayjs'
import _ from 'lodash'
import { CircleDashed } from 'tabler-icons-react'

// Images
import UsersIcon from '../../assets/images/users.svg'

// Components
import { AddUserModal } from '../../components/AddUserModal'
import { Button } from '../../components/Button'
import { DataTable } from '../../components/DataTable'
import { StateContainer } from '../../components/StateContainer'
import { TextInput } from '../../components/TextInput'

// Store
import { UserStoreContext } from '../../stores/UserStore'

// Service
import { addUser, getUsers, resendUserInvitation, updateUser } from '../../services/users.service'

// Utils & Styles
import colors from '../../utils/colors'
import { handlePagination, toast } from '../../utils/helpers'

/**
 *
 * AdminUsers
 *
 */
const AdminUsers = observer(() => {
  // Context
  const { isEEAdmin, user } = useContext(UserStoreContext)

  // State
  const [loadingUsers, setLoadingUsers] = useState(true)
  const [users, setUsers] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [filter, setFilter] = useState(null)
  const [showUserModal, setShowUserModal] = useState(false)
  const [loadingResend, setLoadingResend] = useState(false)
  const [loadingUser, setLoadingUser] = useState(false)
  const [editUser, setEditUser] = useState(false)

  // Pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [totalRows, setTotalRows] = useState(0)
  const [perPage, setPerPage] = useState(20)
  const [pages, setPages] = useState(null)

  // Ref
  const searchInputRef = useRef(null)

  const handleErrors = (m) => toast(m, 'error')
  const handleSuccess = (m) => toast(m, 'success')

  /**
   * Gets the updated list of users; updates pagination.
   * @param {string} url
   * @returns list of results
   */
  const getUpdatedUserList = async (url) => {
    const response = await getUsers(url, handleErrors, setLoadingUsers, () => {})

    if (response) {
      setTotalRows(response.count)
      setPages({ next: response.next, previous: response.previous })
      setUsers(response.results)
    }
  }

  /**
   * When the filter or row count changes, get the updated list of users.
   */
  useEffect(() => {
    if (filter) {
      getUpdatedUserList(`/users/?ee_role=Admin&ee_role=Member&limit=${perPage}&${filter}`)
    } else {
      getUpdatedUserList(`/users/?ee_role=Admin&ee_role=Member&limit=${perPage}`)
    }
  }, [filter, perPage])

  /**
   * Updates the search query based on `search`.
   * @param {string} search
   */
  const updateSearch = (search) => {
    let updatedFilter = ''
    if (search) updatedFilter = `q=${search}`

    setFilter(updatedFilter)
  }

  const filterUsers = useCallback(_.debounce(updateSearch, 500), [])

  const resetForm = () => {
    setEditUser(false)
    setShowUserModal(false)
  }

  /**
   * Handles submitting the add or edit user forms.
   * @param {object} data
   */
  const onSubmit = (data) => {
    if (editUser) {
      const updatedUser = { ...data }
      delete updatedUser.phoneNumber
      delete updatedUser.mfaDevice

      updateUser(updatedUser, handleErrors, setLoadingUser, (m) => {
        getUpdatedUserList(`/users/?ee_role=Admin&ee_role=Member&limit=${perPage}`)
        handleSuccess(m)
        resetForm()
      })
    } else {
      addUser(data, handleErrors, setLoadingUser, (m) => {
        getUpdatedUserList(`/users/?ee_role=Admin&ee_role=Member&limit=${perPage}`)
        handleSuccess(m)
        resetForm()
      })
    }
  }

  const columns = [
    {
      id: 'name',
      grow: 2,
      name: 'Name',
      selector: (row) => `${row.firstName} ${row.lastName}`,
      cell: (row) => (
        <div className="flex flex-col">
          <span className="text-sm font-bold text-dark">
            {row.firstName} {row.lastName}
          </span>
          {row.lastLogin && (
            <span className="text-xs text-gray-600">
              Last Logged In: {dayjs(row.lastLogin).format('M/D/YYYY h:mm A')}
            </span>
          )}
        </div>
      ),
      sortable: true,
      sortBy: 'first_name',
      minWidth: '250px',
    },
    {
      id: 'email',
      grow: 2,
      name: 'Email',
      selector: (row) => row.email,
      sortable: true,
      sortBy: 'email',
      minWidth: '200px',
    },
    {
      id: 'status',
      grow: 1,
      name: 'Status',
      cell: (row) => renderStatusCell(row),
      minWidth: '200px',
    },
  ]

  if (isEEAdmin) {
    columns.push({
      id: 'edit',
      grow: 0.25,
      name: '',
      cell: (row) => (
        <button
          className="font-bold text-blue-600 hover:rounded-full hover:bg-status-blue hover:px-2 hover:py-0.5 disabled:cursor-not-allowed disabled:opacity-50"
          disabled={row.id === user.id}
          type="button"
          onClick={() => {
            const userToEdit = {
              id: row.id,
              firstName: row.firstName,
              lastName: row.lastName,
              email: row.email,
              eeRole: row.eeRole,
              phoneNumber: row.phoneNumber,
              mfaDevice: row.mfaDevice,
            }

            setEditUser(userToEdit)
            setShowUserModal(true)
          }}
        >
          Edit
        </button>
      ),
      center: true,
    })
  }

  /**
   * Renders the `Status` cell in the DataTable
   * - Displays a pill label based on user `status` or `role`
   * @param {object} row
   */
  const renderStatusCell = (row) => {
    if (row.isInvited && row.lastLogin === null) {
      return (
        <div className="flex shrink-0 flex-row space-x-6">
          <div className="rounded-full bg-status-blue px-2.5 py-0.5">
            <span className="text-xs font-bold text-status-darkBlue">Invite Pending</span>
          </div>

          {isEEAdmin && (
            <button
              disabled={loadingResend && loadingResend === row.id}
              type="button"
              className="px-2 py-0.5 font-bold text-purple hover:rounded-full hover:bg-status-purple disabled:opacity-50"
              onClick={async () =>
                resendUserInvitation(row.id, handleErrors, setLoadingResend, (m) => {
                  getUpdatedUserList(`/users/?ee_role=Admin&ee_role=Member&limit=${perPage}`)
                  handleSuccess(m)
                })
              }
            >
              <div className="flex flex-row items-center">
                {loadingResend && loadingResend === row.id && (
                  <svg className="mr-1 h-4 w-4 motion-safe:animate-spin-slow" viewBox="0 0 24 24">
                    <CircleDashed size={24} strokeWidth={2} color={colors.purple.DEFAULT} />
                  </svg>
                )}
                Resend
              </div>
            </button>
          )}
        </div>
      )
    }

    if (row.eeRole === 'Admin') {
      return (
        <div className="rounded-full bg-status-green px-2.5 py-0.5">
          <span className="text-xs font-bold text-role-admin">{row.eeRole}</span>
        </div>
      )
    }

    if (row.eeRole === 'Member') {
      return (
        <div className="rounded-full bg-status-purple px-2.5 py-0.5">
          <span className="text-xs font-bold text-role-user">{row.eeRole}</span>
        </div>
      )
    }

    return null
  }

  return (
    <div className="h-full">
      <StateContainer stripedBackground>
        <div className="relative flex h-full w-full flex-col space-y-2 rounded-lg bg-background p-3 shadow-md md:h-[85%] md:w-[90%] md:p-4 short:overflow-y-auto">
          {isEEAdmin && (
            <Button
              background="bg-purple border-purple hover:bg-purple-600"
              className="absolute right-4"
              icon={<PlusIcon className="h-5 sm:h-6" />}
              label="Add User"
              onClick={() => setShowUserModal(true)}
            />
          )}

          <div className="flex flex-col">
            <div className="flex flex-row items-center space-x-1">
              <img src={UsersIcon} alt="Users" className="h-4" />
              <span className="text-md  font-bold">Users</span>
            </div>

            <span className="text-xs">Manage user permissions and add users</span>
          </div>

          <TextInput
            className="w-full rounded-full py-2.5 pl-10 pr-4 placeholder:font-normal placeholder:text-gray-600 md:w-[450px]"
            icon={<MagnifyingGlassIcon className="ml-2 h-5 text-gray-dark" aria-hidden="true" />}
            id="search"
            endIcon={
              searchTerm ? (
                <button
                  type="button"
                  onClick={() => {
                    filterUsers('')
                    setSearchTerm('')
                    searchInputRef.current.value = ''
                  }}
                >
                  <XMarkIcon className="mr-2 h-5 text-gray-dark" aria-hidden="true" />
                </button>
              ) : null
            }
            name="search"
            onChange={(e) => {
              filterUsers(e.target.value)
              setSearchTerm(e.target.value)
            }}
            placeholder="Looking for something?"
            ref={searchInputRef}
            value={searchTerm}
          />

          <DataTable
            columns={columns}
            data={users}
            defaultSortFieldId="name"
            defaultSortAsc
            onChangePage={(page) =>
              handlePagination(
                page,
                currentPage,
                perPage,
                totalRows,
                pages,
                setCurrentPage,
                getUpdatedUserList,
                '/users/?ee_role=Admin&ee_role=Member&limit=',
                filter,
              )
            }
            onChangeRowsPerPage={async (currentRowsPerPage) => setPerPage(currentRowsPerPage)}
            onSort={(column, direction) => {
              const d = direction === 'asc' ? '' : '-'
              const url = `/users/?ee_role=Admin&ee_role=Member&order_by=${d}${column.sortBy}&limit=${perPage}`
              getUpdatedUserList(url)
            }}
            pagination
            paginationPerPage={perPage}
            paginationRowsPerPageOptions={[10, 15, 20, 30, 50]}
            paginationTotalRows={totalRows}
            paginationServer
            progressPending={loadingUsers}
            sortServer
          />
        </div>
      </StateContainer>

      {showUserModal && (
        <AddUserModal
          loadingUser={loadingUser}
          onSubmit={onSubmit}
          editUser={editUser}
          roles={{
            allowRoleChange: true,
            key: 'eeRole',
            showRoleDescriptions: false,
          }}
          setEditUser={setEditUser}
          showRoleDescriptions
          showUserModal={showUserModal}
          setShowUserModal={setShowUserModal}
        />
      )}
    </div>
  )
})

export default AdminUsers
