/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import dayjs from 'dayjs'
import { DocumentPlusIcon, LinkIcon, PlusIcon } from '@heroicons/react/24/outline'
import { EyeIcon } from '@heroicons/react/20/solid'
import { useForm } from 'react-hook-form'

// Components
import { Button } from '../../components/Button'
import { DataTable } from '../../components/DataTable'
import { EventHeader } from '../../components/EventHeader'
import { FileUploader } from '../../components/FileUploader'
import { Modal } from '../../components/Modal'
import { StarsIcon } from '../../components/StarsIcon'
import { StateContainer } from '../../components/StateContainer'
import { TextInput } from '../../components/TextInput'
import { Toggle } from '../../components/Toggle'

// Images
import Add from '../../assets/images/add.svg'
import Edit from '../../assets/images/editCircle.svg'
import Inbox from '../../assets/images/inbox.svg'

// Stores
import { NavigationStoreContext } from '../../stores/NavigationStore'

// Service
import {
  addEventMaterial,
  deleteEventMaterial,
  getEventMaterials,
  updateEventMaterial,
} from '../../services/materials.service'
import { updateExhibitor } from '../../services/exhibitors.service'

// Utils
import { handlePagination, toast } from '../../utils/helpers'

/**
 *
 * EventMaterials
 *
 */
const EventMaterials = observer(() => {
  // Context
  const { event, eventId, setEvent } = useContext(NavigationStoreContext)

  // State
  const [loading, setLoading] = useState(true)
  const [materials, setMaterials] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [uploadFile, setUploadFile] = useState(null)
  const [loadingMaterial, setLoadingMaterial] = useState(false)
  const [uploadedFile, setUploadedFile] = useState(null)
  const [editMaterial, setEditMaterial] = useState(null)
  const [loadingExhibitor, setLoadingExhibitor] = useState(false)
  const [enableMaterials, setEnableMaterials] = useState(event?.eventExhibitor?.enableMaterials)
  const [showFirstTimeModal, setShowFirstTimeModal] = useState(
    event?.eventExhibitor?.enableMaterials === null,
  )

  // Pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [totalRows, setTotalRows] = useState(0)
  const [perPage, setPerPage] = useState(10)
  const [pages, setPages] = useState(null)

  const handleErrors = (m) => toast(m, 'error')
  const handleSuccesses = () => toast(`${event.name} updated.`, 'success')

  const DEFAULT = {
    name: '',
    url: '',
    file: '',
  }

  const {
    handleSubmit,
    formState: { errors },
    register,
    reset,
    watch,
  } = useForm({
    defaultValues: DEFAULT,
  })

  /**
   * Gets the updated list of materials; updates pagination.
   * @param {string} url
   */
  const getUpdatedMaterials = async (url) => {
    const response = await getEventMaterials(url, handleErrors, setLoading)

    if (response) {
      setTotalRows(response.count)
      setPages({ next: response.next, previous: response.previous })
      setMaterials(response.results)
    }
  }

  useEffect(() => {
    if (event?.eventExhibitor?.id) {
      getUpdatedMaterials(
        `/events/${eventId}/exhibitors/${event.eventExhibitor.id}/materials/?limit=${perPage}`,
      )
    }
  }, [perPage])

  const renderFileUploader = () => {
    if (uploadFile) {
      return (
        <div className="flex flex-col">
          <FileUploader
            acceptedFileTypes={['application/pdf']}
            autoSave
            handleUploadToServer={async (file) => {
              setUploadedFile(file)
              setUploadFile(null)
            }}
            id="file"
            maxFiles={1}
            type="gcp"
          />

          <div className="-mt-4 mr-2 flex flex-row place-items-center justify-between">
            <span className="text-xs italic text-gray-500">Upload a single file that is .pdf</span>

            <button className="place-self-end" type="button" onClick={() => setUploadFile(null)}>
              <span className="text-xs">Cancel Upload</span>
            </button>
          </div>
        </div>
      )
    }

    return (
      <div className="flex flex-row items-center space-x-4 self-center">
        {editMaterial && editMaterial.fileUrl ? (
          <span className="text-sm text-gray-500">File Uploaded</span>
        ) : (
          <span className="text-sm text-gray-500">
            {uploadedFile ? uploadedFile.name : 'Upload File'}
          </span>
        )}

        <Button
          background="bg-white"
          disabled={watch('url')}
          icon={<img src={Inbox} alt="Upload" className="h-5" />}
          label={uploadedFile || (editMaterial && editMaterial.fileUrl) ? 'Change' : 'Upload'}
          onClick={() => setUploadFile(true)}
          outlined
        />
      </div>
    )
  }

  const resetForm = () => {
    // Clear & close the modal
    setUploadFile(null)
    setUploadedFile(null)
    setShowModal(false)
    setEditMaterial(null)
    // Get updated materials list
    getUpdatedMaterials(
      `/events/${eventId}/exhibitors/${event.eventExhibitor.id}/materials/?limit=${perPage}`,
    )
  }

  const onSubmit = async (data) => {
    let updatedMaterial = { ...data }

    if (uploadedFile) {
      updatedMaterial = {
        ...updatedMaterial,
        fileUrl: uploadedFile.url,
      }
    }

    if (editMaterial) {
      // Update material
      await updateEventMaterial(
        eventId,
        event.eventExhibitor.id,
        updatedMaterial,
        handleErrors,
        setLoadingMaterial,
        (m) => {
          handleSuccesses(m)
          resetForm()
        },
      )
    } else {
      // Create material
      await addEventMaterial(
        eventId,
        event.eventExhibitor.id,
        updatedMaterial,
        handleErrors,
        setLoadingMaterial,
        (m) => {
          handleSuccesses(m)
          resetForm()
        },
      )
    }
  }

  const actions = [
    {
      type: 'cancel',
      label: 'Cancel',
      onClick: () => {
        setUploadFile(null)
        setUploadedFile(null)
        setEditMaterial(null)
      },
    },
    {
      type: 'submit',
      label: 'Save',
      onClick: handleSubmit(onSubmit),
    },
  ]

  if (editMaterial) {
    actions.push({
      type: 'delete',
      label: 'Delete',
      onClick: () =>
        deleteEventMaterial(
          eventId,
          event.eventExhibitor.id,
          editMaterial.id,
          handleErrors,
          setLoadingMaterial,
          (m) => {
            handleSuccesses(m)
            resetForm()
          },
        ),
    })
  }

  const handleToggleChange = async (val) => {
    const payload = {
      enableMaterials: val,
      id: event.eventExhibitor.id,
    }

    const exhibitor = await updateExhibitor(
      eventId,
      payload,
      handleErrors,
      setLoadingExhibitor,
      handleSuccesses,
    )

    if (exhibitor) {
      setEnableMaterials(exhibitor.enableMaterials)
      setEvent({
        ...event,
        eventExhibitor: exhibitor,
      })
    }
  }

  return (
    <div className="h-full w-full">
      <StateContainer>
        <div className="relative flex h-full w-full flex-col space-y-3 overflow-y-auto p-3">
          <div className="flex flex-col space-y-3 sm:flex-row sm:justify-between sm:space-y-0">
            <EventHeader event={event} />

            <Toggle
              inter
              disabled={loading}
              label={<span>Enable Materials</span>}
              name="enableMaterials"
              onChange={(e) => {
                handleToggleChange(e)
              }}
              checked={enableMaterials}
            />
          </div>

          <div className="mb-4 flex w-full flex-row place-items-center justify-between">
            <span className="text-md font-semibold">App Materials</span>

            <Button
              background="bg-purple border-purple hover:bg-purple-600"
              disabled={!enableMaterials}
              icon={<PlusIcon className="h-5 fill-white sm:h-6" />}
              label="Add Materials"
              onClick={() => {
                reset(DEFAULT)
                setShowModal(true)
              }}
            />
          </div>

          <div className="grid">
            {enableMaterials ? (
              <DataTable
                columns={[
                  {
                    id: 'name',
                    grow: 2,
                    name: 'Material Name',
                    selector: (row) => row.name,
                    cell: (row) => <div className="font-medium text-black">{row.name}</div>,
                    minWidth: '300px',
                  },
                  {
                    id: 'type',
                    grow: 2,
                    name: 'Type',
                    selector: (row) => row.type,
                    width: '100px',
                  },
                  {
                    id: 'date',
                    grow: 1,
                    name: 'Date Added',
                    selector: (row) => row.createdAt,
                    cell: (row) => <span>{dayjs(row.created_at).format('MMM. D, YYYY')}</span>,
                    minWidth: '150px',
                  },
                  {
                    id: 'viewEdit',
                    cell: (row) => (
                      <div className="flex items-center gap-8 group-hover:text-white">
                        <button
                          className="px-2 py-0.5 font-bold text-blue-600 hover:rounded-full hover:bg-status-blue disabled:cursor-not-allowed disabled:opacity-50"
                          type="button"
                          onClick={() => {
                            reset(row)
                            setEditMaterial(row)
                            setShowModal(true)
                          }}
                        >
                          Edit
                        </button>
                        <a href={row.url || row.signedFileUrl} target="_blank">
                          <EyeIcon className="h-5 w-5" />
                        </a>
                      </div>
                    ),
                    grow: 1,
                    name: '',
                    right: true,
                    selector: (row) => row.createdAt,
                    minWidth: '180px',
                  },
                ]}
                data={materials}
                defaultSortFieldId="dates"
                defaultSortAsc
                onChangePage={(page) =>
                  handlePagination(
                    page,
                    currentPage,
                    perPage,
                    totalRows,
                    pages,
                    setCurrentPage,
                    getUpdatedMaterials,
                    `/events/${eventId}/exhibitors/${event.eventExhibitor.id}/materials/?limit=`,
                  )
                }
                onChangeRowsPerPage={async (currentRowsPerPage) => setPerPage(currentRowsPerPage)}
                pagination
                paginationPerPage={perPage}
                paginationRowsPerPageOptions={[10, 15, 20, 30, 50]}
                paginationTotalRows={totalRows}
                paginationServer
                progressPending={loading}
                sortingEnabled={false}
              />
            ) : (
              <span className="mt-6 text-center text-sm font-medium">
                When enabled, Materials can be managed and are available on the mobile application.
                When disabled, no Materials are available on the mobile application.
              </span>
            )}
          </div>
        </div>
      </StateContainer>

      <Modal
        actions={actions}
        icon={<img src={editMaterial ? Edit : Add} alt={editMaterial ? 'Edit' : 'Add'} />}
        content={
          <div className="mt-3 flex flex-col space-y-4 text-center sm:mt-5">
            <TextInput
              className="rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
              id="name"
              error={errors.name && 'This field is required'}
              icon={<DocumentPlusIcon className="ml-1.5 h-4 stroke-purple" />}
              label="Name"
              name="name"
              nunito
              placeholder="Name"
              {...register('name', { required: true })}
            />

            <TextInput
              className="rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
              id="url"
              disabled={(editMaterial && editMaterial.fileUrl) || uploadedFile}
              error={errors.url && (errors.url.message || 'This field is required')}
              helpText="https:// prefix required"
              icon={<LinkIcon className="ml-1.5 h-4 stroke-purple" />}
              label="URL"
              name="url"
              nunito
              placeholder="URL"
              {...register('url', {
                required: (!editMaterial || !editMaterial.fileUrl) && !uploadedFile,
                validate: (value) => {
                  if (value && !value.startsWith('https://'))
                    return 'Must be a valid URL, starting with https://'
                  return undefined
                },
              })}
            />

            {renderFileUploader()}
          </div>
        }
        loading={loadingMaterial || loadingExhibitor}
        onClose={() => {
          setShowModal(false)
          reset(DEFAULT)
        }}
        open={showModal}
        setOpen={setShowModal}
        title={editMaterial ? 'Edit' : 'Add Material'}
      />

      <Modal
        actions={[
          {
            type: 'cancel',
            label: 'Disable Materials',
            onClick: () => {
              handleToggleChange(false)
              setShowFirstTimeModal(false)
            },
          },
          {
            type: 'submit',
            label: 'Enable Materials',
            onClick: () => {
              handleToggleChange(true)
              setShowFirstTimeModal(false)
            },
          },
        ]}
        content={
          <div className="mt-3 max-h-96 flex-col space-y-4 overflow-y-auto sm:mt-5">
            <p>
              This is where you will manage Materials. If you <strong>enable</strong> Materials,
              users of the mobile Lead Retrieval application will be able to view and share the
              materials added. If you <strong>disable</strong> Materials, no materials will be
              available in the mobile application.
            </p>
            <p>
              You can change the setting at any time with the toggle in the top right. Would you
              like to start with Materials enabled or disabled?
            </p>
          </div>
        }
        icon={<StarsIcon className="w-[18px] stroke-white" />}
        loading={loading}
        open={showFirstTimeModal}
        title="Enable Materials?"
      />
    </div>
  )
})

export default EventMaterials
