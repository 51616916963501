/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { Fragment, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Menu, MenuButton, MenuItems, MenuItem, Transition } from '@headlessui/react'
import { twMerge as mergeClassNames } from 'tailwind-merge'
import { Controller, useForm } from 'react-hook-form'

// Components
import {
  AlignmentControls,
  BackgroundControls,
  BorderControls,
  FontStyleControls,
  SizeControls,
  TextAlignControls,
} from '../../components/BadgeSettings'
import { Button } from '../../components/Button'
import { ColorPicker } from '../../components/ColorPicker'
import { MergeFieldLogicFields } from '../../components/MergeFieldLogicFields'
import { Modal } from '../../components/Modal'
import { RadioInput } from '../../components/RadioInput'
import { Select } from '../../components/Select'
import { TextInput } from '../../components/TextInput'
import { Toggle } from '../../components/Toggle'
import { WrapTextIcon } from '../../components/WrapTextIcon'

// Images
import Edit from '../../assets/images/editCircle.svg'

// Utils & Service
import { getGlobalCustomFonts, getGoogleFonts } from '../../services/settings.service'
import { INCLUSION_OPTIONS, NON_SYNCED_FIELDS } from '../../utils/constants'
import { configureMergeFieldOptions, mapLogicValues } from '../../utils/helpers'
import { findElementLocation } from './helpers'

const DEFAULT_LOGIC = {
  inclusionLogic: INCLUSION_OPTIONS[0],
  mergeField: null,
  operator: null,
  value: null,
}

/**
 *
 * BadgeElementSettings
 *
 */
const BadgeElementSettings = ({
  activeElement,
  admissionItems,
  availableCustomFields,
  badgeHeight,
  badgeWidth,
  categories,
  config,
  copyElement,
  createGroup,
  currentPanelIndex,
  deleteElement,
  enabledCustomFields,
  layers,
  moveElement,
  panels,
  temporaryGroup,
  sessions,
  setCustomFonts,
  setGoogleFonts,
  updateElement,
}) => {
  // State
  const [fonts, setFonts] = useState([])
  const [loadingFonts, setLoadingFonts] = useState(false)
  const [selectedLayer, setSelectedLayer] = useState(null)
  const [showVisibilityModal, setShowVisibilityModal] = useState(false)

  const mergeFieldOptions = useMemo(() => {
    let mergeFieldLabels = NON_SYNCED_FIELDS
    if (enabledCustomFields) {
      const customFields = _.map(enabledCustomFields, (f) => availableCustomFields[f]?.text)
      mergeFieldLabels = _.concat(NON_SYNCED_FIELDS, customFields)
    }

    return configureMergeFieldOptions(
      mergeFieldLabels,
      availableCustomFields,
      categories,
      admissionItems,
      sessions,
    )
  }, [])

  const categoryOptions = _.map(_.keys(categories), (k) => ({
    id: k,
    label: categories[k],
  }))

  const {
    control,
    clearErrors,
    handleSubmit,
    formState: { errors },
    register,
    getValues,
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      id: null,
      always: true,
      inclusionLogic: INCLUSION_OPTIONS[0],
      visibilityLogic: [DEFAULT_LOGIC],
    },
  })

  useEffect(() => {
    const getGlobalFonts = async () => {
      setLoadingFonts(true)

      let options = []
      // Pull Google Fonts
      const data = await getGoogleFonts(
        () => {},
        () => {},
      )
      if (data) {
        const filteredFonts = _.map(
          _.filter(
            data.items,
            (i) => i.variants.includes('regular') && i.variants.includes('italic'),
          ),
          (f) => ({
            id: f.family,
            label: f.family,
            ...f,
          }),
        )

        options = filteredFonts
      }

      // Pull Custom Fonts
      const globalFonts = await getGlobalCustomFonts(
        () => {},
        () => {},
      )

      if (globalFonts) {
        options = [
          ..._.map(globalFonts, (f) => ({ id: f.id, label: f.name, url: f.signedUrl })),
          ...options,
        ]
      }

      setLoadingFonts(false)
      setFonts(options)
    }

    getGlobalFonts()
  }, [])

  /**
   * Tracks the visibility of the `activeElement`
   * - If the element is always visible, set the visibility to `always`
   * - If the element is not always visible, set the visibility to the field, operator, and value
   */
  useEffect(() => {
    // If there's no active element, reset the form
    if (!activeElement) {
      reset({ id: null, always: true, visibilityLogic: [] })
    }
    // If `activeElement` is a layer and visibility is `always`
    else if (activeElement.className === 'Layer' && activeElement.attrs.visibility === 'always') {
      reset({ id: activeElement.attrs.id, always: true, visibilityLogic: [] })
    }
    // If `activeElement` is a layer and visibility is configured
    else if (activeElement.className === 'Layer') {
      let categoryId

      // Handle cases where visibility is a string - this is to make our new logic
      // backwards compatible
      if (_.isString(activeElement.attrs.visibility)) {
        // eslint-disable-next-line prefer-destructuring
        categoryId = activeElement.attrs.visibility.split(':')[2]
      }
      // Otherwise, just use the id set
      else {
        categoryId = activeElement.attrs.visibility[0].value
      }

      reset({
        id: activeElement.attrs.id,
        always: false,
        inclusionLogic: INCLUSION_OPTIONS[0],
        visibilityLogic: mapLogicValues(
          [
            {
              inclusionLogic: INCLUSION_OPTIONS[0].id,
              mergeField: 'categoryId',
              operator: 'is',
              value: categoryId,
            },
          ],
          mergeFieldOptions,
          availableCustomFields,
          categories,
        ),
      })
    }
    // If `activeElement` is not a layer and visibility is `always`
    else if (activeElement.attrs.visibility === 'always') {
      reset({ id: activeElement.attrs.id, always: true, visibilityLogic: [] })
    }
    // If `activeElement` is not a layer and visibility is configured as the old format
    // Handle cases where visibility is a string - this is to make our new logic
    // backwards compatible
    else if (_.isString(activeElement.attrs.visibility)) {
      const visibility = activeElement.attrs.visibility.split(':')

      reset({
        id: activeElement.attrs.id,
        always: false,
        inclusionLogic: INCLUSION_OPTIONS[0],
        visibilityLogic: mapLogicValues(
          [
            {
              inclusionLogic: INCLUSION_OPTIONS[0].id,
              mergeField: visibility[0],
              operator: visibility[1],
              value: visibility[2],
            },
          ],
          mergeFieldOptions,
          availableCustomFields,
          categories,
          admissionItems,
          sessions,
        ),
      })
    }
    // Otherwise, configure the visibilty logic
    else {
      reset({
        id: activeElement.attrs.id,
        always: false,
        inclusionLogic: _.find(
          INCLUSION_OPTIONS,
          (o) => o.id === activeElement.attrs.visibility[0].inclusionLogic,
        ),
        visibilityLogic: mapLogicValues(
          activeElement.attrs.visibility,
          mergeFieldOptions,
          availableCustomFields,
          categories,
          admissionItems,
          sessions,
        ),
      })
    }

    if (activeElement && activeElement?.className !== 'Layer') {
      const location = findElementLocation(config, activeElement.attrs.id)
      setSelectedLayer(layers[location.layerIndex])
    }
  }, [activeElement])

  const renderGroupButton = () => {
    // Check that the elements in the temporary group are on the same layer
    const location = findElementLocation(config, temporaryGroup[0].attrs.id)
    const sameLayer = _.every(temporaryGroup, (element) => {
      const l = findElementLocation(config, element.attrs.id)
      return l.layerIndex === location.layerIndex
    })

    if (_.every(temporaryGroup, (el) => el.attrs.inGroup)) return null

    return (
      <div className="flex">
        <Button
          disabled={!sameLayer} // Disable button if elements are not on the same layer
          fullWidth
          label="Create Group"
          onClick={createGroup}
          title={!sameLayer ? 'Elements must be on the same layer to group.' : undefined}
        />
      </div>
    )
  }

  const renderVisibilityLogic = () => {
    const logic = _.map(getValues('visibilityLogic'), (l) => ({
      ...l,
      mergeField: l.mergeField?.id,
      operator: l.operator?.id,
      inclusionLogic: l.inclusionLogic?.id,
      value: l.value?.id || l.value,
    }))

    return (
      <div className="flex flex-col gap-1">
        {_.map(logic, (l, i) => (
          <div
            className={mergeClassNames(
              'flex flex-row items-center justify-between gap-2',
              i !== logic.length - 1 && 'border-b border-gray-300 pb-2',
            )}
            key={l.id}
          >
            <div className="flex flex-row gap-1 text-xs">
              <span>{`{{ ${l.mergeField} }}`}</span>
              <span>{l.operator}</span>
              <span className="font-bold">{l.value}</span>
            </div>

            {i !== logic.length - 1 && (
              <span className="flex items-center rounded-full bg-purple-100 px-1 py-0.5 text-[8px] font-bold text-purple">
                {l.inclusionLogic}
              </span>
            )}
          </div>
        ))}
      </div>
    )
  }

  const renderSettings = () => {
    let title = ''
    let settingsSection = null
    let visibilitySection = null
    let moveSection = null
    let customSection = null

    // If the `activeElement` doesn't match the form, return null
    // to prevent any issues with timing state updates.
    if (activeElement.attrs.id !== getValues('id')) return null

    if (activeElement.className === 'Layer') {
      title = 'Layer'

      const layerName = activeElement.attrs.name || ''

      settingsSection = (
        <TextInput
          className="rounded-2xl border-gray-550 py-2 pl-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
          id="name"
          name="name"
          onChange={(event) => {
            updateElement({
              name: event.target.value,
            })
          }}
          placeholder="Layer Name"
          value={layerName}
        />
      )

      if (categories) {
        const options = _.concat([{ id: '-1', label: 'Always Visible' }], categoryOptions)

        visibilitySection = (
          <div className="space-y-1">
            <span className="text-sm font-semibold">Visibility</span>

            <Select
              className="rounded-2xl border-gray-550 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
              fullWidth
              id="visibility"
              nunito
              onChange={({ id }) => {
                updateElement({
                  visibility: id === '-1' ? 'always' : `category:is:${id}`,
                })
              }}
              options={options}
              label="Layer Visible for Category"
              placeholder="Select a Category"
              style={{ flex: true, width: '100%' }}
              value={
                getValues('always')
                  ? options[0]
                  : _.find(options, (o) => o.id === activeElement.attrs.visibility.split(':')[2])
              }
            />
          </div>
        )
      }
    } else if (activeElement.attrs.qrImage) {
      title = 'QR Code'

      const options = [
        {
          id: 'no-print',
          label: 'No QR Code',
        },
        { id: 'scrambled', label: 'Scrambled QR Code' },
      ]

      settingsSection = (
        <div className="flex">
          <AlignmentControls
            containerHeight={badgeHeight}
            containerWidth={badgeWidth}
            element={activeElement}
            isInGroup={activeElement.attrs.inGroup || false}
            moveElement={moveElement}
            updateElement={updateElement}
          />
        </div>
      )

      customSection = (
        <RadioInput
          className="mt-4 self-center"
          label="If Attendee Opts Out, Print:"
          labelClassName="text-sm font-semibold"
          options={options}
          onChange={(visibility) => updateElement({ qrPrint: visibility })}
          value={_.find(options, (o) => activeElement.attrs.qrPrint === o.id).id}
        />
      )
    } else if (activeElement.className === 'Image') {
      title = 'Image'

      settingsSection = (
        <div className="flex flex-col space-y-5">
          {!activeElement.attrs.isBackground && (
            <>
              <SizeControls element={activeElement} updateElement={updateElement} />
              <BorderControls element={activeElement} updateElement={updateElement} />
            </>
          )}

          <BackgroundControls element={activeElement} updateElement={updateElement} />

          {!activeElement.attrs.isBackground && (
            <AlignmentControls
              containerHeight={badgeHeight}
              containerWidth={badgeWidth}
              element={activeElement}
              isInGroup={activeElement.attrs.inGroup || false}
              moveElement={moveElement}
              updateElement={updateElement}
            />
          )}
        </div>
      )
    } else if (activeElement.className === 'Text') {
      title = 'Text Field'

      const selectedFont = fonts.find((f) => {
        if (f.id === activeElement.attrs.fontId || f.family === activeElement.attrs.fontFamily)
          return f
        return null
      })

      settingsSection = (
        <div className="flex flex-col space-y-5">
          <div className="space-y-1.5">
            <Select
              className="rounded-2xl border-gray-550 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
              data-testid="fontFamily"
              disabled={loadingFonts}
              fullWidth
              id="fontFamily"
              name="fontFamily"
              nunito
              onChange={(newFont) => {
                // Only update google fonts for selected google fonts
                if (!newFont.url) {
                  setGoogleFonts({
                    font: newFont.family,
                    weights: newFont.variants,
                  })

                  updateElement({ fontFamily: newFont.family, fontId: null })
                } else {
                  setCustomFonts(newFont.id)
                  updateElement({ fontFamily: newFont.label, fontId: newFont.id })
                }
              }}
              options={fonts}
              search
              style={{ flex: true, width: '100%' }}
              value={selectedFont}
            />

            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <TextInput
                  type="number"
                  className="w-[80px] rounded-2xl border-gray-550 py-2 pl-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                  id="fontSize"
                  name="fontSize"
                  min={1}
                  max={100}
                  onChange={(event) =>
                    updateElement({ fontSize: parseInt(event.target.value, 10) })
                  }
                  placeholder="Font Size"
                  value={activeElement.attrs.fontSize}
                />

                <ColorPicker
                  color={activeElement.attrs.fill}
                  onChange={(color) => updateElement({ fill: color.hex })}
                  tooltip="Text color"
                />
              </div>

              <FontStyleControls element={activeElement} updateElement={updateElement} />
            </div>

            <div className="flex justify-between">
              <TextAlignControls element={activeElement} updateElement={updateElement} />

              <div className="flex">
                <button
                  onClick={() => {
                    updateElement({ uppercase: !activeElement.attrs.uppercase })
                  }}
                  className={mergeClassNames(
                    'flex h-8 w-8 cursor-pointer items-center justify-center rounded-md border border-transparent hover:bg-purple-400',
                    activeElement.attrs.uppercase && 'bg-purple-400 hover:bg-purple-200',
                  )}
                  aria-label="Format uppercase"
                  title="Format uppercase"
                  type="button"
                >
                  <box-icon type="regular" color="black" name="font-size" size="sm" />
                </button>

                <button
                  type="button"
                  className={mergeClassNames(
                    'flex h-8 w-8 cursor-pointer items-center justify-center rounded-md border border-transparent hover:bg-purple-400',
                    activeElement.attrs.wrap === 'word' && 'bg-purple-400 hover:bg-purple-200',
                  )}
                  aria-label="Wrap text"
                  title="Wrap text"
                  onClick={() => {
                    updateElement({ wrap: activeElement.attrs.wrap === 'word' ? 'none' : 'word' })
                  }}
                >
                  <WrapTextIcon />
                </button>
              </div>
            </div>
          </div>

          <AlignmentControls
            containerHeight={badgeHeight}
            containerWidth={badgeWidth}
            element={activeElement}
            isInGroup={activeElement.attrs.inGroup || false}
            moveElement={moveElement}
            updateElement={updateElement}
          />
        </div>
      )
    } else if (activeElement.className === 'Rect' || activeElement.className === 'Group') {
      title = activeElement.className === 'Rect' ? 'Rectangle' : 'Group'

      settingsSection = (
        <div className="flex flex-col space-y-5">
          <div className="space-y-1">
            <span className="text-sm font-bold">Background</span>
            <ColorPicker
              color={activeElement.attrs.fill}
              onChange={(color) => updateElement({ fill: color.hex })}
              transparentOption
              tooltip="Background color"
            />
          </div>

          <BorderControls element={activeElement} updateElement={updateElement} />

          <AlignmentControls
            containerHeight={badgeHeight}
            containerWidth={badgeWidth}
            element={activeElement}
            moveElement={moveElement}
            updateElement={updateElement}
          />
        </div>
      )
    }

    if (activeElement.className !== 'Layer' && !activeElement.attrs.isBackground) {
      visibilitySection = (
        <div className="space-y-1">
          <span className="text-sm font-bold">Visibility</span>

          <Controller
            name="always"
            control={control}
            render={({ field: { value } }) => (
              <Toggle
                checked={value}
                onChange={(checked) => {
                  if (checked) {
                    setValue('always', true)
                    updateElement({ visibility: 'always' })
                  } else {
                    setValue('visibilityLogic', [DEFAULT_LOGIC])
                    setShowVisibilityModal(true)
                  }
                }}
                label="Always Visible"
                labelClassName="text-sm"
              />
            )}
          />

          {!watch('always') && (
            <div className="flex flex-col gap-3 pt-2">
              {renderVisibilityLogic()}

              <Button fullWidth label="Edit Logic" onClick={() => setShowVisibilityModal(true)} />
            </div>
          )}
        </div>
      )

      moveSection = (
        <div className="space-y-1">
          <span className="text-sm font-bold">Placement</span>

          <div className="space-y-2">
            {layers.length > 1 && (
              <>
                <Select
                  className="rounded-2xl border-gray-550 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                  fullWidth
                  id="placement"
                  label="Change Layer"
                  nunito
                  onChange={({ id }) => {
                    // `id` is the layer index
                    moveElement('layer', id)
                  }}
                  options={layers}
                  style={{ flex: true, width: '100%' }}
                  value={selectedLayer}
                />

                <Menu as="div" className="inline-block w-full">
                  <MenuButton className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-purple px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-400">
                    Copy to another layer
                  </MenuButton>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <MenuItems className="absolute right-0 z-10 mt-2 w-56 origin-top-right overflow-hidden rounded-md bg-white shadow-lg">
                      <div className="flex flex-col">
                        {_.map(
                          _.filter(layers, (l) => l.id !== selectedLayer?.id),
                          (l) => (
                            <MenuItem
                              className="text-primary cursor-pointer select-none px-3 py-2 text-sm hover:bg-purple hover:text-white"
                              key={`label-${l.id}`}
                              onClick={() => {
                                // `id` is the layer index
                                copyElement('layer', l.id)
                              }}
                            >
                              <span>Copy to {l.label}</span>
                            </MenuItem>
                          ),
                        )}
                      </div>
                    </MenuItems>
                  </Transition>
                </Menu>
              </>
            )}

            {panels.length > 2 && (
              <Menu as="div" className="inline-block w-full">
                <MenuButton className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-purple px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-400">
                  Copy to another panel
                </MenuButton>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <MenuItems className="absolute right-0 z-10 mt-2 w-56 origin-top-right overflow-hidden rounded-md bg-white shadow-lg">
                    <div className="flex flex-col">
                      {_.map(
                        _.filter(
                          panels,
                          (p) => p.label !== '+ Add Panel' && p.id !== currentPanelIndex,
                        ),
                        (p) => (
                          <MenuItem
                            className="text-primary cursor-pointer select-none px-3 py-2 text-sm hover:bg-purple hover:text-white"
                            key={`panel-${p.id}`}
                            onClick={() => {
                              // `id` is the panel index
                              copyElement('panel', p.id)
                            }}
                          >
                            <span>Copy to {p.label}</span>
                          </MenuItem>
                        ),
                      )}
                    </div>
                  </MenuItems>
                </Transition>
              </Menu>
            )}
          </div>
        </div>
      )
    }

    return (
      <div className="flex flex-col pb-3">
        <span className="text-sm font-bold uppercase">{title}</span>

        <div className="space-y-5">
          {/* Settings */}
          {settingsSection}

          {/* Visibility */}
          {!activeElement.attrs.inGroup && visibilitySection}

          {/* Custom Settings */}
          {customSection}

          {/* Move Element */}
          {!activeElement.attrs.inGroup && (layers.length > 1 || panels.length > 2) && moveSection}

          <Button
            background="bg-red"
            fullWidth
            label={activeElement.className === 'Group' ? 'Remove Group' : 'Delete'}
            onClick={deleteElement}
          />
        </div>
      </div>
    )
  }

  return (
    <div className="z-0 h-full max-h-[850px] w-[30%] overflow-y-auto rounded-xl bg-gray-400 px-4 pt-3">
      {activeElement !== null && <div className="h-full w-full">{renderSettings()}</div>}

      {temporaryGroup &&
        _.filter(temporaryGroup, (el) => el.className !== 'Group').length > 1 &&
        renderGroupButton()}

      <Modal
        actions={[
          {
            type: 'cancel',
            label: 'Close',
            onClick: () => {
              setShowVisibilityModal(false)
            },
          },
          {
            type: 'submit',
            label: 'Save',
            onClick: handleSubmit(async (data) => {
              const logic = _.map(data.visibilityLogic, (l) => ({
                ...l,
                mergeField: l.mergeField.id,
                operator: l.operator.id,
                inclusionLogic: l.inclusionLogic.id,
                value: l.value?.id || l.value,
              }))

              // Only update the element if there was logic set
              if (logic.length > 0) {
                updateElement({ visibility: logic })
                setValue('always', false)
              }

              setShowVisibilityModal(false)
            }),
          },
        ]}
        icon={<img src={Edit} alt="Edit" />}
        content={
          <div className="mb-2 flex h-full w-full flex-col gap-4 pt-4">
            <MergeFieldLogicFields
              admissionItems={admissionItems}
              baseField="visibilityLogic"
              categories={categories}
              control={control}
              clearErrors={clearErrors}
              errors={errors}
              getValues={getValues}
              mergeFields={availableCustomFields}
              mergeFieldOptions={mergeFieldOptions}
              register={register}
              sessions={sessions}
              setValue={setValue}
              watch={watch}
            />
          </div>
        }
        className="w-[90%] sm:max-w-screen-md"
        open={showVisibilityModal}
        title="Visibility Logic"
      />
    </div>
  )
}

BadgeElementSettings.defaultProps = {
  activeElement: null,
  admissionItems: null,
  availableCustomFields: null,
  badgeHeight: 0,
  badgeWidth: 0,
  categories: null,
  config: null,
  enabledCustomFields: [],
  sessions: null,
  temporaryGroup: null,
}

BadgeElementSettings.propTypes = {
  activeElement: PropTypes.object,
  admissionItems: PropTypes.object,
  availableCustomFields: PropTypes.object,
  badgeHeight: PropTypes.number,
  badgeWidth: PropTypes.number,
  categories: PropTypes.object,
  copyElement: PropTypes.func.isRequired,
  config: PropTypes.object,
  createGroup: PropTypes.func.isRequired,
  currentPanelIndex: PropTypes.number.isRequired,
  deleteElement: PropTypes.func.isRequired,
  enabledCustomFields: PropTypes.array,
  layers: PropTypes.array.isRequired,
  moveElement: PropTypes.func.isRequired,
  panels: PropTypes.array.isRequired,
  temporaryGroup: PropTypes.array,
  sessions: PropTypes.object,
  setCustomFonts: PropTypes.func.isRequired,
  setGoogleFonts: PropTypes.func.isRequired,
  updateElement: PropTypes.func.isRequired,
}

export default BadgeElementSettings
