import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'
import {
  FunnelIcon as FunnelIconFilled,
  PlusIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid'
import { FunnelIcon } from '@heroicons/react/24/outline'
import _ from 'lodash'

// Images
import Badger from '../../assets/images/badger.svg'
import Lead from '../../assets/images/fetch.svg'
import OrganizationPlaceholder from '../../assets/images/org_placeholder.svg'
import Trackr from '../../assets/images/trackr.svg'

// Components
import { AddEventModal } from '../../components/AddEventModal'
import { Button } from '../../components/Button'
import { DataTable } from '../../components/DataTable'
import { MetricTile } from '../../components/MetricTile'
import { MoneyIcon } from '../../components/MoneyIcon'
import { MultiSelectDropdown } from '../../components/MultiSelectDropdown'
import { StateContainer } from '../../components/StateContainer'
import { TabSelector } from '../../components/TabSelector'
import { TextInput } from '../../components/TextInput'
import { TicketIcon } from '../../components/TicketIcon'

// Store
import { NavigationStoreContext } from '../../stores/NavigationStore'
import { UserStoreContext } from '../../stores/UserStore'

// Service
import { getEvents, getEventsKpis } from '../../services/events.service'

// Utils & Style
import baseColors from '../../utils/colors'
import { handlePagination, toast } from '../../utils/helpers'

/**
 *
 * AdminDashboard
 *
 */
const AdminDashboard = observer(() => {
  // Context
  const { setEventId, updateActiveEntity } = useContext(NavigationStoreContext)
  const { isEEAdmin } = useContext(UserStoreContext)
  const navigate = useNavigate()
  const location = useLocation()

  // State
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [kpis, setKpis] = useState(null)
  const [loadingEvents, setLoadingEvents] = useState(false)
  const [events, setEvents] = useState([])
  const [organizations, setOrganizations] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedOrganizations, setSelectedOrganizations] = useState([])
  const [filter, setFilter] = useState(null)
  const [showEventModal, setShowEventModal] = useState(false)

  // Pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [totalRows, setTotalRows] = useState(0)
  const [perPage, setPerPage] = useState(20)
  const [pages, setPages] = useState(null)

  // Ref
  const searchInputRef = useRef()

  useEffect(() => {
    if (!location.pathname.includes('/dashboard')) navigate('/dashboard', { replace: true })
  }, [])

  const handleErrors = (m) => toast(m, 'error')

  /**
   * Gets the updated event list; updates pagination.
   * @param {string} url
   * @param {boolean} skipLoading
   * @returns list of results
   */
  const getUpdatedEventList = async (url, skipLoading) => {
    const response = await getEvents(
      url,
      handleErrors,
      skipLoading ? () => {} : setLoadingEvents,
      () => {},
    )

    if (response) {
      if (organizations.length === 0) {
        const updatedOrganizations = _.uniqBy(
          _.map(response.results, (r) => ({ name: r.organizerName, id: r.organizerId })),
          'name',
        )
        setOrganizations(updatedOrganizations)
      }
      setTotalRows(response.count)
      setPages({ next: response.next, previous: response.previous })
      setEvents(response.results)
      return response.results
    }

    return []
  }

  useEffect(() => {
    const loadData = async () => {
      const results = await getEventsKpis(setError, setLoading, () => {})
      setKpis(results)
    }

    loadData()
  }, [])

  /**
   * When the filter or the row count changes, get the updated event list.
   */
  useEffect(() => {
    if (filter) {
      getUpdatedEventList(`/events/?limit=${perPage}&${filter}`)
    } else {
      getUpdatedEventList(`/events/?limit=${perPage}`)
    }
  }, [filter, perPage])

  /**
   * Updates the search query based on `search` and `orgs`.
   * @param {string} search
   * @param {Array} orgs
   */
  const updateSearch = (search, orgs) => {
    let updatedFilter = ''
    if (search) updatedFilter = `q=${search}`
    if (orgs !== null && orgs.length > 0) {
      const orgFilter = _.join(
        _.map(orgs, (o) => o.id),
        '&organizer=',
      )

      updatedFilter = `${
        updatedFilter.length > 0 ? `${updatedFilter}&` : ''
      }organizer=${orgFilter}`
    }

    setFilter(updatedFilter)
  }

  const filterEvents = useCallback(_.debounce(updateSearch, 500), [])

  return (
    <div className="relative h-full overflow-x-hidden">
      <StateContainer error={error} loading={loading} stripedBackground>
        <div className="relative flex h-full w-full flex-col rounded-lg bg-background p-3 shadow-md md:h-[85%] md:w-[90%] md:p-4 short:overflow-y-auto">
          {isEEAdmin && (
            <Button
              label="Add Event"
              icon={<PlusIcon className="h-5 sm:h-6" />}
              background="bg-purple border-purple hover:bg-purple-600"
              className="absolute right-4"
              onClick={() => setShowEventModal(true)}
            />
          )}

          {kpis && (
            <TabSelector
              tabs={[
                {
                  name: 'Lead Retrieval',
                  id: 'lead-retrieval',
                  content: (
                    <div className="flex w-full flex-col items-center space-y-2 md:flex-row md:justify-between md:space-x-4 md:space-y-0">
                      <MetricTile
                        data={{
                          icon: <TicketIcon fill={baseColors.purple.DEFAULT} />,
                          label: 'Active Events',
                          value: kpis.activeEventCount,
                        }}
                      />

                      <MetricTile
                        chartLabel="Percent Activated"
                        data={{
                          label: 'Licenses Purchased',
                          value: kpis.activatedLicenseCount,
                          total: kpis.totalLicenseCount,
                        }}
                        type="chart"
                      />

                      <MetricTile
                        data={{
                          icon: <MoneyIcon fill={baseColors.purple.DEFAULT} />,
                          label: 'Lead Retrieval Revenue',
                          value: `${kpis.totalRevenue.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })}`,
                        }}
                      />
                    </div>
                  ),
                },
                {
                  name: 'Badging',
                  id: 'badging',
                  content: (
                    <div className="flex w-full flex-col items-center space-y-2 md:flex-row md:space-x-4 md:space-y-0">
                      <MetricTile
                        data={{
                          icon: <TicketIcon fill={baseColors.purple.DEFAULT} />,
                          label: 'Active Events',
                          value: kpis.activeEventCount,
                        }}
                        width="md:w-[1/2]"
                      />

                      <MetricTile
                        chartLabel="Percent Checked In"
                        data={{
                          label: 'Total Registrations',
                          value: kpis.checkedInAttendeeCount,
                          total: kpis.totalAttendeeCount,
                        }}
                        type="chart"
                        width="md:w-[1/2]"
                      />
                    </div>
                  ),
                },
              ]}
            />
          )}

          <div className="mt-4 flex h-full flex-1 flex-col tall:overflow-y-hidden">
            <TextInput
              className="mb-3 w-full rounded-full py-2.5 pl-10 pr-4 placeholder:font-normal placeholder:text-gray-600 md:w-[450px]"
              icon={<MagnifyingGlassIcon className="ml-2 h-5 text-gray-dark" aria-hidden="true" />}
              id="search"
              endIcon={
                searchTerm ? (
                  <button
                    type="button"
                    onClick={() => {
                      setSearchTerm('')
                      filterEvents('', selectedOrganizations)
                      searchInputRef.current.value = ''
                    }}
                  >
                    <XMarkIcon className="mr-2 h-5 text-gray-dark" aria-hidden="true" />
                  </button>
                ) : null
              }
              name="search"
              onChange={(e) => {
                filterEvents(e.target.value, selectedOrganizations)
                setSearchTerm(e.target.value)
              }}
              placeholder="Looking for something?"
              ref={searchInputRef}
              value={searchTerm}
            />

            <DataTable
              columns={[
                {
                  id: 'name',
                  grow: 2,
                  name: 'Event Name',
                  selector: (row) => row.name,
                  cell: (row) => (
                    <button
                      className="text-black hover:font-bold hover:text-purple"
                      key={`event:${row.id}`}
                      onClick={() => {
                        setEventId(row.id)
                        updateActiveEntity('organization', row.organizerId)
                        navigate(`/organization/${row.organizerId}/event/${row.id}/home`)
                      }}
                      type="button"
                    >
                      {row.name}
                    </button>
                  ),
                  sortable: true,
                  sortBy: 'name',
                  minWidth: '250px',
                },
                {
                  id: 'dates',
                  grow: 1,
                  name: 'Dates',
                  selector: (row) => row.dateRange,
                  sortable: true,
                  sortBy: 'starts_at',
                  minWidth: '180px',
                },
                {
                  id: 'organization',
                  grow: 1.5,
                  name: (
                    <div className="flex items-center">
                      <MultiSelectDropdown
                        customButton={
                          selectedOrganizations.length > 0 ? (
                            <FunnelIconFilled className="mt-0.5 h-3.5" />
                          ) : (
                            <FunnelIcon className="mt-0.5 h-3.5" />
                          )
                        }
                        id="organization-filter"
                        onChange={(o) => {
                          filterEvents(searchTerm, o)
                          setSelectedOrganizations(o)
                        }}
                        options={organizations}
                        style={{
                          left: 0,
                          position: 'absolute',
                          zIndex: 10,
                        }}
                        value={selectedOrganizations}
                      />
                      <div>Organization</div>
                    </div>
                  ),
                  selector: (row) => row.organizerName,
                  cell: (row) => (
                    <button
                      className="flex flex-row place-items-center text-black hover:font-bold hover:text-purple"
                      key={`event:${row.id}:organization`}
                      onClick={() => {
                        updateActiveEntity('organization', row.organizerId)
                        navigate(`/organization/${row.organizerId}/dashboard`)
                      }}
                      type="button"
                    >
                      <div className="mr-2 flex h-8 w-8 items-center justify-center overflow-hidden rounded-full drop-shadow">
                        {row.organizerSignedLogoUrl ? (
                          <img alt="logo" src={row.organizerSignedLogoUrl} className="h-8" />
                        ) : (
                          <img src={OrganizationPlaceholder} alt={row.name} className="h-8" />
                        )}
                      </div>

                      {row.organizerName}
                    </button>
                  ),
                  sortable: true,
                  sortBy: 'organizer__name',
                  minWidth: '200px',
                },
                {
                  id: 'stack',
                  center: true,
                  width: '100px',
                  name: 'Stack',
                  cell: (row) => {
                    const stack = []

                    if (row.enableLeadRetrieval)
                      stack.push(
                        <img
                          alt="lead retrieval"
                          className="h-6"
                          key={`event:${row.id}:lead`}
                          src={Lead}
                        />,
                      )
                    if (row.enableBadging)
                      stack.push(
                        <img
                          alt="badging"
                          className="h-6"
                          key={`event:${row.id}:badging`}
                          src={Badger}
                        />,
                      )
                    if (import.meta.env.VITE_TRACKR_ENABLED === 'true' && row.enableTrackr)
                      stack.push(
                        <img
                          alt="tracker"
                          className="h-7"
                          key={`event:${row.id}:tracker`}
                          src={Trackr}
                        />,
                      )

                    return (
                      <div className="flex w-full items-center justify-center space-x-2">
                        {stack}
                      </div>
                    )
                  },
                },
              ]}
              data={events}
              defaultSortFieldId="dates"
              defaultSortAsc={false}
              onChangePage={(page) =>
                handlePagination(
                  page,
                  currentPage,
                  perPage,
                  totalRows,
                  pages,
                  setCurrentPage,
                  getUpdatedEventList,
                  '/events/?limit=',
                  filter,
                )
              }
              onChangeRowsPerPage={async (currentRowsPerPage) => setPerPage(currentRowsPerPage)}
              onSort={(column, direction) => {
                const d = direction === 'asc' ? '' : '-'
                const url = `/events/?order_by=${d}${column.sortBy}&limit=${perPage}`
                getUpdatedEventList(url)
              }}
              pagination
              paginationPerPage={perPage}
              paginationRowsPerPageOptions={[10, 15, 20, 30, 50]}
              paginationTotalRows={totalRows}
              paginationServer
              progressPending={loadingEvents}
              sortServer
            />
          </div>
        </div>
      </StateContainer>

      {showEventModal && (
        <AddEventModal
          loadData={() => getUpdatedEventList(`/events/?expand=organizer&limit=${perPage}`)}
          organizations={_.map(organizations, (o) => ({ id: o.id, label: o.name }))}
          setShowEventModal={setShowEventModal}
        />
      )}
    </div>
  )
})

export default AdminDashboard
