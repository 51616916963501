import React, { useContext, useEffect, useState } from 'react'
import { twMerge as mergeClassNames } from 'tailwind-merge'
import dayjs from 'dayjs'
import { observer } from 'mobx-react'

// Components
import { AddEventModal } from '../../components/AddEventModal'
import { Button } from '../../components/Button'
import { EditIcon } from '../../components/EditIcon'
import { EventHeader } from '../../components/EventHeader'
import { EventKPITile } from '../../components/EventKPITile'
import { StateContainer } from '../../components/StateContainer'
import { TasklistTile } from '../../components/TasklistTile'

// Images
import Check from '../../assets/images/check.svg'

// Stores
import { NavigationStoreContext } from '../../stores/NavigationStore'
import { UserStoreContext } from '../../stores/UserStore'

// Service
import { getEvent, getEventKpis } from '../../services/events.service'

// Utils & Style
import baseColors, {
  configureActiveBackgroundStyles,
  configureActiveTextStyle,
} from '../../utils/colors'
import { toast } from '../../utils/helpers'

/**
 *
 * OrganizerEventHome
 *
 */
const OrganizerEventHome = observer(() => {
  // Context
  const { event, eventId, organization, organizationId, setEvent } =
    useContext(NavigationStoreContext)
  const { isEEUser } = useContext(UserStoreContext)

  // State
  const [showEventModal, setShowEventModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [KPI, setKPI] = useState(null)
  const [graphRevenueByMonth, setGraphRevenueByMonth] = useState([])

  const handleErrors = (m) => toast(m, 'error')

  useEffect(() => {
    const getUpdatedKpis = async () => {
      const response = await getEventKpis(event?.id, handleErrors, setLoading)

      if (response) {
        const graphData = Object.entries(response.revenueByMonth).map((r) => ({
          x: dayjs(`2024-${r[0]}`).format('MMM'),
          y: r[1],
        }))

        setGraphRevenueByMonth(graphData || [])
        setKPI(response)
      }
    }

    getUpdatedKpis()
  }, [])

  return (
    <div className="h-full w-full">
      <StateContainer loading={loading}>
        <div className="relative flex h-full w-full flex-col space-y-3 overflow-y-auto p-3">
          <div className="flex w-full flex-col items-start justify-between sm:flex-row sm:items-center">
            <div className="flex flex-row space-x-3 sm:hidden">
              <div
                className={mergeClassNames(
                  'flex flex-row place-items-center space-x-1.5 rounded-full pl-3 shadow-sm',
                  configureActiveBackgroundStyles(event),
                  event?.status === 'Active' ? 'pr-4' : 'pr-3',
                )}
              >
                {event?.status === 'Active' ? (
                  <img alt="Check" className="h-5" src={Check} />
                ) : null}

                <span
                  className={mergeClassNames(
                    'text-xs font-medium lg:text-sm',
                    configureActiveTextStyle(event),
                  )}
                >
                  {event?.status}
                </span>
              </div>

              {isEEUser && (
                <Button
                  background="bg-white"
                  icon={<EditIcon className="h-[14px] fill-gray-600" />}
                  label="Edit"
                  size="md"
                  outlined
                  onClick={() => setShowEventModal(true)}
                />
              )}
            </div>

            <EventHeader event={event} />

            <div className="hidden flex-row space-x-3 sm:flex">
              <div
                className={mergeClassNames(
                  'flex flex-row place-items-center space-x-1.5 rounded-full px-3 shadow-sm',
                  configureActiveBackgroundStyles(event),
                  event?.status === 'Active' && 'pr-4',
                )}
              >
                {event?.status === 'Active' && <img alt="Check" className="h-5" src={Check} />}
                <span
                  className={mergeClassNames(
                    'text-xs font-medium lg:text-sm',
                    configureActiveTextStyle(event),
                  )}
                >
                  {event?.status}
                </span>
              </div>

              {isEEUser && (
                <Button
                  background="bg-white"
                  icon={<EditIcon className="h-[14px] fill-gray-600" />}
                  label="Edit"
                  size="md"
                  outlined
                  onClick={() => setShowEventModal(true)}
                />
              )}
            </div>
          </div>

          <div className="flex h-auto w-full flex-col space-y-8 md:grid md:grid-cols-2 md:gap-8 md:space-y-0 lg:grid-cols-3 lg:grid-rows-2">
            <div className="h-max w-full shrink-0 md:col-span-2 md:row-span-1 md:h-full lg:col-span-1 lg:row-span-2">
              <TasklistTile
                eventUrlPrefix={`/organization/${organizationId}/event/${eventId}`}
                tasklist={event?.tasklistItems}
              />
            </div>

            <div className="w-full md:col-span-1 md:row-span-1">
              <EventKPITile
                className="h-full"
                data={{
                  main: `$${KPI?.totalRevenue.toLocaleString()}`,
                  data: [
                    {
                      id: 1,
                      color: baseColors.purple.DEFAULT,
                      data: graphRevenueByMonth,
                    },
                  ],
                }}
                label="Total Revenue"
                subLabel="Total revenue from license purchases"
                type="line"
              />
            </div>

            <div className="w-full md:col-span-1 md:row-span-1">
              <EventKPITile
                className="h-full"
                data={{
                  display: 'total',
                  data: [
                    {
                      color: baseColors.teal.DEFAULT,
                      textColor: 'text-teal',
                      id: 'Licenses',
                      value: KPI?.totalLicenseCount,
                      type: 'total',
                    },
                    {
                      color: baseColors.purple.DEFAULT,
                      textColor: 'text-purple',
                      id: 'Rentals',
                      value: KPI?.rentalDeviceCount,
                      type: 'total',
                    },
                  ],
                }}
                label="Total Purchases"
                subLabel="Total number of licenses and devices purchased"
                type="pie"
              />
            </div>

            <div className="w-full md:col-span-1 md:row-span-1">
              <EventKPITile
                className="h-full"
                data={{
                  main: KPI?.totalAttendeeCount,
                }}
                label="Total Attendees"
                subLabel="The total number of attendees"
                type="default"
              />
            </div>

            <div className="w-full md:col-span-1 md:row-span-1">
              <EventKPITile
                className="h-full"
                data={{
                  display: 'percent',
                  data: [
                    {
                      color: baseColors.teal.DEFAULT,
                      textColor: 'text-teal',
                      id: 'Attendees',
                      value: KPI?.checkedInAttendeeCount,
                      type: 'percent',
                    },
                  ],
                  total: KPI?.totalAttendeeCount,
                }}
                label="Attendees Checked In"
                subLabel="Number of attendees who have checked in"
                type="pie"
              />
            </div>
          </div>
        </div>
      </StateContainer>

      {showEventModal && (
        <AddEventModal
          editEvent={event}
          loadData={async () => {
            const updatedEvent = await getEvent(
              organizationId,
              eventId,
              () => {},
              () => {},
              () => {},
            )
            if (updatedEvent) setEvent(updatedEvent)
          }}
          organization={organization}
          setShowEventModal={setShowEventModal}
        />
      )}
    </div>
  )
})

export default OrganizerEventHome
